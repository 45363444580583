<div class="heading-container">
  <div class="back-action-container">
      <div class="back-button"><a href="javascript:void(0);" (click)="backToList()"><span class="material-icons"> arrow_back </span>Commitments</a></div>
      <h1 class="text-truncate">Commitments Intake</h1>
  </div>
</div>

<div class="row m-0 mb-0" *ngIf="commitmentDetails">
    <div id="accordion" class="accordion collapse-view col-md-12 accordian-responses grid-wrapper-section">
        <div id="collapseOne" data-parent="#accordion" class="collapse show">
            <div class="biller-survey">
                <div>
                    <ul>
                        <li *ngIf="commitmentDetails?.products && commitmentDetails.products.length > 0">Product Name<span class="info">
                            {{commitmentDetails.products[0].productTitle}}</span>
                        </li>
                        <li *ngIf="commitmentDetails?.clientName">Client Name<span class="info">{{commitmentDetails?.clientName}}</span></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="main-container-sub responsive-container separate-form-container m-0">
    <div class="mb-3">
        <h3>Commitment</h3>
    </div>
    <div class="pt-4">
        <div class="alert alert-success alert-dismissible" *ngIf="isSuccess">
          <span class="iconLabel" aria-label="sucess"></span>
          <i class="material-icons" aria-hidden="true">check_circle</i>
          <p> {{successMsg}} <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isSuccess=false"> × </button> </p>
        </div>
        <div class="alert alert-dismissible alert-danger" *ngIf="isError">
          <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="isError=false"> × </button>
          <i class="material-icons">warning</i>
          <p [innerHtml]="errorMsg"></p>
        </div>
    </div>
    <app-commitment-intake-form
        [readOnly]="readOnly"
        [originalCommitmentDetails]="commitmentDetails"
        [commitmentProjectKey]="commitmentProjectKey" 
        [commitmentForm]="commitmentForm"
        (onSaved)="updateCommitment($event)"
        [isSubmitted]="isSubmitted"
        (onBackToList)="backToList()"></app-commitment-intake-form>
</div>