<nav role="navigation" class="navbar navbar-expand-md navbar-light">
  <a class="navbar-brand" href="javascript:void(0);" routerLink="/dashboard" *ngIf="!isClientExecutiveUserOnly">
    <img alt="fiserv" src="assets/images/Fiserv Logo.png">
  </a>
  <a class="navbar-brand" href="javascript:void(0);" routerLink="/{{commitmentDashboardPath}}/"
    *ngIf="isClientExecutiveUserOnly">
    <img alt="fiserv" src="assets/images/Fiserv Logo.png">
  </a>

  <div class="" id="inverseNavbarSupportedContent" style="margin-right: 0;">
    <ul class="navbar-nav navbar-main">
      <li class="nav-item dropdown show navigation">
        <button class="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse"
          aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-collapse collapse" id="navbarCollapse">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item initiate-data-gathering-width"
              *ngIf="isClientExecutiveUserOnly || clientCommitmentDashboard">
              <a class="nav-link" href="javascript:void(0);" routerLink="/{{commitmentDashboardPath}}/"
                routerLinkActive="active">
                {{content['commitmentDashboard']}}
              </a>
            </li>
            <li class="nav-item" *ngIf="showAdminLink">
              <a class="nav-link" href="javascript:void(0);" routerLink="/admin" routerLinkActive="active"
                (click)="updateSubNavHeader()">Administration </a>
            </li>
            <li class="nav-item" *ngIf="!isClientUser">
              <a class="nav-link" href="javascript:void(0);" routerLink="/self-service"
                routerLinkActive="active">{{content['selfServiceHeader']}} </a>
            </li>

          </ul>

        </div>
      </li>
      <li class="nav-item dropdown right-side-dropdown-menu">

        <div class="utilities-wrapper">

          <div class="navbar-collapse " id="navbarCollapseForms">
            <section style="margin-right: 30px;">
              <app-theme-switcher></app-theme-switcher>
            </section>
            <ul class="navbar-nav">
              <li class="nav-item" style="padding-left: 10px;">
                <div data-placement="top" class="tag" title="Help Topic">
                  <button class="btn nav-link ic-live-help-image" data-toggle="modal" data-target="#helpModal"
                    aria-label="Help Topic">
                  </button>
                </div>
              </li>

              <li class="nav-item quick-access-icon" *ngIf="(clientCommitmentQuickLinks || showClientDashboardLink)">
                <!-- *ngIf="(clientCommitmentQuickLinks || showClientDashboardLink) && !isClientExecutiveUserOnly"> -->
                <div data-placement="top" class="tag" title="Quick Access" id="quickLinksIcon"
                  (click)="quickLinks($event)">
                  <a class="quick-access-btn" href="javascript:void(0);" [ngClass]="{'blueThemeMode':(blueThemeMode)}" aria-label="Icon with Quick access links">
                    <i class="material-icons mat-icon-button quick-access">flash_on</i>
                  </a>
                  <div class="SiteNav quick-access-links d-none" id="quickLinkID">
                    <div class="SiteNavWrapper">
                      <ul class="siteNavList_list">
                        <li class="quick-access-menuitem" *ngIf="clientCommitmentQuickLinks && clientCommitmentId > 0">
                          <a class="quick-access_link" routerLink="/self-service/{{commitmentIntakesPath}}"
                            (click)="onCommitmentIntakeClick($event)">
                            <span class="siteNavItem_labelContainer">
                              Commitment Intake
                            </span>
                          </a>
                        </li>
                        <li class="quick-access-menuitem">
                          <a class="quick-access_link" target="_blank" href="{{summaryReportUrl}}">
                            <span class="siteNavItem_labelContainer">
                              Commitment Summary Report
                            </span>
                          </a>
                        </li>
                        <li class="quick-access-menuitem" *ngIf="showClientDashboardLink">
                          <a class="quick-access_link" href="javascript:void(0);" routerLinkActive="active"
                            routerLink="/{{commitmentDashboardPath}}">
                            <span class="siteNavItem_labelContainer">
                              Client Dashboard
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="nav-item form-responses-width user-dropdown-container">
          <a href="javascript:void(0);" class="nav-link dropdown-toggle user-dropdown" id="loggedInUser">
            <img src="assets/images/thumbnail-150x150.png" alt="" class="thumbnail img-w28 mr-1">
            <span class="uname">
              {{loggedInUser}}
              <div *ngIf="!isClientUser" class="role">{{userOrg}} </div>
            </span>

            <i class="material-icons caret-custom">expand_more</i>
          </a>
          <div (mouseenter)="showMenu=true" class="SiteNav fadeSiteNav user-info-panel" id="userInfo">
            <!-- <div class="SiteHeaderArrow" data-js-target="SiteHeader.arrow" aria-hidden="true"></div> -->
            <div class="SiteNavWrapper">
              <ul class="siteNavList_list">
                <li class="siteNavItem-isArrowHidden">
                  <a class="siteNavItem_link" href="javascript:void(0);" (click)="logout()">
                    <span class="siteNavItem_labelContainer">
                      <i class="fa fa-sign-out mr-1"></i> {{content['headerSignOut']}}
                    </span>
                  </a>
                </li>
                <li class="siteNavItem-isArrowHidden" *ngIf="hasMultipleOrgs">
                  <a class="siteNavItem_link" href="javascript:void(0);" data-toggle="modal"
                    data-target="#switchOrgModal">
                    <span class="siteNavItem_labelContainer">
                      <i class="material-icons switch-org-icon">compare_arrows</i> Switch Org
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>

<!-- Notification Modal -->
<div class="modal full-width fade position-right" id="notificationModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content" style="height: 100%;">
      <div class="modal-header white-modal-header">
        <h4 class="modal-title notification-image" id="myModalLabel">Notifications
          <span *ngIf="notificationCounts > 0" class="badge rounded-pill bg-danger">{{ notificationCounts }}</span>
        </h4>
        <button type="button" class="close" data-dismiss="modal" tabindex="0" autofocus=""
          aria-label="Close popup"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <div *ngIf="userNofitications && userNofitications.length > 0">
          <div *ngFor="let item of userNofitications | slice:0:5; let i = index;" href="javascript:void(0);"
            data-dismiss="modal" (click)="viewUserNotification(item)" style="cursor: pointer;"
            class="container description-image">
            <h5 class="d-flex justify-content-start"><span [ngClass]="getNotificationStatusCss(item)"
                style="margin-top: 0.4rem"></span>{{item.title}}</h5>
            <p style="margin-top:-10px"><small>{{item.createdOn | date: 'MM/dd/yyyy HH:mm' || 'N/A'}}</small></p>
            <p class="description text-truncate" title="{{item.messages}}" [innerHTML]="item.messagesForDisplay"></p>
          </div>
          <br>
          <div class="d-flex justify-content-between">
            <div>
              <a href="javascript:void(0);" (click)="navigateToUserNotificationPage()" data-dismiss="modal">All
                Notifications
                <span class="arrow-right-image small-image"></span>
              </a>
            </div>
            <div *ngIf="notificationCounts > 0">
              <a href="javascript:void(0);" (click)="markAllAsRead()">Mark all as read
                <span class="arrow-right-image small-image"></span>
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="!userNofitications || userNofitications.length == 0">
          <span class="text-center">No results found</span>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TO DO -->
<div class="modal full-width fade position-right" id="todosModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header white-modal-header">
        <h4 class="modal-title qr-code-image" id="myModalLabel">My To Dos
          <span class="badge rounded-pill bg-info text-white">2</span>
        </h4>
        <button type="button" class="close" data-dismiss="modal" tabindex="0" autofocus=""
          aria-label="Close popup"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <div class="container description-image">
          <h5>New client created. Review pending.</h5>
          <p class="description">Note: Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Suspendisse vel orci vitae mi fermentum fermentum. Nunc et rutrum sem. Sed faucibus
            iaculis urna, ut facilisis ligula.</p>
          <a href="#">Create Now
            <span class="arrow-right-image small-image"></span>
          </a>
        </div>
        <div class="container description-image">
          <h5>New client created. Review pending.</h5>
          <p class="description">Note: Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Suspendisse vel orci vitae mi fermentum fermentum. Nunc et rutrum sem. Sed faucibus
            iaculis urna, ut facilisis ligula.</p>
          <a href="#">Create Now
            <span class="arrow-right-image small-image"></span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Help -->
<div class="modal full-width fade position-right" id="helpModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header white-modal-header">
        <h4 class="modal-title ic-live-help-image"><span>Help</span></h4>
        <button type="button" class="close" data-dismiss="modal" tabindex="0" autofocus=""
          aria-label="Close popup"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">
        <div class="help-recommended-topics">
          <h4></h4>                    
          <div class="help-body">
            <a href="javascript:void(0);" href="{{clientDashboardFAQs}}" target="_blank" class="description-image"> Client Commitment Tracker Dashboard - FAQ</a>
            <a href="javascript:void(0);" href="{{cctUserManual}}" target="_blank" class="description-image"> Client Commitment Tracker Dashboard - User Manual</a> 
            <a *ngIf="!isClientUser" href="javascript:void(0);" href="{{cctUserGuide}}" target="_blank" class="description-image"> Client Commitment Tracker Internal User Guide</a>       
          </div>              
        </div>        
      </div>
    </div>
  </div>
</div>

<!-- Search -->
<!-- <div class="modal full-width fade position-right" id="searchModal" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" style="display: none;" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header white-modal-header">
        <i style="margin-left: -10px;" class="material-icons">search</i>
        <h4 style="margin-left: -30px;" class="modal-title"><span>Quick Search</span></h4>       
      </div>
      <div class="modal-body"> 
        <div class="help-recommended-topics">
          <h4>Recommended results</h4>
          <div class="help-body">
            <a href="{{clientDashboardFAQs}}" target="_blank" class="description-image"> Client Commitment Tracker Dashboard - FAQ</a>
            <a href="{{cctUserManual}}" target="_blank" class="description-image"> Client Commitment Tracker Dashboard - User Manual</a>             
          </div>          
        </div>        
      </div>
    </div>
  </div>
</div> -->

<!--Starts Switch Org Modal dialog-->
<div id="switchOrgModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  class="modal full-width fade position-right switch-org-modal" style="display: none;" aria-hidden="true">
  <div role="document" class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header white-modal-header">
        <h4 class="modal-title switch-org-modal-image"><span>Switch Organization</span></h4>
        <button type="button" data-dismiss="modal" tabindex="0" autofocus="" aria-label="Close popup"
          class="close"><span aria-hidden="true">×</span></button>
      </div>
      <div class="modal-body">

        <div class="org-structure">
          <h4>Select an Organization to Switch </h4>
          <div class="org-body" *ngFor="let org of organizationContext">
            <div class="description-image fdl-2" *ngIf="org.isCurrent">
              {{ org.organizationTitle }}
              <span class="badge badge-primary-dark"><span class="material-icons"> check </span> Current </span>
            </div>
            <div class="description-image fdl-2" *ngIf="!org.isCurrent">
              {{ org.organizationTitle }}
              <button class="btn btn-primary-dark" (click)="switchUserOrg(org.organizationId)"> Switch</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--Ends Switch Org Modal dialog-->
