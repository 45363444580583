import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../services/user.service';
import { PermissionsService } from '../services/permissions.service';
import { map } from 'rxjs/operators';
import { allowedPermissions, COMMITMENT_DASHBOARD_PATH, ROLES } from './constants';
import { IUser } from '../shared/user';

@Injectable({
  providedIn: 'root'
})
export class UserInitializeGuard implements CanActivate, CanLoad {
  constructor(private userService: UserService, private permissionService: PermissionsService, private router: Router) { };
  canLoad(route: Route, segments: UrlSegment[]): boolean | Observable<boolean> | Promise<boolean> {
    const user = this.userService.getUserListData();
    if (!user) {
      return this.userService.callUserListData().pipe(map((data) => {
        this.userService.setUserListData(data);
        this.permissionService.setUserData(data);
        let usersPermission = [];
        data.permissions.forEach((ele) => {
          let permission: any = allowedPermissions[ele];
          for (let perm in permission) {
            if (permission[perm])
              usersPermission[perm] = permission[perm]
          }
        });

        this.userService.setPermissions(usersPermission);
        return true;

      }));
    }
    else {
      return of(true);
    }
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    const user = this.userService.getUserListData();
    if (!user) {
      return this.userService.callUserListData().pipe(map((data) => {
        // #274199: If a client user has only ‘Client Executive’ role, then user should re-direct to ‘Client Dashboard’ page
        if (this.isClientExecutiveUserOnly(data)) {
          this.router.navigate([`/${COMMITMENT_DASHBOARD_PATH}`]);
          return true;
        }
       
        this.userService.setUserListData(data);
        this.permissionService.setUserData(data);
        let usersPermission = [];
        data.permissions.forEach((ele) => {
          let permission: any = allowedPermissions[ele];
          for (let perm in permission) {
            if (permission[perm])
              usersPermission[perm] = permission[perm]
          }
        });

        this.userService.setPermissions(usersPermission);
        return true;
      }));
    }
    else {
      return of(true);
    }
  }

  isClientExecutiveUserOnly(user: IUser): boolean {
    return (user.roles.length === 1 && user.roles[0].roleTitle === ROLES.Client_Executive);
  }
}
