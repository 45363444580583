import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/internal/operators/take';
import { CommitmentIntakeService } from 'src/app/services/commitment-intake.service';
import { IQuery } from 'src/app/shared/query';
import { CommitmentIntakeSearchTag } from './models/commitment-intake-search-tag';
import { environment } from 'src/environments/environment';
import { IPager } from 'src/app/shared/pagination';
import { UserService } from 'src/app/services/user.service';
import { ICommitmentIntakeDto, ICommitmentIntakePageTemplateAccessDTO } from './models/commitment-intake-d-t-o';
import { PermissionsService } from 'src/app/services/permissions.service';
import { CommitmentIntakeSearchType } from './enums/commitment-intake-search-type.enum';
import { AlertInput } from './models/alert-input';
import { AlertType } from './enums/alert-type.enum';
import { CommitmentIntakeSearchLocalStorage } from './models/commitment-intake-search-local-storage';
import { COMMITMENT_INTAKE_FILTER, COMMITMENT_INTAKES_TAB_NAME, IS_PAGE_TEMPLATE_ADMIN } from 'src/app/core/constants';
import { forkJoin } from 'rxjs';
import { CommitmentIntakeSuggestionDisplayNames, CommitmentIntakeSuggestionFields } from './enums/commitment-intake-filter-fields.enum';
import { Router } from '@angular/router';
import { IUser } from 'src/app/shared/user';
import { PreferenceService } from 'src/app/services/preference.service';
import { ManageCommitmentListService } from './services/manageCommitmentList.service';
declare var $: any;

@Component({
  selector: 'app-commitment-intakes.component',
  templateUrl: './commitment-intakes.component.html',
  styleUrls: ['./commitment-intakes.component.css'],
})
export class CommitmentIntakesComponent implements OnInit {
  public isError: boolean = false;
  public alertType = AlertType;
  public selectedTabName = 'All';
  public searchFields: any = [];
  public queryParam: IQuery = {};
  public filterColumn = '';
  public isAsc = true;

  public tabList: string[] = [];
  public commitmentIntakeList: ICommitmentIntakeDto[] = [];
  public pagerObject: IPager;
  public showSubmitNewCommitmentsIntakeDrawer = false;
  public isReset = false;
  public isAdmin = false;
  public isCCTAdmin = false;
  public isSalesExecutive = false;
  public isRestrictedAdmin = false;
  public isRelationshipManager = false;
  public isAccountManager = false;
  public pageTemplateAdmin = false;
  public pageTemplateReadAdmin = false;
  public blueThemeMode = false;
  public columnSettingErrorMsg: string;
  public columnSettings: { columnPreferences: any, allColumnList: any };
  public alertInput: AlertInput = {
    alertType: AlertType.Success,
    message: ''
  };
  
  private userData: IUser;
  private searchCriteria: CommitmentIntakeSearchTag[] = [];
  private readonly _validDatasourceTabNames = ['CIMS', 'Clarity', 'Manual', 'Servicenow', 'eCRM' ];
  public isCreateNewOneView = false;
  public isEditOneView = false;
  public canEditOneView = false;
  public selectedOneViewId = null;

  constructor(
    private router: Router,
    private userService: UserService,
    private permission: PermissionsService,
    private preferenceService: PreferenceService,
    private _commitmentIntakeService: CommitmentIntakeService,
    private _manageCommitmentListService: ManageCommitmentListService
  ) { 
    this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r);
  }

  getTabActiveClass(tabName: string) {
    if (this.selectedTabName === tabName) {
      return 'active';
    }

    return '';
  }

  ngOnInit(): void {
    this.initQueryParams();
    this.userData = this.userService.getUserListData();
    this.isAdmin = this.userData.isAdmin;
    this.isCCTAdmin = this.permission.isCCTAdmin();
    this.isSalesExecutive = this.permission.isSalesExecutive();
    this.isRestrictedAdmin = this.permission.isRestrictedAdmin();
    this.isRelationshipManager = this.permission.isRelationShipManager();
    this.isAccountManager = this.permission.isAccountManager();
    this.selectedTabName = localStorage.getItem(COMMITMENT_INTAKES_TAB_NAME) ?? 'All';

    if (this.isFromViewCancelling()) {
      const commitmentIntakePage = this._manageCommitmentListService.commitmentIntakePage;
      this.pageTemplateAdmin = commitmentIntakePage.pageTemplateAdmin;
      this.pageTemplateReadAdmin = commitmentIntakePage.pageTemplateReadAdmin;

      this.columnSettings = commitmentIntakePage.columnSettings;
      
      this.commitmentIntakeList = commitmentIntakePage.commitmentIntakeList;
      this.pagerObject = commitmentIntakePage.pagerObject;
      this.setQueryOrder();
      
      // For search by fields
      this.searchFields = commitmentIntakePage.searchFields;
      this.loadQueryParamsFromStorage();
      this.populateTabList();
    }
    else {
      this.getPageTemplateAccess();
      this.loadCommitmentIntakes();
      this.fetchFilterSuggestions();
      if (!this.columnSettings) {
        this.getColumnPreferences();
      }
    }
  }

  private initQueryParams(): void {
    this.queryParam = Object.assign(
      {},
      {
        pageNumber: 1,
        pageSize: environment.pageSize,
      }
    );
  }

  private loadQueryParamsFromStorage() {
    const storedUserFiltering = localStorage.getItem(COMMITMENT_INTAKE_FILTER);
    if (storedUserFiltering) {
      const userFilteringData = <CommitmentIntakeSearchLocalStorage>JSON.parse(storedUserFiltering);
      const hasSearchFields = Object.keys(userFilteringData.queryParam.searchFields).length > 0;
      if (userFilteringData.searchCriteria.length > 0 || hasSearchFields) {
        this.searchCriteria = userFilteringData.searchCriteria;
        this.queryParam = userFilteringData.queryParam;

        if (this.queryParam?.orderBy) {
          const splittingOrder = this.queryParam?.orderBy.split(' ');
          this.isAsc = false;
          this.filterColumn = splittingOrder[0];
          if (splittingOrder[1]) {
            this.isAsc = splittingOrder[1] !== 'desc';
          }
        }
      }
    }

    if (this.selectedTabName === 'All') {
      this.queryParam.isAllSelected = true;
    } else if (this.selectedTabName === 'Tracked') {
      this.queryParam.searchFields.isTracked = [true];
    } else if (this.selectedTabName === 'Presale') {
      this.queryParam.isPresaleSelected = true;
    }
  }

  private loadCommitmentIntakes(): void {
    this.loadQueryParamsFromStorage();
    this.searchFromList(this.queryParam);
  }

  setQueryOrder() {
    if (this.queryParam?.orderBy) {
      const splittingOrder = this.queryParam?.orderBy.split(' ');
      this.isAsc = false;
      this.filterColumn = splittingOrder[0];
      if (splittingOrder[1]) {
        this.isAsc = splittingOrder[1] !== 'desc';
      }
    }
    else {
      this.filterColumn = '';
      this.isAsc = true;
    }
  }

  searchFromList(queryParam: IQuery) {
    this.queryParam = queryParam;
    this.getCommitmentList();
    const storedUserFiltering = localStorage.getItem(COMMITMENT_INTAKE_FILTER);
    if (storedUserFiltering) {
      const userFilteringData = <CommitmentIntakeSearchLocalStorage>JSON.parse(storedUserFiltering);
      if (userFilteringData.searchCriteria.length > 0) {
        const searchLocalStorage: CommitmentIntakeSearchLocalStorage = {
          searchCriteria: this.searchCriteria,
          queryParam: this.queryParam
        }

        localStorage.setItem(COMMITMENT_INTAKE_FILTER, JSON.stringify(searchLocalStorage));
      }
    }

    this.setQueryOrder();
  }
  
  public onSearchClicked(event: { searchCriteria: CommitmentIntakeSearchTag[], shouldReset?: boolean, isTabClicked?: boolean }) {
    const { isTabClicked, shouldReset } = event;
    if (!isTabClicked && shouldReset) {
      this.isReset = true;
    }

    this.setAlertMsgToEmpty();

    let orderBy: any;
    if (this.queryParam?.orderBy) {
      orderBy = this.queryParam.orderBy;
    }

    this.initQueryParams();
    const { searchCriteria } = event;
    if (searchCriteria && searchCriteria.length > 0) {
      this.queryParam.orderBy = orderBy;
    }

    if (this.queryParam?.orderBy) {
      const splittingOrder = this.queryParam?.orderBy.split(' ');
      this.isAsc = false;
      this.filterColumn = splittingOrder[0];
      if (splittingOrder[1]) {
        this.isAsc = splittingOrder[1] !== 'desc';
      }
    }
    
    if (shouldReset) {
      this.filterColumn = '';
      this.isAsc = false;
    }

    const searchCriteriaFromForm = searchCriteria.filter(r => r.fieldName !== 'Datasource');
    if (this._validDatasourceTabNames.indexOf(this.selectedTabName) >= 0) {
      const searchCriteria: CommitmentIntakeSearchTag = {
        type: 'Data Source',
        title: this.selectedTabName,
        fieldName: 'Datasource',
        typeEnumForSort: CommitmentIntakeSearchType.Datasource
      };
      searchCriteriaFromForm.push(searchCriteria);
    }

    this.commitmentIntakeList = [];
    const searchFields: any = {};
    searchCriteriaFromForm.forEach((item) => {
      const fielName = item.fieldName;
      const value = item.title === '(Blanks)' ? '' : item.title;
      if (!searchFields[fielName]) {
        searchFields[fielName] = [value];
      } else {
        searchFields[fielName].push(value);
      }
      
      if (fielName === 'Status' && value === 'Cancelled') {
        searchFields[fielName].push('Cancelled');        
      }

      if (fielName === 'IsTracked') { // This section is temprorarry till we fix the issue from database
        if (searchFields[fielName].length == 1) {
          const isTracked = searchFields[fielName][0] === 'Tracked';
          searchFields[fielName] = [isTracked];
        }
        else if (searchFields[fielName].length > 1) {
          searchFields[fielName] = [true, false];
        }
      }
    });
    
    switch(this.selectedTabName) {
      case 'All':
        this.queryParam.isAllSelected = true;
        break;
      case 'Tracked':
        searchFields.isTracked = [true];
        break;
      case 'Presale':
        this.queryParam.isPresaleSelected = true;
        break;
    }
    this.queryParam.searchFields = searchFields;
    this.searchCriteria = searchCriteriaFromForm;
    
    const commitmentIntakeSearchData: CommitmentIntakeSearchLocalStorage = {
      searchCriteria: this.searchCriteria,
      queryParam: this.queryParam
    }
    localStorage.setItem(COMMITMENT_INTAKE_FILTER, JSON.stringify(commitmentIntakeSearchData));
    this.getCommitmentList();
  }

  private fetchFilterSuggestions() {
    const fieldNames = Object.values(CommitmentIntakeSuggestionFields);
    // const requests = [];
    const defaultSortedStatusIntake = [
      "Not Started",
      "In Planning",
      "In Progress",
      "Delivered",
      "Completed",
      "Fiserv - On Hold",
      "Client - On Hold",
      "On Hold",
      "Cancelled", 
      null, 
      ""
    ];    
 
    const searchFieldNames = Object.values(CommitmentIntakeSearchType).filter((item) => {
      return isNaN(Number(item));
    });
    const suggestionFields = searchFieldNames.map(fielName => {
      return { 
        loading: true,
        fieldDisplayName: CommitmentIntakeSuggestionDisplayNames[fielName], 
        fieldName: fielName, 
        fieldSuggestions: [],
        originalSuggestions: []
      };
    });
    
    this.searchFields = [...suggestionFields];
    fieldNames.forEach(fieldName => {
      const suggestionField = suggestionFields.find(suggestionField => suggestionField.fieldName === fieldName);
      if (fieldName !== CommitmentIntakeSuggestionFields.isTracked && suggestionField) {
        this._commitmentIntakeService.getFilterSuggestionByField(fieldName, true).subscribe(response => {
          const body = response.body as any;
          if (!body) {
            return;
          }

          if (body.fieldName === CommitmentIntakeSuggestionFields.status) {
            const statusList = [];
            defaultSortedStatusIntake.forEach(st => {
              if (body.values.some(x => x == st)){
                statusList.push(st);
              }
            });
            body.values = statusList;
          }
          if (body) {
            const suggestions = this._manageCommitmentListService.formatSuggestions(body);
            suggestionField.fieldSuggestions = suggestions;
            suggestionField.originalSuggestions = suggestions;
            suggestionField.loading = false;
          }
          
          if (fieldName === CommitmentIntakeSuggestionFields.datasource) {
            this.populateTabList();
          }
        }, (error) => {
          this.alertInput.alertType = AlertType.Error;
          this.alertInput.message = `Form field '${fieldName}' suggestion list can't be loaded. ${error}`;
        });
      }
      else if (suggestionField) {
        const suggestions = ['Tracked', 'Untracked'];
        suggestionField.fieldSuggestions = suggestions;
        suggestionField.originalSuggestions = suggestions;
        suggestionField.loading = false;
      }
    });
  }

  private populateTabList() {
    const dataSourceValues = this.searchFields.find(x => x.fieldName == 'Datasource').fieldSuggestions;
      this.tabList = dataSourceValues.filter((r) => r && r.trim() !== '');
      this.rePopulateTabList('ServiceNow');
      this.rePopulateTabList('servicenow');
      this.rePopulateTabList('cims');
      this.rePopulateTabList('clarity');
      this.rePopulateTabList('manual');
      this.rePopulateTabList('ecrm');
      const validTab = this.isValidTabList();
      if (validTab) {
        const missingTabs = this._validDatasourceTabNames.filter(t => this.tabList.indexOf(t) < 0);
        this.tabList = [
          ...this.tabList,
          ...missingTabs
        ];
      }
      this.tabList.sort((a, b) => this._validDatasourceTabNames.indexOf(a) - this._validDatasourceTabNames.indexOf(b));
  }

  private rePopulateTabList(srcTabName: string) {
    const index = this.tabList.indexOf(srcTabName);
    const destTabName = this._validDatasourceTabNames.find(x => x.toLocaleLowerCase() === srcTabName.toLocaleLowerCase());
    if (index > -1 && !this.tabList.includes(destTabName)) {
      this.tabList[index] = destTabName;
    }
    else if (index > -1) {
      this.tabList.splice(index, 1);
    }
  }

  public onTabClicked(tabName: string): void {
    this.selectedTabName = tabName;
    localStorage.setItem(COMMITMENT_INTAKES_TAB_NAME, tabName);
    this.onSearchClicked({ searchCriteria: this.searchCriteria, isTabClicked: true });
  }

  private getCommitmentList(): void {
    this._commitmentIntakeService.getCommitmentList(this.queryParam)
      .pipe(take(1))
      .subscribe(
        (responses: any) => {
          const commitmentIntakeList = responses.body.commitments as ICommitmentIntakeDto[];
          commitmentIntakeList.forEach((commitment) => {
            commitment.opportunitySuggestionsIsCalled = false;
            commitment.fulfillmentSuggestionsIsCalled = false;           
          });

          this.commitmentIntakeList = commitmentIntakeList;
          if (responses.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(responses.headers.get('X-pagination')) as IPager);
          }
          this.isReset = false;
        },
        (error) => {
          this.alertInput.alertType = AlertType.Error;
          this.alertInput.message = error;
          this.isReset = false;
        }
      );
  }

  public openSubmitNewCommitmentsIntake(): void {
    this.showSubmitNewCommitmentsIntakeDrawer = true;
    document.getElementById('containerPage').classList.add('overflow-hidden');
  }

  public closeSubmitNewCommitmentsDrawers(): void {
    document.getElementById('containerPage').classList.remove('overflow-hidden');
    this.showSubmitNewCommitmentsIntakeDrawer = false;
  }

  public changeAlert(alertInput: AlertInput): void {
    this.alertInput = alertInput;
  }

  public setAlertMsgToEmpty(): void {
    this.alertInput.message = '';
  }

  public canCreateNewCommitmentIntake(): boolean {
    const hasWriteAccess = this.permission.hasWriteAccessOnCommitmentIntake(this.pageTemplateAdmin);
    return hasWriteAccess;
  }

  public redirectToDetails(event: { projectKey: string }): void {
    const { projectKey } = event;
    this._manageCommitmentListService.commitmentIntakePage = {
      pagerObject: this.pagerObject,
      commitmentIntakeList: [...this.commitmentIntakeList],
      searchFields: this.searchFields,
      pageTemplateAdmin: this.pageTemplateAdmin,
      pageTemplateReadAdmin: this.pageTemplateReadAdmin,
      columnSettings: this.columnSettings,
      // queryParam: this.queryParam
    };
    this.router.navigate([`/self-service/commitment-intakes`, projectKey]);
  }

  private getPageTemplateAccess() {
    this._commitmentIntakeService.getPageTemplateAccess()
    .pipe(take(1))
    .subscribe(
      (page) => {
        const pageTemplateAccess = page.body as ICommitmentIntakePageTemplateAccessDTO;
        this.pageTemplateAdmin = pageTemplateAccess.isPageAdmin;
        this.pageTemplateReadAdmin = pageTemplateAccess.isPageReadAdmin;
        localStorage.setItem(IS_PAGE_TEMPLATE_ADMIN, JSON.stringify(this.pageTemplateAdmin));
      },
      (error) => {
        this.alertInput.alertType = AlertType.Error;
        this.alertInput.message = error;
      }
    );
  }

  getColumnPreferences() {
    if (!this.userData) { 
      return;
    }
    
    const userId = this.userData.id;
    const listName = PreferenceService.commitmentIntakeList;
    const fetchPreferenceByUserId = this.preferenceService.getPreferenceByUserId(userId, listName);
    const fetchAllPreferenceByListName = this.preferenceService.getAllPreferenceByListName(listName);
    forkJoin([fetchPreferenceByUserId, fetchAllPreferenceByListName]).subscribe((response: any) => {
      this.columnSettings = { columnPreferences: response[0], allColumnList: response[1] };
    }, error => {
      this.isError = true; 
      this.columnSettingErrorMsg = error;
    });
  }

  public canCreateOneView(): boolean {
    const result = this.permission.hasWriteAccessOnOneView(this.pageTemplateAdmin);

    return result;
  }

  public openOnViewCreateModal(): void {
    this.isCreateNewOneView = true;
    this.isEditOneView = false;
    this.canEditOneView = this.permission.hasWriteAccessOnOneView(this.pageTemplateAdmin);
    $('#clientViewModal').modal('show');
  }

  public oneViewClicked(oneViewId: any): any {
    this.canEditOneView = this.permission.hasWriteAccessOnOneView(this.pageTemplateAdmin);
    this.isEditOneView = true;
    this.isCreateNewOneView = false;
    this.selectedOneViewId = Object.assign(oneViewId);
    $('#clientViewModal').modal('show');
  }

  public onOneViewSuccess(event: any): any {
    const alert: AlertInput = {
      alertType: AlertType.Success,
      message: event
    };

    this.changeAlert(alert);
    this.getCommitmentList();
    $('#clientViewModal').modal('hide');
  }

  private isFromViewCancelling() {
    const commitmentIntakePage = this._manageCommitmentListService.commitmentIntakePage;
    return this._manageCommitmentListService.isFromViewCancelling && commitmentIntakePage;
  }

  private isValidTabList(): boolean {
    return this.tabList.length !== this._validDatasourceTabNames.length;
  }
}
