<div class="box-container commitments-table mt-0">
    <div class="box-wrapper table-container" [ngClass]="{'blueThemeMode':(blueThemeMode)}">
      <div class="filter-wrapper">
        <div class="advance-filter-container">
          <form [formGroup]="filterForm" autocomplete="off">
            <div class="search-filter project-number">
              <label>Project Number:</label>
              <div class="ng-select-input-group input-group">
                <ng-select placeholder="Type to search" formControlName="projectNumber"
                  class="dashboard-commitment-select"
                  [items]="projectNumberSuggestions"
                  [clearOnBackspace]="true"
                  [editableSearchTerm]="true"
                  (search)="onSelectSearch($event, 'projectNumber')"
                  bindLabel="name" bindValue="name"
                  (keydown)="handleSelectKeyDown($event, 'projectNumber')"
                  #projectNumberSelect
                  (clear)="onClearText('projectNumber')">
                  <ng-template class="col" ng-option-tmp let-itemTemp="item" let-item$="item$" let-index="index">
                      <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                  </ng-template>
                </ng-select>
                <span class="input-group-btn" *ngIf="isSearchButtonDisplay('projectNumber')">
                  <button type="button" class="btn"><i class="material-icons">search</i> </button>
                </span>
              </div>              
            </div>
            <div class="search-filter title">
              <label>Title:</label>
              <div class="ng-select-input-group input-group">
                <ng-select placeholder="Type to search" formControlName="commitmentTitle"
                  class="dashboard-commitment-select"
                  [items]="rowsTitleSuggestions"
                  [clearOnBackspace]="true"
                  [editableSearchTerm]="true"
                  bindLabel="name" bindValue="name"
                  (search)="onSelectSearch($event, 'commitmentTitle')"
                  (keydown)="handleSelectKeyDown($event, 'commitmentTitle')"
                  #commitmentTitleSelect
                  (clear)="onClearText('commitmentTitle')">
                  <ng-template class="col" ng-option-tmp let-itemTemp="item" let-item$="item$" let-index="index">
                      <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                  </ng-template>
                </ng-select>
                <span class="input-group-btn" *ngIf="isSearchButtonDisplay('commitmentTitle')">
                  <button type="button" class="btn"><i class="material-icons">search</i> </button>
                </span>
              </div>
            </div>
            <div class="search-filter mr-2">
              <label>Stage:</label>
              <div class="mid-width">
                <ng-select [multiple]="true"
                  class="ng-select dashboard-commitment-select"
                  [items]="commitmentStatusesSuggestions" 
                  [closeOnSelect]="false"
                  [virtualScroll]="true" 
                  [clearOnBackspace]="false" 
                  placeholder="Type to search"
                  (change)="onStateChanged($event)" 
                  bindLabel="name" 
                  bindValue="id"
                  formControlName="commitmentStatus">
                  <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$" let-index="index">
                    <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                    <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
                  </ng-template>
                  <ng-template ng-multi-label-tmp>
                    <span class="ng-value-label items">{{getStateSelectedLabel()}}</span>
                  </ng-template>
                </ng-select>
              </div>
            </div>
            <div class="fdl-2 button-position ">
              <button type="button" class="btn fdl-btn btn-primary-orange ml-3" aria-label="Search"
                      (click)="advanceSearch()" [ngClass]="{'buttonColor':(blueThemeMode)}">
                Search
              </button>
              <button type="button" class="btn fdl-btn btn-secondary-light ml-3" aria-label="Reset"
                      (click)="cancelSearch('All')" [ngClass]="{'buttonColor':(blueThemeMode)}">
                Reset
              </button>
            </div>
          </form>
        </div>
        <div class="icon-wrapper" style="margin-left: auto;">
          <button (click)="exportExcel()" class="download-icon" [ngClass]="{'blueThemeMode':(blueThemeMode)}">
            <div class="icon"> </div>
          </button>
        </div>
      </div>
      <div class="table-section cct-table s-overflow-auto">
        <div class="no-data-message-container p-0 pt-2"
             *ngIf="paginatedAllRows.length==0 && !commitmentsError">
          <div class="product-wrapper no-data-message">
            <h3>No Data Available</h3>
          </div>
        </div>
        <div class="no-data-message-container p-0 pt-2" *ngIf="commitmentsError">
          <div class="product-wrapper no-data-message">
            <h3>There was a problem. Please try again later.</h3>
          </div>
        </div>
        <app-data-table-updated *ngIf="paginatedAllRows.length>0" [ngShow]="!isRowsLoading"
                                (sortingClicked)="sort($event)" [headerColumns]="headerColumns" [keys]="keys"
                                [rows]="paginatedAllRows" (buttonClicked)="navigate($event)" [filterColumn]="sortingColumn"></app-data-table-updated>
        <app-pagination *ngIf="allRowsTotalPages > 1 && !isRowsLoading"
                        [isCalledFromCCT]="blueThemeMode"
                        [pagerObject]="{totalPages : allRowsTotalPages, currentPage: allRowsPage}"
                        (pagingChanged)="changePage($event)"></app-pagination>
      </div>
    </div>
  </div>