import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
import { PageTemplateService } from "src/app/services/page-template.service";
import { UserService } from "src/app/services/user.service";
import { IResponseStatusResloved } from "src/app/shared/status";
import { MasterService } from "src/app/services/master.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { isNullOrUndefined, isUndefined } from "util";
import { MAX_LENGTHS, REGEX_PATTERNS, REGEX_VALIDATION_MESSAGE } from "src/app/core/constants";
import { ProjectsService } from "src/app/services/projects.service";
import { forkJoin } from "rxjs";
import { IPageTemplateResponse } from "src/app/shared/page-template";
import { Utils } from "formiojs";
import { DrmsFileService } from "src/app/services/file.service";
import { ClientCommitmentsService } from "src/app/services/client-commitments.service";
import { take } from "rxjs/operators";
import { Location } from '@angular/common';
import { environment } from "src/environments/environment";
declare var $: any;

@Component({
    selector: 'page-container',
    templateUrl: './page-container.component.html'
})

export class PageContainerComponent implements OnInit, OnDestroy {
    @Input('form') formJson;
    @Input('pageResponse') pageResponse;
    @Input('pageTemplate') pageTemplate;
    @Input('pageTemplateResponseId') pageTemplateResponseId;
    @Input('pageTemplateId') pageTemplateId;
    @Input('reservedKeysData') reservedKeysData;
    @Input() formdata: any;
    @Input() readonly: boolean = false;
    @Input() shouldEnableSaveButtonForSpecialCase = false;
    @Output() saveChanged = new EventEmitter<any>();
    @ViewChild('formio') formioForm;
    tempPageTitle: string;
    pageTitle: string;
    pageTemplateTitle: string;

    formJsonCopy: any;
    fieldSets = [];
    currentFieldSetIndex: number = 0
    currentFieldSet: { components: any[]; };
    hideLeftNav: boolean = false;
    isSubmitClicked: boolean = false;
    draftData: any;
    answerJson: any = [];
    answer: any = {};
    answer2: any = {};
    pdfanswer: any = {};
    pageStatus: any = {};
    isError: boolean;
    errorMsg: string = '';
    pageCount: number;
    uploadedAnswerJson: boolean = false;
    draftModeMessage: any = '';
    isSubmitted: boolean;
    isSuccess: boolean = false;
    successMsg: string;
    callBackEvent: any = {};
    changeNewData: any;
    dataChange: boolean = false;
    isShowPage: boolean = true;
    showApproverButtons: boolean = false;
    responsePostData: any;
    userData: any;
    isUpdateTitle: boolean = false;
    missingMessage: string;
    isPageResponse: boolean = false;
    _maxLengths: any;
    approvalForm: FormGroup;
    isRejected: boolean;
    isRejectedError: boolean = false;
    isApproved: boolean = false;
    sentBackApprover: any;
    completeApproverList: any = [];
    actionMsg: string = '';
    pageTemplateResponseFormValue: IPageTemplateResponse;

    organizationSuggestion: any = {};
    productSuggestion: any = {};
    clientSuggestion: any = {};

    keyInformationCollection: any = [];
    refreshForm: any;
    selectApproverComponentKeys = [];
    sensitiveInformtion: any;
    responseEncryptedFields: any;
    responseEncryptedFieldsData: any = [];
    formbuilder = { fileService: new DrmsFileService() };
    responseEncryptedFieldsDataInSession: string[] = [];
    eventDataCopy: any;
    regexValidationMessage: any;

    constructor(
      private _router: Router,
      private pageTemplateService: PageTemplateService,
      private userService: UserService,
      private route: ActivatedRoute,
      private masterService: MasterService,
      private projectService: ProjectsService,
      private clientCommitmentService: ClientCommitmentsService, private location: Location
    ) { this._maxLengths = MAX_LENGTHS; }

    ngOnInit() {
        this.regexValidationMessage = REGEX_VALIDATION_MESSAGE;
        this.userData = this.userService.getUserListData();
        this.pageStatus = {};
        this.tempPageTitle = this.pageResponse?.title || this.pageTemplate?.title;
        this.pageTitle = this.pageResponse?.title || this.pageTemplate?.title;
        this.pageTemplateTitle = this.pageResponse?.pageTemplateTitle || this.pageTemplate?.title;
        this.isPageResponse = this.pageResponse?.id;
        this.pageTemplateResponseFormValue = this.pageTemplateService.formVal;
        this.initData();
        this.getAllApprovers();
        this.getSuggestions();
        this.handleAfterCreateNewCommitmentSuccessful();
    }

    handleAfterCreateNewCommitmentSuccessful() {
      this.route.queryParams.subscribe(params => {
        if (params.state === 'createdSuccess') {
          this.isSuccess = true;
          this.successMsg = "Request - '" + params.title + ( "' is saved successfully.");
          this._router.navigate([], {
              relativeTo: this.route,
              queryParams: {state: null, title: null}, 
              queryParamsHandling: 'merge',
            }
          );
        }
      });
    }

    getSuggestions() {
        let apiCall = [];
        let params = [];
        let searchClientValue = this.reservedKeysData['drmsReservedClientName'] || this.pageResponse?.clientTitle;

        apiCall.push(this.projectService.getSuggestions("organizations", ""));
        apiCall.push(this.projectService.getSuggestions('products', true, "ignoreAll"));
        if (searchClientValue) {
          params.push({paramName: "title", value: searchClientValue});
          apiCall.push(this.projectService.getClientSuggestionsWithMDM("clients", params, 100));
        }
        else {
          apiCall.push(this.projectService.getClientSuggestionsWithMDM("clients", null, 100));
        }
        
        forkJoin(apiCall).subscribe(data => {
          this.organizationSuggestion = data[0];
          this.productSuggestion = data[1];
          this.clientSuggestion = data[2];
        },
        (error) => { this.isError = true; this.errorMsg = error; });
    }

    ngOnDestroy() {
        this.pageTemplateResponseFormValue = undefined;
        this.pageTemplateService.formVal = undefined;
    }

    updateTitle(status: boolean) {
        this.isUpdateTitle = !this.isUpdateTitle;
        if(status) {
            this.pageTitle = this.tempPageTitle;
            this.updatePageResponseTitle();
        }
        else
            this.tempPageTitle = this.pageTitle;
    }

    initData() {
        if (!!this.formJson.components) {
            this.fieldSets = this.formJson.components.filter((data) => {
            if (data.type == 'panel' || data.type == 'fieldset') {
                data.type = "fieldset";
                data.legend = data.title;
                let flowName = 'response';
                if (this._router.url.indexOf('self-service') !== -1) {
                    flowName = 'self-service';
                }
                return true;
            }
            });
            this.setFieldSet(this.currentFieldSetIndex);
        }

        if (this.pageResponse?.preFilledData) {
            let prefilledData = JSON.parse(this.pageResponse.preFilledData);
            for (let k in prefilledData) {
            if (this.answer && this.answer.data) {
            } 
            else {
                this.answer = {
                'data': {}
                }
            }
            this.answer.data[k] = prefilledData[k];
            }
        }

        if (this.pageResponse?.answerJSON) {
            let json = JSON.parse(this.pageResponse?.answerJSON);
            if (json) {
                if (this.answer && this.answer.data)
                this.answer = { 'data': {} };
                this.answer.data = json;
            }
        }

        if (this.pageTemplate && this.reservedKeysData) {
            this.answer.data = {};
            this.answer.data = this.reservedKeysData;
        }
        if (this.pageTemplate) {
            this.answer.data['drmsReservedUserName'] = this.userData.name;
            if(this.userData.type === 'Fiserv') {
                this.answer.data['drmsReservedUserOrganization'] = this.userData.organizationTitle;
            }
            this.answer.data['newRM'] = this.pageTemplateResponseFormValue.newRMName;
            this.answer.data['newRMEmailAddress'] = this.pageTemplateResponseFormValue.newRMEmail;
            this.answer.data['relationshipManagerAccountExecutiveIntake'] = this.reservedKeysData.drmsReservedRelationshipManagerName;
            this.answer.data['relationshipManagerEmailAddress'] = this.reservedKeysData.drmsReservedRelationshipManagerEmail;
            this.answer.data['globalUltimateDunsC'] = this.reservedKeysData.drmsReservedClientGudId;
        }
    
        let approver: boolean = false;       
        approver = this.isApproverAndUnderReview();
        
        this.answer.data['drmsReservedIsApprover'] = approver;
        this.answer.data['drmsReservedApproverLevel'] = approver ? this.pageResponse?.currentLevel : 0;
        if(this.pageTemplate || (this.pageResponse?.pageTemplateResponseStatusId < 3 && this.pageResponse?.submittedById === this.userData.id)) {
            this.answer.data['drmsReservedRespondentName'] =  this.userData.name;
            this.answer.data['drmsReservedRespondentEmail'] = this.userData.email;
        }
        this.answer.data['drmsReservedIsClientUser'] = this.userData.type === 'Client' ? true : false;
        this.answer.data['drmsReservedIsFiservUser'] = this.userData.type === 'Fiserv' ? true : false;
        this.answer.data['drmsReservedUserOrganizationId'] =  this.userData.type === 'Fiserv' ? this.userData.organization : 0;
        this.answer.data['drmsReservedResponseId'] = this.pageResponse?.id > 0 ? this.pageResponse?.id : 0;
        this.answer.data['drmsReservedLoggedInUserName'] =  this.userData.name;
        this.answer.data['drmsReservedLoggedInUserEmail'] = this.userData.email;
        
        this.showApproverButtons = this.isApproverAndUnderReview();
        this.refreshForm = new EventEmitter();
        this.updateKeysInTable();    
    }

    getAllApprovers() {
        let formApproverList = [];
        let approverLevelDetails = [];
        let approverLevelIDs = [];
        approverLevelDetails[0] = [];
        approverLevelDetails[0].push(this.pageResponse?.submittedByName);
        approverLevelIDs[0] = [];
        approverLevelIDs[0].push(this.pageResponse?.submittedById);

        for (let k in this.pageResponse?.pageTemplateApprovers) {
            let userData = this.pageResponse?.pageTemplateApprovers[k].user;
          if (this.pageResponse?.pageTemplateApprovers[k]?.level < this.pageResponse?.currentLevel) {
            if (!approverLevelDetails[this.pageResponse?.pageTemplateApprovers[k]?.level]) {
              approverLevelDetails[this.pageResponse?.pageTemplateApprovers[k]?.level] = [];
              approverLevelIDs[this.pageResponse?.pageTemplateApprovers[k]?.level]=[];
            }
            approverLevelDetails[this.pageResponse?.pageTemplateApprovers[k]?.level].push(userData.name);
            approverLevelIDs[this.pageResponse?.pageTemplateApprovers[k]?.level].push(userData.id);
          }
        }

        for (let j in approverLevelDetails) {
          let levelName = '';
          if (approverLevelDetails[j] != '') {
            if (j == '0') {
              levelName = 'Respondent';
            } 
            else {
              levelName = 'Approver Level ' + j
            }
            formApproverList.push({ 'id': j, 'userId': approverLevelIDs[j].join(','), 'level': levelName, 'name': approverLevelDetails[j].join(',') })
          }
        }
        this.sentBackApprover = formApproverList;
    }

    toggleSideBar() {
        this.hideLeftNav = !this.hideLeftNav;
    }

    isApproverAndUnderReview() {
        return (this.pageResponse?.pageTemplateResponseStatusId === this.getStatus("under review")
          && this.pageResponse?.pageTemplateApprovers.some(a => a.approverId === this.userData?.id && a.level === this.pageResponse?.currentLevel && !a.isReviewComplete));
    }

    getStatus(value: string) {
        const resolvedData: IResponseStatusResloved = this.route.snapshot.data['resolveData'] || {};
        if (!resolvedData.responseStatus) {
          resolvedData.responseStatus = this.masterService.getResponseStatusList();
        }
        let status = resolvedData.responseStatus.filter(item => item.title.trim().toLowerCase() === value);
        return status[0]?.id;
    }

    setFieldSet(index, showSuccessDraft: boolean = false) {
        this.currentFieldSetIndex = index;
        this.currentFieldSet = { components: [] };
        this.changeNewData = null;
        this.dataChange = false;
        this.currentFieldSet.components.push(this.fieldSets[this.currentFieldSetIndex]);
        let btnComponent = this.formJson.components.filter(data => data.type === "button");
    }

    getClassName(fieldSet) {
        if (this.currentFieldSet.components && fieldSet.legend === this.currentFieldSet.components[0].legend)
            return 'active';
        if (fieldSet.status === 'Completed')
            return 'completed';
        // if (fieldSet.status === 'Skipped')
        //     return 'error';
    }

    checkDataLoss(callBack, callBackData = null) {
        if (callBack==='skip')
            this.dataChange = true;

        this.callBackEvent = { 'name': callBack, 'data': callBackData }
        if (this.changeNewData && !this.readonly && this.dataChange) {
            $('#dataLoss').modal('show');
        }
        else {
            this.formBtnsHandler(callBackData);
        } 
    }

    change(evt: any) {
        if (evt.data && evt.changed != undefined) {
            this.draftData = evt;
            // this.CustomComponentsService.setData(this.draftData);
            if (!evt.changed.component.hidden) {
            this.changeNewData = evt.data;
            }
        }
    }  

    isFieldSetsPending() {
        let result = false;
        let pendingFieldSets = this.formJson.components
          .filter(item => ((isNullOrUndefined(item.status) || (!item.status || (item.status && (item.status == ''))) || (item.status && item.status.toLowerCase() != "completed")) && (isNullOrUndefined(item.tempshow) || item.tempshow != false)))
          .map(item => item.key);
        const index = pendingFieldSets.indexOf(this.currentFieldSet.components[0].key);
        if (index > -1) {
          pendingFieldSets.splice(index, 1);
        }
        if (pendingFieldSets.length > 0) {
          result = true;
        }
        let visibleSteps = this.formJson.components
          .filter(item => {
            if (item.key != this.currentFieldSet.components[0].key && (isNullOrUndefined(item.status) || (!item.status || (item.status && (item.status == ''))) || (item.status && item.status.toLowerCase() != "completed")) && (isNullOrUndefined(item.show) || item.tempshow != false)) {
              return true;
            }
          }).map(item => item.title);
        if (result) {
          this.missingMessage = "Page " + visibleSteps.join(", ") + " is not yet marked complete. To submit the form, each page must be marked complete by clicking the \"Continue\" button.";
        }
        return result;
    }

    saveContinueForm(submitClicked: boolean) {
        this.isSubmitClicked = submitClicked;
        this.isSuccess = false;
        this.actionMsg = 'updated';

        // for skip or incomplete pages : page level error message
        if (this.fieldSets.length > 1 && this.isSubmitClicked && this.isFieldSetsPending()) {
            return;
        }

        this.formioForm.formio.submit(true);
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            $('.has-message')[0].scrollIntoView(true);
        }, 100);
    }

    onSubmit(evt: any, draftMode: boolean = false) {
      this.createPostData(evt,draftMode);
      this.answer.data = evt.data;
      if (this.pageTemplateResponseId)
          this.updatePageResponse(this.responsePostData.title, draftMode);
      else
          this.createPageResponse(this.responsePostData.title, draftMode);
    }

    private createPostData(evt : any,draftMode:boolean=false): void {
      if(!draftMode) {
        this.formJson.components.filter(component => component.type !== "button").forEach(element => {
          if (element.legend == this.currentFieldSet.components[0].legend) {
            //    element.fieldData.data = submittedData;
            if (!draftMode) {
              element.status = 'Completed';
            } else {
              element.status = 'skipped';
            }
          }
        });
      }

      if (this.pageTemplateResponseId) {
        this.responsePostData = {
          "id" : Number(this.pageTemplateResponseId),
          "title" : this.pageTitle,
          "rowVersion": this.pageResponse?.rowVersion,
          "percentageComplete": (this.pageResponse?.percentage || 0),
          "pageTemplateResponseStatusId" : this.getStatusId(),
          "approvalSchema": this.pageResponse?.approvalSchema,
          "formJSON" : JSON.stringify(this.formJson),
          "answerJSON" :  JSON.stringify(evt.data),
          "startDate": this.pageResponse?.startDate,
          "dueDate": this.pageResponse?.dueDate,
          "isApproved" : this.isApproved
          // , "responseEncryptedFields": this.responseEncryptedFields
        }
      }
      else {
        this.responsePostData = {
          "title" : this.pageTitle,
          "description" : this.pageTemplate?.description,
          "submittedById" : Number(this.userService.getUserListData().id),
          "pageTemplateResponseStatusId" : 1,
          "approvalSchema" : this.pageTemplate?.approvalSchema,
          "pageTemplateId" : Number(this.pageTemplateId),
          "formJSON" : JSON.stringify(this.formJson),
          "answerJSON" :   JSON.stringify(evt.data),
          "startDate": new Date().toISOString(),
          "dueDate": new Date().toISOString(),
          "isSubmitted": this.isSubmitClicked
        }    
      }

      if (this.isSubmitClicked && this.pageResponse?.isPageTemplateLinkingAllowed) {
        this.responsePostData['isLinked'] = true;
      }
      else if (this.pageResponse?.isLinked) {
        this.responsePostData['isLinked'] = true;
      }
      else {
        this.responsePostData['isLinked'] = false;
      }

      this.checkEntitiesAvailable();
      let approverList = this.changeApprover();
      if (approverList.length > 0) {
        this.responsePostData['pageTemplateApprovers'] = approverList;
      }
    }

    createPageResponse(title: string, draftMode: boolean = false) {
      this.clientCommitmentService.createPageResponse({...this.responsePostData, isDraftMode: draftMode })
      .subscribe(
        (ids) => {
          this.clientCommitmentService.getPageTemplateResponseById(ids[0]).pipe(take(1)).subscribe(response => {
            this.onCreateComplete(title, draftMode);
            this.pageResponse = response as IPageTemplateResponse;
            this.pageTemplateResponseId = this.pageResponse.id;
            let json = JSON.parse(this.pageResponse.answerJSON);
            if (json) {
              this.answer.data = {};
              this.answer.data = json;
              this.navigateToResponse(this.pageTemplateResponseId, title);
            }
            this.Cancel();
          });
        },
        error => {
          this.isError = true;
          this.errorMsg = error;
        }
      );
    }

    navigateToResponse(pageResponseId: number, title: string) {
      let url: string = '/self-service/pageTemplate/' + environment.clientCommitmentPageTemplateId + "/response"
      this._router.navigate([url, pageResponseId], { queryParams: {state: 'createdSuccess', title: title}});
    }

    updatePageResponse(title: string, draftMode: boolean = false) {
      this.clientCommitmentService.updatePageResponse({...this.responsePostData, isDraftMode: draftMode })
        .subscribe(() => {
          this.clientCommitmentService.getPageTemplateResponseById(this.pageTemplateResponseId).pipe(take(1)).subscribe(response => {
            this.pageResponse = response as IPageTemplateResponse;
            this.pageTemplateResponseId = this.pageResponse.id;
            this.onCreateComplete(title, draftMode);

            // For some special cases need to handle enable/disable on UI side (Eg: Is track)
            const answerJson = JSON.parse((response as IPageTemplateResponse).answerJSON);
            this.saveChanged.emit({isTracked: answerJson.isTracked, statusIntake: answerJson.statusIntake });
          });
        },
        error => {
            this.isError = true;
            this.errorMsg = error;
        }
      );
    }
    
    Cancel() {
        this.isSubmitted = false;
        this.isSubmitClicked = false;
        this.isApproved = false;
        this.isRejected = false;
        this.isError = false;
        this.errorMsg = "";
        this.isUpdateTitle = false;
    }

    private onCreateComplete(title: string, draftMode: boolean = false): void {
        if (this.currentFieldSetIndex === this.fieldSets.length - 1 && (this.isSubmitClicked || this.isSubmitted || this.isRejected)) {
            this.pageTemplateService.isResponseCreated = "Request - '" + title + (this.isPageResponse ? "' is " + this.actionMsg + " successfully." : "' is created successfully.");

            // let url: string = '/self-service/pageTemplate/' + this.pageTemplateId + "/responses"
            // this._router.navigate([url]);
          this.location.back();
        }
        else if (draftMode) {
            this.isSuccess = true;
            this.successMsg = "Request - '" + title + (this.pageTemplateResponseId ? "' is updated successfully." : "' is saved successfully.");
        }
        else {
            this.currentFieldSetIndex++;
            this.setFieldSet(this.currentFieldSetIndex);
        }

        this.pageTemplateService.formVal = undefined;
    }

    formBtnsHandler(index : any) {
        switch (this.callBackEvent.name) {
          case 'skip':
            this.skipForm();
            break;
          case 'previous':
            this.previousForm();
            break;
          case 'next':
            this.nextForm();
            break;
          case 'approve':
            this.openApprovalModal(true);
            break;
        case 'reject':
            this.openRejectModal(true);
            break;
        case 'sentBack':
            this.openApprovalModal(false);
            break;
          case 'fieldSetChange':
            this.setFieldSet(this.callBackEvent.data);
            break;
        }
    }

    skipForm() {
        $(window).scrollTop(0);
        this.isNextPage();
        if (this.currentFieldSet.components[0].status !== "Completed") {
          this.currentFieldSet.components[0].status = "Skipped";
        }
        this.setFieldSet(++this.currentFieldSetIndex);
    }

    isNextPage() {
        this.isShowPage = false;
        for (let k: number = this.currentFieldSetIndex + 1; k < this.fieldSets.length; k++) {
          if (this.fieldSets[k].tempshow != false) {
            this.isShowPage = true;
          }
        }
    }

    nextForm() {
        $(window).scrollTop(0);
        this.setFieldSet(++this.currentFieldSetIndex);
    }
    
    previousForm() {
        $(window).scrollTop(0);
        this.currentFieldSetIndex = this.currentFieldSetIndex - 1;
        if (this.fieldSets[this.currentFieldSetIndex].show == false) {
            this.previousForm();
            return;
        }
        this.setFieldSet(this.currentFieldSetIndex);
    }

    doSubmitButton() {
        let show = false;
        if (this.pageTemplate && this.currentFieldSetIndex === this.fieldSets.length-1) {
            show = true;
        }
        else if (this.pageResponse && this.pageResponse.pageTemplateResponseStatusId &&
            (this.pageResponse.pageTemplateResponseStatusId == this.getStatus("not started") ||
            this.pageResponse.pageTemplateResponseStatusId == this.getStatus("in progress") ||
            this.pageResponse.pageTemplateResponseStatusId == this.getStatus("overdue"))
            && this.currentFieldSetIndex === this.fieldSets.length-1 && !this.readonly) {
            show = true;
        }
        return show;
    }

    saveDraft() {
        if (this.draftData && this.draftData.data) {
            this.onSubmit(this.draftData, true)
            this.isSubmitClicked = false;
        }
    }
    
    goBack() {
        // let id = 0;
        // if(this.pageResponse)
        //     id = this.pageResponse.pageTemplateId;
        // if(this.pageTemplate)
        //     id = this.pageTemplate.id;

        // let url: string = '/self-service/pageTemplate/' + id + '/responses';
        // this._router.navigate([url]);
      this._router.navigate(['self-service/commitments-intake']);
      //this.location.back();
    }

    closeDataLossModal() {
        $('#dataLoss').modal('hide');
    }

    closeApprovalCommentsModal() {
        this.isRejected = false;
        this.isApproved = false;
        $('#dataLoss').modal('hide');
    }

    computeClazzForNavigation(): string {
        if (this.fieldSets && this.fieldSets.length > 1) {
          if (this.hideLeftNav) return "main-content-collapsed";
          else return "col-xl-10 col-md-9 col-sm-9 main-content-expand";
        } else {
          return "col-lg-12";
        }
    }

    getStatusId(eventName: string = '') {
        let statusId;
        if (eventName === 'Rejected') 
            statusId = this.getStatus('rejected');
        else if (eventName === 'SentBack') 
            statusId = this.getStatus('in progress');
        else if (this.pageResponse && this.pageResponse.pageTemplateResponseStatusId) {
            let formStatusId = this.pageResponse.pageTemplateResponseStatusId;

            if (formStatusId == this.getStatus("not started") || formStatusId == this.getStatus("in progress") || formStatusId == this.getStatus("overdue")) {
                if (this.isSubmitClicked) { 
                    if (this.isApproverSet())
                        statusId = this.getStatus('under review');
                    else
                        statusId = this.getStatus('completed');
                }
                else {
                    if (formStatusId == this.getStatus("overdue"))
                        statusId = this.getStatus('overdue');
                    else
                        statusId = this.getStatus('in progress');
                }
            }
            else if (formStatusId == this.getStatus("under review")) {
                statusId = this.getStatus('under review');
            }
            else if (formStatusId == this.getStatus("completed")) {
                statusId = this.getStatus('completed');
            }
        }

        return statusId;
    }

    isApproverSet() {
        return (this.pageResponse && this.pageResponse.pageTemplateApprovers.some(a => a.approverId === this.userData.id && a.level === this.pageResponse.currentLevel));
    }

    submitApprovalForm(isrejectedFlow: any = '') {
        this.resetErrorAndSuccessMsg();
        this.isSubmitted = true;

        let approverSentBackLevel = 0;
        let approverSentBackUser;

        if (isrejectedFlow === 'sentback') {
          let levelValue = this.approvalForm.get('sentBackLevel').value;
          if (levelValue !== '' && Number(levelValue)) {
            approverSentBackLevel = Number(levelValue);
          }
          approverSentBackUser =  this.sentBackApprover.filter(item => Number(item.id) === approverSentBackLevel);
        }

        let comments = this.approvalForm.get('comments').value?.trim();

        if (comments != '') {
            $('#approvalComments').modal('hide');
            if (isrejectedFlow == 'rejected')
                this.approveForm('Rejected', comments);
            else if (isrejectedFlow == 'approved')
                this.approveForm('Approved', comments);
            else if (isrejectedFlow == 'sentback') {
                let data = {
                    "comments": comments,
                    "approverId": approverSentBackUser[0].userId,
                    "approverLevel": approverSentBackLevel
                }
                this.sentBackToApprover(data);
            }
        }
    }

    sentBackToApprover (data) {
        this.pageTemplateService.sentBackToApprover(this.pageResponse.id, data)
        .subscribe(
            (data) => {
                this.onCreateComplete(this.pageResponse.title);
                this.Cancel();
            },
            error => {
                this.isError = true;
                this.errorMsg = error;
            }
        );
    }

    approveForm(eventName: string, comments: string) {
        this.isError = false;
        this.responsePostData = {
            "id" : Number(this.pageTemplateResponseId),
            "title" : this.pageTitle,
            "rowVersion": this.pageResponse.rowVersion,
            "percentageComplete": eventName !== 'SentBack' ? 100 : 0,
            "pageTemplateResponseStatusId" : this.getStatusId(eventName),
            "approvalSchema": this.pageResponse.approvalSchema,
            "formJSON" : JSON.stringify(this.formJson),
            "answerJSON" :  this.pageResponse.answerJSON,
            "startDate": this.pageResponse.startDate,
            "dueDate": this.pageResponse.dueDate,
            "isApproved" : this.isApproved,
            "eventName": eventName,
            "comments": comments
        }
            
        this.updatePageResponse(this.responsePostData.title);
    }

    resetErrorAndSuccessMsg() {
        this.isError = false;
        this.draftModeMessage = "";
        this.errorMsg = "";
    }

    openApprovalModal(value: boolean) {
        this.isError = false;
        this.isApproved = value;
        this.actionMsg = value ? 'approved' : 'sent back';
        this.initCommentsForm();
        $('#approvalComments').modal('show');
    }
    
    openRejectModal(value: boolean) {
        this.actionMsg = 'rejected';
        this.isError = false;
        this.isRejected = value;
        this.initCommentsForm();
        $('#approvalComments').modal('show');
    }

    initCommentsForm() {
        let validatorArray = [Validators.maxLength(this._maxLengths.Approval.Comments)]
        validatorArray.push(Validators.required);
        //Comments are mandatory when response is rejected
        this.approvalForm = new FormGroup({
            comments: new FormControl('', validatorArray),
            sentBackLevel: new FormControl('0', validatorArray)
        });
        this.isSubmitted = false;
    }

    updatePageResponseTitle() {
        if (this.pageTemplateResponseId) {
            this.responsePostData = {
                "id" : Number(this.pageTemplateResponseId),
                "title" : this.pageTitle,
                "rowVersion": this.pageResponse.rowVersion,
                "percentageComplete": (this.pageResponse.percentage || 0),
                "pageTemplateResponseStatusId" : this.getStatusId(),
                "approvalSchema": this.pageResponse.approvalSchema,
                "formJSON" : JSON.stringify(this.formJson),
                "answerJSON" :  this.pageResponse.answerJSON,
                "startDate": this.pageResponse.startDate,
                "dueDate": this.pageResponse.dueDate,
                "isApproved" : false
            }
            this.checkEntitiesAvailable();
            this.updatePageResponse(this.responsePostData.title, true);
        }
    }

    checkEntitiesAvailable() {
        if(this.pageTemplateResponseFormValue) {
            if(this.pageTemplateResponseFormValue?.clientId > 0) { // && !submittedJSON.hasOwnProperty('drmsReservedClientName')) {
                this.responsePostData["clientId"] = this.pageTemplateResponseFormValue.clientId;
                this.responsePostData["drmsReservedClientId"] = this.pageTemplateResponseFormValue.clientId;
            }
            if(this.pageTemplateResponseFormValue?.productId > 0) //  && !submittedJSON.hasOwnProperty('drmsReservedProductName'))
                this.responsePostData["productId"] = this.pageTemplateResponseFormValue.productId;
            if(this.pageTemplateResponseFormValue?.organizationId > 0) //  && !submittedJSON.hasOwnProperty('drmsReservedOrganizationName'))
                this.responsePostData["organizationId"] = this.pageTemplateResponseFormValue.organizationId;
            if(this.pageTemplateResponseFormValue?.approvalSchema && this.pageTemplateResponseFormValue?.approvalSchema != "")
                this.responsePostData["approvalSchema"] = this.pageTemplateResponseFormValue.approvalSchema;
            if(this.pageTemplateResponseFormValue?.approvers?.length > 0)
               this.responsePostData['pageTemplateApprovers'] = this.pageTemplateResponseFormValue.approvers;
      }

      if (this.pageTemplateResponseFormValue == undefined && this.pageResponse) {
        if (this.pageResponse?.clientId > 0) { // && !submittedJSON.hasOwnProperty('drmsReservedClientName')) {
          this.responsePostData["clientId"] = this.pageResponse.clientId;
          this.responsePostData["drmsReservedClientId"] = this.pageResponse.clientId;
        }
        if (this.pageResponse?.productId > 0) //  && !submittedJSON.hasOwnProperty('drmsReservedProductName'))
          this.responsePostData["productId"] = this.pageResponse.productId;
        if (this.pageResponse?.organizationId > 0) //  && !submittedJSON.hasOwnProperty('drmsReservedOrganizationName'))
          this.responsePostData["organizationId"] = this.pageResponse.organizationId;
        if (this.pageResponse?.approvalSchema && this.pageResponse?.approvalSchema != "")
          this.responsePostData["approvalSchema"] = this.pageResponse.approvalSchema;
        if (this.pageResponse?.approvers?.length > 0)
          this.responsePostData['pageTemplateApprovers'] = this.pageResponse.approvers;
      }
    }

    render(evt: any) {
        setTimeout(function (e) {
            $('button.buttonclick').on('click', function () {
              $('#inputapiCall').val($(this).attr('name'));
              $('#apiCall').trigger('click');
            })
        }, 500);
    }

    apiCall() {
        let id = $('#inputapiCall').val().split('[');
        let buttonKey = id[1].split(']')
        let componentInfo=(this.keyInformationCollection[buttonKey[0]]);
        if (componentInfo && componentInfo.properties && componentInfo.properties.apiURL && componentInfo.properties.destinationKey) {
          if (this.answer && this.answer.data) {
            this.answer.data['api-call-key-class'] = 'done'
          } 
          else {
            this.answer = {}
            this.answer.data = {};
            this.answer.data['api-call-key-class'] = 'done'
          }

          let tempUrl = 'url ="'+ componentInfo.properties.apiURL.split('{{').join('"+').split('}}').join('+"')+'"';
          let urlToCall = eval(tempUrl);
    
          this.pageTemplateService.getCall(urlToCall).subscribe(data => {
            this.answer.data[componentInfo.properties.destinationKey] = data
            this.refreshForm.emit({
                submission: { data: this.answer.data },
            });
          });
         
        }
    }

    updateKeysInTable() {
        for (let k in this.fieldSets) {
          let source: any = {};
          source = this.fieldSets[k].components;  
          var components = [];
    
          Utils.eachComponent(source, (component) => {
            if (component.properties && component.properties.hasOwnProperty('type') && component.properties.type == "approver") {
              this.selectApproverComponentKeys.push(component.key);
            }
            let widget = ''
            /*
            if (component.type == 'sensitiveinformation') {
                if (!this.sensitiveInformtion) {
                  this.sensitiveInformtion = [];
                }
                this.sensitiveInformtion[component.key] = '';
            }
            */
            if (component.widget)
              widget = component.widget
            let disabled = false;
            if (component.disabled)
              disabled = component.disabled;
            let properties: any = '';
            if (component.properties)
              properties = component.properties
            let conditional: any = 'No';
            if (component.conditional) {
              conditional = component.conditional
            }
            /*
            let typeOfSensitiveInformation = 'No';
            if (component.typeOfSensitiveInformation) {
            typeOfSensitiveInformation = component.typeOfSensitiveInformation
            }
            */
            let customConditional: any = 'No';
            if (component.customConditional) {
              customConditional = component.customConditional
            }
            let calculateValue: any = 'No';
            if (component.calculateValue && component.allowCalculateOverride) {
              calculateValue = component.calculateValue
            }
            this.keyInformationCollection[component.key] = { key: component.key, label: component.label, id: component.id, type: component.type, input: component.input, defaultValue: component.defaultValue, values: component.values, hidden: component.hidden, widget: widget, disabled: disabled, 'properties': properties, 'conditional': conditional, 'customConditional': customConditional, "calculateValue": calculateValue}; // , "typeOfSensitiveInformation": typeOfSensitiveInformation };
    
            components = [...components, { key: component.key, label: component.label, id: component.id, type: component.type, input: component.input, defaultValue: component.defaultValue, values: component.values, hidden: component.hidden, disabled: disabled, calculateValue: calculateValue, 'conditional': conditional, 'customConditional': customConditional }];
          }, true);
        }
    }

    changeApprover() {
      const approverList = []; //Approver List from Select User Component
      let data = this.answer.data
      this.selectApproverComponentKeys.forEach(componentKey => {
        let component = this.keyInformationCollection[componentKey];
        let level = 1
        let show: boolean = true;
        if (component.properties && component.properties.hasOwnProperty('level') && (component.properties.level != "0" || component.properties.level != "")) {
          level = component.properties.level;
        }

        if (component.conditional && component.conditional != 'No' && component.conditional.show != null) {
          show = !component.conditional.show;
          if ((data && data[component.conditional.when] && data[component.conditional.when].toString() == component.conditional.eq.toString()) || ((isUndefined(this.keyInformationCollection[component.conditional.when]) || (this.keyInformationCollection[component.conditional.when] && (this.keyInformationCollection[component.conditional.when].type == 'checkbox' || this.keyInformationCollection[component.conditional.when].type == 'selectboxes'))) && data && data[component.conditional.when] && data[component.conditional.when][component.conditional.eq]) || (data && !data[component.conditional.when] && !component.conditional.eq)) {
            show = component.conditional.show;
          }
        }
        else if (component.customConditional && component.customConditional != 'No') {
          var a = '';
          if (data) {
            a = "var data=" + data + ';' + component.customConditional;
          }
          show = true;
          var b = eval(a)
        }

        if (show) {
          if (component.type == 'select') {
            // return false;
            if (this.answer.data[componentKey] && this.answer.data[componentKey].id) {
              approverList.push({
                "email": this.answer.data[componentKey].email,
                "level": level
              })
            }
          }
          else if (component.type == 'selectboxes') {
            for (let i in this.answer.data[componentKey]) {
              if (this.answer.data[componentKey][i] && REGEX_PATTERNS.Email.test(i.toLowerCase()))
                approverList.push({
                  "email": i,
                  "level": level
                })
            }
          }
          else {
            // return false;
            if (this.answer.data[componentKey]) {
              approverList.push({
                "email": this.answer.data[componentKey],
                "level": level
              })
            }
          }
        }
      });

      return approverList;
    }

    public canEdit(): boolean {
      if (this.pageTemplateId === environment.clientCommitmentPageTemplateId &&
        this.userData.roles.some(r =>
          r.roleTitle === 'Division Leader' ||
          r.roleTitle === 'BU Leader' ||
          r.roleTitle === 'Delivery Owner' ||
          r.roleTitle === 'Delivery Leader' ||
          r.roleTitle === 'Account Team')) {
        return false;
      }
      return true;
    }
}
