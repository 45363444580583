import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { PageTemplateService } from '../../../services/page-template.service';
import { CommitmentDashboardService } from '../../../services/commitment-dashboard.service';
import { IPager } from '../../../shared/pagination';
import { IQuery } from '../../../shared/query';
import * as $ from 'jquery';
import { ContentService } from '../../../services/content.service';
declare var $: any;
import { Router } from '@angular/router';
import { ExportToExcelService } from '../../../services/export-to-excel.service';
import moment from 'moment';
import { IUser } from '../../../shared/user';
import { UserService } from '../../../services/user.service';
import { IS_PAGE_TEMPLATE_ADMIN, OneViewType, ROLES } from 'src/app/core/constants';
import { IPageTemplate } from 'src/app/shared/page-template';
import { PermissionsService } from 'src/app/services/permissions.service';
import { CommitmentReviewDTO } from './models/commitment-review-d-t-o';
import { ClientNoteService } from 'src/app/services/client-note.service';
import { ClientNoteInput, OneViewNoteInput } from './models/client-note-input';
import { AlertType } from '../commitments-intake/enums/alert-type.enum';
import { AlertInput } from '../commitments-intake/models/alert-input';
import { DateFormatPipe } from 'src/app/SharedModule/Pipes/date-format.pipe';
import { ClientsService } from 'src/app/services/clients.service';
import { ClientDashboardContactDTO, ClientSuggestionDTO, DashboardCommitmentDTO, DashboardCommitmentProductDTO } from './models/client-dashboard-d-t-o';
import { ManageClientDashboardService } from './services/manageClientDashboard.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommitmentIntakeService } from 'src/app/services/commitment-intake.service';
import { ICommitmentIntakePageTemplateAccessDTO } from '../commitment-intakes/models/commitment-intake-d-t-o';
import { NgSelectComponent } from '@ng-select/ng-select';
import { ClientOneViewService } from 'src/app/services/client-oneview.service';
import { DateTimeService } from '../commitment-intakes/services/date-time.service';
import { CustomClientService } from 'src/app/services/custom-client.service';

@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.css']
})
export class ClientDashboardComponent implements OnInit, AfterViewInit {
  public alertType = AlertType;
  public alertInput: AlertInput = {
    alertType: AlertType.Success,
    message: ''
  };
  public note = '';
  public isClientSuggestionLoading: boolean = true;
  public isNotpadSectionVisible = false;
  public isStrategicClientAssignedUser = false;
  public SelectedCEUsers: any[];
  public SelectedCEUsersDetails: any[];
  public commitmentReviews: CommitmentReviewDTO[] = [];
  public sortingObjectProject: { key?: string, isAsc?: boolean } = {};
  public sortingObjectNonProject: { key?: string, isAsc?: boolean } = {};
  public selectedClientEntityKey?: number = null;
  public selectedClientDunsNumber?: string = null;
  public selectedClientEntityTitle: string = null;
  public allClientOneViews: ClientSuggestionDTO[] = [];
  public clientSuggestions: ClientSuggestionDTO[] = [];
  public oneViewClientSuggestions: any[] = [];
  public selectedOneViewClients: any[] = [];
  public isOneViewSelected = false;
  public clientTypeSearch: any = 'duns';
  public projectTotalCount : any = 0;
  public nonProjectTotalCount :any = 0;
  public manualRowsPerPage : number = 10;
  public drawerActive: boolean = false;
  public detailsCommitment: DashboardCommitmentDTO = null;
  public content: any;
  public summary: any = {};
  public slaDashActive: boolean = true;
  public productOptions: any;
  public commitmentStats: any = { 'Active': 0, 'Cancelled': 0, "Completed": 0, "On Hold": 0, "Total": 0, "Delivered": 0 };
  public isCommitmentCalendarError: boolean = false;
  public isRowsLoading: boolean = true;
  public commitmentProducts: any;
  public isError: boolean;
  public errorMsg: any;
  public lastLoginTimeStamp: string;
  public allCommitments: DashboardCommitmentDTO[] = [];
  public contacts: ClientDashboardContactDTO[] = [];
  public isPageLoad: boolean = true;
  public typeOfCommitmentCalander: string = 'quarter';
  public filterPage: any = [];
  public isRm: boolean = false;
  public isClientAdmin = false;
  public isClient: boolean = false;
  public isAdmin: boolean = false;
  public lineOfBusinessOptions: any = [];
  public pagerObjectProduct: IPager = {
    totalCount: 0,
    pageSize: 0,
    currentPage: 0,
    totalPages: 0
  };
  public pageSizeProduct: any = 7;
  public ngShows: boolean = false;
  public pageProducts: any[] = [];
  public isClientExecutive: boolean = false;
  public commitmentsError: boolean = false;
  public showClientUserEditModal: boolean = false;
  public selectedForeditUserAssignement: any;
  public pageTemplate: IPageTemplate;
  public pageTemplateAdmin: boolean = false;
  public userData: any;
  public isRestrictedAdmin: boolean = false;
  public isAccountTeam: boolean = false;
  public isCCTAdmin: boolean = false;
  public isPdfExport: boolean = false;
  public isCCTAdminReadOnly = false;
  public isSalesExecutive = false;
  public isAccountManager = false;
  public isAccountExecutive = false;
  public isNewRM=false;
  public totalDayChangesSign = '×';
  public isInitialSearch: boolean = true; 
  public commitmentProjectRows: DashboardCommitmentDTO[] = [];
  public commitmentManualRows: DashboardCommitmentDTO[] = [];
  public commitmentProjectRowsCount: number = 0;
  public commitmentManualRowsCount: number = 0;
  public isCustomClient: boolean = false;
  public topFilterForm: FormGroup;
  public blueThemeMode = false;
  public isClientLoadedPartofOneView: boolean = false;
  public isClientSuperUser: boolean = false;
  public filterUserActivePage: boolean = false;
  public clientUserSuggestions: any[];
  public selectedCEUsersForSearch: any[];

  private firstTime = true;  
  private notepadSectionType = '';
  private readonly _oneViewSuffix = '[OneView]';
  private _grayStage = [
    'Fiserv - On Hold',          
    'Client - On Hold',
    'On Hold',
    'Delivered',
    'Completed',
    'Cancelled'
  ];
  private _redStage = [            
    'In Progress',
    'In Planning',
    'Not Started',
    'Fiserv - On Hold'      
  ];

  @ViewChild('clientSelect') private clientSelect:
  | NgSelectComponent
  | undefined;

  @ViewChild('lineOfBusinessSelect') private lineOfBusinessSelect:
    | NgSelectComponent
    | undefined;

  @ViewChild('selectedProductSelect') private selectedProductSelect:
    | NgSelectComponent
    | undefined;
    
  constructor(
    private fb: FormBuilder,
    private pageTemplateService: PageTemplateService,
    private _commitmentIntakeService: CommitmentIntakeService,
    private commitmentDashboardService: CommitmentDashboardService,
    private contentService: ContentService,
    private clientService: ClientsService,
    private router: Router,
    private excelService: ExportToExcelService,
    private userService: UserService,
    private clientNoteService: ClientNoteService,
    private customClientService: CustomClientService,
    private permission: PermissionsService,
    private _datePipe: DateFormatPipe,
    private _manageClientDashboardService: ManageClientDashboardService,
    private _oneViewService: ClientOneViewService,
    private _dateTimeService: DateTimeService) {
      this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r); 
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $(".cct-table").tooltip({ selector: '[data-toggle=tooltip]' });
      $('[data-toggle="tooltip"]').tooltip();
    });
    
    $('.view-contacts-dropdown').on('hide.bs.dropdown', function () {
      const contactsButton =$(this).find('.btn-contacts');
      $(contactsButton).removeClass('clicked');
    });

    this.sortingObjectProject = { key:"isRisk", isAsc: false};
    this.sortingObjectNonProject = { key:"isRisk", isAsc: false};
    this.clearTopFilter();
    this.selectedClientEntityKey = null;
    this.selectedClientDunsNumber = null;
    const user = this.userService.getUserListData();
    if (!user) {
      this.userService.callUserListData().subscribe((data) => {
        this.initialUserData(data);
      });
    }
    else {
      this.initialUserData(user);
    }

    this.content = this.contentService.getcontent();
    if (!this.content) {
      this.contentService.setContent().then(data => {
        this.content = this.contentService.getcontent();
      });
    }

    this.getPageTemplateData();
  }

  private initialUserData(user: IUser) {
    if (user.type.toLowerCase() === 'client') {
      // For OneView
      this._oneViewService.getByClientId(user.organization).subscribe(oneView => {
        this.isOneViewSelected = true;
        this.initalDashboard(user, oneView);
      }, (error) => {
        this.initalDashboard(user);
      });
    } else {
      this.initalDashboard(user);
    }    
  }

  private initalDashboard(user: IUser, oneView?: any) {
    this.setUserData(user);
    this.clearDashboard();
    if (oneView) {
      // Incase user is a client user and user's client has been assigned to an oneview then oneview name should be shown instead of client name.
      this.selectedClientEntityKey = oneView.id;
      this.selectedClientEntityTitle = oneView.oneViewGroupName;
      this.loadOneViewNote();
    }
    this.initialPageTemplate();
    if (this.isClient) {
      this.getDataDateWise();
    }
    if (!this.isClient || this.isClientAdmin) {
      this.getCommitmentClients();
    }
  }

  private initialPageTemplate() {
    this.content = this.contentService.getcontent();
    if (!this.content) {
      this.contentService.setContent().then(data => {
        this.content = this.contentService.getcontent();
      });
    }

    this.getPageTemplateData();
  }

  private setUserData(user: IUser) {
    this.userData = user;
    this.getLoginTimestamp(user);
    if (user.type.toLowerCase() === 'client') {
      this.selectedClientEntityKey = user.organization;
      this.selectedClientEntityTitle = user.organizationTitle;
      this.selectedClientDunsNumber = user.clientDunsId;
      this.isClient = true;
      if (this.isOneViewSelected == false) {
        this.loadClientNote();
      }
      this.isClientAdmin = user.roles.some(r => r.roleTitle.toLowerCase() === 'client admin');
    }
    this.isClientSuperUser = user.roles.some(r => r.roleTitle.toLowerCase() === 'admin') && user.roles.some(r => r.roleTitle.toLowerCase() === 'client view user');
    this.isSalesExecutive = this.permission.isSalesExecutive();
    this.isAdmin = false;
    this.isCCTAdmin = false;
    this.isRm = false;
    this.isClientExecutive = false;
    this.isAccountTeam = false;
    this.isAccountManager = false;
    for (let p in user.roles) {
      const role = user.roles[p].roleTitle.toLowerCase();
      switch (role) {
        case ROLES.Admin.toLocaleLowerCase():
          this.isAdmin = true;
          break;
        case ROLES.CCT_Admin.toLocaleLowerCase():
          this.isCCTAdmin = true;
          break;
        case ROLES.Relationship_Manager.toLocaleLowerCase():
          this.isRm = true;
          break;
        case ROLES.Account_Team.toLocaleLowerCase():
          this.isAccountTeam = true;
          break;
        case ROLES.Account_Manager.toLocaleLowerCase():
          this.isAccountManager = true;
          break;
        case ROLES.Client_Executive.toLowerCase():
          this.isClientExecutive = true;
          break;
        case ROLES.Account_Executive.toLowerCase():
          this.isAccountExecutive = true;
          break;
        case ROLES.New_RM.toLowerCase():
          this.isNewRM = true;
          break;
        default:
          break;
      }
    }
  }

  initData() {
    this.clearDashboard();
    this.getDataDateWise();
    this.getCommitmentClients();
  }

  clearTopFilter() {
    this.topFilterForm = this.fb.group({
      lineOfBusiness: [null],
      selectedProduct: [null],
      fromMonth: null,
      toMonth: null,
    });

    if (this.lineOfBusinessSelect) {
      this.lineOfBusinessSelect.searchTerm = null;
    }
    if (this.selectedProductSelect) {
      this.selectedProductSelect.searchTerm = null;
    }
  }

  clearDashboard() {
    this.filterPage = '';
    this.slaActiveTab(true);
    this.clearTopFilter();
    this.allCommitments = [];
    this.contacts = [];
    this.isRowsLoading = false;
    this.typeOfCommitmentCalander = 'quarter';
    this.ngShows = false;
    this.commitmentProducts = [];
    this.pagerObjectProduct = {
      totalCount: 0,
      pageSize: 0,
      currentPage: 0,
      totalPages: 0
    };
    this.pageProducts = [];
  }

  ngAfterViewInit(): void {
    this.heightCalculator();

    const resize_ob = new ResizeObserver(() => {
      this.heightCalculator();
    });

    resize_ob.observe(document.getElementById("myTabContentForClientDashboard"));
  }

  slaActiveTab(value: boolean) {
    this.slaDashActive = value;
  }

  onCommitmentStatsChange(event: Object) {
    this.commitmentStats = event;
  }

  clearTopSearch() {
    this.resetHeight();
    this.clearTopFilter();
    this.getDataDateWise();
  }

  topSearch() {
    this.getDataDateWise(true);
  }

  getDataDateWise(load = false) {
    if (load === true) {
      this.resetHeight();
    }
    const queryParam = this.generateQueryParam();
    this.initateFilterSearch(queryParam);
    this.filterUserActivePage = false;
    this.getCommitmentReviewsByClientId();
  }

  initateFilterSearch(queryParam: IQuery) {
    this.isInitialSearch = true;
    this.getClientCommitments(queryParam);
  }

  generateQueryParam(): IQuery {
    const formFields = { 'isReviewed': [true] };
    const formValue = this.topFilterForm.value;
    if (formValue && formValue.lineOfBusiness) {
      formFields['LineOfBusinesses'] = [formValue.lineOfBusiness]
    }
    if (formValue && formValue.selectedProduct) {
      formFields['ProductNames'] = [formValue.selectedProduct]
    }

    const queryParam = {
      searchFields: formFields,
      searchClientBy: this.clientTypeSearch
    };

    if (formValue && formValue.fromMonth) {
      let fromDate: any = '';
      if (formValue.fromMonth != '') {
        const getYearAndMonth = formValue.fromMonth.split('-');
        fromDate = `${Number(getYearAndMonth[0])}-${Number(getYearAndMonth[1])}-1`;
      }
      queryParam['StartDate'] = fromDate;
    }
    if (formValue && formValue.toMonth) {
      let toDate: any = '';
      if (formValue.toMonth != '') {
        const toDateYearAndMonth = formValue.toMonth.split('-');
        const tempToDate = new Date(toDateYearAndMonth[0], toDateYearAndMonth[1], 0)
        toDate = `${tempToDate.getFullYear()}-${tempToDate.getMonth() + 1}-${tempToDate.getDate()}`;
      }
      queryParam['EndDate'] = toDate;
    }

    if (this.clientTypeSearch === 'duns' && this.isOneViewSelected) {
      queryParam['oneViewGroupId'] = this.selectedClientEntityKey;
      queryParam['searchClientBy'] = 'oneview';
    } else if (this.clientTypeSearch === 'duns') {
      queryParam['clientId'] = this.selectedClientEntityKey;
      queryParam['clientDunsNumber'] = this.selectedClientDunsNumber;
    } else if (this.clientTypeSearch === 'gud') {
      queryParam['gud'] = this.selectedClientEntityKey.toString();
    }

    if (this.isOneViewSelected && this.selectedOneViewClients && this.selectedOneViewClients.length > 0) {
      queryParam['oneViewClientIds'] = this.selectedOneViewClients;
    }
    else {
      delete queryParam['oneViewClientIds'];
    }

    if (this.selectedCEUsersForSearch) {
      queryParam['strategicClientUserIds'] = this.selectedCEUsersForSearch;
    }

    return queryParam;
  }

  getPageTemplateData() {
    this._commitmentIntakeService.getPageTemplateAccess()
    .pipe(take(1))
    .subscribe(
      (page) => {
        const pageTemplateAccess = page.body as ICommitmentIntakePageTemplateAccessDTO;
        this.pageTemplateAdmin = pageTemplateAccess.isPageAdmin;
        this.isCCTAdminReadOnly = pageTemplateAccess.isPageReadAdmin;
        localStorage.setItem(IS_PAGE_TEMPLATE_ADMIN, JSON.stringify(this.pageTemplateAdmin));
      },
      (error) => {
        this.errorMsg = error;
      }
    );
  }

  populateProjectTotalCount(total: number) {
    this.projectTotalCount = total;
  }

  populateProjectNonTotalCount(total: number) {
    this.nonProjectTotalCount = total;
  }

  clientTypeChange() {
    this.clearDashboard();
    this.clearClientSelected();
    this.getCommitmentClients();
  }

  public onReviewCommitmentModalSubmit(): void {
    this.getDataDateWise(true);
  }

  private getCommitmentReviewsByClientId(): void {
    this.commitmentReviews = [];
    this.pageTemplateService.getCommitmentReviewsByClientId(this.selectedClientEntityKey).subscribe(result => {
      this.commitmentReviews = result.body as CommitmentReviewDTO[];
    });
  }

  openDrawer() {
    this.drawerActive = true;
  }

  closeDrawer() {
    this.detailsCommitment = null;
    this.drawerActive = false;
    $('body').removeClass('modal-open');
    $('body').removeClass('overflow-hidden');
    $('html').removeClass('overflow-hidden');
  }

  updateStrategicClientUsers(commitment: any) {
    var commitmentId = commitment.commitmentId;
    var request = [];
    this.customClientService.createCustomClientUsers(commitmentId, request).subscribe((response) => { }, (error) => { });
  }

  public untrackCommitment(): void {

    this.updateStrategicClientUsers(this.detailsCommitment);
    this.commitmentDashboardService.untrackCommitment(this.detailsCommitment.datasource, this.detailsCommitment.projectKey).subscribe(() => {
      this.getDataDateWise(true);
      this.closeDrawer();
    }); 
  }

  navigate(event: { action: string, data: DashboardCommitmentDTO }) {
    this.detailsCommitment = event.data;
    this.getStrategicClientAssignedUsers(this.detailsCommitment);
    this.openDrawer();
  }

  getStrategicClientAssignedUsers(commitment: any) {
    var projectKey = commitment.projectKey;
    var OneViewId = commitment.oneViewId;
    if (OneViewId > 0) {
      this.SelectedCEUsersDetails = [];
      this.customClientService.GetCustomOneViewUsers(projectKey).subscribe(
        result => {
          this.SelectedCEUsersDetails = result;
          let SelectedCEUsersArray = [];
          for (var i = 0; i < this.SelectedCEUsersDetails.length; i++) {
            SelectedCEUsersArray.push(this.SelectedCEUsersDetails[i].userId);
          }
          this.SelectedCEUsers = SelectedCEUsersArray;
        }

        , error => { }
      );
    }
    else {
      this.SelectedCEUsersDetails = [];
      this.customClientService.getCustomCLientUsers(projectKey).subscribe(
        result => {
          this.SelectedCEUsersDetails = result;
          let SelectedCEUsersArray = []
          for (var i = 0; i < this.SelectedCEUsersDetails.length; i++) {
            SelectedCEUsersArray.push(this.SelectedCEUsersDetails[i].userId);
          }
          this.SelectedCEUsers = SelectedCEUsersArray;
        }
        , error => { }
      );
    }    
  }

  openUpdateUsersModal(pageResponse: any) {
    this.selectedForeditUserAssignement = pageResponse;
    this.showClientUserEditModal = true;
  }

  editCommitmentUsersModal() {
    this.showClientUserEditModal = false;
    this.closeDrawer();
    let event = { action: "view", data : this.selectedForeditUserAssignement }
    this.navigate(event);
  }

  getSuggestions() {
    this.productOptions = [];
    this.lineOfBusinessOptions = [];
    if (!this.selectedClientEntityKey) {
      return;
    }
    
    const productTitles: string[] = this.allCommitments
      .map((p) => p.productList)
      .reduce((a, b) => a.concat(b), [])
      .map(x => x.productName);
   
    const uniqueProductsMap = new Map<string, string>();
    productTitles.map((title, index) => {
      if (title && !uniqueProductsMap.has(title)) {
        uniqueProductsMap.set(title, index.toString());
      }
    });

    this.productOptions = Array.from(uniqueProductsMap, ([title, key]) => ({
      id: key,
      title: title
    }));

    const lobs: string[] = this.allCommitments
    .map((p) => p.lineOfBusinessArray)
    .reduce((a, b) => a.concat(b), [])
    .map(x => x);
   
    const uniqueLobsMap = new Map<string, string>();
    lobs.map((title, index) => {
      if (title && !uniqueLobsMap.has(title)) {
        uniqueLobsMap.set(title, index.toString());
      }
    });

    this.lineOfBusinessOptions = Array.from(uniqueLobsMap, ([title, key]) => ({
      id: key,
      title: title
    }));
  }

  getClientCommitments(queryParam: IQuery) {
    this.isRowsLoading = true;
    this.commitmentsError = false;
    this.allCommitments = [];
    this.contacts = [];
    this.commitmentProducts = [];
    this.pageProducts = [];
    this.commitmentDashboardService.getCommitments(queryParam).pipe(take(1)).subscribe(
      response => {
        this.heightCalculator();
        const commitmentsData = response.body;
        this.rePopulateCommitments(commitmentsData.commitments);
        this.contacts = commitmentsData.contacts;
        this.allCommitments = commitmentsData.commitments;

        if (this.isInitialSearch) {
          this.getSuggestions();
          this.commitmentsbyProduct();
        }
        
        this.isRowsLoading = false;
      },
      (error) => {
        this.commitmentsError = true;
      }
    );
  }

  /***Commitments By Products */
  commitmentsbyProduct() {
    this.ngShows = false;
    const products: DashboardCommitmentProductDTO[] = this.allCommitments
      .map((p) => p.productList)
      .reduce((a, b) => a.concat(b), [])
      .map(x => {
        return {
          productName: x.productName,
          productDescription: x.productDescription
        };
      });

    const productList = [];
    products.forEach(p => {
      const existingProduct = productList.find(c => c.productName === p.productName);
      if (existingProduct) {
        existingProduct.totalCommitments += 1;
      }
      else {
        productList.push({ ...p, totalCommitments: 1 });
      }
    });

    this.commitmentProducts = productList.sort((a, b) => parseFloat(b.totalCommitments) - parseFloat(a.totalCommitments));
    let totalPages = Math.floor(this.commitmentProducts.length / this.pageSizeProduct);
    if ((totalPages * this.pageSizeProduct < this.commitmentProducts.length)) {
      totalPages = totalPages + 1;
    }
    this.pagerObjectProduct = { "totalCount": this.commitmentProducts.length, "pageSize": this.pageSizeProduct, "currentPage": 1, "totalPages": totalPages }
    this.pageWiseProduct(1);
    this.ngShows = true;
  }

  changePageProduct(page) {
    this.pageWiseProduct(page)
  }

  pageWiseProduct(pageNumber: number) {
    this.pageProducts = [];
    const start = (pageNumber - 1) * this.pageSizeProduct;
    const end = pageNumber * this.pageSizeProduct;
    for (let k = start; k < end; k++) {
      const currentProduct = this.commitmentProducts[k];
      if (currentProduct) {
        currentProduct.productName = currentProduct.productName.trim();
        if (currentProduct.productDescription) {
          currentProduct.productDescription = currentProduct.productDescription.trim();
        }
        else {
          currentProduct.productDescription = currentProduct.productName;
        }
        this.pageProducts.push(currentProduct)
      }
    }
  }

  private normalizeArrayFromText(arrayText: string) {
    if (!arrayText) {
      return [];
    }

    const array = JSON.parse(arrayText) as any[];
    if (!array || array.length === 0) {
      return [];
    }

    return array.map(p => p.replace(/"/g, ""));
  }

  private rePopulateCommitments(commitments: DashboardCommitmentDTO[]) {
    commitments.forEach((data) => {
      const normalizedProducts = data.productList.map(p => p.productName);
      if (normalizedProducts) {
        data.productNames = normalizedProducts.join(',');
      }

      const lineOfBusinesses = JSON.stringify(data.lineOfBusinesses.split(','));
      const normalizedLobs = this.normalizeArrayFromText(lineOfBusinesses);
      if (normalizedLobs) {
        data.lineOfBusinesses = normalizedLobs.join(',');
        data.lineOfBusinessArray = normalizedLobs;
      }
      
      if (data.clientTotalOfDaysChangedCount && data.clientTotalOfDaysChanged && data.fiservTotalOfDaysChangedCount && data.fiservTotalOfDaysChanged) {
        data.totalOfDaysChanged = `Client,${data.clientTotalOfDaysChangedCount},${data.clientTotalOfDaysChanged}×Fiserv,${data.fiservTotalOfDaysChangedCount},${data.fiservTotalOfDaysChanged}`;
      }
      const commitmentStatuses = [
        'Completed',
        'Cancelled',
        'On Hold',
        'Delivered',
        'Client - On Hold' 
      ];
      if (commitmentStatuses.includes(data.commitmentStatus) || ((['Fiserv - On Hold', 'Not Started', 'In Planning'].includes(data.commitmentStatus)) && data.isRisk == 'false')) {
        data.isRisk = 'blank';
      }
    });
  }
  
  setTypeOfCommitmentCalander(typeOfCommitmentCalander: string) {
    this.typeOfCommitmentCalander = typeOfCommitmentCalander;
  }

  isAtRisk(row: any, key: any) {
    const data = row[key].replace('Fulfillment - ', '');
    if (key !== 'isRisk') {
      return false;
    }

    const commitmentStatus = row['commitmentStatus'];
    const isRisk = data.toString() === 'true';
    return isRisk && (this._redStage.includes(commitmentStatus));
  }
  
  setOrderByProject(sortingData: { key?: string, isAsc?: boolean }) {
    if (sortingData) {
      this.sortingObjectProject = sortingData;
    }
    else {
      this.sortingObjectProject = {};
    }
    this.isInitialSearch = false;
  }

  setOrderByNonProject(sortingData: { key?: string, isAsc?: boolean }) {
    if (sortingData) {
      this.sortingObjectNonProject = sortingData;
    }
    else {
      this.sortingObjectNonProject = {};
    }
    this.isInitialSearch = false;
  }

  getCommitmentClients() {
    this.isClientSuggestionLoading = true;
    this.commitmentDashboardService.getCommitmentClients(this.clientTypeSearch).subscribe(
      data => {
        this.allClientOneViews = data;
        const clientData = data;
        const oneViews = [];
        var clients = [];
        clientData.forEach(item => {
          if (item.oneViewGroupName && !oneViews.some(x => x.id === item.oneViewGroupId)) {
            oneViews.push({
              id: item.oneViewGroupId,
              titleWithDuns: `${item.oneViewGroupName} ${this._oneViewSuffix}`,
              type: OneViewType
            });
          }

          if (!clients.some(x => x.id === item.id)) {
            clients.push(item);
          }
        });
        if (this.isClientAdmin) {
          clients = clients.filter(c => c.oneViewGroupId == 0);
        }
        this.clientSuggestions = [...oneViews, ...clients];
        if (this.isClientAdmin && this.firstTime) {
          this.firstTime = false;
          this.selectedClientEntityTitle = this.clientSuggestions.find(r => r.id === this.selectedClientEntityKey).titleWithDuns;
          this.clientSelect.searchTerm = this.selectedClientEntityTitle;
        }
        
        this.isClientSuggestionLoading = false;
      },
      error => {
        this.isClientSuggestionLoading = false;
      }
    );
  }

  private heightCalculator(): void {
    const tabSection = document.getElementById('tabSection');
    if (!tabSection) {
      return;
    }

    const tabSectionHeight = tabSection.clientHeight;
    document.getElementById('sideButton').style.height = (tabSectionHeight / 2 - 15) + 'px';
    document.getElementById('sideButton2').style.height = (tabSectionHeight / 2 - 17) + 'px';
    if (this.noteVisibilityCheck()) {
      document.getElementById('noteTextarea').style.height = (tabSectionHeight - (tabSectionHeight / 3)) + 'px';
    } else {
      document.getElementById('notePreTag').style.height = (tabSectionHeight - (tabSectionHeight / 3)) + 'px';
    }

    const temp15 = document.getElementById('tab15boxWrapper');
    if (temp15) {
      temp15.style.height = (tabSectionHeight - 32) + 'px';
    }
    const temp16 = document.getElementById('tab16boxWrapper');
    if (temp16) {
      temp16.style.height = (tabSectionHeight - 32) + 'px';
    }
  }

  private resetHeight(): void {
    document.getElementById('sideButton').style.height = '339px';
    document.getElementById('sideButton2').style.height = '337px';
    if (this.noteVisibilityCheck()) {
      document.getElementById('noteTextarea').style.height = '472px';
    } else {
      document.getElementById('notePreTag').style.height = '472px';
    }

    const temp15 = document.getElementById('tab15boxWrapper');
    if (temp15) {
      temp15.style.height = '676px';
    }
    const temp16 = document.getElementById('tab16boxWrapper');
    if (temp16) {
      temp16.style.height = '676px';
    }
  }

  clientSelected(event) {
    if (!this.clientSuggestions) {
      return;
    }

    this.note = '';
    this.alertInput.message = '';
    this.resetHeight();
    if (!event) {
      this.clearClientSelected();
      return;
    }

    if (event.type === OneViewType) {
      this.oneViewClientSuggestions = this.allClientOneViews.filter(x => x.oneViewGroupId === event.id).map(x => {
        return {
          id: x.id,
          name: x.titleWithDuns
        }
      });
      this.isClientLoadedPartofOneView = false;
      this.isOneViewSelected = true;
      this.selectedClientDunsNumber = null;
      this.selectedClientEntityKey = event.id;
      this.getClientUserSuggestions();
      this.loadOneViewNote();
    }
    else {
      this.isOneViewSelected = false;
      this.oneViewClientSuggestions = [];
      this.selectedOneViewClients = [];
      if (this.clientTypeSearch == 'duns') {
        this.selectedClientEntityKey = event.id;
        this.selectedClientDunsNumber = event.dunsId;
        this.getClientUserSuggestions();
      }
      else {
        this.selectedClientEntityKey = event.gud;
      }
    }

    this.selectedClientEntityTitle = event.titleWithDuns;
    this.checkRestrictedAdmin();
    this.initData();
    if (this.isOneViewSelected == false) {
      this.loadClientNote();
      this.checkIfClientPartofOneView();
      this.clientService.getClientDataById(this.selectedClientEntityKey).subscribe(
        res => {
          this.isCustomClient = res.isSrategicCLient;
        }, error => { }
      );
    }
    if (this.isOneViewSelected == true) {
      this._oneViewService.get(this.selectedClientEntityKey).subscribe(
        result => {
          var res = result.clients.filter(x => x.isStrategicClient == true);
          if (res.length > 0) {
            this.isCustomClient = true;
          }
          else {
            this.isCustomClient = false;
          }
        }, error => { }
      );
    }
  }

  clearClientSelected() {
    this.note = '';
    this.alertInput.message = '';
    this.resetHeight();
    this.selectedClientDunsNumber = null;
    this.selectedClientEntityKey = null;
    this.selectedClientEntityTitle = null;
    this.isOneViewSelected = false;
    this.oneViewClientSuggestions = [];
    this.selectedOneViewClients = [];
    this.isCustomClient = false;
    this.clientUserSuggestions = [];
    this.lineOfBusinessOptions = [];
    this.productOptions = [];
    this.selectedCEUsersForSearch = [];
    this.clientSelect.searchTerm = null;
    this.clearDashboard();
  }

  oneViewClientsSelected() {
    this.getDataDateWise(false);
  }

  checkIfClientPartofOneView() {
    this._oneViewService.getByClientId(this.selectedClientEntityKey).subscribe(
      result => {
        if (result.id > 0) {
          this.isClientLoadedPartofOneView = true;
        }
      }, error => { }
    );
  }

  getClientUserSuggestions() {
    if (this.isOneViewSelected == true) {
      this.clientService.getClientExecutiveUsersByOneViewGroupId(this.selectedClientEntityKey)
        .subscribe(suggestions => {
          this.clientUserSuggestions = suggestions;
          this.selectAllForDropdownItems(this.clientUserSuggestions);
        },
          error => { this.errorMsg = error; });
    }
    else {
      this.clientService.getClientExecutiveUsersByClientId(this.selectedClientEntityKey)
        .subscribe(suggestions => {
          this.clientUserSuggestions = suggestions;
          this.selectAllForDropdownItems(this.clientUserSuggestions);
        },
          error => { this.errorMsg = error; });
    }
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = items => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };

    allSelect(items);
  }

  addUserstoAdvanceSearch() {
    this.initData();
  }

  addUserstocancelSearch() {
    this.selectedCEUsersForSearch = [];
    this.initData();
  }

  checkRestrictedAdmin() {
    if (this.userData.isRestrictedAdmin === true) {
      let isExists: boolean = false;
      const sortOrder = ['Read', 'Write'];
      const groups = this.userData.groups.sort((a, b) => { return sortOrder.indexOf(a.accessLevel) - sortOrder.indexOf(b.accessLevel); });
      for (let g in groups) {
        isExists = false;
        if (this.selectedClientEntityKey != null && this.selectedClientEntityKey > 0) {
          if (groups[g].clients.some(clnt => clnt.clientId === this.selectedClientEntityKey)) {
            isExists = true;
          }
        }

        if (isExists) {
          this.isRestrictedAdmin = groups[g].accessLevel === "Write" ? true : false;
        }
      }
    }
  }

  exportExcelNonRisk(query: { formFields: any, orderBy: string }, isManualExporting: boolean = false) {
    const { formFields, orderBy } = query;
    const exportResponses = [];
    const queryParam = this.generateQueryParam();
    queryParam.orderBy = orderBy;
    queryParam.searchFields = {
      ...queryParam.searchFields,
      ...formFields
    };
    this.commitmentDashboardService.exportCommitments(queryParam).subscribe(responses => {
      const originalList = responses.body;
      originalList.forEach(element => {
        // element.displayPlannedCommittedDeliveryDate = "" when stage like '%On Hold%' for all sources and Only On ClientDashboard/export - CCT and Detail page shows value from the db.      
        element.displayPlannedCommittedDeliveryDate = element.commitmentStatus.indexOf('On Hold') > -1 ? "" : this.dateFormat(element.displayPlannedCommittedDeliveryDate);               
        element.originalPlannedCommittedDeliveryDate = this.dateFormat(element.originalPlannedCommittedDeliveryDate);
      });
      let sortingObject = null;
      if (isManualExporting) {
        sortingObject = this.sortingObjectNonProject;
      }
      else {
        sortingObject = this.sortingObjectProject;
      }
      const exportResponseList = this._manageClientDashboardService.applySorting(originalList, sortingObject);
      const len = Object.keys(exportResponseList).length;
      let j = 0;
      for (let i = 0; i < len; i++) {
        const projectNumber = exportResponseList[i]['projectNumber'] ? exportResponseList[i]['projectNumber'] : '— — —';
        const commitmentStatus = exportResponseList[i]['commitmentStatus'];
        let displayPlannedCommittedDeliveryDate = exportResponseList[i]['displayPlannedCommittedDeliveryDate'];
        displayPlannedCommittedDeliveryDate = commitmentStatus.indexOf('On Hold') > -1 ? '— — —' :      
          (!displayPlannedCommittedDeliveryDate || displayPlannedCommittedDeliveryDate.includes('1900'))
          ? '— — —'
          : displayPlannedCommittedDeliveryDate;

        const isManual = exportResponseList[i]['datasource'].toLowerCase() === 'manual';
        const canExport = isManualExporting 
          ? (isManual && !projectNumber.includes('PR')) 
          : (!isManual || (isManual && projectNumber.includes('PR')));

        if(canExport) {
          const originalPlannedCommittedDeliveryDate = exportResponseList[i]['originalPlannedCommittedDeliveryDate'];
          exportResponses[j] = [];
          exportResponses[j]['Status'] = exportResponseList[i]['riskStatus'];
          exportResponses[j]['Project Number'] = projectNumber;
          exportResponses[j]['Title'] = exportResponseList[i]['commitmentTitle'];

          exportResponses[j]['Stage'] = commitmentStatus;
          exportResponses[j]['Original Delivery Date'] = (!originalPlannedCommittedDeliveryDate || originalPlannedCommittedDeliveryDate.includes('1900')) ? '— — —' : this._datePipe.transform(originalPlannedCommittedDeliveryDate.trim(), 'MM/dd/yyyy');
          exportResponses[j]['Target Delivery Date'] = displayPlannedCommittedDeliveryDate;
          exportResponses[j]['Date Change By Stakeholder'] = this.getStakeholderDaysChangedForExportExcel(exportResponseList[i]);
          
          const isRisk = exportResponseList[i]['isRisk']?.toLowerCase() === 'true';
          if (isRisk && (this._redStage.includes(commitmentStatus))) {
            exportResponses[j]['Status'] = 'Red';
          } else if (commitmentStatus == 'In Progress' && !isRisk) {
            exportResponses[j]['Status'] = 'Green';
          } else {
            exportResponses[j]['Status'] = 'Gray';
          }

          if (this._grayStage.includes(exportResponses[j]['Stage'])) {
            exportResponses[j]['Status'] = 'Gray';
          }

          const normalizedProducts = this.normalizeArrayFromText(exportResponseList[i]['productNames']);
          if (normalizedProducts) {
            exportResponses[j]['Product'] = normalizedProducts.join(',');
          }
          j++;
        }
      }
      this.excelService.exportAsExcelFile(exportResponses, `${this.selectedClientEntityTitle}_commitments`);
    }, (error) => { this.errorMsg = error; });
  }

  exportRiskExcel(query: { formFields: any, orderBy: string }, isManualExporting: boolean = false) {
    const { formFields, orderBy } = query;
    const queryParam = this.generateQueryParam();
    queryParam.orderBy = orderBy;
    queryParam.searchFields = {
      ...queryParam.searchFields,
      ...formFields
    };
    
    const statusOrder: {[key:string]:number} = {
      'In Progress': 1,
      'In Planning': 2,
      'Not Started': 3,
      'Fiserv - On Hold': 4,
      'Client - On Hold': 5,
      'On Hold': 6,
      'Delivered': 7,
      'Completed': 8,
      'Cancelled': 9      
    };

    const exportResponsesRed = [];
    const exportResponsesGreen = [];
    const exportResponsesBlank = [];
    
    this.commitmentDashboardService.exportCommitments(queryParam).subscribe(responses => {
      const originalList = responses.body;
      originalList.forEach(element => {        
        // element.displayPlannedCommittedDeliveryDate = "" when stage like '%On Hold%' for all sources and Only On ClientDashboard/export - CCT and Detail page shows value from the db.      
        element.displayPlannedCommittedDeliveryDate = element.commitmentStatus.indexOf('On Hold') > -1 ? "" : this.dateFormat(element.displayPlannedCommittedDeliveryDate);   
        element.originalPlannedCommittedDeliveryDate = this.dateFormat(element.originalPlannedCommittedDeliveryDate);
      });
      let sortingObject = null;
      if (isManualExporting) {
        sortingObject = this.sortingObjectNonProject;
      }
      else {
        sortingObject = this.sortingObjectProject;
      }
      const exportResponseList = this._manageClientDashboardService.applySorting(originalList, sortingObject);
      const len = Object.keys(exportResponseList).length;
      let j = 0; let k = 0; let l = 0;
      for (let i = 0; i < len; i++) {
        const projectNumber = exportResponseList[i]['projectNumber'] ? exportResponseList[i]['projectNumber'] : '— — —';
        const commitmentStatus = exportResponseList[i]['commitmentStatus'];
        let displayPlannedCommittedDeliveryDate = exportResponseList[i]['displayPlannedCommittedDeliveryDate'];
        displayPlannedCommittedDeliveryDate = commitmentStatus.indexOf('On Hold') > -1 ? '— — —'
          : (!displayPlannedCommittedDeliveryDate || displayPlannedCommittedDeliveryDate.includes('1900'))
            ? '— — —'
            : displayPlannedCommittedDeliveryDate;
        const isManual = exportResponseList[i]['datasource'].toLowerCase() === 'manual';
        const isRisk = exportResponseList[i]['isRisk']?.toLowerCase() === 'true';
        const canExport = isManualExporting 
          ? (isManual && !projectNumber.includes('PR')) 
          : (!isManual || (isManual && projectNumber.includes('PR')));

        if(canExport) {
          if ((isRisk && this._redStage.includes(commitmentStatus)))  {
            const originalPlannedCommittedDeliveryDate = exportResponseList[i]['originalPlannedCommittedDeliveryDate'];
            exportResponsesRed[j] = [];
            exportResponsesRed[j]['Status'] = exportResponseList[i]['riskStatus'];
            exportResponsesRed[j]['Project Number'] = projectNumber;
            exportResponsesRed[j]['Title'] = exportResponseList[i]['commitmentTitle'];
  
            exportResponsesRed[j]['Stage'] = commitmentStatus;
            exportResponsesRed[j]['Original Delivery Date'] = (!originalPlannedCommittedDeliveryDate || originalPlannedCommittedDeliveryDate.includes('1900')) ? '— — —' : this._datePipe.transform(originalPlannedCommittedDeliveryDate.trim(), 'MM/dd/yyyy');
            exportResponsesRed[j]['Target Delivery Date'] = displayPlannedCommittedDeliveryDate;
            exportResponsesRed[j]['Date Change By Stakeholder'] = this.getStakeholderDaysChangedForExportExcel(exportResponseList[i]);
            exportResponsesRed[j]['Status'] = 'Red';

            const normalizedProducts = this.normalizeArrayFromText(exportResponseList[i]['productNames']);
            if (normalizedProducts) {
              exportResponsesRed[j]['Product'] = normalizedProducts.join(',');
            }
            j++;
            exportResponsesRed.sort((a,b) => {
              return statusOrder[a['Stage']] - statusOrder[b['Stage']];
            });
          }
          else if ((commitmentStatus == 'In Progress' && !isRisk)) {
            const originalPlannedCommittedDeliveryDate = exportResponseList[i]['originalPlannedCommittedDeliveryDate'];
            exportResponsesGreen[k] = [];
            exportResponsesGreen[k]['Status'] = exportResponseList[i]['riskStatus'];
            exportResponsesGreen[k]['Project Number'] = projectNumber;
            exportResponsesGreen[k]['Title'] = exportResponseList[i]['commitmentTitle'];
            exportResponsesGreen[k]['Stage'] = commitmentStatus;
            exportResponsesGreen[k]['Original Delivery Date'] = (!originalPlannedCommittedDeliveryDate || originalPlannedCommittedDeliveryDate.includes('1900')) ? '— — —' : this._datePipe.transform(originalPlannedCommittedDeliveryDate.trim(), 'MM/dd/yyyy');
            exportResponsesGreen[k]['Target Delivery Date'] = displayPlannedCommittedDeliveryDate;
            exportResponsesGreen[k]['Date Change By Stakeholder'] = this.getStakeholderDaysChangedForExportExcel(exportResponseList[i]);
            exportResponsesGreen[k]['Status'] = 'Green';

            const normalizedProducts = this.normalizeArrayFromText(exportResponseList[i]['productNames']);
            if (normalizedProducts) {
              exportResponsesGreen[k]['Product'] = normalizedProducts.join(',');
            }
            k++;
            exportResponsesGreen.sort((a,b) => {
              return statusOrder[a['Stage']] - statusOrder[b['Stage']];
            });
          }
          else {
            const originalPlannedCommittedDeliveryDate = exportResponseList[i]['originalPlannedCommittedDeliveryDate'];
            exportResponsesBlank[l] = [];
            exportResponsesBlank[l]['Status'] = exportResponseList[i]['riskStatus'];
            exportResponsesBlank[l]['Project Number'] = projectNumber;
            exportResponsesBlank[l]['Title'] = exportResponseList[i]['commitmentTitle'];
            exportResponsesBlank[l]['Stage'] = commitmentStatus;
            exportResponsesBlank[l]['Original Delivery Date'] = (!originalPlannedCommittedDeliveryDate || originalPlannedCommittedDeliveryDate.includes('1900')) ? '— — —' : this._datePipe.transform(originalPlannedCommittedDeliveryDate.trim(), 'MM/dd/yyyy');
            exportResponsesBlank[l]['Target Delivery Date'] = displayPlannedCommittedDeliveryDate;
            exportResponsesBlank[l]['Date Change By Stakeholder'] = this.getStakeholderDaysChangedForExportExcel(exportResponseList[i]);
            exportResponsesBlank[l]['Status'] = 'Gray';
            const normalizedProducts = this.normalizeArrayFromText(exportResponseList[i]['productNames']);
            if (normalizedProducts) {
              exportResponsesBlank[l]['Product'] = normalizedProducts.join(',');
            }
            l++;
            exportResponsesBlank.sort((a,b) => {
                return statusOrder[a['Stage']] - statusOrder[b['Stage']];
            });
          }
        }
      }

      let isRiskSorting = false;
      if (isManualExporting) {
        isRiskSorting = this.isRiskSorting(this.sortingObjectNonProject);
      }
      else {
        isRiskSorting = this.isRiskSorting(this.sortingObjectProject);
      }
      
      let exportResponses = [];
      if (isRiskSorting) {
        exportResponses = [...exportResponsesRed,...exportResponsesGreen,...exportResponsesBlank];
      }
      else{
        exportResponses = [...exportResponsesBlank,...exportResponsesGreen,...exportResponsesRed];
      }
      this.excelService.exportAsExcelFile(exportResponses, `${this.selectedClientEntityTitle}_commitments`);
    }, (error) => { this.errorMsg = error; });
  }

  getLoginTimestamp(user: IUser) {
    this.userService.getLoginData(user.userId).subscribe((data) => {
      moment.locale(this.content['locale']);
      this.lastLoginTimeStamp = moment.utc(data.body).local().format("dddd D MMMM YYYY HH:mm");
    },
    (error) => console.log("Error"));
  }

  openUserFilterPage() {
    this.filterUserActivePage = !this.filterUserActivePage;
  }

  renderPdfExport() {
    this.renderToExport().subscribe(()=> {
      setTimeout(() => {
        this.exportToPDF();
      }, 300);
    });
  }

  renderPPTExport() {
    this.renderToExport().subscribe(()=> {
      setTimeout(() => {
        this.exportToPPT();
      }, 300);
    });
  }

  renderToExport() {
    this.isPdfExport = true;
    const queryParam = this.generateQueryParam();
    queryParam['isNoteIncluded'] = true;    
    this.commitmentProjectRows = [];
    this.commitmentManualRows = [];
    return this.commitmentDashboardService.exportCommitments(queryParam).pipe(take(1)).map((response) => {
      const allCommitments = response.body;

      // Process project rows
      this.commitmentProjectRows = allCommitments.filter(e => {
        if (e.datasource.toLowerCase() === 'manual' && e.projectNumber != null && e.projectNumber.indexOf('PR') != -1) {
          return true;
        }
        return e.datasource.toLowerCase() !== 'manual';
      });
      this.commitmentProjectRows = this.processExportingRows(this.commitmentProjectRows, this.sortingObjectProject);
      this.commitmentProjectRowsCount = this.commitmentProjectRows.length;
      
      // Process manual rows
      this.commitmentManualRows = allCommitments.filter(e => e.datasource.toLowerCase() === 'manual' && e.projectNumber == null || e.projectNumber.indexOf('PR') === -1);
      this.commitmentManualRows = this.processExportingRows(this.commitmentManualRows, this.sortingObjectNonProject);
      this.commitmentManualRowsCount = this.commitmentManualRows.length;
      return true;
    });
  }

  processExportingRows(commitmentRows: DashboardCommitmentDTO[], sortingObject: { key?: string, isAsc?: boolean }) {
    const commitmentStatuses = [
      'Completed',
      'Cancelled',
      'On Hold',
      'Delivered',
      'Client - On Hold' 
    ];
    
    commitmentRows.forEach((data) => {
      const normalizedProducts = this.normalizeArrayFromText(data.productNames);
      if (normalizedProducts) {
        data.productNames = normalizedProducts.join(',');
      }
     
      if (commitmentStatuses.includes(data.commitmentStatus) || (data.commitmentStatus == 'Fiserv - On Hold' && data.isRisk == 'false')) {
        data.isRisk = 'blank';
      }
      
      data.totalOfDaysChanged = this.getStakeholderDaysChangedForExportPDF(data);
      // element.displayPlannedCommittedDeliveryDate = "" when stage like '%On Hold%' for all sources and Only On ClientDashboard/export - CCT and Detail page shows value from the db.
      data.displayPlannedCommittedDeliveryDate = data.commitmentStatus.indexOf('On Hold') > -1 ? "" : this.dateFormat(data.displayPlannedCommittedDeliveryDate);       
      data.originalPlannedCommittedDeliveryDate = this.dateFormat(data.originalPlannedCommittedDeliveryDate); 
      data['dateChangeByStakeholderClient'] = this.getStakeholderDaysChanged(data, 'Client');
      data['dateChangeByStakeholderFiserv'] = this.getStakeholderDaysChanged(data, 'Fiserv');
    });

    const sortedRows = this._manageClientDashboardService.applySorting(commitmentRows, sortingObject);
    const isRiskSort = sortingObject.key && sortingObject.key.includes("isRisk");
    if (!this.isInitialSearch && !isRiskSort) {
      return sortedRows;
    }
    return this._manageClientDashboardService.sortByRisk(sortedRows, sortingObject);
  }

  private isRiskSorting(sortingObject: { key?: string, isAsc?: boolean }) {
    // Risk is sorting by default
    if (!sortingObject || !sortingObject.key) {
      return true;
    }

    return sortingObject.key.includes("isRisk") && !sortingObject.isAsc;
  }

  public deleteLatestCommitmentIsReviewed(): void {
    // todo: CHECK
    // this.advanceSearchProject();
    // this.advanceSearchNonProject();
    this.getCommitmentReviewsByClientId();
  }

  public hideUndoButton(): boolean {
    return !this.selectedClientEntityKey 
      || (!this.isCCTAdmin && !this.isAdmin && !this.isRestrictedAdmin && !this.isRm) 
      || this.commitmentReviews.length === 0 
      || this.isCCTAdminReadOnly;
  }

  public toggleNotpadSection(notepadSectionType: string): void {
    if (!this.isNotpadSectionVisible && notepadSectionType !== this.notepadSectionType) {
      this.isNotpadSectionVisible = true;
      document.getElementById('tabSection').classList.add('openNotepad');
      document.getElementById('notepadSection').classList.add('openNotepad');
      this.notepadSectionType = notepadSectionType;
    } else if (this.isNotpadSectionVisible && notepadSectionType === this.notepadSectionType) {
      document.getElementById('tabSection').classList.remove('openNotepad');
      document.getElementById('notepadSection').classList.remove('openNotepad');
      this.isNotpadSectionVisible = false;
      this.notepadSectionType = '';
    } else {
      this.notepadSectionType = notepadSectionType;
    }
  }

  public saveNote(): void {
    if (this.isOneViewSelected) {
      this.saveOneViewNote();
    } else {
      this.saveClientNote();
    }
  }

  private loadClientNote(): void {
    this.clientNoteService.getClientNote(this.selectedClientEntityKey).subscribe(result => {
      this.note = (result.body as any).note;
    }, error => {
      this.alertInput.alertType = AlertType.Error;
      this.alertInput.message = error;
    });
  }

  public saveClientNote(): void {
    const clientNoteInput: ClientNoteInput = {
      clientId: this.selectedClientEntityKey,
      note: this.note
    };
    this.clientNoteService.createOrUpdateClientNote(clientNoteInput).subscribe(() => {
      this.alertInput.alertType = AlertType.Success;
      this.alertInput.message = 'Note saved successfully.';
    }, error => {
      this.alertInput.alertType = AlertType.Error;
      this.alertInput.message = error;
    }).add(() => {
      this.scrollToElement('#alertInput');
    });
  }

  private loadOneViewNote(): void {
    this._oneViewService.getOneViewNote(this.selectedClientEntityKey).subscribe(result => {
      this.note = (result.body as any).note;
    }, error => {
      this.alertInput.alertType = AlertType.Error;
      this.alertInput.message = error;
    });
  }

  public saveOneViewNote(): void {
    const noteInput: OneViewNoteInput = {
      oneViewId: this.selectedClientEntityKey,
      note: this.note
    };
    this._oneViewService.updateOneViewNote(noteInput).subscribe(() => {
      this.alertInput.alertType = AlertType.Success;
      this.alertInput.message = 'Note saved successfully.';
    }, error => {
      this.alertInput.alertType = AlertType.Error;
      this.alertInput.message = error;
    }).add(() => {
      this.scrollToElement('#alertInput');
    });
  }

  private scrollToElement(elementId: string) {
    setTimeout(() => {
      const el = document.querySelector<HTMLElement>(elementId);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }

  public setAlertMsgToEmpty(): void {
    this.alertInput.message = '';
  }

  public noteVisibilityCheck(): boolean {
    return this.isRm || this.isAdmin || (this.isCCTAdmin && !this.isCCTAdminReadOnly) || this.isSalesExecutive || this.isAccountManager || this.isRestrictedAdmin || this.isAccountExecutive ||this.isNewRM;
  }

  exportToPDF() {
    const query = {
      htmlList: this.getHtmlsToExport(),
      isOneViewSelected: this.isOneViewSelected
    };

    this.commitmentDashboardService.exportClientDashboardPDF(this.selectedClientEntityKey, query).subscribe(response => {
      let newBlob = new Blob([response.body], { type: "application/pdf" });
      const nav = window.navigator as any;
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
      } else {
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.download = `clientdashboard-${this.selectedClientEntityTitle}-${this.selectedClientEntityKey}.pdf`;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);

        this.isPdfExport = false;
      }
    }, (error) => {
      this.isPdfExport = false;
      this.errorMsg = error;
    });
  }

  exportToPPT() {
    const query = {
      htmlList: this.getHtmlsToExport(),
      isOneViewSelected: this.isOneViewSelected
    };

    this.commitmentDashboardService.exportClientDashboardPPT(this.selectedClientEntityKey, query).subscribe(response => {
      let newBlob = new Blob([response.body], { type: "application/pptx" });
      const nav = window.navigator as any;
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
      } else {
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.download = `clientdashboard-${this.selectedClientEntityTitle}-${this.selectedClientEntityKey}.pptx`;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);

        this.isPdfExport = false;
      }
    }, (error) => {
      this.isPdfExport = false;
      this.errorMsg = error;
    });
  }

  getHtmlsToExport() {
    const statisticsExportingHtml = $('.statistics-container')[0].outerHTML;
    const deliveryExportingHtml = $('.delivery-container')[0].outerHTML;
    const commitmentsExportingHtml = $('.commitments-container')[0].outerHTML;
    const manualCommitmentsExportingHTML = $('.commitments-container')[1].outerHTML;
    const contactsExportingHtml = $('.contacts-container')[0].outerHTML;
    const productsExportingHtml = $('.products-container')[0].outerHTML;
    const clientNoteExportingHtml = $('.client-note-container')[0].outerHTML;

    const firstHtml = statisticsExportingHtml + deliveryExportingHtml;
    return [
      firstHtml,
      commitmentsExportingHtml, 
      manualCommitmentsExportingHTML, 
      productsExportingHtml, 
      clientNoteExportingHtml,
      contactsExportingHtml
    ];
  }

  getStakeholderDaysChangedForExportExcel(row: DashboardCommitmentDTO): any {
    const result = `Client ${this.getStakeholderDaysChanged(row, 'Client')} \r\nFiserv ${this.getStakeholderDaysChanged(row, 'Fiserv')}`;
    return result;
  }

  getStakeholderDaysChangedForExportPDF(row: DashboardCommitmentDTO): any {
    const result = `Client ${this.getStakeholderDaysChanged(row, 'Client')} <br>Fiserv ${this.getStakeholderDaysChanged(row, 'Fiserv')}`;
    return result;
  }

  getStakeholderDaysChanged(row: DashboardCommitmentDTO, type: string): any {
    const result = '(0x / 0 days)';
    if (!row.clientTotalOfDaysChangedCount && !row.clientTotalOfDaysChanged && !row.fiservTotalOfDaysChangedCount && !row.fiservTotalOfDaysChanged) {
      return result;
    }

    switch (type) {
      case 'Client':
        return `(${row.clientTotalOfDaysChangedCount}x / ${row.clientTotalOfDaysChanged} days)`;
      case 'Fiserv':
        return `(${row.fiservTotalOfDaysChangedCount}x / ${row.fiservTotalOfDaysChanged} days)`;
    }
  }

  isSearchButtonDisplay(controlName: string) {
    return !this.topFilterForm.get(controlName).value;
  }

  onSelectSearch(event: { term: string, items: any[] }, fieldName: string) {
    const { term } = event;
    let matchedItem: { id: string, title: string };
    switch (fieldName) {
      case 'lineOfBusiness':
        matchedItem = this.lineOfBusinessOptions.find(item => item.title.toLowerCase() === term.toLowerCase());
        break;
      case 'selectedProduct':
        matchedItem = this.productOptions.find(item => item.title.toLowerCase() === term.toLowerCase());
        break;
    }
    
    if (matchedItem) {
      this.topFilterForm.get(fieldName).setValue(matchedItem.title);
    }
  }

  handleSelectKeyDown(event, controlName: string) {
    const { key, target } = event;
    if (key === 'Backspace') {
      const { selectionStart, selectionEnd, textLength } = target;
      if (selectionStart === 0 && selectionEnd === textLength) {
        switch(controlName) {
          case 'selectedClientEntityKey':
            this.clearClientSelected();
            break;
          default:
            this.topFilterForm.get(controlName).setValue(null);
            break;
        }
      }
    }

    if (event.ctrlKey && event.key === 'x') {
      switch (controlName) {
        case 'commitmentTitle':
        case 'projectNumber':
          this.topFilterForm.get(controlName).setValue(null);
          break;
      }
    }
  }

  public redirectToClientReviewTrailOverall(): void {
    if (this.isOneViewSelected) {
      this.redirectOneViewReviewTrailOverall();
    } else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/commitmentreviewtrailoverall`, { clientDunsNumber: this.selectedClientDunsNumber, clientName: this.selectedClientEntityTitle }])
      );
      window.open(url, '_blank');
    }
  }

  public redirectOneViewReviewTrailOverall(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/oneview-review-trail/`, this.selectedClientEntityKey])
    );
    window.open(url, '_blank');
  }

  private dateFormat(value: string): string {
    const isValidDate = this._dateTimeService.IsValidDate(value);
    if (!value || value === 'To Be Scheduled' || !isValidDate) { 
      return value; 
    }

    if (this._dateTimeService.isFormatMatched(value, 'MM/DD/yyyy')) {
      return value;
    }

    const result = this._dateTimeService.formatDate(value, 'MM/DD/yyyy');
    return result;
  }
}
