import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { IQuery } from '../shared/query';

@Injectable({
  providedIn: 'root'
})
export class CommitmentManualService {
  private _manualCommitmentBaseUrl: string = "commitmentintakes/manuals";

  constructor(private http: HttpClient) { }

  public updateCommitment(projectKey: string, update: any) {
    return this.http.put(`${this._manualCommitmentBaseUrl}/${projectKey}`, update);
  }

  public updateCommitments(updates: any) {
    return this.http.put(`${this._manualCommitmentBaseUrl}`, updates);
  }

  public deleteCommitment(projectKey: string) {
    return this.http.delete(`${this._manualCommitmentBaseUrl}/${projectKey}`);
  }

  public createCommitment(create: any) {
    return this.http.post(`${this._manualCommitmentBaseUrl}`, create);
  }
}
