import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserService } from '../services/user.service';
import { IUser } from '../shared/user';
import { map, tap } from 'rxjs/operators';
import { PermissionsService } from '../services/permissions.service';
import { allowedPermissions, COMMITMENT_DASHBOARD_PATH } from '../core/constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CanAccessProjectGuard implements CanActivate {
  constructor(private _userService: UserService,
    private permissionService: PermissionsService,
    private router: Router) { }
    private isCCT = environment.isCCT;
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const user = this._userService.getUserListData();
    if (!user) {
      return this._userService.callUserListData().pipe(map((data) => {
        this._userService.setUserListData(data);
        this.permissionService.setUserData(data);
        let usersPermission = [];
        data.permissions.forEach((ele) => {
          let permission: any = allowedPermissions[ele];
          for (let perm in permission) {
            if (permission[perm])
              usersPermission[perm] = permission[perm]
          }
        });
        const canAccessProject = this.checkProjectAccess(data);
        if (canAccessProject) {
          return true;
        }
        else {          
          if (this.isCCT)
            this.router.navigate([COMMITMENT_DASHBOARD_PATH]);
          else
            this.router.navigate(["dashboard/mydashboard"]);
          
        }
      }));
    }
    else {
      const canAccessProject = this.checkProjectAccess(user);
      if (canAccessProject) {
        return of(true);
      }
      else {
        if (this.isCCT)
          this.router.navigate([COMMITMENT_DASHBOARD_PATH]);
        else
          this.router.navigate(["dashboard/form"]);
      }
    }
  }

  private checkProjectAccess(user: IUser): boolean {
    return user.isAdmin || user.permissionsForUI.some(group => group.groupName === "Projects");
  }
}
