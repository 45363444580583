import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { environment } from 'src/environments/environment';
import { CommitmentMapType } from '../enums/commitment-map-type.enum';
import { CommitmentIntakeService } from 'src/app/services/commitment-intake.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-commitment-unmap-fulfillment-modal',
  templateUrl: './commitment-unmap-fulfillment-modal.component.html',
  styleUrls: ['./commitment-unmap-fulfillment-modal.component.css']
})
export class CommitmentUnmapFulfillmentModalComponent {
  public blueThemeMode = false;

  @Input()
  public mappingCommitmentIntakeId = 0;

  @Input()
  public projectKey = '';

  @Input()
  public commitmentMapType = CommitmentMapType.Opportunity;

  @Input()
  public projectName = '';

  @Input()
  public zIndexMode = false;

  @Output()
  private unmap = new EventEmitter<string>();

  constructor(private commitmentIntakeService: CommitmentIntakeService,  private userService: UserService) { 
    this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r);
  }

  public onUnmapClicked(): void {
    let unmapSubscription = null;
    if (this.commitmentMapType === CommitmentMapType.Project) {
      unmapSubscription = this.commitmentIntakeService.unmapProject(this.projectKey, this.mappingCommitmentIntakeId);
    } else {
      unmapSubscription = this.commitmentIntakeService.unmapOpportunity(this.projectKey, this.mappingCommitmentIntakeId);
    }

    if (unmapSubscription) {
      unmapSubscription.subscribe(() => {
        this.unmap.emit('');
      },
        error => {
          this.unmap.emit(error);
        });
    }
  }
}
