<div class="box-wrapper" [ngClass]="{'blueThemeMode':(blueThemeMode)}">
  <div role="status" *ngIf="isSummaryLoading" aria-label="Please wait, do not refresh the page"
       class="ccdrms-loader" style="position:relative;margin-top:20%"></div>
  <div class="swtich-container" *ngIf="!isSummaryLoading">
    <div [ngClass]="isSla?'switch-wrapper':''">
      <div *ngIf="isSla" class="tab-switch" [ngClass]="slaDashActive?'active':''"
           (click)="setSlaActiveTab(true)">
        Non-SLA
      </div>
      <div class="tab-switch " *ngIf="isSla" [ngClass]="slaDashActive?'':'active'"
           (click)="setSlaActiveTab(false)">
        SLA
      </div>
    </div>
    <div class="total-count" [ngClass]="slaDashActive?'d-flex':'d-none'">
      <div class="value">
        Total Commitment(s):
      </div>
      <div class="count-badge" [ngClass]="{'buttonColor':(blueThemeMode)}">
        {{commitmentStats['Total']}}
      </div>
    </div>
    <div class="filter-section" style="margin-right:0px !important;"
         [ngClass]="slaDashActive?'d-none':'d-flex'">
      <label>Product/Function:</label>
      <div class="search-filter">
        <app-type-ahead [(ngModel)]="selectedSlaProduct" [options]="slaProductSearch"
                        (selectDone)="slaGraphChange()" [placeholder]="'Type to search'"></app-type-ahead>
      </div>
    </div>
  </div>
  <!------------------SLA-->
  <div class="scroll-horizontal sla" [ngClass]="slaDashActive?'d-none':'d-flex'">
    <ul class="box commitment-status sla">
      <li class="box product-chat">
        <div class="status-wrapper">
          <div class="status-count-container">
            <div style="align-items:center;" class="filter-section">
              <label><strong>SLA Goal</strong></label>
            </div>
          </div>
          <div class="chart-container">
            <div class="chart">
              <div class="chart">
                <div class="semi-donut margin" style="--percentage: {{slaPercentage}}; --fill: #0080BF; ">
                  {{slaPercentage}}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>

      <li class="box full-width-chart">
        <div class="chart" *ngIf="showGraph">
          <canvas id="areaChart"></canvas>
        </div>
      </li>
    </ul>
  </div>

  <!----------------------------SLA END-->
  <div class="scroll-horizontal non-sla flex-column" *ngIf="!isSummaryLoading"
       [ngClass]="slaDashActive?'d-flex':'d-none'">
    <ul class="box commitment-status">
      <li class="box not-started">
        <div class="status-wrapper">
          <div class="status-count-container">
            <div class="status darkgrey"> Not Started</div>
            <div class="status-count">{{ this.commitmentStatsCount['Not Started']}}</div>
          </div>
          <div class="chart-container">
            <div class="chart">
              <div class="chart">
                <div class="semi-donut margin"
                     style="--percentage: {{commitmentStats['Not Started']}}; --fill: #36454f;">
                  {{commitmentStats['Not Started']}}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="box in-planning">
        <div class="status-wrapper">
          <div class="status-count-container">
            <div class="status grey"> In Planning</div>
            <div class="status-count">{{ this.commitmentStatsCount['In Planning']}}</div>
          </div>
          <div class="chart-container">
            <div class="chart">
              <div class="chart">
                <div class="semi-donut margin"
                     style="--percentage: {{commitmentStats['In Planning']}}; --fill: #B7B7B7;">
                  {{commitmentStats['In Planning']}}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="box active">
        <div class="status-wrapper">
          <div class="status-count-container">
            <div class="status active"> In Progress</div>
            <div class="status-count">{{ this.commitmentStatsCount['In Progress']}}</div>
          </div>
          <div class="chart-container">
            <div class="chart">
              <div class="chart">
                <div class="semi-donut margin"
                     style="--percentage: {{commitmentStats['In Progress']}}; --fill: #FF5E0E;">
                  {{commitmentStats['In Progress']}}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="box fulfilled">
        <div class="status-wrapper">
          <div class="status-count-container">
            <div class="status blue">Delivered</div>
            <div class="status-count">{{ this.commitmentStatsCount['Delivered']}}</div>
          </div>
          <div class="chart-container">
            <div class="chart">
              <div class="chart">
                <div class="semi-donut margin"
                     style="--percentage: {{commitmentStats['Delivered']}}; --fill: #0080BF;">
                  {{commitmentStats['Delivered']}}%
                </div>
              </div>
            </div>

          </div>
        </div>
      </li>
      <li class="box fulfilled">
        <div class="status-wrapper">
          <div class="status-count-container">
            <div class="status blue">Completed</div>
            <div class="status-count">{{ this.commitmentStatsCount['Completed']}}</div>
          </div>
          <div class="chart-container">
            <div class="chart">
              <div class="semi-donut margin"
                   style="--percentage: {{ this.commitmentStats['Completed']}}; --fill: #0080BF;">
                {{
                this.commitmentStats['Completed']
                }}%
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="box on-hold">
        <div class="status-wrapper">
          <div class="status-count-container">
            <div class="status yellow"> On Hold</div>
            <div class="status-count">{{ this.commitmentStatsCount['On Hold']}}</div>
          </div>
          <div class="chart-container">
            <div class="chart">
              <div class="semi-donut margin"
                   style="--percentage: {{ this.commitmentStats['On Hold']}}; --fill: #FFC626;">
                {{
                this.commitmentStats['On Hold']
                }}%
              </div>
            </div>

          </div>
        </div>
      </li>
      <li class="box cancelled">
        <div class="status-wrapper">
          <div class="status-count-container">
            <div class="status lightblue">Cancelled</div>
            <div class="status-count">{{ this.commitmentStatsCount['Cancelled']}}</div>
          </div>
          <div class="chart-container">
            <div class="chart">
              <div class="semi-donut margin"
                   style="--percentage: {{ this.commitmentStats['Cancelled']}}; --fill: #90d5ff; ">
                {{
                this.commitmentStats['Cancelled']
                }}%
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>

  </div>
  <div class="legend-wrapper" *ngIf="!isSummaryLoading">
    <span class="legend-text">Overall Progress</span>
  </div>
</div>
