import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';
import { AlertInput } from '../../commitment-intakes/models/alert-input';
import { AlertType } from '../../commitment-intakes/enums/alert-type.enum';
import { CommitmentIntakeNoteInput } from '../models/commitment-intake-note-input';
import { CommitmentIntakeNoteService } from 'src/app/services/commitment-intake-note.service';
import { UserService } from 'src/app/services/user.service';
import { DashboardCommitmentDTO } from '../models/client-dashboard-d-t-o';
declare var $: any;

@Component({
  selector: 'app-client-commitment-details',
  templateUrl: './client-commitment-details.component.html',
  styleUrls: ['./client-commitment-details.component.css']
})
export class ClientCommitmentDetailsComponent implements OnInit, OnChanges {
  @Input() detailsCommitment: DashboardCommitmentDTO;
  @Input() isClientExecutive: boolean = false;
  @Input() isClientAdmin: boolean = false;
  @Input() isCCTAdminReadOnly: boolean = false;
  @Input() clientEntityKey?: number = null;
  @Input() isCustomClient: boolean = false;
  @Input() drawerActive: boolean = false;
  @Input() isCCTAdmin: boolean = false;
  @Input() pageTemplateAdmin: boolean = false;
  @Input() isAdmin: boolean = false;
  @Input() isRestrictedAdmin: boolean = false;
  @Input() isRm: boolean = false;
  @Input() isSalesExecutive: boolean = false;
  @Input() isAccountManager: boolean = false;
  @Input() isAccountExecutive: boolean = false;
  @Input() isNewRM: boolean = false;
  @Input() userData: any;
  @Input() SelectedCEUsers: any[];
  @Input() SelectedCEUsersDetails: any[];
  @Input() isClientLoadedPartofOneView: boolean = false;
  @Input() isClientSuperUser: boolean = false;

  @Output() setAlertMsgToEmpty = new EventEmitter();
  @Output() openUpdateUsersModal = new EventEmitter<any>();
  @Output() onCloseDrawer = new EventEmitter<any>();
  
  public noteForCommitment = '';
  public alertType = AlertType;
  public alertInputPopup: AlertInput = {
    alertType: AlertType.Success,
    message: ''
  };
  public reasonForm: FormGroup;
  public commitmentNoteForm: FormGroup;
  public blueThemeMode = false;

  private _formValidators = {
    reason: [
      requiredIfValidator(
        () => this.reasonForm.get('isRisk').value == true
      ),
    ],
    description: [
      requiredIfValidator(
        () => this.reasonForm.get('reason').value == 'other'
      ),
    ]
  }

  constructor(private fb: FormBuilder, 
    private commitmentIntakeNoteService: CommitmentIntakeNoteService, 
    private userService: UserService) { 
      this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r); 
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.drawerActive && changes.drawerActive.currentValue) {
      this.openDrawer();
    }
    else if (changes.drawerActive) {
      this.closeDrawer();
    }
  }

  ngOnInit(): void {
    
  }

  public noteVisibilityCheck(): boolean {
    return this.isRm || this.isAdmin || (this.isCCTAdmin && !this.isCCTAdminReadOnly) || this.isSalesExecutive || this.isAccountManager || this.isRestrictedAdmin || this.isAccountExecutive ||this.isNewRM;
  }

  openDrawer() {
    this.commitmentNoteForm = this.fb.group({
      noteForCommitment: ['', []],
    });
    this.reasonForm = this.fb.group({
      reason: new FormControl(this.detailsCommitment.riskReason || '', [requiredIfValidator(() => this.reasonForm.get('isRisk').value == true)]),
      description: new FormControl(this.detailsCommitment.riskDescription || '', [requiredIfValidator(() => this.reasonForm.get('reason').value.toLowerCase() === "other")]),
      isRisk: [this.detailsCommitment.isRisk == 'true' ? true : false],
    });
    window.scroll(0, 0);

    setTimeout(function () {
      $('html, body').addClass('overflow-hidden');
      $('#containerPage').addClass('overflow-hidden');
    }, 0);
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
      $('#commitmentTypeTooltip').tooltip({ placement: 'auto', title: '<p class="typeTooltip">1. Base/New Product Feature - Commitment to deliver Standard Product offering or New product feature</p><p class="typeTooltip">2. Custom Feature and Enhancement - Commitment to deliver custom functionality</p><p class="typeTooltip"> 3. Implementation - Commitment to configure, implement the product offerings</p><p class="typeTooltip">4. Advisory - Commitment to provide advisory or consultation services</p><p class="typeTooltip">   5. Staff Augmentation - Commitment to provide required staffing</p>', html: true });
    });
    this.loadCommitmentNote();
  }

  closeDrawer() {
    setTimeout(function () {
      $('html, body').removeClass('overflow-hidden');
      $('#containerPage').removeClass('overflow-hidden');
      document.getElementsByTagName('body')[0].classList.remove('modal-open');
      window.scroll(0, document.body.scrollHeight);
    }, 0);
    this.onCloseDrawer.emit();
  }  

  canDelete() {
    if (this.isClientExecutive) {
      return false;
    }

    if ((this.isCCTAdmin && this.pageTemplateAdmin) || (this.isCCTAdmin && !this.pageTemplateAdmin && this.detailsCommitment.cretedById === this.userData.id)) {
      return true;
    }
    
    return this.isAdmin || this.isRestrictedAdmin || this.isRm || this.isSalesExecutive || this.isAccountManager ||this.isAccountExecutive || this.isNewRM;
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  onOpenUpdateUsersModal(detailsCommitment: any) {
    this.openUpdateUsersModal.emit(detailsCommitment);
  }

  private loadCommitmentNote(): void {
    this.commitmentNoteForm.get('noteForCommitment').setValue("");
    this.alertInputPopup.message = '';
    this.noteForCommitment = '';
    this.commitmentIntakeNoteService.getCommitmentNote(this.detailsCommitment.projectKey).subscribe(result => {
      this.noteForCommitment = (result.body as any).note;
      this.commitmentNoteForm.get('noteForCommitment').setValue(this.noteForCommitment)
    }, error => {
      this.alertInputPopup.alertType = AlertType.Error;
      this.alertInputPopup.message = error;
    });
  }

  public saveNoteForCommitment(): void {
    const commitmentNoteInput: CommitmentIntakeNoteInput = {
      projectKey: this.detailsCommitment.projectKey,
      dataSource: this.detailsCommitment.datasource,
      note: this.commitmentNoteForm.get('noteForCommitment').value
    };
    this.commitmentIntakeNoteService.createOrUpdateCommitmentNote(commitmentNoteInput).subscribe(() => {
      this.alertInputPopup.alertType = AlertType.Success;
      this.alertInputPopup.message = 'Commitment Note saved successfully.';
    }, error => {
      this.alertInputPopup.alertType = AlertType.Error;
      this.alertInputPopup.message = error;
    });
  }

  reEvaluateValidators(fieldName, isNested: any = 'no') {
    let fieldCtrl: any;
    fieldCtrl = this.reasonForm.controls[fieldName];
    fieldCtrl.setValidators(this._formValidators[fieldName]);
    fieldCtrl.updateValueAndValidity();
  }

  
  isProjectManagerShown() {
    const dataSource = this.detailsCommitment.datasource.toLowerCase();
    if (dataSource === 'ecrm') {
      return false;
    }

    if (dataSource === 'cims' || dataSource === 'servicenow') {
      return true;
    }

    if ((dataSource === 'clarity' || dataSource === 'manual') && this.detailsCommitment.dateStatus === 'Committed') {
      return true;
    }
  }

  isProjectManagerLinkShown() {
    const dataSource = this.detailsCommitment.datasource.toLowerCase();
    if (dataSource != 'ecrm') {
      if (this.detailsCommitment.projectManager && this.detailsCommitment.projectManagerEmailAddress) {
        return true;
      }
    }
    return false;
  }

  isProjectManagerTextShown() {
    const dataSource = this.detailsCommitment.datasource.toLowerCase();
    if (dataSource != 'ecrm') {
      if (this.detailsCommitment.projectManager && !this.detailsCommitment.projectManagerEmailAddress) {
        return true;
      }
    }
    return false;
  }

  isDeliveryOwnerShown() {
    if (!this.detailsCommitment.deliveryOwner || this.detailsCommitment.deliveryOwner === '')
      return false;
    return true;
  }

  isDeliveryOwnerTextShown() {
    if (this.detailsCommitment.deliveryOwner && !this.detailsCommitment.deliveryOwnerEmailAddress
      && this.detailsCommitment.deliveryOwnerEmailAddress === '') {
      return true;
    }
    return false;
  }

  isDeliveryOwnerLinkShown() {
    if (this.detailsCommitment.deliveryOwner && this.detailsCommitment.deliveryOwnerEmailAddress
      && this.detailsCommitment.deliveryOwnerEmailAddress !== '') {
      return true;
    }
    return false;
  }

  clearAlertMessage() {
    this.setAlertMsgToEmpty.emit();
  }
  
  public openDeleteModal(): void {
    $('#deleteWarningModal').show();
    $("#deleteWarningModal").addClass('show');
    $('body').addClass('modal-open');
  }

  canRemoveCommitment() {
    if (this.isClientExecutive) {
      return false;
    }

    if (this.isAdmin || this.isRestrictedAdmin || this.isRm || this.isSalesExecutive || this.isAccountManager || this.isAccountExecutive || this.isNewRM) {
      return true;
    }

    if (this.isCCTAdmin && this.pageTemplateAdmin) {
      return true;
    }
    
    return this.isCCTAdmin && !this.pageTemplateAdmin && this.detailsCommitment.cretedById === this.userData.id;
  }

  canEditUserAssignment() {
    if (this.isClientExecutive) {
      return false;
    }

    if (this.isClientAdmin) {
      return false;
    }

    if (this.isAdmin || this.isRestrictedAdmin || this.isRm || this.isSalesExecutive || this.isAccountManager || this.isAccountExecutive || this.isNewRM) {
      return true;
    }

    if (this.isCCTAdmin && this.pageTemplateAdmin) {
      return true;
    }

    return this.isCCTAdmin && !this.pageTemplateAdmin && this.detailsCommitment.cretedById === this.userData.id;
  }

  public keyDown(keyboardEvent: KeyboardEvent): void {
    const focusableElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.getElementById('drawerDialog');
    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
    const focusableContent = modal.querySelectorAll(focusableElements);
    const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal
    if (keyboardEvent.shiftKey) { // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        (lastFocusableElement as HTMLElement).focus(); // add focus for the last focusable element
        keyboardEvent.preventDefault();
      }
    } else { // if tab key is pressed
      if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
        (firstFocusableElement as HTMLElement).focus(); // add focus for the first focusable element
        keyboardEvent.preventDefault();
      }
    }
  }
}

function requiredIfValidator(predicate) {
  return (formControl => {
    if (!formControl.parent) {
      return null;
    }

    console.log('predicate()' + predicate())
    if (predicate()) {
      return Validators.required(formControl);
    }
    return null;
  })
}
