import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, concat, of } from 'rxjs';
import { debounceTime, switchMap,catchError, distinctUntilChanged, tap } from 'rxjs/operators';
import { ManageCommitmentService } from '../services/manageCommitment.service';
import { Observable } from 'rxjs-compat';
import { ISuggestion } from 'src/app/shared/suggestion';
import { UserService } from 'src/app/services/user.service';
import { CommitmentIntakeService } from 'src/app/services/commitment-intake.service';

@Component({
  selector: 'app-commitment-intake-create-drawer',
  templateUrl: './commitment-intake-create-drawer.component.html',
  styleUrls: ['./commitment-intake-create-drawer.component.css']
})
export class CommitmentIntakeCreateDrawerComponent implements OnInit {
  @Input() canRequest = false;
  @Input() isEntityLink: boolean = true;
  @Output() private cancel = new EventEmitter<void>();
  public clientName = '';
  public isError = false;
  public errorMsg = ''
  commitmentFormGroup: FormGroup;
  selectedClientId: number = 0;
  selectedClientName: string;
  invalidClientDuns: boolean = false;
  invalidClientName: boolean = false;
  selectedClientValue: string = '';
  selectedClientDunsNumber: string = '';
  relationshipManagerName: string = '';
  relationshipManagerEmailAddress: string = '';
  newRM: string = '';
  blueThemeMode = false;

  clientSuggestions$: Observable<any[]>;
  clientSuggestionInput$ = new Subject<string>();
  isClientsuggestionLoading = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    private _commitmentIntakeService: CommitmentIntakeService,
    private _manageCommitmentService : ManageCommitmentService) {
      this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r);
  }

  ngOnInit(): void {
    this.initiateCommitmentForm();
    this.apiFormData();
  }

  searchFunction(term: string, item: ISuggestion) {
    return item.titleWithDuns.includes(term) || item.title.includes(term);
  }

  trackByFn(item: ISuggestion) {
		return item.id;
	}

  loadClientSuggestions() {
    this.selectedClientValue = '';
    if (!this.commitmentFormGroup.valid) {
      return;
    }

    this.fetchClientSuggestions();
  }

  private fetchClientSuggestions() {
    this._commitmentIntakeService.getClientWithRmSuggestions([{ paramName: "isGetForUpdate", value: true }], 100).subscribe(responses => {
      this.clientSuggestions$ = concat(
        of([...responses]), // default items
        this.clientSuggestionInput$.pipe(
          debounceTime(500),
          distinctUntilChanged(),
          switchMap((term) =>
          {
            const filteredList = responses.filter(item => this.searchFunction(term, item));
            if (filteredList && filteredList.length > 0) {
              return of([...filteredList]);
            }

            const params = [
              { paramName: "isGetForUpdate", value: 'true' },
              { paramName: "title", value: term }
            ];

            this.isClientsuggestionLoading = true;
            return this._commitmentIntakeService.getClientWithRmSuggestions(params, 100).pipe(
              catchError(() => of([])), // empty list on error
              tap(() => (this.isClientsuggestionLoading = false)),
            )
          }),
        )
      );
    });
	}

  apiFormData() {
    this.resetClientChecks();
    this.fetchClientSuggestions();
  }

  initiateCommitmentForm() {
    this.commitmentFormGroup = this.fb.group({
      'clientName': new FormControl(null)
    });
  }

  goToCreateCommitment() {
    if (this.invalidClientDuns) { 
      return;
    }

    this.resetClientChecks();
    const selectedClient = this.commitmentFormGroup.get('clientName').value;
    if (selectedClient === "") {
      this.invalidClientName = true;
      this.resetClientData();
      return;
    }

    if (selectedClient && this.selectedClientId < 1) {
      this.invalidClientName = true;
      return;
    }

    if (!this.commitmentFormGroup.valid) { 
      return; 
    }

    if (this.selectedClientId) {
      if (!selectedClient) {
        this.invalidClientName = true;
        this.resetClientData();
        return;
      }
    }

    this._manageCommitmentService.createFormVal = {
      clientName: this.selectedClientName,
      clientDunsNumber: this.selectedClientDunsNumber,
      relationshipManagerName: this.relationshipManagerName,
      relationshipManagerEmailAddress: this.relationshipManagerEmailAddress,
      newRM: this.newRM
    };
    this.resetCommitmentCreatingData();
    this.cancel.emit();
    this.redirectToCreateCommitment();
  }

  resetClientChecks() {
    this.invalidClientName = false;
    this.invalidClientDuns = false;
  }

  resetClientData() {
    this.selectedClientId = 0;
    this.selectedClientName = "";
    this.selectedClientValue = "";
    this.selectedClientDunsNumber = "";
    this.relationshipManagerName = "";
    this.relationshipManagerEmailAddress = "";
    this.newRM = "";
  }

  resetCommitmentCreatingData() {
    this.selectedClientId = 0;
    this.selectedClientName = ""
    this.selectedClientValue = "";
  }

  redirectToCreateCommitment() {
    this.router.navigate(['/self-service/commitment-intakes/create']);
  }

  cancelCreatingCommitmentForm() {
    this.onClientClear();
    this.loadClientSuggestions();
    this.cancel.emit();
  }

  onClientClear() {
    this.resetClientChecks();
    this.resetCommitmentCreatingData();
    this._manageCommitmentService.createFormVal = undefined;
    this.commitmentFormGroup.reset();
  }

  formInitialized(name: string, form: any) {
    this.commitmentFormGroup.setControl(name, form);
  }

  onClientSelect = (event: any) => {
    this.selectedClientValue = '';
    if (this.commitmentFormGroup.valid && event) {
      this.selectedClientId = event.id;
      this.selectedClientName = event.title;
      this.invalidClientName = false;
      this.selectedClientValue = event.titleWithDuns;
      this.selectedClientDunsNumber = event.dunsId;
      this.relationshipManagerName = event.relationshipManagerName;
      this.relationshipManagerEmailAddress = event.relationshipManagerEmail;
      this.newRM = event.relationshipManagerName;
      this.checkValidDuns();
    }
    else {
      this.onClientClear();
    }
  }

  checkValidDuns() {
    if (this.selectedClientDunsNumber === null || this.selectedClientDunsNumber === undefined || isNaN(+this.selectedClientDunsNumber)) {
      this.invalidClientDuns = true;
      return;
    }
    else {
      this.invalidClientDuns = false;
    }
  }

  validation(controlName: string, values: FormArray): ValidationErrors {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let array: any = [];
      for (let k in values[controlName]) {
        let fieldValue = values[controlName][k];
        const keyName = fieldValue.id

        if (fieldValue.name != '') {
          if (array[keyName]) {
            array[keyName].push({ "index": k });
          }
          else {
            array[keyName] = [];
            array[keyName].push({ "index": k });
          }
        }
      }
      for (let i in array) {
        if (array[i].length > 1) {
          return { 'duplicate': true };
        }
      }
      return null;
    }
  }

  
  public keyDown(keyboardEvent: KeyboardEvent): void {
    const focusableElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.getElementById('newCommitment');
    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
    const focusableContent = modal.querySelectorAll(focusableElements);
    let lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal
    if (this.selectedClientId < 1) {
      lastFocusableElement = focusableContent[focusableContent.length - 2];
    }
    if (keyboardEvent.shiftKey) { // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        (lastFocusableElement as HTMLElement).focus(); // add focus for the last focusable element
        keyboardEvent.preventDefault();
      }
    } else { // if tab key is pressed
      if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
        (firstFocusableElement as HTMLElement).focus(); // add focus for the first focusable element
        keyboardEvent.preventDefault();
      }
    }
  }
}
