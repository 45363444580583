export enum CommitmentIntakeSearchType {
    ClientName,
    ClientDunsNumber,
    OneViewGroupName,
    ProductNames,
    ProjectNumber,
    CommitmentTitle,
    DisplayPlannedDeliveryYear,
    CommitmentType,
    Status,
    OpportunityId,
    RelationshipManagerName,
    NewRM,
    IsTracked,
    Datasource,
}