import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommitmentReviewTrailDTO } from './models/commitment-review-trail-d-t-o';
import moment from 'moment';
import { CommitmentReviewTrailService } from 'src/app/services/cmmitment-review-trail.service';
import { CommitmentReviewTrailAction } from './enums/commitment-review-trail-action.enum';

@Component({
  selector: 'app-commitment-review-trail',
  templateUrl: './commitment-review-trail.component.html',
  styleUrls: ['./commitment-review-trail.component.css']
})
export class CommitmentReviewTrailComponent implements OnInit {
  public action = CommitmentReviewTrailAction;
  public data: Array<CommitmentReviewTrailDTO> = new Array<CommitmentReviewTrailDTO>();

  @Input()
  public set projectKey(value: string) {
    if (value) {
      this.loadData(value);
    }
  }

  @Output()
  private modalClosed = new EventEmitter<void>();

  constructor(private _commitmentReviewTrailService: CommitmentReviewTrailService) { }

  ngOnInit(): void {
  }

  private loadData(projectKey: string): void {
    this._commitmentReviewTrailService.getReviewTrailDetails(projectKey)
      .subscribe(result => {
        this.data = result.body as Array<CommitmentReviewTrailDTO>;
        this.data.forEach(item => {
          item.modifiedOn = moment.utc(item.modifiedOn).local();
        });
      });
  }

  public resetData(): void {
    this.data = [];
    this.modalClosed.emit();
  }

  public getTimelineStatusCSSClass(status: CommitmentReviewTrailAction, value: string): string {
    let color = 'single-timeline-area grey';
    if (status === CommitmentReviewTrailAction.IsRisk && (value === 'true' || value === 'True')) {
      color = 'single-timeline-area red';
    }
    return color;
  }

  public translate(status: CommitmentReviewTrailAction, value: string): string {
    let result = '';
    switch (status) {
      case CommitmentReviewTrailAction.Description:
        result = 'Description';
        break;
      case CommitmentReviewTrailAction.IsRisk:
        if (value === 'true' || value === 'True') {
          result = 'Risk Added';
        } else {
          result = 'Risk Removed';
        }
        break;
      case CommitmentReviewTrailAction.IsTracked:
        if (value === 'true' || value === 'True') {
          result = 'Tracked';
        } else {
          result = 'Untracked';
        }
        break;
      case CommitmentReviewTrailAction.ManualCommitmentIsCreated:
        result = 'Manual Commitment created';
        break;
      case CommitmentReviewTrailAction.Title:
        result = 'Title';
        break;
      case CommitmentReviewTrailAction.Note:
        result = 'Note';
        break;
      case CommitmentReviewTrailAction.Deleted:
        result = 'Deleted';
        break;
      default:
        result = '';
        break;
    }
    return result;
  }
}
