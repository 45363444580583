<form novalidate="" autocomplete="off" class="form form-border mb-3 collapse-view">
    <h2 data-toggle="collapse" href="#collapseFilter" class="h4 collapse-arrow" aria-expanded="true">Search</h2>
    <div id="collapseFilter" class="filter-form-container-column2 search-filter multi-form-container collapse show">
        <div class="advance-search-filter">
            <div id="collapseFormFieldFilter" *ngIf="searchFieldsValue"
                class="filter-form-container-column2 ml-0 search-filter collapse multi-form-container show mt-0">
                <div class="row mr-3">
                    <ng-container *ngFor="let searchFieldItem of searchFieldsValue; let i = index">
                        <div *ngIf="searchFieldItem.fieldName !== 'Datasource'"
                            class="filter-form-control pr-4 typeahead-wrapper typeahead-menu-min-height custom-checkbox-container-box col-md-2">
                            <div class="custom-search-input custom-checkbox-container">
                                <label class="control-label">{{searchFieldItem.fieldDisplayName.replace('Commitment Status', 'Stage')}}</label>
                                <ng-select [items]="searchFieldItem.fieldSuggestions" 
                                    [multiple]="true"
                                    [closeOnSelect]="false"
                                    [virtualScroll]="true" 
                                    [clearOnBackspace]="false"
                                    placeholder="{{ searchFieldItem.loading ? 'Loading...' : 'Type to search'}}"
                                    (change)="onSearchFilterChanged($event, searchFieldItem.fieldDisplayName, searchFieldItem.fieldName)"
                                    [(ngModel)]="searchFieldItem.selected" [ngModelOptions]="{standalone: true}"
                                    [attr.aria-label]="'Multiselect dropdown for ' + searchFieldItem.formfieldlabel">
                                    <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$" let-index="index">
                                        <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                        <span [attr.aria-labelledby]="itemTemp" title="{{itemTemp}}"> {{itemTemp}} </span>
                                    </ng-template>
                                    <ng-template ng-multi-label-tmp>
                                        <span class="ng-value-label items">{{searchFieldItem.selected.length}}
                                            Item{{searchFieldItem.selected.length > 1 ? 's' : ''}}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="filter-wrapper filtered-data " id="tableChildAccordion2"
                [ngClass]="{'blueThemeMode':(blueThemeMode)}" *ngIf="currentFilterTags.length > 0">
                <div class="filter-tag filter-action action" *ngFor="let item of currentFilterTags">
                    <div class="tag">
                        <div class="data-wrapper">
                            <span class="label">{{item.type}}</span>
                            <span class="value">{{item.title}}</span>
                        </div>
                        <a href="javascript:void(0)" (click)="onDeleteSearchFilter(item)" aria-label="Close"
                            class="tag-close"><i aria-hidden="true" tabindex="-1" class="material-icons">cancel</i></a>
                    </div>
                </div>
            </div>
            <div class="mt-2">
                <button type="button" class="btn fdl-btn btn-primary-dark" aria-label="Search"
                    (click)="onSearchClicked()">Search</button>
                <button *ngIf="showResetButton" type="button" class="btn fdl-btn btn-primary-dark ml-3"
                    aria-label="Reset" (click)="onResetClicked()">Reset</button>
            </div>
        </div>
    </div>
</form>