import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { CommitmentIntakeNoteInput } from '../self-service/components/client-dashboard/models/commitment-intake-note-input';

@Injectable({
  providedIn: 'root'
})
export class CommitmentIntakeNoteService {
  private _commitmentIntakeNoteBaseUrl: string = "commitmentintakenotes";

  constructor(private http: HttpClient) { }

  public createOrUpdateCommitmentNote(request: CommitmentIntakeNoteInput) {
    const options = { 'refresh': 'true' };
    return this.http.post(this._commitmentIntakeNoteBaseUrl, request, { headers: options, observe: 'response' });
  }

  public getCommitmentNote(projectKey: string) {
    const options = { 'refresh': 'true' };
    return this.http.get(`${this._commitmentIntakeNoteBaseUrl}/${projectKey}`, { headers: options, observe: 'response' });
  }
}