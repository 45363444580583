
<td colspan="30" class="td-child">
    <!-- Mapped Opportunity -->
    <div class="table-child-container accordion collapse-view white">
        <div class="nested-table-header">
            <a class="table-child-heading" data-toggle="collapse"
                href="{{'#tableChildOpportunityAccordion'+ rowIndex}}" aria-expanded="true">
                <span>Mapped Opportunity</span>
            </a>
        </div>


        <div class="filter-wrapper collapse show" id="{{'tableChildOpportunityAccordion'+ rowIndex}}">
            <div *ngIf="item.opportunityId">
                <div class="filter-tag filter-action action"
                    (click)="openMappedDetailsModal('ecrm')">
                    <div class="tag actionDwDialog">
                        <div class="data-wrapper">
                            <span class="label">Opportunity ID</span>
                            <span
                                class="value">{{item.opportunityId}}</span>
                        </div>
                        <div class="data-wrapper">
                            <span class="label">Opportunity System</span>
                            <span *ngIf="item.datasource === 'ecrm'"
                                class="value">{{item.datasource}}</span>
                            <span *ngIf="item.datasource !== 'ecrm'"
                                class="value">ecrm</span>
                        </div>
                        <!--<a *ngIf="canUnmapMappedOpportunity(item)" data-toggle="modal"
                            data-target="#unmapModal_one" (click)="openUnmapModal()"
                            aria-label="Close" class="tag-close"><i tabindex="0" aria-hidden="true"
                                class="material-icons">cancel</i></a>-->
                    </div>
                </div>
            </div>
            <div *ngIf="!item.opportunityId"
                class="two-col-nested-child">
                <div class="filter-wrapper collapse show">
                    <div class="filter-tag not-avil-message ">
                        <div class="tag">
                            <div class="data-wrapper"> <span class="label">
                                    {{ item.opportunitySuggestionsIsCalled &&
                                    (!item.opportunitySuggestions ||
                                    item.opportunitySuggestions.length === 0) ? 'Not Available.' : ''
                                    }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--<div *ngIf="canGetOpportunitySuggestion(item)" class="col-md-12 mt-3 get_suggestion" [ngClass]="{'blueThemeMode':(blueThemeMode)}">
                <a (click)="getOpportunityMappingSuggestions(item)"><span><i
                            class="material-icons f-30">add_circle_outline</i> Get Opportunity
                        Suggestions</span></a>
            </div>-->
        </div>
    </div>

    <!-- Mapped Fulfillment -->
    <div class="table-child-container accordion collapse-view grey">
        <div class="nested-table-header">
            <a class="table-child-heading" data-toggle="collapse"
                href="{{'#tableChildMappedFulfillmentAccordion'+ rowIndex}}" aria-expanded="true">
                <span>Mapped Project</span>
            </a>
        </div>
        <div class="filter-wrapper collapse show" id="{{'tableChildMappedFulfillmentAccordion'+ rowIndex}}">
            <div *ngIf="item.projectNumber" class="row">
                <div class="col-md-12">
                    <div class="filter-tag filter-action action"
                        (click)="openMappedDetailsModal(item.datasource)">
                        <div class="tag actionDwDialog">
                            <div class="data-wrapper">
                                <span class="label">Project Number</span>
                                <span class="value">
                                    {{item.projectNumber}}
                                </span>
                            </div>
                            <!--<a *ngIf="canUnmapMappedProject(item)" data-toggle="modal"
                                data-target="#unmapModal_one" (click)="openUnmapModal()"
                                aria-label="Close" class="tag-close"><i tabindex="0" aria-hidden="true"
                                    class="material-icons">cancel</i></a>-->
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!item.projectNumber"
                class="two-col-nested-child">
                <div class="filter-wrapper collapse show">
                    <div class="filter-tag not-avil-message ">
                        <div class="tag">
                            <div class="data-wrapper"> <span class="label">
                                    {{ item.fulfillmentSuggestionsIsCalled &&
                                    (!item.fulfillmentSuggestions ||
                                    item.fulfillmentSuggestions.length === 0) ? 'Not Available.' : ''
                                    }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--<div class="col-md-12 mt-3 get_suggestion" *ngIf="canGetProjectSuggestion(item)" [ngClass]="{'blueThemeMode':(blueThemeMode)}">
                <a (click)="getClarityMappingSuggestions(item)"><span><i
                            class="material-icons f-30">add_circle_outline</i> Get
                        Project Suggestions</span></a>
            </div>-->
        </div>
    </div>

    <!-- Matching Suggestions -->
    <div *ngIf="currentIndex === item.id && (item.fulfillmentSuggestionsIsCalled || item.opportunitySuggestionsIsCalled)"
        class="table-child-container accordion collapse-view white">
        <div>
            <div class="nested-table-header">
                <a class="table-child-heading" data-toggle="collapse"
                    href="{{'#tableChildSuggestionsAccordion'+ rowIndex}}" aria-expanded="true">
                    <span *ngIf="getFulfillmentSuggestionsClicked">Matching Project Suggestions</span>
                    <span *ngIf="getOpportunitySuggestionsClicked">Matching Opportunity
                        Suggestions</span>
                </a>
            </div>
        </div>

        <div id="matchingSuggestionsDiv">
            <table class="f-12 table child-nested-table table-label collapse show"
                id="{{'tableChildSuggestionsAccordion'+ rowIndex}}">

                <!-- For Fulfillment Suggestions -->
                <tbody
                    *ngIf="item.fulfillmentSuggestions && item.fulfillmentSuggestions.length > 0 && getFulfillmentSuggestionsClicked"
                    class="child-tbody">
                    <tr>
                        <td colspan="4">
                            <div class="link-action">
                                <a (click)="removeSuggestions(item)">
                                    <i class="material-icons remove_suggestion_icon">delete</i>
                                    <span>Remove Suggestions</span>
                                </a>
                            </div>
                        </td>
                    </tr>
                    <tr style="background: #FAFAFA; position: sticky; top: 0; z-index: 3;">
                        <th><label style="margin-left: 38px;">Project Title</label></th>
                        <th><label>Project Number</label></th>
                        <th><label>Product</label></th>
                        <th><label></label></th>
                    </tr>
                    <tr style="z-index: 1;"
                        *ngFor="let suggestion of item.fulfillmentSuggestions; let i = index">
                        <td>
                            <div class="d-flex justify-content-start">
                                <a class="link" title="View details"
                                    (click)="openMapFulfillment('clarity', suggestion)">
                                    <div class="material-icons grey mt-1">chevron_right</div>
                                </a>
                                <div class="map_div_icon"></div>
                                <label>{{suggestion.title}}</label>
                            </div>
                        </td>
                        <td><label>{{suggestion.projectNumber}}</label></td>
                        <td><label>{{suggestion.products}}</label></td>
                        <td><label></label></td>
                    </tr>
                </tbody>

                <!-- For Opportunity Suggestions -->
                <tbody
                    *ngIf="item.opportunitySuggestions && item.opportunitySuggestions.length > 0 && getOpportunitySuggestionsClicked"
                    class="child-tbody">
                    <tr>
                        <td style="border: none;">
                            <div class="link-action">
                                <a (click)="removeSuggestions(item)">
                                    <i class="material-icons remove_suggestion_icon">delete</i>
                                    <span>Remove Suggestions</span>
                                </a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th><label style="margin-left: 38px;">Opportunity</label></th>
                        <th><label>ID</label></th>
                        <th><label>Product</label></th>
                        <th><label></label></th>
                    </tr>
                    <tr *ngFor="let suggestion of item.opportunitySuggestions; let i = index">
                        <td>
                            <div class="d-flex justify-content-start">
                                <a class="link" title="View details"
                                    (click)="openMapFulfillment('ecrm', suggestion)">
                                    <div class="material-icons grey mt-1">chevron_right</div>
                                </a>
                                <div class="map_div_icon"></div>
                                <label>{{suggestion.title}}</label>
                            </div>
                        </td>

                        <td><label>{{suggestion.opportunityId}}</label></td>
                        <td><label>{{suggestion.products}}</label></td>
                        <td><label></label></td>
                    </tr>
                </tbody>

                <!-- No results -->
                <tbody
                    *ngIf="((!item.opportunitySuggestions || item.opportunitySuggestions.length === 0) && getOpportunitySuggestionsClicked)
                    || ((!item.fulfillmentSuggestions || item.fulfillmentSuggestions.length === 0) && getFulfillmentSuggestionsClicked)"
                    class="child-tbody">
                    <tr>
                        <td colspan="4">
                            <div class="filter-wrapper collapse show">
                                <div class="filter-tag not-avil-message">
                                    <div class="tag border-color-red">
                                        <div class="data-wrapper"> <span class="label">Not
                                                Available.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</td>
