import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { environment } from 'src/environments/environment';
import { AlertInput } from '../models/alert-input';
import { AlertType } from '../enums/alert-type.enum';
import { CommitmentMapType } from '../enums/commitment-map-type.enum';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ICommitmentIntakeMapDetailsDTO } from '../models/commitment-intake-map-details-d-t-o';
import { CommitmentIntakeService } from 'src/app/services/commitment-intake.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-commitment-map-fulfillment-modal',
  templateUrl: './commitment-map-fulfillment-modal.component.html',
  styleUrls: ['./commitment-map-fulfillment-modal.component.css']
})
export class CommitmentMapFulfilmentModalComponent {

  public calledForMap = true;
  public showProjectIdSection = true;
  public errorMsg = '';
  public selectedProjectKey = '';
  public selectedCommitmentIntakeId = 0;
  public commitmentIntakeMapDetails: ICommitmentIntakeMapDetailsDTO | null = null;
  public displayUnmapButton = false;
  public displayMapButton = false;
  public blueThemeMode = false;
  public currentDataSource: string = null;
  @Input() public isReadOnlyMode: boolean;
  @Input() isPageTemplateReadAdmin: boolean;
  @Input() isPageTemplateAdmin: boolean;
  @Input() public title = '';
  @Input() projectDataSource = '';
  @Input()
  public set isCalledForMap(value: boolean) {
    this.calledForMap = value;
    if (!this.calledForMap) {
      this.showProjectIdSection = false;
    }
  };

  @Input() canMapOrUnmap = false;
  @Input() commitmentMapType = CommitmentMapType.Opportunity;

  @Input()
  public set projectKey(value: string) {
    this.selectedProjectKey = value;
    if (!value && this.calledForMap) {
      this.showProjectIdSection = true;
    }
    else {
      this.showProjectIdSection = false;
    }

    if (value && this.calledForMap) {
      this.loadProjectDetails();
    }

    if (value && !this.calledForMap) {
      this.loadMappedProjectDetails();
    }
  }

  @Input() currentCommimentDataSource: string;

  // @Input() opportunityId: string;

  @Output()
  private submit = new EventEmitter<AlertInput>();

  @Output()
  private cancel = new EventEmitter<void>();

  constructor(private userService: UserService, 
    private commitmentIntakeService: CommitmentIntakeService, 
    private permission: PermissionsService) { 
    this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r);
  }

  public loadProjectDetails(): void {
    this.errorMsg = '';
    this.commitmentIntakeService.getProjectDetailForMapping(this.selectedProjectKey, this.projectDataSource).subscribe(data => {
      this.commitmentIntakeMapDetails = data as unknown as ICommitmentIntakeMapDetailsDTO;
      this.currentDataSource = this.commitmentIntakeMapDetails.dataSource.toLowerCase();
      const businessUnits = this.commitmentIntakeMapDetails.projectDetails.bu;
      if (businessUnits) {
        this.commitmentIntakeMapDetails.projectDetails.bu = JSON.parse(businessUnits).join(',');
      }

      const products = this.commitmentIntakeMapDetails.projectDetails.product;
      if (products) {
        this.commitmentIntakeMapDetails.projectDetails.product = JSON.parse(products).join(',');
      }
      this.checkForUnmapAbility();
      this.checkForMapAbility();
    },
    error => {
      this.errorMsg = error;
    });
  }

  public loadMappedProjectDetails(): void {
    this.errorMsg = '';
    this.commitmentIntakeService.getMappedProjectDetails(this.selectedProjectKey, this.projectDataSource).subscribe(data => {
      this.commitmentIntakeMapDetails = data as unknown as ICommitmentIntakeMapDetailsDTO;
      this.currentDataSource = this.commitmentIntakeMapDetails.dataSource.toLowerCase();
      this.title = this.commitmentIntakeMapDetails.title;
      const businessUnits = this.commitmentIntakeMapDetails.projectDetails.bu;
      if (businessUnits) {
        this.commitmentIntakeMapDetails.projectDetails.bu = JSON.parse(businessUnits).join(',');
      }

      const products = this.commitmentIntakeMapDetails.projectDetails.product;
      if (products) {
        this.commitmentIntakeMapDetails.projectDetails.product = JSON.parse(products).join(',');
      }
      if (this.projectDataSource.toLowerCase() === "clarity") {
        this.selectedProjectKey = this.commitmentIntakeMapDetails.projectDetails.projectNumber;
      }
      else if (this.projectDataSource.toLowerCase() === "ecrm") {
        this.selectedProjectKey = this.commitmentIntakeMapDetails.projectDetails.opportunityId;
      }

      this.checkForUnmapAbility();
      this.checkForMapAbility();
    },
    error => {
      this.errorMsg = error;
    });
  }

  public closeDrawer(): void {
    this.cancel.emit();
  }

  public onMappingSubmit(): void {
    this.errorMsg = '';
    if (!this.calledForMap) {
      return;
    }

    if (this.commitmentMapType === CommitmentMapType.Project) {
      this.commitmentIntakeService.mapProject(this.selectedCommitmentIntakeId, this.commitmentIntakeMapDetails.id, this.selectedProjectKey)
      .subscribe(() => {
        const alertInput: AlertInput = {
          alertType: AlertType.Success,
          message: `Project "${this.selectedProjectKey}" is mapped successfully.`,
        };
        this.submit.emit(alertInput);
      },
      error => {
        this.errorMsg = error;
      });
    } else {
      this.commitmentIntakeService.mapOpportunity(this.selectedCommitmentIntakeId, this.commitmentIntakeMapDetails.id, this.selectedProjectKey)
      .subscribe(() => {
        const alertInput: AlertInput = {
          alertType: AlertType.Success,
          message: `Opportunity "${this.selectedProjectKey}" is mapped successfully.`,
        };
        this.submit.emit(alertInput);
      },
      error => {
        this.errorMsg = error;
      });
    }
  }

  public unmap(errorMessage: string): void {
    if (errorMessage === '') {
      const alertInput: AlertInput = {
        alertType: AlertType.Success,
        message: `Fulfillment "${this.selectedProjectKey}" is unmapped successfully.`,
      };
      this.submit.emit(alertInput);
    } else {
      this.errorMsg = errorMessage;
    }
  }

  public checkForMapAbility() {
    this.displayMapButton = this.calledForMap && this.canMapOrUnmap && !this.isReadOnlyMode;
  }

  //Scenario 1 -CCT admin with page template Read only access -Should see mapped project detail but unmap should be hidden 
  //Secenrio 2 - CCT admin with Page template admin access >> 
  //should view mapped project detail and unmap button if data source is not Clarity or Ecrm 
  //hide Unmap if datasoure is Ecrm/Clarity 
  //Scenrio 3 - RM role only - RM should have access of view mapped project detail and Unmap  Project id /Opportunity if data source is not Clarity or Ecrm 
  //If datasoure is Ecrm/Clarity than Unmap should be hidden
  //Scenario 4 - if datasource = 'servicenow' hide unmap button

  public checkForUnmapAbility() {
    const currentDatasource = this.currentCommimentDataSource.toLowerCase();
    const mappingDatasource = this.commitmentIntakeMapDetails.dataSource.toLowerCase();
    
    if (this.permission.isCCTAdmin() && this.isPageTemplateReadAdmin) {
      this.displayUnmapButton = false;
    }
    else if ((this.permission.isCCTAdmin() || this.permission.isRelationShipManager())) {
      if (currentDatasource === 'clarity' && mappingDatasource === 'clarity') {
        this.displayUnmapButton = false;
      }
      else if (currentDatasource === 'ecrm' && mappingDatasource === 'ecrm') {
        this.displayUnmapButton = false;
      }
    } else if (currentDatasource === 'ecrm' && mappingDatasource === 'ecrm') {
      this.displayUnmapButton = false;
    } else if (currentDatasource === 'servicenow' || (currentDatasource === 'cims')) {
      this.displayUnmapButton = false;
    } else {
      this.displayUnmapButton = this.commitmentIntakeMapDetails.canUnmap && !this.calledForMap && !this.isReadOnlyMode;
    }
  }

  public keyDown(keyboardEvent: KeyboardEvent): void {
    const focusableElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.getElementById('mapFulfilmentMainDiv');
    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
    const focusableContent = modal.querySelectorAll(focusableElements);
    let lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal
    if ((this.displayMapButton || this.displayMapButton) && (this.selectedProjectKey === '' || this.commitmentIntakeMapDetails === null)) {
      lastFocusableElement = focusableContent[focusableContent.length - 2];
    }
    if (keyboardEvent.shiftKey) { // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        (lastFocusableElement as HTMLElement).focus(); // add focus for the last focusable element
        keyboardEvent.preventDefault();
      }
    } else { // if tab key is pressed
      if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
        (firstFocusableElement as HTMLElement).focus(); // add focus for the first focusable element
        keyboardEvent.preventDefault();
      }
    }
  }
}
