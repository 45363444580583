<td role="cell">
    <div>
        <a id="{{'arrow'+ rowIndex}}" href="{{'#tableParentAccordion'+ rowIndex}}"
            class="material-icons td-parent-accordion collapsed" data-toggle="collapse"
            aria-expanded="false"></a>
    </div>
</td>

<!-- columns setting -->
<td role="cell" *ngFor="let colSetting of columnPreferenceSettings; let colIndex = index">
  <div [ngStyle]="{'margin-top': colSetting.key == 'isRisk' ? '7px' : null }" class="checkbox"
       [attr.title]="colSetting.key == 'isTracked' && item.datasource == 'ecrm' ? 'eCRM opportunities are for reference only and disabled from tracking on the dashboard.  Should there be reason to track an eCRM opportunity with a client, please contact CommitmentTrackerSupport@fiserv.com for assistance.' : null"
       *ngIf="colSetting.fieldType == 'checkbox'">
    <!-- <i class="material-icons info"
  *ngIf="colSetting.key === 'isTracked' && item?.CommitmentIntakeMappedDetails"
  title="{{showTitleDataForMappedCommitmentDetails(item)}}">info</i> -->

    <input id="{{'dataCheckbox'+ rowIndex + colIndex}}"
           *ngIf="colSetting.key == 'isTracked' && !colSetting.dependentChildField && item.isCustomCommitment && !disabledCustomCommitment"
           [disabled]="shouldDisableIsTrackCheckbox(item, colSetting)"
           value="true" type="checkbox" (click)="openAddUserClicked(item)"
           [(ngModel)]="item[colSetting.key]"
           (change)="onFieldChanging(item, colSetting.key)">

    <input id="{{'dataCheckbox'+ rowIndex + colIndex}}"
           *ngIf="colSetting.key == 'isTracked' && !colSetting.dependentChildField && item.isCustomCommitment && disabledCustomCommitment"
           [disabled]=true
           value="true" type="checkbox"
           [(ngModel)]="item[colSetting.key]"
           (change)="onFieldChanging(item, colSetting.key)">

    <input id="{{'dataCheckbox'+ rowIndex + colIndex}}"
           *ngIf="colSetting.key == 'isTracked' && !colSetting.dependentChildField && !item.isCustomCommitment && !disabledNonCustomCommitment"
           [disabled]="shouldDisableIsTrackCheckbox(item, colSetting)"
           value="true" type="checkbox"
           [(ngModel)]="item[colSetting.key]"
           (change)="onFieldChanging(item, colSetting.key)">

    <input id="{{'dataCheckbox'+ rowIndex + colIndex}}"
           *ngIf="colSetting.key == 'isTracked' && !colSetting.dependentChildField && !item.isCustomCommitment && disabledNonCustomCommitment"
           [disabled]=true
           value="true" type="checkbox"
           [(ngModel)]="item[colSetting.key]"
           (change)="onFieldChanging(item, colSetting.key)">

    <!-- For IsRisk -->
    <input id="{{'dataCheckbox'+ rowIndex + colIndex}}"
           *ngIf="colSetting.key == 'isRisk' && colSetting.dependentChildField"
           [disabled]="shouldDisableIsRiskCheckbox(item, colSetting)"
           value="true" type="checkbox"
           [(ngModel)]="item[colSetting.key]"
           (change)="onFieldChanging(item, colSetting.key, colSetting.dependentChildField)">

    <!-- END New version (PBI 324651) -->

    <label for="{{'dataCheckbox'+ rowIndex + colIndex}}"></label>
  </div>

    <input [ngStyle]="{'border': (item[colSetting.dependentParentField] || item[colSetting.dependentParentField] == 'true') 
                && (item[colSetting.key] == '' || item[colSetting.key] == undefined || item[colSetting.key] == null)
                && colSetting.dependentParentField != undefined && colSetting.dependentParentField != '' && colSetting.isMandatory ? 'red 1px solid' : ''}"
        class="data-input form-control"
        title="{{item[colSetting.key]}}"
        *ngIf="!(item['accessLevel'] == 'Read') && colSetting.fieldType == 'textbox' && colSetting.isEditable"
        type="text" [(ngModel)]="item[colSetting.key]"
        (change)="formFieldChanged(item, colSetting.key)">

    <textarea [ngStyle]="{'border': (item[colSetting.dependentParentField] || item[colSetting.dependentParentField] == 'true') 
                && (item[colSetting.key] == '' || item[colSetting.key] == undefined || item[colSetting.key] == null)
                && colSetting.dependentParentField != undefined && colSetting.dependentParentField != '' && colSetting.isMandatory ? 'red 1px solid' : ''
                }" class="data-input form-control"
        title="{{item[colSetting.key]}}"
        *ngIf="!(item['accessLevel'] == 'Read') && colSetting.fieldType == 'textarea' && colSetting.isEditable"
        [(ngModel)]="item[colSetting.key]"
        (change)="formFieldChanged(item, colSetting.key)">
    </textarea>

    <div class="fdl-select" *ngIf="colSetting.fieldType == 'select' && colSetting.isEditable"
        [ngClass]="{'has-error' : (item[colSetting.dependentParentField] || item[colSetting.dependentParentField] == 'true') 
        && (item[colSetting.key] == '' || item[colSetting.key] == undefined || item[colSetting.key] == null)
        && colSetting.dependentParentField != undefined && colSetting.dependentParentField != '' && colSetting.isMandatory
    }">
        <select *ngIf="colSetting.dependentParentField != undefined && colSetting.dependentParentField != ''"
            [disabled]="shouldDisableDuetoReadOnlyRoles(item, colSetting.key) || colSetting.dependentParentField != undefined && colSetting.dependentParentField != '' && !(item[colSetting.dependentParentField] || item[colSetting.dependentParentField] == 'true')"
            class="data-input form-control" type="text"
            [(ngModel)]='item[colSetting.key]'
            title="{{item[colSetting.key]}}"
            (change)="formFieldChanged(item, colSetting.key)">
            <option *ngFor="let selectedItem of riskReasonOptions" [value]="selectedItem.value">
                {{ selectedItem.name }}
            </option>
        </select>
        <select *ngIf="!(colSetting.dependentParentField != undefined && colSetting.dependentParentField != '')"
            [disabled]="shouldDisableDuetoReadOnlyRoles(item, colSetting.key)"
            class="data-input form-control" type="text"
            [(ngModel)]='item[colSetting.key]'
            title="{{item[colSetting.key]}}"
            (change)="formFieldChanged(item, colSetting.key)">
            <option value=""> </option>
            <option *ngFor="let selectedItem of riskReasonOptions" [value]="selectedItem.value">
                {{ selectedItem.name }}
            </option>
        </select>

        <!-- Eg: For Risk reason description -->
        <input
            [ngStyle]="{'border': item[colSetting.key] == 'other' && (item[colSetting.otherOptionFieldApiKey] == undefined || item[colSetting.otherOptionFieldApiKey] == '')? 'red 1px solid' : ''}"
            class="data-input form-control"
            *ngIf="item[colSetting.key] == 'other' && colSetting.showOtherOptionField"
            [readonly]="shouldDisableDuetoReadOnlyRoles(item, colSetting.key)" type="text"
            [(ngModel)]="item[colSetting.otherOptionFieldApiKey]"
            title="{{item[colSetting.otherOptionFieldApiKey]}}"
            (change)="formFieldChanged(item, colSetting.otherOptionFieldApiKey)">
    </div>

    <label *ngIf="colSetting.fieldType === 'date' && colSetting.key !== 'displayPlannedDeliveryDate'"
        title="{{item[colSetting.key] | date: content['dateHourFormat']}}">
        {{item[colSetting.key] | date: content['dateHourFormat']}}
    </label>
    <label *ngIf="colSetting.key === 'displayPlannedDeliveryDate'"
        title="{{formatDateTime(item[colSetting.key], 'MM/dd/yyyy', 'en-US')}}">
        {{formatDateTime(item[colSetting.key], 'MM/dd/yyyy', 'en-US')}}
    </label>

    <!-- Explain for condition isDisableByColumnKey() in ngIf
        Because we show the risk reason dropdown above in disable mode for Read only users so we don't need to show the label below for risk reason -->
    <label
        title="{{item[colSetting.key]}}"
        *ngIf="colSetting.key === 'status' && !isDisableByColumnKey(colSetting.key) && (item['accessLevel'] == 'Read' || !colSetting.isEditable) && colSetting.fieldType != 'checkbox' && colSetting.fieldType !== 'date'">
        {{item[colSetting.key]}}
    </label>
    <label
        title="{{item[colSetting.key]}}"
        *ngIf="colSetting.key === 'productNames' && !isDisableByColumnKey(colSetting.key) && (item['accessLevel'] == 'Read' || !colSetting.isEditable) && colSetting.fieldType != 'checkbox' && colSetting.fieldType !== 'date'">
        {{item[colSetting.key]}}
    </label>
    <label
        title="{{item[colSetting.key]}}"
        *ngIf="colSetting.key !== 'productNames' && colSetting.key !== 'status' && colSetting.key !== 'datasource' && !isDisableByColumnKey(colSetting.key) && (item['accessLevel'] == 'Read' || !colSetting.isEditable) && colSetting.fieldType != 'checkbox' && colSetting.fieldType !== 'date'">
        <!-- TOCCTT-33: PLESE KEEP THE white-space: pre-wrap; here, don't add the new line or whitespace to this html content -->
        <span *ngIf="colSetting.key !== 'oneViewGroupName'" style="white-space: pre-wrap;">{{removeUnnecessaryCharacters(colSetting.key, item[colSetting.key])}}</span>

        <!-- For One view column -->
        <span *ngIf="colSetting.key === 'oneViewGroupName'">
            <a style="text-decoration: none;" href="javascript:void(0);" title="{{item.oneViewGroupName}}" (click)="oneViewClick(item.oneViewGroupId)">{{item.oneViewGroupName}}</a>
        </span>
    </label>
    <label
        title="{{item[colSetting.key]}}"
        *ngIf="colSetting.key === 'datasource' && !isDisableByColumnKey(colSetting.key) && (item['accessLevel'] == 'Read' || !colSetting.isEditable) && colSetting.fieldType != 'checkbox' && colSetting.fieldType !== 'date'">
        {{transformDataSourceString(item[colSetting.key])}}
    </label>
    <label
        *ngIf="!isDisableByColumnKey(colSetting.key) && (item['accessLevel'] == 'Read' || !colSetting.isEditable) && colSetting.fieldType != 'checkbox' && item[colSetting.key] == 'other' && colSetting.showOtherOptionField"
        title="{{item[colSetting.otherOptionFieldApiKey]}}">
        {{item[colSetting.otherOptionFieldApiKey]}}
    </label>
</td>

<!-- Actions three dot -->
<td (click)="addZIndex(rowIndex)" [attr.id]="'actions-' + rowIndex" class="actions" role="cell">
    <div (click)="addZIndex(rowIndex)" class="kebab-menu dropleft">
        <a href="javascript:void(0);" (click)="addZIndex(rowIndex)" data-toggle="dropdown" id="headerSwitcher"
            aria-expanded="false">
            <span ariaReadOnly="true" class="material-icons"> more_vert </span>
        </a>
        <div x-placement="left-start"
            class="dropdown-menu dropdown-menu-dark top-15 header-dropdown-switcher">
          <div class="dropdown-list-option">
            <a *ngIf="canView(item)" class="dropdown-item" (click)="onViewClicked()">View</a>
            <a *ngIf="canUpdateUserForCustom(item)" class="dropdown-item"  data-toggle="modal" data-target="#editCommitmentUsersFIO" (click)="openUpdateUserClicked()">Edit Custom User Assignment</a>
            <a *ngIf="canViewEdit(item)" class="dropdown-item" (click)="onViewClicked()">
              View
              &amp; Edit
            </a>
            <!--<a *ngIf="canMapProject(item)" class="dropdown-item"
               (click)="openMapFulfillment('')">
              Map Project
            </a>-->
            <a *ngIf="isCCTAdminWriteAccess || isAdmin || createdByLoggedInUser(item)" (click)="openDeleteModal()"
               data-toggle="modal" class="dropdown-item" data-target="#deleteModal_one">Delete</a>
          </div>
        </div>
    </div>
</td>
