import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import Chart from 'chart.js/auto';
import { ManageClientDashboardService } from '../services/manageClientDashboard.service';
import { DashboardCommitmentDTO } from '../models/client-dashboard-d-t-o';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-client-commitment-calendar',
  templateUrl: './client-commitment-calendar.component.html',
  styleUrls: ['./client-commitment-calendar.component.css']
})
export class ClientCommitmentCalendarComponent implements OnChanges {
  @Input() allCommitments: DashboardCommitmentDTO[] = [];
  @Input() isCalendarReady: boolean = false;
  @Input() typeOfCommitmentCalander: any = 'quarter';
  @Input() isCommitmentCalendarError: boolean = false;

  @Output() setTypeOfCommitmentCalander = new EventEmitter<string>();
  
  public commitmentCalendarNumbers: any = [];
  public fromYear: any = new Date().getFullYear() - 5;
  public toYear: any = new Date().getFullYear() + 5;
  public isPageLoad: boolean = true;
  public chart2: any;
  public blueThemeMode = false;
  public showTable = false;
  public tableData: any = null;
  public commitmentCalendarLabels: any = [];

  constructor(
    private _manageClientDashboardService: ManageClientDashboardService,
    private userService: UserService)
  { 
      this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r); 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.allCommitments) {
      this.renderDeliveryCalendar(this.typeOfCommitmentCalander);
    }

    if (changes.typeOfCommitmentCalander) {
      this.renderDeliveryCalendar(this.typeOfCommitmentCalander);
    }
  }

  renderDeliveryCalendar(calendarType: string) {
    const commitmentCalendarData = this._manageClientDashboardService.getDeliveryCalendarData(this.allCommitments, calendarType);
    this.commitmentCalendarNumbers = [];
    this.commitmentCalendarLabels = [];
    for (const currentCalendarData of commitmentCalendarData) {
      this.commitmentCalendarLabels.push(currentCalendarData.label);
      this.commitmentCalendarNumbers.push(currentCalendarData.numberOfCommitments);
    }

    const commitmentCalendarData_inProgressCommitments = [];
    const commitmentCalendarData_inPlanningCommitments = [];
    const commitmentCalendarData_notStartedCommitments = [];
    const commitmentCalendarData_onHoldCommitments = [];
    const commitmentCalendarData_canceledCommitments = [];
    const commitmentCalendarData_completedCommitments = [];
    const commitmentCalendarData_deliveredCommitments = [];
    for (let k in commitmentCalendarData) {
      commitmentCalendarData_inProgressCommitments.push(commitmentCalendarData[k].inProgressCommitments);
      commitmentCalendarData_inPlanningCommitments.push(commitmentCalendarData[k].inPlanningCommitments);
      commitmentCalendarData_notStartedCommitments.push(commitmentCalendarData[k].notStartedCommitments);
      commitmentCalendarData_onHoldCommitments.push(commitmentCalendarData[k].onHoldCommitments);
      commitmentCalendarData_canceledCommitments.push(commitmentCalendarData[k].canceledCommitments);
      commitmentCalendarData_completedCommitments.push(commitmentCalendarData[k].completedCommitments);
      commitmentCalendarData_deliveredCommitments.push(commitmentCalendarData[k].deliveredCommitments);
    }

    const data2 = [
      { 'label': 'Not Started', 'data': commitmentCalendarData_notStartedCommitments, 'backgroundColor': '#36454f' },
      { 'label': 'In Planning', 'data': commitmentCalendarData_inPlanningCommitments, 'backgroundColor': '#B7B7B7' },
      { 'label': 'In Progress', 'data': commitmentCalendarData_inProgressCommitments, 'backgroundColor': '#FF5E0E' },
      { 'label': 'Delivered', 'data': commitmentCalendarData_deliveredCommitments, 'backgroundColor': '#0080BF' },
      { 'label': 'Completed', 'data': commitmentCalendarData_completedCommitments, 'backgroundColor': '#0080BF' },      
      { 'label': 'On Hold', 'data': commitmentCalendarData_onHoldCommitments, 'backgroundColor': '#FFC626' },
      { 'label': 'Cancelled', 'data': commitmentCalendarData_canceledCommitments, 'backgroundColor': '#90d5ff' }
    ];

    this.tableData = data2;
    this.commitmentCalendarGraph(this.commitmentCalendarLabels, data2);
  }

  onToYearSelect() {
    this.getCalendarCommitment('year');
  }

  getCalendarCommitment(calendarType: string) {
    this.setTypeOfCommitmentCalander.emit(calendarType);
  }

  /**
   * *
   * @param labels
   * @param Month
   * @param type
   */
  commitmentCalendarGraph(labels, Month) {
    const ctx2: any = document.getElementById('areaChart2') as HTMLElement;
    const data = {
      labels: labels,
      datasets: Month
    };

    const options: any = {
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true
        }
      },
      animation: false,
      maintainAspectRatio: false,
      scaleLabel: function (label) {
        return Math.round(label.value);
      }, ticks: {
        precision: 0
      },
      plugins: {
        animation: {
          duration: 0
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 10,
            padding: 5,
            fontColor: 'white',
            fontSize: 0,
          },
        },
      },
    };
    if (this.chart2) {
      this.chart2.destroy();
    }

    this.chart2 = new Chart(ctx2, {
      type: 'bar',
      data: data,
      options: options,
    });
  }

  public switchView(mode: boolean): void {
    this.showTable = mode;
  }
}
