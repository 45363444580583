import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable()
export class CustomClientService {
  private _customClientUsersBaseUrl: string = "customclients";
  private _customOneViewUsersBaseUrl: string = "CustomClientUserOneView";

  constructor(private http: HttpClient) { }

  createCustomClientUsers(projectKey: string, data) {
    return this.http.post<any>(`${this._customClientUsersBaseUrl}/${projectKey}`, data);
  }

  getCustomCLientUsers(projectKey: string) {
    return this.http.get<any>(`${this._customClientUsersBaseUrl}/${projectKey}`);
  }

  GetCustomOneViewUsers(projectKey: string) {
    return this.http.get<any>(`${this._customOneViewUsersBaseUrl}/${projectKey}`);
  }
}
