import { Component, OnInit } from '@angular/core';
import { CommitmentIntakeReviewTrailOverallDTO } from './models/commitment-intake-review-trail-overall-d-t-o';
import { ActivatedRoute } from '@angular/router';
import { NoteReviewTrailDTO } from './models/note-review-trail-d-t-o';
import { ClientNoteService } from 'src/app/services/client-note.service';
import moment from 'moment';
import { CommitmentReviewTrailService } from 'src/app/services/cmmitment-review-trail.service';
import { take } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-commitment-review-trail-overall',
  templateUrl: './commitment-review-trail-overall.component.html',
  styleUrls: ['./commitment-review-trail-overall.component.css']
})
export class CommitmentReviewTrailOverallComponent implements OnInit {
  public projectKey: string;
  public dataSource: string = null;
  public data: Array<CommitmentIntakeReviewTrailOverallDTO> = new Array<CommitmentIntakeReviewTrailOverallDTO>();
  public noteData: Array<NoteReviewTrailDTO> = new Array<NoteReviewTrailDTO>();
  public clientDunsNumber: string;
  public clientName = '';
  public noData = false;
  public noteNoData = false;

  constructor(private _commitmentReviewTrailService: CommitmentReviewTrailService,
    private clientNoteService: ClientNoteService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.pipe(take(1)).subscribe((params) => {
      this.clientDunsNumber = params['clientDunsNumber'];
      this.clientName = params['clientName'];
      if (this.clientDunsNumber) {
        if (this.clientName) {
          this.clientName = decodeURIComponent(this.clientName);
        }
        this.loadData();
        this.loadNoteData();
      }
    });
    
  }

  private loadData(): void {
    this._commitmentReviewTrailService.getReviewTrailsByClientDunsNumber(this.clientDunsNumber)
      .subscribe(result => {
        this.data = result.body as Array<CommitmentIntakeReviewTrailOverallDTO>;
        if (!this.data || this.data.length === 0) {
          this.noData = true;
        } else {
          this.data.forEach(item => {
            item.modifiedOn = moment.utc(item.modifiedOn).local();
          });
        }
      });
  }

  private loadNoteData(): void {
    this.clientNoteService.getClientNoteReviewTrailByDunsNumber(this.clientDunsNumber)
      .subscribe(result => {
        this.noteData = result.body as Array<NoteReviewTrailDTO>;
        if (!this.noteData || this.noteData.length === 0) {
          this.noteNoData = true;
        } else {
          this.noteData.forEach(item => {
            item.createdOn = moment.utc(item.createdOn).local();
          });
        }
      });
  }

  public selectProject(projectKey: string): void {
    this.projectKey = projectKey;
  }

  public keypress(projectKey: string): void {
    this.selectProject(projectKey);
    $('#reviewTrail').modal('show');
  }

  public modalClosed(): void {
    setTimeout(() => {
      const temp = document.getElementById('tr' + this.projectKey);
      temp.focus();
      this.projectKey = null;
    }, 500);
  }
}
