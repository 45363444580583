import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
import { ManageClientDashboardService } from '../services/manageClientDashboard.service';
import { DashboardCommitmentDTO } from '../models/client-dashboard-d-t-o';
import { UserService } from 'src/app/services/user.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { formatDate } from '@angular/common';
import { DateTimeService } from '../../commitment-intakes/services/date-time.service';

@Component({
  selector: 'app-client-commitment-list',
  templateUrl: './client-commitment-list.component.html',
  styleUrls: ['./client-commitment-list.component.css']
})
export class ClientCommitmentListComponent implements OnInit, OnChanges {
  @Input() clientEntityKey?: number = null;
  @Input() commitmentsError: boolean = false;
  @Input() isRowsLoading: boolean = true;
  @Input() allCommitments: DashboardCommitmentDTO[] = [];
  @Input() isInitialSearch: boolean = true;
  @Input() isNonProject: boolean = false;

  @Output() onNavigate = new EventEmitter<any>();
  @Output() exportRiskExcel = new EventEmitter<{ formFields: any, orderBy: string }>();
  @Output() exportExcelNonRisk = new EventEmitter<{ formFields: any, orderBy: string }>();
  @Output() populateTotalCount = new EventEmitter<number>();
  @Output() onSetOrderBy = new EventEmitter<{ key?: string, isAsc?: boolean }>();

  headerColumns = ['Status', 'Project Number', 'Title', 'Stage', 'Original Delivery Date', 'Target Delivery Date', 'Date Change By Stakeholder', 'Product'];
  keys = ['isRisk', 'projectNumber', 'commitmentTitle', 'commitmentStatus', 'originalPlannedCommittedDeliveryDate', 'displayPlannedCommittedDeliveryDate', 'dateChangeByStakeholder', 'productNames'];
  rowsTitleSuggestions: any;
  projectNumberSuggestions: any;
  commitmentStatusesSuggestions: { id: string, name: string }[] = [];
  sortingColumn: string;
  sortingObject: { key?: string, isAsc?: boolean } = {};
  filterForm: FormGroup;
  allRowsTotalPages: number = 1;
  allRowsPerPage : number = 10;
  public basedCommitments : DashboardCommitmentDTO[] = [];
  public filteredCommitments : DashboardCommitmentDTO[] = [];
  public allRowsPage : number = 1;
  public paginatedAllRows: DashboardCommitmentDTO[] = [];
  public blueThemeMode = false;
  // public statusNamesArray: any = [];

  private _previousSelectedStatuses: string[] = [];
  private _blankSelectText: string = '(Blanks)';

  @ViewChild('projectNumberSelect') private projectNumberSelect:
    | NgSelectComponent
    | undefined;

  @ViewChild('commitmentTitleSelect') private commitmentTitleSelect:
    | NgSelectComponent
    | undefined;

  constructor(private fb: FormBuilder, 
    private _manageClientDashboardService: ManageClientDashboardService,
    private userService: UserService,
    private _dateTimeService: DateTimeService) {
    this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r); 
   }
  
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clientEntityKey) {
      this.initData();
    }

    if (changes.allCommitments) {
      this.processCommitments();
    }
  }

  ngOnInit(): void {
    this.initData(); 
  }

  initData() {
    this.initFormGroup();
    this._previousSelectedStatuses = [];
    this.paginatedAllRows = [];
    this.allRowsTotalPages = 1;
    this.sortingObject = {};
    this.sortingColumn = null;
  }

  initFormGroup() {
    this.filterForm = this.fb.group({
      projectNumber: [null],
      commitmentTitle: [null],
      commitmentStatus: [[]]
    });
    this.projectNumberSelect.searchTerm = null;
    this.commitmentTitleSelect.searchTerm = null;
  }

  navigate(event: { action: string, data: DashboardCommitmentDTO }) {
    this.onNavigate.emit(event);
  }

  changePage(page: number) {
    this.onAllRowsPageChange(page);
  }

  processCommitments() {
    if (!this.allCommitments || this.allCommitments.length === 0) {
      this.projectNumberSuggestions = [];
      this.rowsTitleSuggestions = [];
      this.commitmentStatusesSuggestions = [];
      this.basedCommitments = [];
      this.filteredCommitments = [];
      this.paginatedAllRows = [];
      this.populateTotalCount.emit(0);
      return;
    }

    this.basedCommitments = [];
    if (this.isNonProject) {
      this.basedCommitments = this.allCommitments.filter(e => e.datasource.toLowerCase() === 'manual' && e.projectNumber == null || (e.projectNumber.indexOf('PR') === -1 && e.datasource.toLowerCase() != 'ecrm') );
    }
    else {
      this.basedCommitments = this.allCommitments.filter(e => {
        if (e.datasource.toLowerCase() === 'manual' && e.projectNumber != null && e.projectNumber.indexOf('PR') != -1) {
          return true;
        }
        return e.datasource.toLowerCase() !== 'manual';
      });
    }

    this.basedCommitments.forEach(element => {
      // element.displayPlannedCommittedDeliveryDate = "" when stage like '%On Hold%' for all sources and Only On ClientDashboard/export - CCT and Detail page shows value from the db.      
      element.displayPlannedCommittedDeliveryDate = element.commitmentStatus.indexOf('On Hold') > -1 ? "" : this.dateFormat(element.displayPlannedCommittedDeliveryDate);       
      element.originalPlannedCommittedDeliveryDate = this.dateFormat(element.originalPlannedCommittedDeliveryDate);
      element['dateChangeByStakeholderClient'] = this.getStakeholderDaysChanged(element, 'Client');
      element['dateChangeByStakeholderFiserv'] = this.getStakeholderDaysChanged(element, 'Fiserv');
    });

    this.processStatusSuggestions();
    // Apply filter
    const commitmentRows = this.applyFiltering(this.basedCommitments);

    // Apply sorting
    const sortedRows = this._manageClientDashboardService.applySorting(commitmentRows, this.sortingObject);
    const isRiskSort = this.sortingObject.key && this.sortingObject.key.includes("isRisk");
    if (this.isInitialSearch || isRiskSort) {
      this.filteredCommitments = this._manageClientDashboardService.sortByRisk(commitmentRows, this.sortingObject);
    }
    else {
      this.filteredCommitments = sortedRows;
    }

    this.allRowsTotalPages = Math.ceil(this.filteredCommitments.length/this.allRowsPerPage);
    if (this.isInitialSearch) {
      this.onAllRowsPageChange(1);
    }          
    else {
      this.getPaginatedAllRows();
    }
    
    this.processSuggestions();
    
    const commitmentStatuses = this.filterForm.get('commitmentStatus').value;
    if (!commitmentStatuses || commitmentStatuses.length === 0) {
      this.filterForm.get('commitmentStatus').setValue(null);
    }
    else {
      const updatedCommitmentStatuses = commitmentStatuses.filter(x => this.commitmentStatusesSuggestions.some(c => c.id === x));
      this.filterForm.get('commitmentStatus').setValue(updatedCommitmentStatuses);
    }
    this.populateTotalCount.emit(sortedRows.length);
  }

  applyFiltering(commitmentRows: DashboardCommitmentDTO[]) {
    const searchFilterValue = this.filterForm.value;
    Object.keys(this.filterForm.controls).forEach(formKey => {
      const filterValue = searchFilterValue[formKey];
      if (formKey === 'commitmentStatus' && filterValue && filterValue.length > 0) {
        const statusSuggestions = [...this.commitmentStatusesSuggestions];
        const filterStatuses = searchFilterValue['commitmentStatus'].map(x => {
          return statusSuggestions.find(c => c.id === x).name;
        });
        commitmentRows = commitmentRows.filter(c => filterStatuses.includes(c.commitmentStatus));
      }
      else if (formKey !== 'commitmentStatus' && filterValue) {
        commitmentRows = commitmentRows.filter(c => {
          if (filterValue === this._blankSelectText) {
            return !c[formKey];
          }
          return c[formKey] === filterValue;
        });
      }
    });

    return commitmentRows;
  }

  processSuggestions() {
    const allRowsTitleSuggestions = [];
    const allRowsProjectNumberSuggestions = [];
    this.filteredCommitments.forEach(element => {
      if (!allRowsTitleSuggestions.includes(element.commitmentTitle) && element.commitmentTitle) {
        allRowsTitleSuggestions.push(element.commitmentTitle);
      }
      if (!allRowsProjectNumberSuggestions.includes(element.projectNumber) && element.projectNumber) {
        allRowsProjectNumberSuggestions.push(element.projectNumber);
      }
    });

    this.rowsTitleSuggestions = allRowsTitleSuggestions.map((title, index) => {
      return {
        id: (index).toString(),
        name: title
      }
    });

    this.projectNumberSuggestions = allRowsProjectNumberSuggestions.map((title, index) => {
      return {
        id: (index).toString(),
        name: title
      }
    });
  }

  processStatusSuggestions() {
    const allRowsStatusSuggestions = [];
    if (!this.hasFilter()) {
      this.basedCommitments.forEach(element => {
        allRowsStatusSuggestions.push(element.commitmentStatus);       
      });
    } else if (this.filterForm.get('commitmentTitle').value || this.filterForm.get('projectNumber').value) {
      this.filteredCommitments.forEach(element => {
        allRowsStatusSuggestions.push(element.commitmentStatus);
      });
    }

    const rowsStatusSuggestions = allRowsStatusSuggestions.map((title, index) => ({
        id : (index).toString(),
        name: title
    }));

    const orderedStates = [
      'Not Started',
      'In Planning',
      'In Progress',
      'Delivered',
      'Completed',
      'Fiserv - On Hold',
      'Client - On Hold',
      'On Hold',      
      'Cancelled'      
    ];

    const commitmentStatusesSuggestions = [{
      id: '',
      name: 'All'
    }];
    for (const state of orderedStates) {
      const currentStatus = rowsStatusSuggestions.find(x => x.name === state);
      if (currentStatus) {
        const stateIndex = orderedStates.indexOf(state);
        commitmentStatusesSuggestions.push({
          id: (stateIndex).toString(),
          name: currentStatus.name
        });
      }
    }

    this.commitmentStatusesSuggestions = commitmentStatusesSuggestions;
  }

  getPaginatedAllRows() {
    const startIndex = (this.allRowsPage - 1)* this.allRowsPerPage;
    const endIndex = startIndex + this.allRowsPerPage;
    this.paginatedAllRows = this.filteredCommitments.slice(startIndex,endIndex);
  }
  
  onAllRowsPageChange(page: number):void{
    this.allRowsPage = page;
    this.getPaginatedAllRows();
  }

  hasFilter() {
    if (this.filterForm.get('projectNumber').value) {
      return true;
    }
    if (this.filterForm.get('commitmentTitle').value) {
      return true;
    }
    return false;
  }

  sort(event) {
    this.sortingObject = event;
    if (this.sortingColumn !== this.sortingObject.key) {
      this.sortingColumn = this.sortingObject.key;
    }
    
    this.isInitialSearch = false;
    this.processCommitments();
    this.onSetOrderBy.emit(this.sortingObject);
  }

  onClearText(type: string) {
    this.cancelSearch(type);
  }

  handleSelectKeyDown(event, controlName: string) {
    const { key, target } = event;
    if (key === 'Backspace') {
      const { selectionStart, selectionEnd, textLength } = target;
      if (selectionStart === 0 && selectionEnd === textLength) {
        this.onClearText(controlName);
      }
    }

    if (event.ctrlKey && event.key === 'x') {
      switch (controlName) {
        case 'commitmentTitle':
        case 'projectNumber':
          this.filterForm.get(controlName).setValue(null);
          break;
      }
    }
  }
  
  onSelectSearch(event: { term: string, items: any[] }, controlName: string) {
    const { term } = event;
    let matchedItem: { id: string, name: string };
    switch (controlName) {
      case 'commitmentTitle':
        matchedItem = this.rowsTitleSuggestions.find(item => item.name.toLowerCase() === term.toLowerCase());
        break;
      case 'projectNumber':
        matchedItem = this.projectNumberSuggestions.find(item => item.name.toLowerCase() === term.toLowerCase());
        break;
    }
    
    if (matchedItem) {
      this.filterForm.get(controlName).setValue(matchedItem.name);
    }
  }

  onStateChanged(event) {
    let commitmentStatus = null;
    if (event && event.length > 0 && event.some(x => x.id !== '')) {
      if (event[event.length - 1].id === '') {
        commitmentStatus = this.commitmentStatusesSuggestions.map(x => x.id);
      }
      else if (this._previousSelectedStatuses.every(x => x !== '') && event.every(x => x.id !== '') && event.length === this.commitmentStatusesSuggestions.filter(x => x.id !== '').length) {
        commitmentStatus = this.commitmentStatusesSuggestions.map(x => x.id);
      }
      else if (this._previousSelectedStatuses.some(x => x === '') && event.every(x => x.id !== '') && event.length === this.commitmentStatusesSuggestions.filter(x => x.id !== '').length) {
        commitmentStatus = [];
      }
      else {
        commitmentStatus = event.map(x => x.id).filter(x => x !== '');
      }
    }
    else if (event && event.length === 1 && event[0].id === '') {
      commitmentStatus = this.commitmentStatusesSuggestions.map(x => x.id);
    }

    this.filterForm.get('commitmentStatus').setValue(commitmentStatus);
    this._previousSelectedStatuses = commitmentStatus;
  }
  
  getStateSelectedLabel() {
    const commitmentStatus = this.filterForm.get('commitmentStatus').value;
    const isSelectedAll = commitmentStatus.indexOf('') >= 0;
    const selectedLabel = isSelectedAll ? 'All' : commitmentStatus.length;
    return `${selectedLabel} item${commitmentStatus.length > 1 || isSelectedAll ? 's' : ''}`;
  }

  cancelSearch(type: string = '') {
    if (type === 'All') {
      const formKeys = Object.keys(this.filterForm.controls);
      formKeys.forEach(key => {
        this.filterForm.get(key).setValue(null);
      });

      this.projectNumberSelect.searchTerm = null;
      this.commitmentTitleSelect.searchTerm = null;
      this._previousSelectedStatuses = [];
    }
    else {
      this.filterForm.get(type).setValue(null);
    }

    this.sortingObject = {};
    this.sortingColumn = null;
    this.isInitialSearch = true;
    this.onSetOrderBy.emit(null);
    if (!this.clientEntityKey) {
      return;
    }

    this.processCommitments();
  }
  
  advanceSearch() {
    if (!this.clientEntityKey) {
      return;
    }
    
    this.isInitialSearch = !this.sortingObject || !this.sortingObject.key;
    this.processCommitments();
  }

  exportExcel() {
    const formFields = this.generateSearchFields();
    let orderBy = null;
    if (this.sortingObject && this.sortingObject.key) {
      orderBy = this.sortingObject.isAsc ? this.sortingObject.key : `${this.sortingObject.key} desc`;
    }
    
    if (this.isRiskSorting(this.sortingObject)) {
      this.exportRiskExcel.emit({ formFields: formFields, orderBy: orderBy });
    }
    else {
      this.exportExcelNonRisk.emit({ formFields: formFields, orderBy: orderBy });
    }
  }

  private generateSearchFields(): any {
    const formFields = { 'isReviewed': [true] };
    const formValue = this.filterForm.value;
    if (formValue && formValue.projectNumber) {
      formFields['ProjectNumber'] = [formValue.projectNumber]
    }

    if (formValue && formValue.commitmentTitle) {
      formFields['CommitmentTitle'] = [formValue.commitmentTitle]
    }

    if (formValue && formValue.commitmentStatus) {
      const selectedStatuses = formValue.commitmentStatus.map(current => {
        const currentStatus = this.commitmentStatusesSuggestions.find(suggestion => suggestion.id == current);
        return currentStatus.name;
      });
      formFields['Status'] = selectedStatuses;
    }

    return formFields;
  }

  private isRiskSorting(sortingObject: { key?: string, isAsc?: boolean }) {
    // Risk is sorting by default
    if (!sortingObject || !sortingObject.key) {
      return true;
    }

    return sortingObject.key.includes("isRisk");
  }


  getStakeholderDaysChangedForExportExcel(row: DashboardCommitmentDTO): any {
    const result = `Client ${this.getStakeholderDaysChanged(row, 'Client')} \r\nFiserv ${this.getStakeholderDaysChanged(row, 'Fiserv')}`;
    return result;
  }

  getStakeholderDaysChanged(row: DashboardCommitmentDTO, type: string): any {
    const result = '(0x / 0 days)';
    if (!row.clientTotalOfDaysChangedCount && !row.clientTotalOfDaysChanged && !row.fiservTotalOfDaysChangedCount && !row.fiservTotalOfDaysChanged) {
      return result;
    }

    switch (type) {
      case 'Client':
        return `(${row.clientTotalOfDaysChangedCount}x / ${row.clientTotalOfDaysChanged} days)`;
      case 'Fiserv':
        return `(${row.fiservTotalOfDaysChangedCount}x / ${row.fiservTotalOfDaysChanged} days)`;
    }
  }

  isSearchButtonDisplay(controlName: string) {
    return !this.filterForm.get(controlName).value;
  }

  private dateFormat(value: string): string {
    const isValidDate = this._dateTimeService.IsValidDate(value);
    if (!value || value === 'To Be Scheduled' || !isValidDate) { 
      return value; 
    }

    if (this._dateTimeService.isFormatMatched(value, 'MM/DD/yyyy')) {
      return value;
    }

    const result = this._dateTimeService.formatDate(value, 'MM/DD/yyyy');
    return result;
  }
}
