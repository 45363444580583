import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { take } from 'rxjs/internal/operators/take';
import * as $ from 'jquery';
import { IPager } from 'src/app/shared/pagination';
import { IQuery } from 'src/app/shared/query';
import { UserService } from 'src/app/services/user.service';
import { ExportToExcelService } from 'src/app/services/export-to-excel.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { forkJoin, from, Observable } from 'rxjs';
import { PreferenceService } from 'src/app/services/preference.service';
import { ContentService } from 'src/app/services/content.service';
import { AlertInput } from '../models/alert-input';
import { AlertType } from '../enums/alert-type.enum';
import { IUser } from 'src/app/shared/user';
import moment from 'moment';
import { mergeMap, toArray } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { ICommitmentIntakeDto } from '../models/commitment-intake-d-t-o';
import { ColumnPreferenceSetting } from '../models/column-preference-setting';
import { CommitmentIntakeService } from 'src/app/services/commitment-intake.service';
import { CommitmentManualService } from 'src/app/services/commitment-manual.service';
import { CommitmentEcrmService } from 'src/app/services/commitment-ecrm.service';
import { ManageCommitmentListService } from '../services/manageCommitmentList.service';
import { CommitmentMapType } from '../enums/commitment-map-type.enum';
import { ICommitmentIntakeMapDetailsDTO } from '../models/commitment-intake-map-details-d-t-o';
import { COMMITMENT_INTAKES_TAB_NAME } from 'src/app/core/constants';
declare var $: any;

@Component({
  selector: 'app-commitment-intake-list',
  templateUrl: './commitment-intake-list.component.html',
  styleUrls: ['./commitment-intake-list.component.css'],
})
export class CommitmentIntakeListComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() commitmentIntakeList: ICommitmentIntakeDto[] = [];
  @Input() public queryParam: IQuery;
  @Input() public pagerObject: IPager;
  @Input() public filterColumn = '';
  @Input() public isAsc = true;
  @Input() public isPageTemplateAdmin = false;
  @Input() public isPageTemplateReadAdmin = false;
  @Input() public isReset: boolean = false;
  @Input() public selectedTabName = "All";
  @Input() public columnSettings: { columnPreferences: any, allColumnList: any };
  @Input() public columnSettingErrorMsg: string;

  @Output() private search = new EventEmitter<IQuery>();
  @Output() private onAlertChanged = new EventEmitter<AlertInput>();
  @Output() private onRedirectToDetails = new EventEmitter();
  @Output() private getColumnPreferences = new EventEmitter();
  @Output() private updateUserClicked = new EventEmitter();
  @Output() private addUserClicked = new EventEmitter();
  @Output() private oneViewClicked = new EventEmitter<any>();
  
  public showUpdateCommitmentsIntakeDrawer = false;
  
  public commimentsTobeUpdated: Array<ICommitmentIntakeDto> = [];
  public enableSaveButton: boolean = false;
  
  public selectedForCopy: ICommitmentIntakeDto;
  public isClientExecutive = false;
  public isCCTAdmin = false;
  public isRm = false;
  public isCCTAdminReadOnly = false;
  public showClientUserModalFIO = false;
  public showClientUserEditModalFIO = false;
  public content: any = [];
  public blueThemeMode = false;
  public isReadOnlyMode: boolean;
  public currentCommimentDataSource: string;
  public isAllTracked: boolean = false;
  public readonly commitmentIntakeDictUrl: string = 'https://fiservcorp.sharepoint.com/:b:/s/fgsbsg/EfnqfNE5vb1JlKOQTS2ka1sB5C-pzH_tkIYKybAw5SXeUA?e=qQZEjV';
  public isMapFulfilmentModalShown = false;
  public isCalledForMap = true;
  public commitmentMapType = CommitmentMapType.Opportunity;
  public commitmentMapTypeEnum = CommitmentMapType;
  public projectKey = '';
  public projectDataSource = '';
  public fulfillmentTitle = '';
  public itemForDelete = null;
  public titleForUnmap = '';
  public commitmentIntakeDetailsId = 0;
  public canMapOrUnmapOnMapModal = false;
  public zIndexMode = false;
  public hasMapOrUnmapPermission = false;
  public originCommitments: Array<any> = [];
  public selectedForUserAssignement: any;
  public selectedForeditUserAssignement: any;
  private _isRefresh: boolean = false;
  private _userData: IUser;
  private _trackedState: any = {};
  public disabledCustomCommitment: boolean = false;
  public disabledNonCustomCommitment: boolean = false;
  public responseId = 0;
  /** start of column settings changes**/
  public columnPreferences: any;
  public prefColumnOptions: any;
  public maxColumnCount: any;
  public showAddColumnOptions: boolean = false;
  public showColumnSettingsDrawer: boolean = false;
  public columnPreferenceSettings: ColumnPreferenceSetting[] = [];
  
  private _scrollPlaceWhereMapFulfillmentCalled = '';
  private _isError: boolean = false;
  private _listPreferenceName: any;
  private _ignoreSortingColumns: string[] = [];
  private _preferenceId: any;
  private _userId: any;
  private _isOpenUnmapModal = false;
  private _cctColumnSettings = [
    {"key":"isTracked","title":"Track","order":0,"fieldType":"checkbox","isEditable":true,"value":false},
    {"key":"isRisk","title":"Risk","order":1,"fieldType":"checkbox","isEditable":true,"value":false,"dependentChildField":"riskReason"},
    {"key":"riskReason","title":"Risk Reason","order":2,"fieldType":"select","isEditable":true, "showOtherOptionField":true, "otherOptionFieldApiKey":"riskDescription","dependentParentField":"isRisk","isMandatory":true},
    {"key":"oneViewGroupName","title":"OneView Name","order":3,"fieldType":"textbox","isEditable":false,"value":""},
    {"key": "clientName", "title": "Client Name", "order": 4, "fieldType": "textbox", "isEditable": false, "value": "" },
    {"key": "commitmentTitle", "title": "Commitment Title", "order": 5, "fieldType": "textbox", "isEditable": false, "value": "" },
    {"key":"clientDunsNumber","title":"DUNS","order":6,"fieldType":"textbox","isEditable":false,"value":""},
    {"key":"productNames","title":"Product","order":7,"fieldType":"textbox","isEditable":false,"value":""},
    {"key":"additionalProductNames","title":"Additional Product Name","order":8,"fieldType":"textbox","isEditable":false,"value":""},
    {"key":"commitmentType","title":"Commitment Type","order":9,"fieldType":"textbox","isEditable":false,"value":""},
    {"key":"status","title":"Stage","order":10,"fieldType":"textbox","isEditable":false,"value":""},
    {"key":"hdnFulfillmentDetails","title":"Fulfillment Id","order":11,"fieldType":"textbox","isEditable":false,"value":""},
    {"key":"displayPlannedDeliveryDate","title":"Planned/ Committed Delivery Date","order":12,"fieldType":"date","isEditable":false,"value":""},
    {"key":"clarityStatus","title":"Clarity Status","order":13,"fieldType":"textbox","isEditable":false,"value":""},
    {"key":"relationshipManagerName","title":"Relationship Manager","order":14,"fieldType":"textbox","isEditable":false,"value":""},
    {"key":"newRM","title":"New RM","order":15,"fieldType":"textbox","isEditable":false,"value":""},
    {"key":"datasource","title":"Data Source","order":16,"fieldType":"textbox"}
  ];
  
  /** end of column settings changes*/

  constructor(
    private userService: UserService,
    private excelService: ExportToExcelService,
    private _commitmentIntakeService: CommitmentIntakeService,
    private _commitmentManualService: CommitmentManualService,
    private _commitmentEcrmService: CommitmentEcrmService,
    private _manageCommitmentListService: ManageCommitmentListService,
    private permission: PermissionsService,
    private preferenceService: PreferenceService,
    private contentService: ContentService) {
      this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r);
  }

  ngAfterViewInit(): void {
    var scrollTop = document.getElementById('scrollTop');
    if (scrollTop) {
      scrollTop.click();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columnSettings) {
      const columnSettings = changes.columnSettings.currentValue;
      this.refreshPreference(columnSettings.columnPreferences, columnSettings.allColumnList);
    }
    if (changes.isPageTemplateAdmin) {
      this.hasMapOrUnmapPermission = this.isPageTemplateAdmin || this.permission.canMapOrUnmapCommitment();
    }
    if (changes.commitmentIntakeList && changes.commitmentIntakeList.currentValue) {
      if (this._isRefresh || this.isReset) {
        this.commimentsTobeUpdated = [];
        this.enableSaveButton = false;
        this.originCommitments = [];
        this.isAllTracked = false;
        this._trackedState = {};
      }
      else if (this.commimentsTobeUpdated && this.commimentsTobeUpdated.length > 0) {
        this.commitmentIntakeList.forEach((element) => {
          const currentSelected = this.commimentsTobeUpdated.find(c => this.isMatch(c, element));
          if (currentSelected) {
            element.selected = !currentSelected.selected ? false : currentSelected.selected;
            element.hasTrackChanged = !currentSelected.hasTrackChanged ? false : currentSelected.hasTrackChanged;
            element.isRisk = !currentSelected.isRisk ? false : currentSelected.isRisk;
            element.riskReason = !currentSelected.riskReason ? '' : currentSelected.riskReason;
            element.isTracked = !currentSelected.isTracked ? false : currentSelected.isTracked;
            element.isReviewed = !currentSelected.isReviewed ? false : currentSelected.isReviewed;
            element.riskDescription = !currentSelected.riskDescription ? '' : currentSelected.riskDescription;
          }
        });
      }

      changes.commitmentIntakeList.currentValue.forEach((element) => {
        const item = {
          id: element.id,
          projectKey: element.projectKey,
          isRisk: !element?.isRisk ? false : element?.isRisk,
          isLinked: element.isLinked,
          riskReason: !element?.riskReason ? '' : element?.riskReason,
          isTracked: !element?.isTracked ? false : element?.isTracked,
          isReviewed: !element?.isReviewed ? false : element?.isReviewed,
          riskDescription: !element?.riskDescription ? '' : element?.riskDescription,
        };

        if (!this.originCommitments.some(c => this.isMatch(c, element)) || this._isRefresh) {
          this.originCommitments.push(item);
        }
      });

      this.isAllTracked = this.checkIsTrackAll();
      this._isRefresh = false;
    }

    $('#refreshTask').addClass('fa-rotation');
  }

  ngOnInit(): void {
    this.content = this.contentService.getcontent();
    if (!this.content) {
      this.contentService.setContent().then(data => {
        this.content = this.contentService.getcontent();
      });
    }
    this.maxColumnCount = PreferenceService.maxColumnCount;
    this._userData = this.userService.getUserListData();

    this.isCCTAdmin = this.permission.isCCTAdmin();
    this.isRm = this.permission.isRelationShipManager();
    this.isClientExecutive = this.permission.isClientExecutive();
    $('#refreshTask').addClass('fa-rotation');
  }

  onFieldChanged(event: { row: ICommitmentIntakeDto, fieldName: string, childField: string, bulkChange: boolean }) {
    const { row, fieldName, childField, bulkChange } = event;
    const alertInput: AlertInput = {
      message: ''
    };
    this.changeAlert(alertInput);
    if (fieldName === 'isTracked') {
      row['isReviewed'] = row[fieldName];
    }
    this.updateDependentField(row, fieldName, childField);
    this.trackingCommitmentToUpdate(row, bulkChange);
  }

  trackingCommitmentToUpdate(row: ICommitmentIntakeDto, bulkChange: boolean = false): void {
    const original = {...this.originCommitments.find(o => this.isMatch(o, row))};
    if (!original || !this.isMatch(original, row)) {
      return;
    }

    if (!bulkChange) {
      if (row.isCustomCommitment) {
        this.disabledNonCustomCommitment = true;
      }
      else if (!row.isCustomCommitment) {
        this.disabledCustomCommitment = true;
      }
    }

    this.commimentsTobeUpdated = this.commimentsTobeUpdated.filter(
      (el) => el.id !== row.id
    );

    // Untrack
    const isUntracked = original.isTracked && !row.isTracked;
    if (isUntracked) {
      row.updateState = 'untrack';
      row.hasTrackChanged = true;
      this.isAllTracked = false;
      this.setTrackedState(false);
      this.commimentsTobeUpdated.push({ ...row });
    }
    else {
      let riskReasonDescriptionChanged = false;
      if ((original.riskDescription || row.riskDescription) && original.riskDescription !== row.riskDescription) {
        riskReasonDescriptionChanged = true;
      }
      else if (original.riskDescription && row.riskDescription && original.riskDescription !== row.riskDescription) {
        riskReasonDescriptionChanged = true;
      }

      if (original.isTracked !== row.isTracked) {
        this.handleCommitmentRowChanging(row, original);
      }
      // To fix Bug 383055: row.isRisk maybe null instead of FALSE. Need to convert it to boolean
      else if (original.isRisk !== !!row.isRisk) {
        this.handleCommitmentRowChanging(row, original);
      }
      else if (original.isRisk && row.isRisk && row.riskReason !== original.riskReason) {
        this.handleCommitmentRowChanging(row, original);
      }
      else if (riskReasonDescriptionChanged) {
        this.handleCommitmentRowChanging(row, original);
      }
      else {
        delete row.hasTrackChanged;

        if (bulkChange && row.isCustomCommitment) {
          this.disabledCustomCommitment = false;
        }
        else if (bulkChange && !row.isCustomCommitment) {
          this.disabledNonCustomCommitment = false;
        }
        else if (!bulkChange && row.isCustomCommitment) {
          this.disabledNonCustomCommitment = false;
        }
        else if (!bulkChange && !row.isCustomCommitment) {
          this.disabledCustomCommitment = false;
        }
      }
      
      if (!original.isTracked && !row.isTracked && this.isAllTracked) {
        this.isAllTracked = false;
        this.setTrackedState(false);
      }
      else if (row.isTracked && !bulkChange && !this.isAllTracked) {
        const dataCanTracked = this.getPossibleTrackedData();
        const isAllTracked = dataCanTracked && dataCanTracked.length > 0 && dataCanTracked.every(c => c.isTracked);
        if (isAllTracked) {
          this.isAllTracked = true;
          this.setTrackedState(true);
        }
      }
      else if (row.isTracked && bulkChange && this.isAllTracked) {
        const dataCanTracked = this.getPossibleTrackedData();
        const isAllTrackedWithoutChanged = dataCanTracked.every(c => !c.hasTrackChanged && c.isTracked);
        if (isAllTrackedWithoutChanged) {
          this.isAllTracked = false;
          this.setTrackedState(false);
        }
      }
    }
    
    this.enableSaveButton = this.commimentsTobeUpdated.length > 0;
  }

  private handleCommitmentRowChanging(row: ICommitmentIntakeDto, original: ICommitmentIntakeDto) {
    const isTrackChanged = original.isTracked !== row.isTracked;
    row.updateState = 'update';
    if (!this.isValidRisk(row) || !isTrackChanged) {
      delete row.hasTrackChanged;
    }
    else if (isTrackChanged) {
      row.hasTrackChanged = true;
    }
    
    this.commimentsTobeUpdated.push({ ...row });
  }

  checkDependentFieldHasError(row: any, parentFieldKey: string, childFieldKey: string) {
    const { columnPreferenceSettings } = this;
    if (columnPreferenceSettings.length === 0) {
      return false;
    }

    const dependentChildField = columnPreferenceSettings.find(e => e.key === childFieldKey);
    if (!dependentChildField?.dependentParentField) {
      return false;
    }

    if (!row[parentFieldKey] && row[parentFieldKey] !== 'true') {
      return false;
    }

    const childFieldIndex = columnPreferenceSettings.findIndex((e) => e.key === childFieldKey);
    return !row[childFieldKey] && columnPreferenceSettings[childFieldIndex].isMandatory == true;
  }

  private validateDependentFields() {
    this._isError = false;
    
    const { columnPreferenceSettings } = this;
    if (columnPreferenceSettings.length === 0) {
      return;
    }

    let shouldBreak = false;
    this.commimentsTobeUpdated.forEach(row => {
      const original = this.originCommitments.find(o => o.id === row.id);
      if (shouldBreak) {
        return;
      }
      for (const parentField of columnPreferenceSettings) {
        const parentFieldKey = parentField['key'];
        if (!parentFieldKey) {
          continue;
        }

        if (!row[parentFieldKey] && row[parentFieldKey] !== 'true') {
          continue;
        }

        const childFieldKey = parentField.dependentChildField;
        if (!childFieldKey) {
          continue;
        }

        const dependentChildField = columnPreferenceSettings.find(e => e.key === childFieldKey);
        const isDependentFieldAnswered = row[dependentChildField.key];
        if (!dependentChildField.isMandatory || (isDependentFieldAnswered && isDependentFieldAnswered !== 'other')) {
          continue;
        }

        const hasRiskChanged = original.isRisk == row.isRisk && row.riskReason == original.riskReason && row.riskDescription == original.riskDescription;
        const isCancelledOrReadOnly = this.isCancelledOrReadOnlyCommitment(row, parentFieldKey);
        if (isCancelledOrReadOnly && (original.isTracked && !row.isTracked) && !hasRiskChanged) {
          continue;
        }

        // Handle the case where the dependent field is a "other" field.
        let isInvalidRisk: boolean;
        if (dependentChildField.showOtherOptionField && row[dependentChildField.key] === 'other') {
          isInvalidRisk = row[dependentChildField.otherOptionFieldApiKey];
        }

        if (!isInvalidRisk) {
          const alertInput: AlertInput = {
            alertType: AlertType.Error,
            message: 'Please provide details in all required highlighted fields.'
          };
          this.changeAlert(alertInput);
          this.showUpdateCommitmentsIntakeDrawer = false;
          $('.container-page').removeClass('overflow-hidden');
          shouldBreak = true;
          this._isError = true;
          break;
        }
      }
    });
  }

  public isCancelledOrReadOnlyCommitment(item: ICommitmentIntakeDto, col: any): boolean {
    const isReadOnlyCommiment = item.accessLevel == 'Read';
    const isCancelledCommitment = item.status == 'Cancelled';
    const shouldDisable = isReadOnlyCommiment || isCancelledCommitment;

    return shouldDisable;
  }

  updateDependentField(row: ICommitmentIntakeDto, parentField: string = '', childField: string = '') {
    if (!parentField || !childField) {
      return;
    }

    if (!row[parentField] || row[parentField] === 'false') {
      row[childField] = '';
    }
  }

  refresh() {
    this._isRefresh = true;
    $('#refreshTask').addClass('fa-rotation');
    this.callSearch();
    this.disabledNonCustomCommitment = false;
    this.disabledCustomCommitment = false;
    $('#refreshTask').removeClass('fa-rotation');
  }

  onSave(): void {
    this.validateDependentFields();
    if (this._isError) {
      return;
    }
    this.showUpdateCommitmentsIntakeDrawer = true;
    this.changeAlert({
      message: ''
    });
    $('.container-page').addClass('overflow-hidden');
  }

  getPossibleTrackedData(): ICommitmentIntakeDto[] {
    if (!this.columnPreferenceSettings || this.columnPreferenceSettings.length === 0) {
      return [];
    }
    
    const colIsTracked = this.columnPreferenceSettings.find(e => e.key === "isTracked");
    const dataCanTracked = this.commitmentIntakeList.filter(c => {
      const canTrack = !this._manageCommitmentListService.shouldDisableIsTrackCheckbox(c, colIsTracked, this.originCommitments, this.isPageTemplateAdmin);
      if (!canTrack) {
        return false;
      }

      return this.isValidRisk(c);
    });

    return dataCanTracked;
  }

  checkIsTrackAll() {
    if (!this._trackedState) {
      return false;
    }

    const dataCanTracked = this.getPossibleTrackedData();
    if (!dataCanTracked || dataCanTracked.length === 0) {
      return false;
    }

    const isAllTrackedWithoutChanged = dataCanTracked.every(c => !c.hasTrackChanged && c.isTracked);
    if (isAllTrackedWithoutChanged) {
      return false;
    }

    const isAllTracked = !dataCanTracked.some(c => !c.isTracked);
    return isAllTracked;
  }

  setTrackedState(isTracked: boolean) {
    const trackedField = `isPage${this.pagerObject.currentPage}Tracked`;
    this._trackedState[this.selectedTabName] = {
      ...this._trackedState[this.selectedTabName],
      [trackedField]: isTracked
    };
  }

  getTrackedState(): boolean {
    if (!this.pagerObject) {
      return false;
    }
    const trackedField = `isPage${this.pagerObject.currentPage}Tracked`; 
    if (!this._trackedState[this.selectedTabName]) {
      return false;
    }
    return this._trackedState[this.selectedTabName][trackedField];
  }

  isValidRisk(row: ICommitmentIntakeDto) {
    if (!row.isRisk) {
      return true;
    }

    if (!row.riskReason) {
      return false;
    }

    const riskReason = row.riskReason.toLowerCase();
    if (riskReason !== 'other') {
      return true;
    }

    return row.riskDescription;
  }

  trackAll() {
    const currentTrackedState = this.getTrackedState();
    this.setTrackedState(!currentTrackedState);
    if (!this.commitmentIntakeList || this.commitmentIntakeList.length === 0) {
      return;
    }

    const colIsTracked = this.columnPreferenceSettings.find(e => e.key === "isTracked");
    for (const row of this.commitmentIntakeList) {
      const canTrack = !this._manageCommitmentListService.shouldDisableIsTrackCheckbox(row, colIsTracked, this.originCommitments, this.isPageTemplateAdmin);
      if (!canTrack || row.isCustomCommitment) {
        if (row.isCustomCommitment && !currentTrackedState) {
          this.disabledCustomCommitment = true;
          continue;
        }
        else if (row.isCustomCommitment && currentTrackedState) {
          this.disabledCustomCommitment = false;
          continue;
        }
        else {
          continue;
        }
      }

      if (!this.isValidRisk(row)) {
        continue;
      }

      const original = this.originCommitments.find(o => this.isMatch(o, row));
      let shouldRaiseChange = false;
      if (this.isAllTracked) {
        row.isTracked = true;
        shouldRaiseChange = true;
      }
      else if (original.isTracked !== row.isTracked) {
        row.isTracked = original.isTracked;
        shouldRaiseChange = true;
      }

      if (shouldRaiseChange) {
        this.onFieldChanged({ row, fieldName: "isTracked", childField: null, bulkChange: true });
      }
    }
  }

  public sort(columnSetting: ColumnPreferenceSetting): void {
    if (this.filterColumn !== columnSetting.key) {
      this.filterColumn = columnSetting.key;
      this.isAsc = false;
    } else {
      this.isAsc = !this.isAsc;
    }

    this.queryParam.orderBy = this.isAsc ? this.filterColumn : `${this.filterColumn} desc`;

    this.queryParam.pageNumber = this.queryParam.pageNumber ?? 1;
    this.queryParam.pageSize = 10;
    this.callSearch();
  }

  public applySorting(columnSetting: ColumnPreferenceSetting): boolean {
    return !(this._ignoreSortingColumns.includes(columnSetting.key)) && (this.commitmentIntakeList.length > 0);
  }

  public displayColumnsSortIcon(columnSetting: ColumnPreferenceSetting): boolean {
    if (this.filterColumn !== '') {
      const temp = this.columnPreferenceSettings.find((r: ColumnPreferenceSetting) => r.key === columnSetting.key);
      if (temp) {
        return this.filterColumn === temp.key;
      }
    }
    return false;
  }

  public changePage(page: number): void {
    this.queryParam.pageNumber = page;
    this.callSearch();
  }

  private callSearch(): void {
    this.search.emit(this.queryParam);
  }

  public openDeleteModal(item: ICommitmentIntakeDto): void {
    this.itemForDelete = item;
  }

  public onOneVliewClicked(event: any) {
    this.oneViewClicked.emit(event);
  }

  public onCommitmentDelete(item: ICommitmentIntakeDto) {
    let deleteSubscription: Observable<Object>;
    if (item.datasource.toLowerCase() === 'manual') {
      deleteSubscription = this._commitmentManualService.deleteCommitment(item.projectKey);
    }
    else if (item.datasource.toLowerCase() === 'ecrm') {
      deleteSubscription = this._commitmentEcrmService.deleteCommitment(item.projectKey);
    }
    else {
      deleteSubscription = this._commitmentIntakeService.deleteCommitment(item.projectKey);
    }

    deleteSubscription.subscribe(() => {
      this.onComponentSubmited();
    },
    error => {
      const alertInput: AlertInput = {
        alertType: AlertType.Error,
        message: error
      };
      this.changeAlert(alertInput);
    });
  }

  public onComponentSubmited(): void {
    this.queryParam.pageNumber = 1;
    const alertInput: AlertInput = {
      alertType: AlertType.Success,
      message: 'Commitment has been deleted successfully.'
    };
    this.changeAlert(alertInput);
    this.callSearch();
  }

  public onMapFulfilmentComponentSubmited(alertInput: AlertInput): void {
    $('#containerPage').removeClass('overflow-hidden');
    this.isMapFulfilmentModalShown = false;
    this.changeAlert(alertInput);
    this.callSearch();
  }

  public redirectToDetails(projectKey: string): void {
    this.onRedirectToDetails.emit({ projectKey });
  }

  public exportExcel(): void {
    const pageNumbers: number[] = [];
    const chunkSize = 500;
    const maxConcurrents = 10;
    const totalPages = Math.ceil(this.pagerObject.totalCount / chunkSize);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    
    const requests = [];
    for (const pageNumber of pageNumbers) {
      const req = this._commitmentIntakeService.exportCommitments({
        ...this.queryParam,
        pageSize: chunkSize,
        pageNumber: pageNumber,
      });
      requests.push(req);
    }

    from(requests).pipe(mergeMap((apiCall) => apiCall, maxConcurrents), toArray()).subscribe((httpResponse: any) => {
      const flattedResponses = [];
      httpResponse.forEach(response => {
        response.body.forEach(responseCommitment => {
          flattedResponses.push(responseCommitment);
        });
      });

      const commitments = this._manageCommitmentListService.applySorting(flattedResponses, this.queryParam.orderBy);
      const populatedCommitments = commitments.map(commitment => {
        return this.populateExportedCommitment(commitment);
      });
      this.excelService.exportAsExcelFile(populatedCommitments, `Commitment_Intakes`);
    }, err => {
      console.error('Caught error ', err);
    });
  }

  populateExportedCommitment(row: ICommitmentIntakeDto) {
    const exportedRow = {};
    if (this.columnPreferenceSettings.length === 0) {
      return;
    }

    for (const columnSetting of this.columnPreferenceSettings) {
      const fieldLabel = columnSetting.title;
      const fieldName = columnSetting.key;
      const otherOptionApiKey = columnSetting.otherOptionFieldApiKey;
      const rowValue = row[fieldName];
      if (columnSetting.fieldType === 'select' && columnSetting.showOtherOptionField === true && rowValue === 'other') {
        exportedRow[fieldLabel] = `${rowValue} - ${row[otherOptionApiKey]}`;
        continue;
      } 
      
      if (rowValue && typeof (rowValue) === 'object') {
        const data = rowValue.map(s => s.replace(/^"|"$/g, '').trim());
        exportedRow[fieldLabel] = data.join(",");
        continue;
      }
      
      switch(fieldName) {
        case 'createdByName':
        case 'modifiedByName':
          break;
        case "createdOn":
          var createdDate = new Date(rowValue);
          createdDate.setHours(createdDate.getHours() - 5);
          exportedRow['Submitted On'] = formatDate(createdDate, 'M/d/yyyy HH:mm', 'en-US');
          break;
        case "modifiedOn":
          var modifiedDate = new Date(rowValue);
          modifiedDate.setHours(modifiedDate.getHours() - 5);
          exportedRow['Modified On'] = formatDate(modifiedDate, 'M/d/yyyy HH:mm', 'en-US');
          exportedRow['Modified By'] = row.modifiedByName; 
          break;
        case "displayPlannedDeliveryDate":
          exportedRow['Planned/ Committed Delivery Date'] = row.displayPlannedDeliveryDate;
          break;
          
        default:
          exportedRow[fieldLabel] = rowValue;
          break;
      }
    }

    exportedRow['Client Users'] = row.clientUsersString;

    return exportedRow;
  }

  successDelegateClick(event: AlertInput) {
    this.changeAlert(event);
    this.refresh();
  }

  saveUsersClick() {
    this.showClientUserModalFIO = false;
    $('#addCommitmentUsersFIO').modal('hide');
    this.onSave();
  }

  onEditUsersClicked(pageResponse: any) {
    this.selectedForeditUserAssignement = pageResponse;
    this.showClientUserEditModalFIO = true;
  }

  closeAddCommitmentUsersModal() {
    this.showClientUserModalFIO = false;
    $('#addCommitmentUsersFIO').modal('hide');
    this.refresh();
  }

  onAddUsersClicked(pageResponse: any) {
    this.selectedForUserAssignement = pageResponse;
    if (!this.selectedForUserAssignement.isTracked && (this.selectedForUserAssignement.isTracked != undefined && this.selectedForUserAssignement.isTracked != true)) {
      this.showClientUserModalFIO = true;
      $('#addCommitmentUsersFIO').modal('show');
    }
  }

  editCommitmentUsersModal() {
    this.showClientUserEditModalFIO = false;
    this.showClientUserModalFIO = false;
    $('#editCommitmentUsersFIO').modal('hide');
    this.refresh();
  }

  updateCommitments() {
    this.changeAlert({
      message: ''
    });
    const projectCommitmentUpdatedList = [...this.commimentsTobeUpdated.filter(c => c.datasource.toLowerCase() !== 'manual' && c.datasource.toLowerCase() !== 'ecrm')];
    const updateMultipleCommitments:  Observable<Object>[] = [];
    if (projectCommitmentUpdatedList && projectCommitmentUpdatedList.length > 0) {
      updateMultipleCommitments.push(this._commitmentIntakeService.updateCommitments(projectCommitmentUpdatedList));
    }

    const manualCommitmentUpdatedList = [...this.commimentsTobeUpdated.filter(c => c.datasource.toLowerCase() === 'manual')];
    if (manualCommitmentUpdatedList && manualCommitmentUpdatedList.length > 0) {
      updateMultipleCommitments.push(this._commitmentManualService.updateCommitments(manualCommitmentUpdatedList));
    }

    const ecrmCommitmentUpdatedList = [...this.commimentsTobeUpdated.filter(c => c.datasource.toLowerCase() === 'ecrm')];
    if (ecrmCommitmentUpdatedList && ecrmCommitmentUpdatedList.length > 0) {
      updateMultipleCommitments.push(this._commitmentEcrmService.updateCommitments(ecrmCommitmentUpdatedList));
    }

    if (updateMultipleCommitments && updateMultipleCommitments.length > 0) {
      forkJoin(updateMultipleCommitments).subscribe(() => {
        this._isRefresh = true;
        this.closeUpdateCommitmentModal();
        this.queryParam.pageNumber = 1;
        $('#refreshTask').addClass('fa-rotation');
        this.changeAlert({
          message: 'Selected Response(s) is updated successfully.',
          alertType: AlertType.Success
        });
        this.callSearch();
      }, error => {
        this.changeAlert({
          message: error,
          alertType: AlertType.Error
        });
        this.closeUpdateCommitmentModal();
      });
    }
    
    $('.container-page').removeClass('overflow-hidden');
  }

  closeUpdateCommitmentModal() {
    this.showUpdateCommitmentsIntakeDrawer = false;
    $('.container-page').removeClass('overflow-hidden');
    this._isRefresh = true;
    this.disabledNonCustomCommitment = false;
    this.disabledCustomCommitment = false;
  }

  /** start of column settings changes**/
  showColumnSettings() {
    $('.container-page').addClass('overflow-hidden');
    this.showColumnSettingsDrawer = true;
    this.columnSettingErrorMsg = '';
  }

  callPreferenceGetService() {
    if (!this._userData) {
      return;
    }

    this.getColumnPreferences.emit();
  }

  savePreference() {
    const hasEmpty = this.preferenceService.hasEmptyColumns(this.columnPreferences);
    if (hasEmpty) {
      this._isError = true;
      this.columnSettingErrorMsg = "Empty column selected.";
      return;
    }

    const preferences = this.preferenceService.getPreferencesToBeSaved(this.columnPreferences, this.prefColumnOptions)
    const isDuplicate = this.preferenceService.checkForDuplicates(preferences);
    if (isDuplicate) {
      this.columnSettingErrorMsg = "Duplicate column selected";
      return;
    }
    const params: any = {
      userId: this._userData.id,
      listName: this._listPreferenceName,
      columnOptions: JSON.stringify(preferences),
    };

    if (this._userId != 0) {
      params.id = this._preferenceId;
      this.updatePreference(params);
    } else {
      this.createPreference(params);
    }

    $('.container-page').removeClass('overflow-hidden');
    this.showColumnSettingsDrawer = false;
  }

  createPreference(params: any) {
    this.preferenceService.savePreference(params).pipe(take(1))
      .subscribe(
        (preference: any) => {
          this.refreshPreference(preference);
        }, error => {
          this._isError = true; 
          this.columnSettingErrorMsg = error;
        }
      );
  }

  updatePreference(params: any) {
    this.preferenceService.updatePreference(params).pipe(take(1))
      .subscribe(
        (preference: any) => {
          this.refreshPreference(preference);
        }, error => {
          this._isError = true;
          this.columnSettingErrorMsg = error;
        }
      );
  }

  refreshPreference(columnPrefs: any, columnList?: any) {
    this._isError = false;
    const columnOptions = JSON.parse(columnPrefs.columnOptions);
    this._listPreferenceName = columnPrefs.listName;
    this._userId = columnPrefs.userId;
    this._preferenceId = columnPrefs.id;
    this.columnPreferences = this.preferenceService.convertToDropDownItems(columnOptions);
    this.showAddColumnOptions = this.columnPreferences && this.columnPreferences.length < this.maxColumnCount;
    if (columnList) {
      const listOfoptions = JSON.parse(columnList.allColumnOptions);
      this.prefColumnOptions = this.preferenceService.convertToDropDownItems(listOfoptions);
    }

    this.columnPreferenceSettings = [...this._cctColumnSettings, ...columnOptions];
    this.preferenceService.refreshGrid(columnOptions);
  }

  cancelPreference() {
    $('.container-page').removeClass('overflow-hidden');
    this.showColumnSettingsDrawer = false;
  }

  removeColumn(counter: any) {
    const columnPrefsCount = this.columnPreferences.length;
    this.showAddColumnOptions = true;
    if (columnPrefsCount > 0) {
      this.columnPreferences.splice(counter, 1);
    }
  }

  addColumn() {
    const columnObject = Object.assign({}, this.columnPreferences[0]);
    let columnPrefsCount = this.columnPreferences.length;
    this.showAddColumnOptions = true;
    if (columnPrefsCount < this.maxColumnCount) {
      this.columnPreferences.push(columnObject);
    }
    columnPrefsCount = this.columnPreferences.length;
    if (columnPrefsCount === this.maxColumnCount) {
      this.showAddColumnOptions = false;
    }
  }


  /** end of column settings changes**/
  
  /* Dynamic columns */
  navigateToDataDict(url: string) {
    window.open(url, "_blank");
  }

  public closeMapFulfilmentDrawer(): void {
    $('#containerPage').removeClass('overflow-hidden');
    this.isMapFulfilmentModalShown = false;
    const element = document.getElementById(this._scrollPlaceWhereMapFulfillmentCalled);
    element.scrollIntoView();
  }

  public unmap(errorMessage: string): void {
    const alertInput: AlertInput = {};
    if (errorMessage === '') {
      alertInput.alertType = AlertType.Success;
      alertInput.message = `Fulfillment "${this.projectKey}" is unmapped successfully.`;
      this.callSearch();
    } else {
      alertInput.alertType = AlertType.Error;
      alertInput.message = errorMessage;
    }
    this.changeAlert(alertInput);
  }

  private formatDateTime(value, format, locale): any {
    const isValidDate = moment(value, 'MM/DD/YYYY', true).isValid();
    if (!value || value === 'To Be Scheduled' || !isValidDate) return value;

    const result = formatDate(value, format, locale);
    return result;
  }

  public showTrackAll(): boolean {
    if (this.isCCTAdmin && this.selectedTabName.toLowerCase() == 'ecrm') {
      return true;
    }

    return this.isCCTAdmin || (this.selectedTabName.toLowerCase() !== 'ecrm' && this.selectedTabName.toLowerCase() !== 'tracked');
  }

  public shouldDisableAllTrackCheckbox(): boolean {
    if (this.selectedTabName.toLowerCase() === 'presale'){
      return true;       
    }

    if (this.selectedTabName.toLowerCase() === 'tracked') {
      return true;
    }

    const isAllStrategic = this.commitmentIntakeList.every(x => x.isCustomCommitment === true);
    if (isAllStrategic) {
      return true;
    }

    const isAllEcrm = this.commitmentIntakeList.every(x => x.datasource.toLowerCase() === 'ecrm');
    if (isAllEcrm) {
      const canTrackEcrm = this.permission.isCCTAdminWriteAccess(this.isPageTemplateAdmin);
      return !canTrackEcrm;
    }

    //const hasNoMappedCommitment = this.commitmentIntakeList.some(x => !this._manageCommitmentListService.isMappedCommitment(x));
    //if (!hasNoMappedCommitment) {
    //  return true;
    //}

    const dataCanTracked = this.getPossibleTrackedData();
    if (!dataCanTracked || dataCanTracked.length === 0) {
      return true;
    }

    const isAllTracked = dataCanTracked.every(c => !c.hasTrackChanged && c.isTracked);
    if (isAllTracked) {
      return true;
    }

    const isAllDisabled = this.commitmentIntakeList.every(x => {
      const original = this.originCommitments.find(origin => this.isMatch(origin, x));
      const hasCancelledAndTracked = this._manageCommitmentListService.isCancelledCommitment(x) && original.isTracked;
      if (hasCancelledAndTracked) {
        const shouldDisable = !this._manageCommitmentListService.isUserWriteAccess(x);
        return shouldDisable;
      }

      const isAllCheckboxDisabled = this.columnPreferenceSettings.every(col => this._manageCommitmentListService.shouldDisableIsTrackCheckboxControl(x, col));
      return isAllCheckboxDisabled;
    });

    return isAllDisabled;
  }

  public openMapFulfillment(item: ICommitmentIntakeDto, rowIndex: number, event: { isCalledForMap: boolean, projectDataSource: string, suggestion?: any }): void {
    const { projectDataSource, suggestion } = event;
    this.canMapOrUnmapOnMapModal = this.hasMapOrUnmapPermission && !this._manageCommitmentListService.isReadOnlyCommiment(item.accessLevel);
    // TODO: check
    this.fulfillmentTitle = item.commitmentTitle;
    this.projectDataSource = projectDataSource;
    if (!projectDataSource) {
      this.projectKey = '';
      this.commitmentMapType = this.commitmentMapTypeEnum.Project;
    }
    else if (suggestion && projectDataSource == 'clarity') {
      this.projectKey = suggestion.projectNumber;
      this.fulfillmentTitle = suggestion.title;
      this.commitmentMapType = this.commitmentMapTypeEnum.Project;
    }
    else if (suggestion && projectDataSource == 'ecrm') {
      this.projectKey = suggestion.opportunityId;
      this.fulfillmentTitle = suggestion.title;
      this.commitmentMapType = this.commitmentMapTypeEnum.Opportunity;
    }

    this.isCalledForMap = true;
    this.isReadOnlyMode = this._manageCommitmentListService.isReadOnlyCommiment(item.accessLevel);
    this._scrollPlaceWhereMapFulfillmentCalled = 'actions-' + rowIndex, item.accessLevel;
    this.currentCommimentDataSource = item.datasource;
    if (this._isOpenUnmapModal) {
      this._isOpenUnmapModal = false;
      this.loadMappedProjectDetails(item.projectKey, projectDataSource);
    } else {
      this.isMapFulfilmentModalShown = true;
      $('#containerPage').addClass('overflow-hidden');
    }
  }

  public openMappedDetailsModal(item: ICommitmentIntakeDto, rowIndex: number, event: { projectDataSource: string }): void {
    const { projectDataSource } = event;
    this.projectDataSource = projectDataSource;
    this.fulfillmentTitle = '';
    if (projectDataSource == 'ecrm') {
      this.projectKey = item.opportunityId;
      this.commitmentMapType = this.commitmentMapTypeEnum.Opportunity;
      this.canMapOrUnmapOnMapModal =
        this.hasMapOrUnmapPermission && item.datasource.toLowerCase() !== 'ecrm' &&
        !this._manageCommitmentListService.isReadOnlyCommiment(item.accessLevel)
    }
    else {
      this.projectKey = item.projectNumber;
      this.commitmentMapType = this.commitmentMapTypeEnum.Project;
      this.canMapOrUnmapOnMapModal = this.hasMapOrUnmapPermission 
        && item.datasource.toLowerCase() !== 'clarity' 
        && item.datasource.toLowerCase() !== 'servicenow' 
        && !this._manageCommitmentListService.isReadOnlyCommiment(item.accessLevel);
    }

    this.isCalledForMap = false;
    this.isReadOnlyMode = this._manageCommitmentListService.isReadOnlyCommiment(item.accessLevel);
    this._scrollPlaceWhereMapFulfillmentCalled = `actions-${rowIndex}`, item.accessLevel;
    this.currentCommimentDataSource = item.datasource;
    if (this._isOpenUnmapModal) {
      this._isOpenUnmapModal = false;
      this.loadMappedProjectDetails(item.projectKey, item.datasource);
    } else {
      this.isMapFulfilmentModalShown = true;
      $('#containerPage').addClass('overflow-hidden');
    }
  }

  public openUnmapModal(): void {
    this._isOpenUnmapModal = true;
    this.titleForUnmap = '';
    this.commitmentIntakeDetailsId = 0;
  }

  private loadMappedProjectDetails(projectKey: string, projectDataSource: string): void {
    this.zIndexMode = true;
    $('#refreshTask').addClass('fa-rotation');
    const alertInput: AlertInput = {
      message: ''
    };
    this.changeAlert(alertInput);
    this._commitmentIntakeService.getMappedProjectDetails(projectKey, projectDataSource).subscribe(response => {
        const data = response as ICommitmentIntakeMapDetailsDTO;
        this.titleForUnmap = data.title;
        this.commitmentIntakeDetailsId = data.id;
        $('#refreshTask').removeClass('fa-rotation');
      },
      error => {
        alertInput.alertType = AlertType.Error;
        alertInput.message = error;
        this.changeAlert(alertInput);
        $('#refreshTask').removeClass('fa-rotation');
      });
  }

  public changeAlert(event: AlertInput) {
    this.onAlertChanged.emit(event);
  }

  private isMatch(current: any, next: any) {
    const isMatch = this.getUniqueKey(current.id, current.projectKey) === this.getUniqueKey(next.id, next.projectKey);

    return isMatch;
  }

  private getUniqueKey(id: any, projectKey: any) {
    const key = id + projectKey;

    return key;
  }
}
