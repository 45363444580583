import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { IQuery } from '../shared/query';
import { ISuggestion } from '../shared/suggestion';
import { InterceptorSkipHeader } from 'src/app/core/constants';

@Injectable()
export class CommitmentIntakeService {
  private _commitmentIntakeBaseUrl: string = "commitmentintakes";

  constructor(private http: HttpClient) { }

  public getCommitmentList(queryParams: IQuery) {
    const options = { 'refresh': 'true' };
    return this.http.post(`${this._commitmentIntakeBaseUrl}/list`, queryParams, { headers: options, observe: 'response' });
  }

  public getCommitmentById(projectKey: string) {
    const options = { 'refresh': 'true' };
    return this.http.get(`${this._commitmentIntakeBaseUrl}/${projectKey}`, { headers: options });
  }

  public updateCommitment(projectKey: string, update: any) {
    return this.http.put(`${this._commitmentIntakeBaseUrl}/${projectKey}`, update);
  }

  public updateCommitments(updates: any) {
    return this.http.put(`${this._commitmentIntakeBaseUrl}`, updates);
  }

  public deleteCommitment(projectKey: string) {
    return this.http.delete(`${this._commitmentIntakeBaseUrl}/${projectKey}`);
  }

  public getFilterSuggestionByField(fieldName: any, skipLoading: boolean = false) {
    const options = { 'refresh': 'true' };
    if (skipLoading) {
      options[InterceptorSkipHeader] = '';
    }
    
    return this.http.get(`${this._commitmentIntakeBaseUrl}/filter-suggestions/${fieldName}`, { headers: options, observe: 'response' });
  }

  public getPageTemplateAccess() {
    return this.http.get(`${this._commitmentIntakeBaseUrl}/page-template-accesses`, { observe: 'response' });
  }

  public exportCommitments(queryParams: IQuery) {
    const httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };
    return this.http.post(`${this._commitmentIntakeBaseUrl}/exports`, queryParams, { headers: httpOptions.headers, observe: 'response' });
  }

  public getClarityMappingSuggestions(commitmentIntakeId: number) {
    const options = { 'refresh': 'true' };
    return this.http.get(`${this._commitmentIntakeBaseUrl}/${commitmentIntakeId}/projects/mapping-suggestions`, { headers: options, observe: 'response' });
  }
  
  public getOpportunityMappingSuggestions(commitmentIntakeId: number) {
    const options = { 'refresh': 'true' };
    return this.http.get(`${this._commitmentIntakeBaseUrl}/${commitmentIntakeId}/opportunities/mapping-suggestions`, { headers: options, observe: 'response' });
  }

  public getProjectDetailForMapping(projectId: string, dataSource: string) {
    let url = `${this._commitmentIntakeBaseUrl}/projects/${projectId}`;
    if (dataSource && dataSource.toLocaleLowerCase() === "servicenow") {
      url += "?dataSource=servicenow";
    }
    else if (dataSource && dataSource.toLocaleLowerCase() === "ecrm") {
      url += "?dataSource=ecrm";
    }

    return this.http.get(url);
  }

  public getMappedProjectDetails(projectKey: string, dataSource: string) {
    const options = { 'refresh': 'true' };
    const url = `${this._commitmentIntakeBaseUrl}/${projectKey}/projects?dataSource=${dataSource}`;
    return this.http.get(url, { headers: options});
  }

  public mapProject(commitmentIntakeId: number, mappingCommitmentIntakeId: number, projectId: string) {
    return this.http.put(`${this._commitmentIntakeBaseUrl}/${commitmentIntakeId}/projects/map`, {
      mappingCommitmentIntakeId: mappingCommitmentIntakeId,
      clarityProjectNumber: projectId
    });
  }

  public mapOpportunity(commitmentIntakeId: number, mappingCommitmentIntakeId: number, opportunityId: string) {
    return this.http.put(`${this._commitmentIntakeBaseUrl}/${commitmentIntakeId}/projects/map`, {
      mappingCommitmentIntakeId: mappingCommitmentIntakeId,
      opportunityId: opportunityId
    });
  }

  public unmapProject(projectId: string, mappedCommitmentIntakeId: number) {
    return this.http.delete(`${this._commitmentIntakeBaseUrl}/projects/${projectId}/map/${mappedCommitmentIntakeId}`);
  }

  public unmapOpportunity(opportunityId: string, mappedCommitmentIntakeId: number) {
    return this.http.delete(`${this._commitmentIntakeBaseUrl}/projects/${opportunityId}/map/${mappedCommitmentIntakeId}?dataSource=ecrm`);
  }

  getClientWithRmSuggestions(params: Array<{ paramName: string, value: string | number | boolean }>, takeNumber?: number) {
    let options = { 'refresh': 'true' }
    let suggestionAPIURL = `${this._commitmentIntakeBaseUrl}/clientwithrmsuggestions`;
    if (params && params.length > 0) {
      suggestionAPIURL = `${suggestionAPIURL}?`;
      params.forEach(element => {
        suggestionAPIURL = `${suggestionAPIURL + (params.indexOf(element) > 0 ? '&' : '')}${element.paramName}=${encodeURIComponent(element.value)}`;
      });
    }

    if (takeNumber) {
      suggestionAPIURL = `${suggestionAPIURL + (params && params.length > 0 ? '&' : '?')}takeNumber=${encodeURIComponent(takeNumber)}`;
    }

    return this.http.get<ISuggestion[]>(suggestionAPIURL, { headers: options });
  }
}
