import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ICommitmentIntakeCreate, ICommitmentIntakeDetailsDto } from '../models/commitment-intake-d-t-o';
import { ManageCommitmentService } from '../services/manageCommitment.service';
import { Subscription } from 'rxjs';
import { CommitmentManualService } from 'src/app/services/commitment-manual.service';
import { ManageCommitmentListService } from '../services/manageCommitmentList.service';
import { createManualAsDraftRequiredFields } from '../constants/commitment-intake-constants';

@Component({
  selector: 'commitment-intake-create',
  templateUrl: './commitment-intake-create.component.html'
})
export class CommitmentIntakeCreateComponent implements OnInit, OnDestroy {
  public commitmentDetails: ICommitmentIntakeDetailsDto;
  public commitmentForm: FormGroup;
  public initalData: ICommitmentIntakeCreate;
  public reservedKeysData: {[key: string]: any};
  public isSubmitted: boolean;
  public isError: boolean;
  public errorMsg: string = '';
  public isSuccess: boolean = false;
  public successMsg: string;
  
  private _datasourceChangSubscription: Subscription;
  private _relationshipManagerNameSubscription: Subscription;
  private _clientDunsNumberSubscription: Subscription;
  private _setDefaultFormValueTimer: any;
  private readonly SET_DEFAULT_FORM_VALUE_NUMBER = 100;

  constructor(private router: Router,
    private _commitmentManualService: CommitmentManualService,
    private _manageCommitmentService: ManageCommitmentService,
    private _manageCommitmentListService: ManageCommitmentListService
  ) { }
 
  ngOnInit(): void {
    this._manageCommitmentService.initialCommitmentIntakeForm();
    this.commitmentForm = this._manageCommitmentService.commitmentIntakeForm;
    this.initalData = this._manageCommitmentService.createFormVal;

    this._setDefaultFormValueTimer = setTimeout(() => {
      this.setFormDefaultValue();
    }, this.SET_DEFAULT_FORM_VALUE_NUMBER);
    this.initialForm();
  }

  initialForm() {
    this.populateReservedKeysData();
  }

  private populateReservedKeysData() {
    if(!this.initalData) {
      return;
    }

    const reservedData: {[key: string]: any} = {};
    const initalDataKeys = Object.keys(this.initalData);
    initalDataKeys.forEach(key => {
      if (this.initalData && this.initalData[key]) {
        reservedData[key] = this.initalData[key];
      }
    });
    this.reservedKeysData = reservedData;
  }

  private setFormDefaultValue() {
    const rootFormKeys = Object.keys(this.commitmentForm.controls);
    rootFormKeys.forEach(rootFormKey => {
      const nestedForm = this.commitmentForm.get(rootFormKey);
      const defaultValue = this._manageCommitmentService.getDefaultValue(this.reservedKeysData, rootFormKey);
      const reservedValue = this.reservedKeysData ? this.reservedKeysData[rootFormKey] : null;
      const value = defaultValue && !reservedValue ? defaultValue : reservedValue;
      nestedForm.setValue(value);
    });
  }

  createCommitment(data: {  isSaveAsDraft: boolean, formData: ICommitmentIntakeDetailsDto }) {
    const { formData, isSaveAsDraft } = data;
    this.isSubmitted = true;
    this.isSuccess = false;
    this.isError = false;
    let invalid = false;
    if (isSaveAsDraft) {
      const rootFormKeys = Object.keys(this.commitmentForm.controls);
      invalid = rootFormKeys.some(rootFormKey => {
        if (createManualAsDraftRequiredFields.includes(rootFormKey)) {
          const control = this.commitmentForm.controls[rootFormKey];
          return control.invalid;
        }

        return false;
      });
    }
    else {
      invalid = this.commitmentForm.invalid;
    }

    if (invalid) {
      return;
    }
    
    this._commitmentManualService.createCommitment(formData).subscribe((response) => {
      const { projectKey } = response as any;
      this.isSuccess = true;
      this.successMsg = `Request - '${formData.commitmentTitle}' is created successfully.`;
      this._manageCommitmentListService.createSuccessMessage = this.successMsg;
      this.router.navigate(['self-service/commitment-intakes', projectKey]);
    },
    error => {
        this.isError = true;
        this.errorMsg = error;
    }, () => {
      this.isSubmitted = false;
    });
  }

  backToList(): void {
    this._manageCommitmentListService.isFromViewCancelling = true;
    this.router.navigate(['self-service/commitment-intakes']);
  }

  ngOnDestroy(): void {
    this._datasourceChangSubscription?.unsubscribe();
    this._relationshipManagerNameSubscription?.unsubscribe();
    this._clientDunsNumberSubscription?.unsubscribe();
    this.commitmentForm?.reset();
    clearTimeout(this._setDefaultFormValueTimer);
  }

}