export enum CommitmentIntakeSuggestionFields {
    oneViewGroupName= "OneViewGroupName",
    clientName= "ClientName",
    clientDunsNumber= "ClientDunsNumber",
    productNames= "ProductNames",
    projectNumber= "ProjectNumber",
    commitmentTitle= "CommitmentTitle",
    DisplayPlannedDeliveryYear= "DisplayPlannedDeliveryYear",
    commitmentType= "CommitmentType",
    status= "Status",
    opportunityId= "OpportunityId",
    relationshipManagerName= "RelationshipManagerName",
    newRM= "NewRM",
    isTracked= "IsTracked",
    datasource= "Datasource",
}

export enum CommitmentIntakeSuggestionDisplayNames {
    OneViewGroupName= "OneView Name",
    ClientName= "Client Name",
    ClientDunsNumber= "DUNS",
    ProductNames= "Product Name",
    ProjectNumber= "Project Number",
    CommitmentTitle= "Commitment Title",
    DisplayPlannedDeliveryYear= "Committed Delivery Date",
    CommitmentType= "Commitment Type",
    Status= "Stage",
    OpportunityId= "Opportunity ID",
    RelationshipManagerName= "Relationship Manager",
    NewRM= "New RM",
    IsTracked= "Tracked/Untracked",
    Datasource= "Data Source",
}
