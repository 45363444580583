<div class="commitments-intake-page">
    <div class="heading-container fdl-2">
        <div class="back-action-container">
            <h1><b>Commitment Intake</b></h1>
        </div>
        <div>
            <button type="button" class="btn fdl-btn btn-primary-orange mt-1 mr-3" [ngClass]="{'myButton':(blueThemeMode)}" 
                *ngIf="canCreateOneView()" (click)="openOnViewCreateModal()"><span
                class="material-icons">add</span>
                Create OneView</button>
            <button type="button" class="btn fdl-btn btn-primary-orange mt-1" 
                [ngClass]="{'myButton':(blueThemeMode)}"
                *ngIf="canCreateNewCommitmentIntake()"
                (click)="openSubmitNewCommitmentsIntake()"><span class="material-icons">add</span>
                Submit New Commitments Intake</button>
        </div>
    </div>
    <div class="form-container">
        <div *ngIf="alertInput.message !== ''">
            <!-- Error Msg Alert -->
            <div class="alert alert-dismissible alert-danger" *ngIf="alertInput.alertType === alertType.Error">
                <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="setAlertMsgToEmpty()">×</button>
                <i class="material-icons">warning</i>
                <p [innerHtml]="alertInput.message"></p>
            </div>
            <!-- Success Msg Alert -->
            <div class="alert alert-success alert-dismissible" *ngIf="alertInput.alertType === alertType.Success">
                <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="setAlertMsgToEmpty()">×</button>
                <i class="material-icons" aria-hidden="true">check_circle</i>
                <p [innerHtml]="alertInput.message"></p>
            </div>
        </div>
        <app-commitment-intake-search
            [searchFields]="searchFields"
            (onSearch)="onSearchClicked($event)"
            ></app-commitment-intake-search>
        <div class="commitments-tabs-wrapper" [ngClass]="{'blueThemeMode':(blueThemeMode)}">
            <ul class="nav nav-tabs scoped-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link" role="tab" aria-selected="true"
                        href="#tab1" data-toggle="tab" (click)="onTabClicked('All')" [ngClass]="getTabActiveClass('All')">All
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" role="tab" aria-selected="false" href="#tab1"
                        data-toggle="tab" (click)="onTabClicked('Tracked')" [ngClass]="getTabActiveClass('Tracked')">Tracked
                    </a>
                </li>
                <li class="nav-item" *ngFor="let tabName of tabList">
                    <a class="nav-link" role="tab" href="#tab1" aria-selected="false" data-toggle="tab"
                        (click)="onTabClicked(tabName)" [ngClass]="getTabActiveClass(tabName)">{{tabName}}
                    </a>
                </li>
                <li *ngIf="isCCTAdmin === true" class="nav-item">
                    <a class="nav-link" role="tab" aria-selected="false" href="#tab1" data-toggle="tab" 
                        (click)="onTabClicked('Presale')" [ngClass]="getTabActiveClass('Presale')">Presale
                    </a>
                </li>
            </ul>

            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade active show" id="tab1" role="tabpanel" aria-labelledby="tab-1">
                    <div class="data-table-container">
                        <app-commitment-intake-list [commitmentIntakeList]="commitmentIntakeList" 
                        (onRedirectToDetails)="redirectToDetails($event)" 
                        [queryParam]="queryParam"
                        [pagerObject]="pagerObject" 
                        [filterColumn]="filterColumn" 
                        [isAsc]="isAsc" 
                        (search)="searchFromList($event)"
                        (onAlertChanged)="changeAlert($event)"
                        (getColumnPreferences)="getColumnPreferences()"
                        (oneViewClicked)="oneViewClicked($event)"
                        [columnSettingErrorMsg]="columnSettingErrorMsg"
                        [columnSettings]="columnSettings"
                        [selectedTabName]="selectedTabName" 
                        [isPageTemplateAdmin]="pageTemplateAdmin"
                        [isPageTemplateReadAdmin]="pageTemplateReadAdmin" 
                        [isReset]="isReset"></app-commitment-intake-list>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-commitment-intake-one-view 
    [isCreate]="isCreateNewOneView"
    [isEdit]="isEditOneView"
    [viewId]="selectedOneViewId"
    [canEdit]="canEditOneView"
    (onSuccess)="onOneViewSuccess($event)">
</app-commitment-intake-one-view>
<app-commitment-intake-create-drawer *ngIf="showSubmitNewCommitmentsIntakeDrawer" (cancel)="closeSubmitNewCommitmentsDrawers()"></app-commitment-intake-create-drawer>