<div class="box-wrapper" [ngClass]="{'blueThemeMode':(blueThemeMode)}" *ngIf="commitmentCalendarNumbers.length==0 && isCalendarReady && !isCommitmentCalendarError">
    <div class="no-data-message-container">
        <div class="product-wrapper no-data-message">
            <h3>No Data Available</h3>
        </div>
    </div>
</div>
<div class="box-wrapper" *ngIf="isCommitmentCalendarError" [ngClass]="{'blueThemeMode':(blueThemeMode)}">
    <div class="no-data-message-container">
        <div class="product-wrapper no-data-message">
            <h3>There was a problem. Please try again later.</h3>
        </div>
    </div>
</div>
<div class="box-wrapper"
        [ngClass]="{'blueThemeMode':(blueThemeMode), 
        'd-flex':((commitmentCalendarNumbers.length!=0 || !isCalendarReady) && !isCommitmentCalendarError),
        'd-none':!(commitmentCalendarNumbers.length!=0 || !isCalendarReady) && !isCommitmentCalendarError}">
    <div class="filter-area">
        <div class="swtich-container mb-0">
            <div class="switch-wrapper">
                <button type="button" class="tab-switch" id="month" 
                    [ngClass]="{'active':(typeOfCommitmentCalander=='month'), 'blueThemeMode':(blueThemeMode)}"
                    (click)="getCalendarCommitment('month')">Monthly</button>
                <button type="button" class="tab-switch " id="quarter" 
                    [ngClass]="{'active':(typeOfCommitmentCalander=='quarter'), 'blueThemeMode':(blueThemeMode)}"
                    (click)="getCalendarCommitment('quarter')">Quarterly</button>
                <button type="button" class="tab-switch" id="year" 
                    [ngClass]="{'active':(typeOfCommitmentCalander=='year'), 'blueThemeMode':(blueThemeMode)}"
                    (click)="getCalendarCommitment('year')">Yearly</button>
            </div>
        </div>
    </div>

    <div class="chart" *ngIf="!isCalendarReady">
        <div role="status" aria-label="Please wait, do not refresh the page" class="ccdrms-loader"
            style="position:relative;margin-top:20%"></div>
    </div>

    <div class="chart" [ngClass]="isCalendarReady?'d-flex':'d-none'">
        <canvas [ngClass]="{'hideTableOrCanvas':showTable}" id="areaChart2"></canvas>
        <div id="calanderTable" class="table-responsive" [ngClass]="{'hideTableOrCanvas':!showTable}">
          <table class="table table-hover  table-label f-12">
            <thead>
              <tr>
                <th aria-hidden="true"></th>
                <th *ngFor="let item of commitmentCalendarLabels">{{item}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of tableData">
                <td>{{item.label}}</td>
                <td *ngFor="let item2 of item.data">{{item2}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="legend-wrapper ml-0 mr-0"><span class="legend-text"
            [ngClass]="{'hideTableOrCanvas': showTable}">
            <i class="material-icons">info</i>
            Click on delivery stage to exclude from the calendar</span>
        </div>
    </div>
</div>