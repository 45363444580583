import { Injectable } from '@angular/core';
import moment from 'moment';
import { ICommitmentIntakeFilterSuggestion } from '../models/commitment-intake-filter-suggestion-d-t-o';
import { ColumnPreferenceSetting } from '../models/column-preference-setting';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ICommitmentIntakeDto } from '../models/commitment-intake-d-t-o';
import { CommitmentIntakePageState } from '../models/commitment-intake-page-state';

@Injectable()
export class ManageCommitmentListService {
  constructor(private permission: PermissionsService) { }

  private _commitmentIntakePage: CommitmentIntakePageState;
  get commitmentIntakePage(): CommitmentIntakePageState {
    return this._commitmentIntakePage;
  }
  set commitmentIntakePage(value: CommitmentIntakePageState) {
    this._commitmentIntakePage = value;
  }
  
  private _isFromViewCancelling: boolean;
  get isFromViewCancelling(): boolean {
    return this._isFromViewCancelling;
  }
  set isFromViewCancelling(value: boolean) {
    if (!value) {
      this._commitmentIntakePage = null;
    }
    this._isFromViewCancelling = value;
  }

  private _createSuccessMessage: string;
  get createSuccessMessage(): string {
    return this._createSuccessMessage;
  }
  set createSuccessMessage(value: string) {
    this._createSuccessMessage = value;
  }

  distinctArray<T>(srcArray: any[], key: string) {
    const distinctedIds = new Set(srcArray.map(r => r[key]));
    const unique = Array.from(distinctedIds).map(id => {
      return srcArray.find(r => r[key] === id);
    }) as T[];

    return unique;
  }

  applySorting(commitmentRows: ICommitmentIntakeDto[], orderBy: string) {
    const sortingObject: { key?: string, isAsc?: boolean } = { key: "modifiedOn", isAsc: false };
    if (orderBy) {
      sortingObject.key = orderBy.split(' ')[0];
      sortingObject.isAsc = orderBy.split(' ')[1] === 'desc' ? false : true;
    } else {
      if (!sortingObject.isAsc) {
        // Default. No order by
        return [...commitmentRows];
      }
    }
    

    let sortResult = [];
    if (sortingObject.key === 'modifiedOn' || sortingObject.key === 'createdOn' ||sortingObject.key.toLowerCase().includes('date')) {
      sortResult = [...commitmentRows.sort((a,b) => {
        const prev = this.conculateDateToCompare(a[sortingObject.key]);
        const next = this.conculateDateToCompare(b[sortingObject.key]);
        if (prev > next) {
          return 1;
        }
        
        if (prev < next) {
          return -1;
        }
        
        const prev2 = a['id'];
        const next2 = a['id'];
        if (prev2 > next2) {
          return 1;
        }
        
        if (prev2 < next2) {
          return -1;
        }

        return 0;
      })];
    }
    else {
      sortResult = [...commitmentRows.sort((a,b) => {
        const prev = a[sortingObject.key].toLowerCase();
        const next = b[sortingObject.key].toLowerCase();
        if (prev > next) {
          return 1;
        }
        
        if (prev < next) {
          return -1;
        }
        
        const prev2 = a['id'];
        const next2 = a['id'];
        if (prev2 > next2) {
          return 1;
        }
        
        if (prev2 < next2) {
          return -1;
        }

        return 0;
      })];
    }

    if (!sortingObject.isAsc) {
      return sortResult.reverse();
    }

    return sortResult;
  }

  conculateDateToCompare(date: string) {
    if (!date) {
      return null;
    }

    if (date === 'To Be Scheduled') {
      return '';
    }

    return moment(date, 'MM/dd/yyyy')
  }

  formatSuggestions(field: ICommitmentIntakeFilterSuggestion) {
    let fieldSuggestions = [];
    const suggestions = field.values;
    if (field.fieldName === 'Status') {      
      const statusList = [];
      suggestions.forEach(r => {        
        statusList.push(r);        
      });
      fieldSuggestions = statusList.filter(r => r && (typeof(r) === "boolean" || (r.trim() !== '' && r !== 'Invalid date')));
    } else {
      suggestions.sort();
      fieldSuggestions = suggestions.filter(r => r && (typeof(r) === "boolean" || (r.trim() !== '' && r !== 'Invalid date')));
    }

    const hasEmpty = suggestions.some(r => !r || typeof(r) !== "boolean" && (r.trim() === '' || r.trim() === '[]'));
    if (hasEmpty) {
      fieldSuggestions.push('(Blanks)');
    }

    return fieldSuggestions;
  }
 
  private convertToDate(value: string): string {
    const temp2 = moment(value).format('MM/DD/YYYY');
    if (!value || value === '' || value === 'Invalid date' || temp2 === 'Invalid date') {
      return '';
    }

    return temp2;
  }

  public shouldDisableIsTrackCheckboxControl(item: ICommitmentIntakeDto, col: ColumnPreferenceSetting): boolean {
    const shouldDisable = this.isCancelledOrReadOnlyCommitment(item, col);

    return shouldDisable;
  }
  
  public isCancelledOrReadOnlyCommitment(item: ICommitmentIntakeDto, col: ColumnPreferenceSetting): boolean {
    const isReadOnlyCommiment = this.isReadOnlyCommitment(item, col);
    const isCancelledCommitment = this.isCancelledCommitment(item);
    const shouldDisable = isReadOnlyCommiment || isCancelledCommitment || !this.isUserWriteAccess(item);

    return shouldDisable;
  }
  
  isCancelledCommitment(item: ICommitmentIntakeDto): boolean {
    const result = item?.status == 'Cancelled';
    return result;
  }

  public isReadOnlyCommitment(item: ICommitmentIntakeDto, col: ColumnPreferenceSetting): boolean {
    const isReadOnly = item['accessLevel'] == 'Read' || !col.isEditable;

    return isReadOnly;
  }

  // Always disable IsTrack if commitment is mapped
  //   For Req1: Affect to Cancelled status and UN-TRACK only. Apply for all data source except eCRM 
  //      - Only user write access can un-track Cancelled record which already tracked. 
  //      - For another thing keep the existing one.
  //   For Req2: Apply for eCRM only
  //      - Now, only CCT Admin write access can track / untrack eCRM commitments no matter status
  public shouldDisableIsTrackCheckbox(item: ICommitmentIntakeDto, col: ColumnPreferenceSetting, originCommitments: any[], isPageTemplateAdmin: boolean = false): boolean {
    const original = originCommitments.find(o => o.projectKey === item.projectKey);
    if (item.datasource?.toLowerCase() !== 'ecrm') {
      //if (this.isMappedCommitment(item)) {
      //  return true;
      //}

      // For Req1
      if (this.isCancelledCommitment(item) && original.isTracked) {
        // Only user write access can un-track cancelled commitment
        const shouldDisable = !this.isUserWriteAccess(item);
        return shouldDisable;
      }

      const shouldDisable = this.shouldDisableIsTrackCheckboxControl(item, col);
      return shouldDisable;
    }
    
    // For Req2
    if (this.isCancelledCommitment(item) && !original.isTracked) {
      return true;
    }
    
    const canTrackEcrm = this.permission.isCCTAdminWriteAccess(isPageTemplateAdmin);
    return !canTrackEcrm;
  }
  
  public isMappedCommitment(item: ICommitmentIntakeDto): boolean {
    if ((item.datasource.toLowerCase() === 'ecrm' && !!item.opportunityId)
        || (item.datasource.toLowerCase() === 'clarity' && !!item.projectNumber)) {
          return true;
    }
    return false;
  }

  isUserWriteAccess(item: ICommitmentIntakeDto): boolean {
    const isWriteAccess = item['accessLevel'] == 'Write';
    return isWriteAccess;
  }

  public isReadOnlyCommiment(accessLevel: string): boolean {
    return (accessLevel === 'Read') || !this.hasRoleCanEditCommitment();
  }

  hasRoleCanEditCommitment(): boolean {
    const result = this.permission.hasWriteAccessOnCommitmentIntake();
    return result;
  }
}
