import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ContentService } from '../../../services/content.service';

@Component({
  selector: 'app-data-table-updated',
  templateUrl: './data-table-updated.component.html',
  styleUrls: ['./data-table-updated.component.css']
})
export class DataTableUpdatedComponent implements OnInit {
  @Input() keys: string[];
  @Input() headerColumns: string[];
  @Output() sortingClicked = new EventEmitter<{ key: string, isAsc: boolean }>();
  @Input() enableSorting: boolean = true;
  @Output() buttonClicked = new EventEmitter<{ action: string, data: string }>();
  @Input() ngShow: boolean = true;
  @Input() showActionButton = true;
  @Input() extraRowFieldName: string = null;
  @Input() filterColumn: string;
  isAsc: boolean;

  @Input() rows;
  content: any;
  constructor(private contentService: ContentService) { }
  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes)
    if (changes.ngShow && changes.ngShow.currentValue) {
      this.ngShow = changes.ngShow.currentValue;
    }
  }

  getRows() {
    if (!this.ngShow) {
      return [];
    }
    return this.rows;
  }

  statusClass(row: any, key: any) {
    const rowData = row[key];
    if (!rowData) {
      return "blank";
    }

    if (key == 'isRisk') {
      const isRisk = rowData.toString().toLowerCase() === 'true';
      const isAtRisk = this.isAtRisk(row, key);
      if (isAtRisk) {
        return 'red';
      }
      
      const commitmentStatus = row['commitmentStatus'];
      if (commitmentStatus == 'In Progress' && !isRisk) {
        return 'green';
      }
      return "blank";
    }

    switch (rowData) {
      case "false":
        case "":
          return "green";
        case "true":
          return "red";              
      case "Active":
      case "Completed":
      case "On Hold":
      case "Cancelled":
      case "Green":
      case "Amber":
      case "blank":
      default:
        return "blank";
    }
  }

  isAtRisk(row: any, key: any) {
    if (key !== 'isRisk') {
      return false;
    }

    const rowData = row[key];
    if (!rowData) {
      return false;
    }

    const commitmentStatus = row['commitmentStatus']?.trim();
    const isRisk = rowData.toString().toLowerCase() === 'true';
    const incompleteStatuses = [
      'In Progress',
      'Not Started',
      'In Planning',
      'Fiserv - On Hold'
    ]
    return isRisk && incompleteStatuses.includes(commitmentStatus);
  }

  highlightRow(row: any, key: any) {
    const isAtRisk = this.isAtRisk(row, key);
    if (isAtRisk) { 
      return 'red-highlight';
    }
    return '';
  }

  highlightRowProduct(row: any, key: any) {
    const isAtRisk = this.isAtRisk(row, key);
    if (isAtRisk) {
      return 'red-highlight-product';
    }
    return '';
  }

  highlightRowDateChange(row: any, key: any) {
    const isAtRisk = this.isAtRisk(row, key);
    if (isAtRisk) {
      return 'red-highlight-date-change';
    }
    return '';
  }

  includeSymbol(row: any, key: any) {
    const rowData = row[key];
    if (!rowData) {
      return '';
    }

    if (key == 'isRisk') {
      const commitmentStatus = row['commitmentStatus'];
      const isRisk = rowData.toString().toLowerCase() === 'true';
      const incompleteStatuses = [
        'In Progress',
        'Not Started',
        'In Planning',
        'Fiserv - On Hold'
      ];
      if (isRisk && incompleteStatuses.includes(commitmentStatus)) {
          return '!';
      }
    }
    return '';
  }

  statusDocument(row: any, key: any) {
    const rowData = row[key];
    switch (rowData) {
      case "":
      case "true":
      case "false":
      case "blank":
        return "";
      case "Amber":
        return "Amber";
      case "Active":
        return "Active";
      case "Completed":
        return "Completed";
      case "On Hold":
        return "On Hold";
      case "Cancelled":
        return "Cancelled";
      default: 
        return rowData;
    }
  }

  isStatusColumn(key: any) {
    return this.isStatusKey(key) || key.toLowerCase().indexOf('isrisk') != '-1';
  }

  isStatusKey(key: any) {
    return key.toLowerCase().indexOf('status') != '-1';
  }

  isRiskColumn(key: any) {
    return key.toLowerCase().indexOf('isrisk') != '-1';
  }

  isDate(key: string) {
    return (key.indexOf('modifiedOn') > -1 ||
      key.indexOf('startDate') > -1 ||
      key.indexOf('modifiedON') > -1 ||
      key.indexOf('createdOn') > -1 ||
      key.indexOf('createdON') > -1 ||
      key.indexOf('startTime') > -1 ||
      key.indexOf('originalDeliveryDate') > -1 ||
      key.indexOf('originalGoLiveDate') > -1 ||
      key.indexOf('originalPlannedCommittedDeliveryDate') > -1
    );
  }

  isDateOnly(key) {
    return key.indexOf('dueDate') > -1 || key.indexOf('modifiedDate') > -1;
  }

  onClick(row, action) {
    this.buttonClicked.emit({ action: action, data: row });
  }

  ngOnInit(): void {
    this.content = this.contentService.getcontent();
    if (!this.content) {
      this.contentService.setContent().then(data => {
        this.content = this.contentService.getcontent();
      });
    }
  }
   /**
   * 
   * Sorting Starts
   */

  sort(key) {    
    if (this.filterColumn !== key) {      
      this.isAsc = true;      
    }
    else {      
      this.isAsc = !this.isAsc;      
    }
    this.sortingClicked.emit({ key: key, isAsc: this.isAsc });    
  }

  applySorting(key): boolean {
    const ignoreSortingColumns = ['dateChangeByStakeholder'];
    return this.enableSorting && (this.rows && this.rows.length > 0) && !ignoreSortingColumns.includes(key);
  }

  isProjectNumberColumn(key: string): boolean {
    // FOR old key = clarityProjectNumber
    // FOR new IODW: key = projectNumber
    const isProjectNumberCol = key === 'clarityProjectNumber' ||  key === 'projectNumber';
    return isProjectNumberCol;
  }

  /**
   * 
   * Sorting ends
   */
}
