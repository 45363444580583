import { AfterViewInit, Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { PageTemplateService } from '../../../services/page-template.service';
import { ICommitments } from '../../../shared/form';
import { IPager } from '../../../shared/pagination';
import { IQuery } from '../../../shared/query';
import * as $ from 'jquery';
import { ContentService } from '../../../services/content.service';
declare var $: any;
import Chart from 'chart.js/auto'
import { Router } from '@angular/router';
import { ProjectsService } from '../../../services/projects.service';
import { ExportToExcelService } from '../../../services/export-to-excel.service';
import { OneViewType, RiskReasons } from 'src/app/core/constants'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { IUser } from '../../../shared/user';
import { UserService } from '../../../services/user.service';
import { ROLES } from 'src/app/core/constants';
import { IPageTemplate } from 'src/app/shared/page-template';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { CommitmentReviewDTO } from './models/commitment-review-d-t-o';
import { ClientNoteService } from 'src/app/services/client-note.service';
import { ClientNoteInput, OneViewNoteInput } from './models/client-note-input';
import { AlertType } from '../commitments-intake/enums/alert-type.enum';
import { AlertInput } from '../commitments-intake/models/alert-input';
import { DateFormatPipe } from 'src/app/SharedModule/Pipes/date-format.pipe';
import { CommitmentNoteInput } from './models/commitment-note-input';
import { CommitmentNoteService } from 'src/app/services/commitment-note.service';
import { forkJoin, Observable } from 'rxjs';
import { ClientsService } from '../../../services/clients.service';
import { ClientOneViewService } from 'src/app/services/client-oneview.service';

@Component({
  selector: 'app-commitment-dashboard',
  templateUrl: './commitment-dashboard.component.html',
  styleUrls: ['./commitment-dashboard.component.css']
})
export class CommitmentDashboardComponent implements OnInit, AfterViewInit {
  selectedTitle: string;
  selectedTitleNonProject: string;
  selectedProjectNumber: string;
  selectedProjectNumberNonProject: string;
  private firstTime = true;
  public note = '';
  public noteForCommitment = '';
  public oneViewNote = '';
  public showTable = false;
  public tableData: any = null;
  public alertType = AlertType;
  public alertInput: AlertInput = {
    alertType: AlertType.Success,
    message: ''
  };
  public alertInputPopup: AlertInput = {
    alertType: AlertType.Success,
    message: ''
  };
  private notepadSectionType = '';
  public isNotpadSectionVisible = false;
  public isStrategicClientAssignedUser = false;
  clientUserSuggestions: any[];
  SelectedCEUsers: any[];
  selectedCEUsersForSearch: any[];
  SelectedCEUsersDetails: any[];

  headerColumns = ['Status', 'Project Number', 'Title', 'Stage', 'Original Delivery Date', 'Target Delivery Date', 'Date Change By Stakeholder', 'Product'];
  keys = ['isRisk', 'clarityProjectNumber', 'commitmentTitle', 'commitmentStatus', 'originalDeliveryDate', 'plannedCommittedDeliveryDate', 'dateChangeByStakeholder', 'product'];
  filterColumn: string;
  filterColumnNonProject: string;
  public commitmentReviews: CommitmentReviewDTO[] = [];
  queryParam: IQuery;
  queryParamNonProject : IQuery;
  selectedEntityId: number = 0;
  clientSuggestions: any = {};
  allClientOneViews: any = {};
  clientText: any = ''
  selectedFormFieldApiKey: string = '';
  commitmentTitles: string[];
  clarityProjectNumbers: string[];
  searchFilter: any = [];
  nonProjectSearchFilter: any = [];
  clientTypeSearch: any = 'duns';
  projectTotalCount : any = 0;
  nonProjectTotalCount :any = 0;
  projectRows: any[] = [];
  projectRowsPage : number = 1;
  projectRowsPerPage : number = 10;
  projectRowsTotalPages : number = 1;
  manualRows : any[] = [];
  manualRowsPage : number = 1;
  manualRowsPerPage : number = 10;
  manualRowsTotalPages : number = 1;
  sortingObject;
  filterActive: boolean = false;
  options: any;
  drawerActive: boolean = false;
  detailsCommitment: any;
  stillLoading: boolean = true;
  content: any;
  summary: any = {};
  slaDashActive: boolean = true;
  products: any = '';
  productsOption: any;
  commitmentStatusList: string[] = [];
  commitmentStatusListNonProject: string[] = [];
  commitmentStats: any = { 'Active': 0, 'Canceled': 0, "Completed": 0, "On Hold": 0, "Total": 0, "Delivered": 0 };
  isSla: boolean = false;
  slaProductSearch: any;
  selectedSlaProduct: any = '';
  slaData: any[] = [];
  counter: number;
  monthlyData: any = [];
  monthlyPData: any = [];
  dataCommitmentCalendarError: boolean = false;
  isPageLoad: boolean = true;
  slaPercentage: any;
  labels: any[];
  chart: any;
  chart2: any;
  showGraph: boolean = true;
  isRowsLoading: boolean = true;
  isProductsLoading: boolean = true;
  commitmentProducts: any;
  allCommitments: any[] = [];
  isError: boolean;
  errorMsg: any;
  reasonForm: FormGroup;
  private _previousTitleSelected: any;
  private _previousSelectedStatuses: string[] = [];
  LastLoginTimeStamp: string;
  typeOfCommitmentCalander: any = 'qtr';
  year: any = [2015, 2016, 2017, 2018, 2019, '2020', '2021', '2022', 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030];
  fromYear: any = new Date().getFullYear() - 5;
  toYear: any = new Date().getFullYear() + 5;
  month: any = new Date().getMonth();
  fromMonth: any = '';
  toMonth: any = '';
  commitmentCalendarLabel: any = [];
  commitmentCalendarNumbers: any = [];
  commitmentCalendarData: any = [];
  commitmentCalendar: boolean = false;
  filterActivePage: boolean = false;
  filterUserActivePage: boolean = false;
  filterPage: any = [];
  isRm: boolean = false;
  isClientAdmin = false;
  isClient: boolean = false;
  isAdmin: boolean = false;
  isClientSuperUser: boolean = false;
  LOB: any = [];
  BU: any = [];
  commitmentStatuses: { id: string, name: string }[] = [];
  commitmentStatusesSuggestions: { id: string, name: string }[] = [];
  commitmentStatusesSuggestionsNonProject: { id: string, name: string }[] = [];
  selectedLOB: any = '';
  selectedClientUser: any = '';
  selectedBU: any = '';
  commitmentStatsCount: any;
  applyFilter: boolean = false;
  pagerObjectProduct: IPager = {
    totalCount: 0,
    pageSize: 0,
    currentPage: 0,
    totalPages: 0
  };
  _pageSizeProduct: any = 7;
  ngShows: boolean = false;
  pageProducts: any = [];
  isClientExecutive: boolean = false;
  cBProductsError: boolean = false;
  commitmentsError: boolean = false;
  showClientUserEditModal: boolean = false;
  selectedForeditUserAssignement: any;
  dataSource: any;
  clientCommitmentId: number = 0;
  pageTemplate: IPageTemplate;
  pageTemplateAdmin: boolean = false;
  userData: any;
  isRestrictedAdmin: boolean = false;
  isAccountTeam: boolean = false;
  isCCTAdmin: boolean = false;
  isPdfExport: boolean = false;
  public isCCTAdminReadOnly = false;
  public isSalesExecutive = false;
  public isAccountManager = false;
  public isAccountExecutive = false;
  public isNewRM=false;
  private _totalDayChangesSign = '×';
  private _blankSelectText: string = '(Blanks)';
  commitmentNoteForm: FormGroup;
  paginatedManualRows: any[] = [];
  paginatedProjectRows: any[] = [];
  searchFlag: number = 0; 
  commitmentAllRows: any[];
  commitmentManualRows: any[];
  clarityProjectNumbersSuggestions: any;
  clarityProjectNumbersSuggestionsNonProject: any;
  rowsTitleSuggestionsProject: any;
  rowsTitleSuggestionsNonProject: any;
  rowsStatusSuggestionsProject :any;
  rowsStatusSuggestionsNonProject : any;
  commitmentAllRowsCount: number = 0;
  commitmentManualRowsCount: number = 0;
  isClientLoad : boolean = false;
  isRiskSort : boolean = false;
  isRiskSortNonProject : boolean = false;
  isOneViewSelected = false;
  oneViewId: number;
  isClientLoadedPartofOneView: boolean = false;
  oneViewClientSuggestions: any[] = [];
  selectedOneViewClients: string[] = [];
  
  public blueThemeMode = false;

  isRiskSortExcel :boolean = false;
  isRiskSortExcelNonProject : boolean = false;
  isCustomClient: boolean = false;
  private _oneViewSuffix = '[OneView]';

  constructor(private projectService: ProjectsService,
    private pageTemplateService: PageTemplateService,
    private contentService: ContentService,
    private router: Router,
    private excelService: ExportToExcelService,
    private fb: FormBuilder,
    private userService: UserService,
    private clientNoteService: ClientNoteService,
    private commitmentNoteService: CommitmentNoteService,
    private clientCommitmentsService: ClientCommitmentsService,
    private permission: PermissionsService,
    private _datePipe: DateFormatPipe, private clientService: ClientsService,
    private oneViewService: ClientOneViewService) {
    this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r); 
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $(".cct-table").tooltip({ selector: '[data-toggle=tooltip]' });
      $('reviewCommitmentsBtn').tooltip({
        selector: "[data-tooltip=tooltip]",
        container: "body"
      });
      $('[data-toggle="tooltip"]').tooltip();
    });
    
    $('.view-contacts-dropdown').on('hide.bs.dropdown', function () {
      const contactsButton =$(this).find('.btn-contacts');
      $(contactsButton).removeClass('clicked');
    });

    this.options = Object.values(RiskReasons).filter(value => typeof value !== 'number');
    this.selectedEntityId = 0;
    
    const user = this.userService.getUserListData();
    if (!user) {
      this.userService.callUserListData().subscribe((data) => {
        this.initialUserData(data);
      });
    }
    else {
      this.initialUserData(user);
    }
  }

  private initialUserData(user: IUser) {
    if (user.type.toLowerCase() === 'client') {
      // For OneView
      this.oneViewService.getByClientId(user.organization).subscribe(oneView => {
        this.isOneViewSelected = true;
        this.setUserData(user);
        if (oneView) {
          // Incase user is a client user and user's client has been assigned to an oneview then oneview name should be shown instead of client name.
          this.selectedEntityId = oneView.id;
          this.clientText = oneView.oneViewGroupName;
          this.loadOneViewNote();
        }        
        this.initData(this.isClientLoad);
        this.initialPageTemplate();
      }, (error) => {
        this.setUserData(user);
        this.initData(this.isClientLoad);
        this.initialPageTemplate();
      });
    } else {
      this.setUserData(user);
      this.initData(this.isClientLoad);
      this.initialPageTemplate();
    }    
  }

  private initialPageTemplate() {
    this.content = this.contentService.getcontent();
    if (!this.content) {
      this.contentService.setContent().then(data => {
        this.content = this.contentService.getcontent();
      });
    }

    if (environment.env === 'prod') {
      this.clientCommitmentId = 8;
    }
    else if (environment.env === 'app') {
      this.clientCommitmentId = 8;
    }
    else if (environment.env === 'dev') {
      this.clientCommitmentId = 14;
    }
    else if (environment.env === 'qa') {
      this.clientCommitmentId = 12;
    }

    this.getPageTemplateData();
    this.commitmentNoteForm = this.fb.group({
      noteForCommitment: ['', []],
    });
  }

  private setUserData(user: IUser) {
    this.userData = user;
    this.getLoginTimestamp(user);
    if (user.type.toLowerCase() === 'client') {
      this.selectedEntityId = user.organization;
      this.clientText = user.organizationTitle;
      this.isClient = true;
      this.isClientLoad = true;
      if (this.isOneViewSelected == false) {
        this.loadClientNote();
      }
      this.isClientAdmin = user.roles.some(r => r.roleTitle.toLowerCase() === 'client admin');      
    }
    this.isClientSuperUser = user.roles.some(r => r.roleTitle.toLowerCase() === 'admin') && user.roles.some(r => r.roleTitle.toLowerCase() === 'client view user');
    this.isSalesExecutive = this.permission.isSalesExecutive();
    this.isAdmin = false;
    this.isCCTAdmin = false;
    this.isRm = false;
    this.isClientExecutive = false;
    this.isAccountTeam = false;
    this.isAccountManager = false;
    for (let p in user.roles) {
      const role = user.roles[p].roleTitle.toLowerCase();
      switch (role) {
        case ROLES.Admin.toLocaleLowerCase():
          this.isAdmin = true;
          break;
        case ROLES.CCT_Admin.toLocaleLowerCase():
          this.isCCTAdmin = true;
          break;
        case ROLES.Relationship_Manager.toLocaleLowerCase():
          this.isRm = true;
          break;
        case ROLES.Account_Team.toLocaleLowerCase():
          this.isAccountTeam = true;
          break;
        case ROLES.Account_Manager.toLocaleLowerCase():
          this.isAccountManager = true;
          break;
        case ROLES.Client_Executive.toLowerCase():
          this.isClientExecutive = true;
          break;
        case ROLES.Account_Executive.toLowerCase():
          this.isAccountExecutive = true;
          break;
        case ROLES.New_RM.toLowerCase():
          this.isNewRM = true;
          break;

        default:
          break;
      }
    }
  }

  initData(isClientLoad = false) {
    this.filterPage = '';
    this.slaActiveTab(true);
    if (this.queryParam) {
      delete this.queryParam['StartDate'];
      delete this.queryParam['EndDate'];
    }
    
    this.commitmentTitles = [];
    this.clarityProjectNumbers = [];
    delete this.searchFilter['ClarityProjectNumber'];
    delete this.searchFilter['commitmentStatus'];
    delete this.nonProjectSearchFilter['ClarityProjectNumber'];
    delete this.nonProjectSearchFilter['commitmentStatus'];
    this.commitmentStatusList = [];
    this.commitmentStatusListNonProject = [];
    this._previousSelectedStatuses = [];
    this.selectedTitle = '';
    this.selectedTitleNonProject = '';
    this.selectedProjectNumber = '';
    this.selectedProjectNumberNonProject = '';
    delete this.searchFilter['CommitmentTitle'];
    delete this.nonProjectSearchFilter['CommitmentTitle'];
    this.selectedLOB = '';
    this.products = '';
    this.toMonth = '';
    this.fromMonth = '';
    this.initQueryParams();
    this.getMonth();
    const formFields = [{ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' }];
    this.getSuggestions({ formFields: JSON.stringify(formFields) });
    this.getDataDateWise(false, isClientLoad);
    this.getRmClients();
  }

  ngAfterViewInit(): void {
    this.heightCalculator();

    const resize_ob = new ResizeObserver(() => {
      this.heightCalculator();
    });
    resize_ob.observe(document.getElementById("myTabContentForClientDashboard"));
  }

  slaActiveTab(value: boolean) {
    this.slaDashActive = value;
  }

  cancelSearch(type: any = '') {
    this.resetHeight();
    if (type == 'main') {
      this.selectedLOB = '';
      this.products = '';
      this.fromMonth = '';
      this.toMonth = '';
      delete this.queryParam['StartDate'];
      delete this.queryParam['EndDate'];
      delete this.queryParamNonProject['StartDate'];
      delete this.queryParamNonProject['EndDate'];
      this.getDataDateWise();
      return;
    }

    if (type != 'All') {
      delete this.searchFilter[type];
    }
    else {
      delete this.searchFilter['commitmentStatus'];
      this.commitmentStatusList = [];
      this._previousSelectedStatuses = [];
      this.selectedTitle = '';
      this.selectedProjectNumber = null;
      delete this.searchFilter['CommitmentTitle'];
      delete this.searchFilter['ClarityProjectNumber'];
      this.clearSorting();
    }
    if (type == 'Product') {
      this.products = '';
    }
    else {
      this.commitmentStatusList = [];
      this._previousSelectedStatuses = [];
    }
    if (!this.queryParam.orderBy || this.queryParam.orderBy.includes('isRisk')) 
      this.initateFilterSearch(true);
    else
      this.initateFilterSearch(false);
    this.getSuggestions(this.queryParam);
  }

  clearSorting() {
    this.sortingObject = null;
    this.isRiskSort = false;
    this.filterColumn = '';
    this.setOrderBy(null);
  }

  cancelSearchNonProject(type: any = '') {
    this.resetHeight();
    if (type == 'main') {
      this.selectedLOB = '';
      this.products = '';
      this.fromMonth = '';
      this.toMonth = '';
      delete this.queryParam['StartDate'];
      delete this.queryParam['EndDate'];
      this.getDataDateWise();
      return;
    }

    if (type != 'All') {
      delete this.nonProjectSearchFilter[type];
    }
    else {
      delete this.nonProjectSearchFilter['commitmentStatus'];
      this.commitmentStatusListNonProject = [];
      this._previousSelectedStatuses = [];
      this.selectedTitleNonProject = '';
      this.selectedProjectNumberNonProject = '';
      delete this.nonProjectSearchFilter['CommitmentTitle'];
      delete this.nonProjectSearchFilter['ClarityProjectNumber'];
      this.clearSortingNonProject();
    }
    if (type == 'Product') {
      this.products = '';
    }
    else {
      this.commitmentStatusListNonProject = [];
      this._previousSelectedStatuses = [];
    }
    if (!this.queryParamNonProject.orderBy || this.queryParamNonProject.orderBy.includes('isRisk')) 
      this.initateFilterSearchNonProject(true);
    else
      this.initateFilterSearchNonProject(false);
    
    this.getSuggestions(this.queryParamNonProject);
  }

  clearSortingNonProject() {
    this.sortingObject = null;
    this.isRiskSortNonProject = false;
    this.filterColumnNonProject = '';
    this.setOrderByNonProject(null);
  }

  getPageTemplateData() {
    this.clientCommitmentsService.getPageTemplateById(this.clientCommitmentId)
      .pipe(take(1))
      .subscribe(page => {
        this.pageTemplate = page.body as IPageTemplate;
        this.pageTemplateAdmin = this.pageTemplate.pageTemplateAdmins?.some(a => a.userId === this.userData.id);
        this.isCCTAdminReadOnly = this.permission.isCCTAdminReadOnly(this.pageTemplate);
      }, (error) => { this.errorMsg = error; });
  }

  advanceSearch() {
    this.resetHeight();
    delete this.searchFilter['Product'];
    delete this.searchFilter['commitmentStatus'];
    if (this.commitmentStatusList && this.commitmentStatusList.length > 0 && this.commitmentStatusList.indexOf('') < 0) {
      const originalStatuses = this.commitmentStatusesSuggestions;
      this.searchFilter['commitmentStatus'] = this.commitmentStatusList.map(x => {
        return originalStatuses.find(o => o.id === x).name;
      }).join(',');
    }

    this.filterActive = !this.filterActive;
    this.clearSorting();
    this.initateFilterSearch(this.isClientLoad, true);
    this.getSuggestions(this.queryParam);
  }

  advanceSearchNonProject() {
    document.getElementById('tab14').className = 'tab-pane fade active show';
    this.resetHeight();
    delete this.searchFilter['Product'];
    delete this.nonProjectSearchFilter['commitmentStatus'];
    if (this.commitmentStatusListNonProject && this.commitmentStatusListNonProject.length > 0 && this.commitmentStatusListNonProject.indexOf('') < 0) {
      const originalStatuses = this.commitmentStatusesSuggestionsNonProject;
      this.nonProjectSearchFilter['commitmentStatus'] = this.commitmentStatusListNonProject.map(x => {
        return originalStatuses.find(o => o.id === x).name;
      }).join(',');
    }

    this.filterActive = !this.filterActive;
    this.clearSortingNonProject();
    this.initateFilterSearchNonProject(this.isClientLoad, true);
    this.getSuggestions(this.queryParamNonProject);
  }

  getMonth() {
    const month = new Date().getMonth() + 1;
    if (month < 10) {
      return `0${month}`;
    }

    return month as unknown as string;
  }

  clientTypeChange() {
    this.filterColumn = null;
    this.selectedEntityId = -1;
    this.clientText = '';
    this.isOneViewSelected = false;
    this.oneViewClientSuggestions = [];
    this.selectedOneViewClients = [];
    this.initData();
  }

  public onReviewCommitmentModalSubmit(): void {
    this.getDataDateWise(true);
  }

  fullAdvanceSearch() {
    this.getDataDateWise(true,this.isClientLoad,true);
  }

  getDataDateWise(load = false, isClientLoad = false, isAdvanceSearch = false) {
    if (load === true) {
      this.resetHeight();
    }
    $('.nav-tabs a[href="#tab13"]').tab('show');
    let fromDate: any = '';
    let toDate: any = '';
    if (this.fromMonth != '') {
      let getYearAndMonth = this.fromMonth.split('-');
      fromDate = Number(getYearAndMonth[0]) + '-' + Number(getYearAndMonth[1]) + '-1';
    }

    if (this.toMonth != '') {
      let toDateYearAndMonth = this.toMonth.split('-');
      let tempToDate = new Date(toDateYearAndMonth[0], toDateYearAndMonth[1], 0)
      toDate = tempToDate.getFullYear() + '-' + (tempToDate.getMonth() + 1) + '-' + tempToDate.getDate();
    }
    
    if (!load) {
      this.applyFilter = false;
      delete this.queryParam.StartDate;
      delete this.queryParam.EndDate;
      delete this.queryParam['bu'];
      delete this.queryParam['lob'];
      delete this.queryParam['product'];
      delete this.queryParam['selectedOneViewClientIds'];
      delete this.queryParamNonProject['selectedOneViewClientIds'];
      this.filterPage = [];
      toDate = '';
      fromDate = '';
      if (this.isOneViewSelected && this.selectedOneViewClients && this.selectedOneViewClients.length > 0) {
        this.queryParam.selectedOneViewClientIds = this.selectedOneViewClients.join(",");
        this.queryParamNonProject.selectedOneViewClientIds = this.selectedOneViewClients.join(",");
      }
      else {
        delete this.queryParam.selectedOneViewClientIds;
        delete this.queryParamNonProject.selectedOneViewClientIds;
      }
    }
    else {
      this.queryParam['StartDate'] = fromDate;
      this.queryParam['EndDate'] = toDate;
      this.queryParamNonProject['StartDate'] = fromDate;
      this.queryParamNonProject['EndDate'] = toDate;
      this.filterPage = [];
      if (this.selectedLOB != '')
        this.filterPage['lob'] = this.selectedLOB;
      if (this.products != '')
        this.filterPage['product'] = this.products;
      this.applyFilter = true;
    }
 
    this.initateFilterSearch(isClientLoad, isAdvanceSearch);
    this.initateFilterSearchNonProject(isClientLoad, isAdvanceSearch);

    this.getSuggestions(this.queryParam);
    this.calanderCommitment(this.typeOfCommitmentCalander);
    this.clientCommitmentSummaray();
    this.commitmentsbyProduct();
    this.filterActivePage = false;
    this.filterUserActivePage = false;
    this.getCommitmentReviewsByClientId();
  }

  private getCommitmentReviewsByClientId(): void {
    this.commitmentReviews = [];
    this.pageTemplateService.getCommitmentReviewsByClientId(this.selectedEntityId).subscribe(result => {
      this.commitmentReviews = result.body as CommitmentReviewDTO[];
    });
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = items => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };

    allSelect(items);
  }

  openDrawer() {
    this.reasonForm = this.fb.group({
      reason: new FormControl(this.detailsCommitment.riskReason || '', [requiredIfValidator(() => this.reasonForm.get('isRisk').value == true)]),
      description: new FormControl(this.detailsCommitment.riskComments || '', [requiredIfValidator(() => this.reasonForm.get('reason').value.toLowerCase() === "other")]),
      isRisk: [this.detailsCommitment.isRisk == 'true' ? true : false],
    });
    this.drawerActive = true;

    window.scroll(0, 0);

    setTimeout(function () {
      $('html, body').addClass('overflow-hidden');
      $('#containerPage').addClass('overflow-hidden');
      document.getElementById('closeButton').focus();
    }, 10);
    $(document).ready(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
    this.loadCommitmentNote();
  }

  openFilter() {
    this.filterActive = !this.filterActive;
  }

  closeDrawer() {
    this.detailsCommitment = [];
    this.drawerActive = false;
    setTimeout(function () {
      $('html, body').removeClass('overflow-hidden');
      $('#containerPage').removeClass('overflow-hidden');
      document.getElementsByTagName('body')[0].classList.remove('modal-open');
      window.scroll(0, document.body.scrollHeight);
    }, 0);
  }

  public openDeleteModal(): void {
    $('#deleteWarningModal').show();
    $("#deleteWarningModal").addClass('show');
    $('body').addClass('modal-open');
  }

  updateStrategicClientUsers(commitment: any) {
    var CommitmentId = commitment.responseId;
    var request = [];
    this.clientCommitmentsService.createStrategicCLientUsers(CommitmentId, request).subscribe((response) => { }, (error) => { });
  }

  public deletePageResponse(): void {
    this.updateStrategicClientUsers(this.detailsCommitment);
    this.clientCommitmentsService.setPageTemplateResponseIsTrackedToFalse(this.detailsCommitment.responseId).subscribe(() => {
      this.clientSelected();
      this.closeDrawer();
    });
  }

  navigate(event: any, mode: boolean) {
    this.detailsCommitment = event.data;
    this.dataSource = this.detailsCommitment.datasource.toLowerCase();
    if (this.isOneViewSelected == true) {
      this.getStrategicOneViewAssignedUsers(this.detailsCommitment.responseId);
    }
    else {
      this.getStrategicClientAssignedUsers(this.detailsCommitment.responseId);
    }
      
    if (this.dataSource === 'servicenow') {
      this.detailsCommitment.projectManager = this.detailsCommitment.projectManagerServicenow
    }
    this.openDrawer();
  }

  getStrategicClientAssignedUsers(commitmentId) {
    this.SelectedCEUsersDetails = [];
    this.clientCommitmentsService.GetStrategicCLientUsers(commitmentId).subscribe(
      result => {
        this.SelectedCEUsersDetails = result;
        let SelectedCEUsersArray = []
        for (var i = 0; i < this.SelectedCEUsersDetails.length; i++) {
          SelectedCEUsersArray.push(this.SelectedCEUsersDetails[i].userId);
        }
        this.SelectedCEUsers = SelectedCEUsersArray;        
      }
      , error => { }
    );
  }

  getStrategicOneViewAssignedUsers(commitmentId) {
    this.SelectedCEUsersDetails = [];
    this.clientCommitmentsService.GetStrategicOneViewUsers(commitmentId).subscribe(
      result => {
        this.SelectedCEUsersDetails = result;
        let SelectedCEUsersArray = []
        for (var i = 0; i < this.SelectedCEUsersDetails.length; i++) {
          SelectedCEUsersArray.push(this.SelectedCEUsersDetails[i].userId);
        }
        this.SelectedCEUsers = SelectedCEUsersArray;
      }
      , error => { }
    );
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

  openUpdateUsersModal(pageResponse: any) {
    this.selectedForeditUserAssignement = pageResponse;
    this.showClientUserEditModal = true;
  }

  editCommitmentUsersModal() {
    this.showClientUserEditModal = false;
    this.closeDrawer();
    let event = { action: "view", data : this.selectedForeditUserAssignement }
    
    this.navigate(event, false);
    /*this.getStrategicClientAssignedUsers(this.selectedForeditUserAssignement.responseId);*/
  }

  initQueryParams(filter = '') {
    let formFields = [];
    let formFieldsNP = [];
    if (filter != '') {
      formFieldsNP = JSON.parse(this.queryParamNonProject.FormFields)
      formFields = JSON.parse(this.queryParam.FormFields)
    }
    formFieldsNP.push({ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' });
    formFields.push({ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' });
    this.queryParam = Object.assign({}, {
      FormFields: JSON.stringify(formFields),
      searchClientBy: this.getClientTypeSearch()
    });

    this.queryParamNonProject = Object.assign({}, {
      FormFields: JSON.stringify(formFieldsNP),
      searchClientBy: this.getClientTypeSearch()
    });

    if (this.selectedCEUsersForSearch) {
      this.queryParam.clientUserIds = this.selectedCEUsersForSearch.toString();
      this.queryParamNonProject.clientUserIds = this.selectedCEUsersForSearch.toString();
    }
  }

  getSuggestions(queryParams: IQuery = Object.assign({}, {})) {
    const queryParam: IQuery = Object.assign({}, queryParams);
    if (this.selectedCEUsersForSearch) {
      queryParam.clientUserIds = this.selectedCEUsersForSearch.toString();
    }
    const suggestionFields = [
      { 'apiKey': 'Product', 'apiKeyValue': '' },
      { 'apiKey': 'LOB', 'apiKeyValue': '' },
    ];
    if (this.selectedEntityId <= 0) {
      this.productsOption = [];
      this.commitmentStatuses = [];
      this.LOB = [];
      this.BU = [];
      return;
    }
    
    const formFieldsJson = queryParam.formFields ?? queryParam.FormFields;
    if (formFieldsJson) {
      const formFields = JSON.parse(formFieldsJson)?.filter(f => f.apiKey !== 'commitmentStatus');
      if (formFields && queryParam.formFields) {
        queryParam.formFields = JSON.stringify(formFields);
      }
      else if (formFields && queryParam.FormFields) {
        queryParam.FormFields = JSON.stringify(formFields);
      }
    }

    this.pageTemplateService.getclientdashboardSuggestion(this.selectedEntityId, suggestionFields, queryParam).subscribe(
      data => {
        this.productsOption = [];
        this.commitmentStatuses = [];
        this.LOB = [];
        this.BU = [];
        if (!data) {
          return;
        }

        const commitMentTypes = [];
        for (const i in data) {
          if (!commitMentTypes[data[i].apiKey]) {
            commitMentTypes[data[i].apiKey] = [];
          }

          for (const k in data[i].apiKeyValues) {
            if (data[i].apiKeyValues[k]) {
              commitMentTypes[data[i].apiKey].push({ 'key': k, 'title': data[i].apiKeyValues[k] });
            }
          }
        }
        for (const k in commitMentTypes) {
          switch (k) {
            case 'Product':
              this.productsOption = commitMentTypes[k];
              break;
            case 'LOB':
              this.LOB = commitMentTypes[k];
              break;
          }
        }
      },
      (error) => { }
    );
  }

  slaGraphChange() {
    this.showGraph = false;
    let productValue = this.selectedSlaProduct;
    if (productValue == '')
      this.createChart(this.labels, this.monthlyData, this.monthlyPData, 'all')
    else {
      let monthlyData = this.slaData[productValue].Data;
      let monthlyPData = this.slaData[productValue].pData;
      this.createChart(this.labels, monthlyData, monthlyPData, productValue)
    }
    this.showGraph = true;
  }

  clientCommitmentSummaray() {
    this.stillLoading = true;
    this.commitmentStats = { 'Not Started': 0, 'In Planning': 0, 'In Progress': 0, 'Canceled': 0, "Completed": 0, "On Hold": 0, "Total": 0, "Delivered": 0 }
    this.commitmentStatsCount = { 'Not Started': 0, 'In Planning': 0, 'In Progress': 0, 'Canceled': 0, "Completed": 0, "On Hold": 0, "Total": 0, "Delivered": 0 };
    if (this.selectedEntityId <= 0) {
      this.stillLoading = false;
      this.summary = null;
      return;
    }
    const queryParam: IQuery = Object.assign({}, this.queryParam);
    if (this.selectedCEUsersForSearch) {
      queryParam.clientUserIds = this.selectedCEUsersForSearch.toString();
    }
    const formFields = [];
    for (let k in this.filterPage) {
      formFields.push({ 'apiKey': k, 'apiKeyValue': this.filterPage[k] })
    }
    
    formFields.push({ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' });
    queryParam.FormFields = JSON.stringify(formFields);
    this.clientCommitmentsService.getclientdashboardSummary(this.selectedEntityId, queryParam).subscribe(
      data => {
        this.stillLoading = false;
        this.summary = data;
        if (!this.summary) {
          return;
        }
        
        let totalCommitmentStatuses = 0;
        this.summary.commitmentStatuses.forEach(c => {
          totalCommitmentStatuses += c.commitmentCount;
        });

        this.commitmentStats['Total'] = totalCommitmentStatuses;
        for (let k in this.summary.commitmentStatuses) {
          const commitmentCount = this.summary.commitmentStatuses[k].commitmentCount;
          const commitmentStatus = this.summary.commitmentStatuses[k].commitmentStatus;
          if (commitmentCount > 0) {
            this.commitmentStats[commitmentStatus] = (commitmentCount / totalCommitmentStatuses) * 100;
          }
          this.commitmentStatsCount[commitmentStatus] = commitmentCount;
          this.commitmentStats[commitmentStatus] = parseFloat(this.commitmentStats[commitmentStatus]).toFixed(2);
        }

        if (this.summary.slmData) {
          this.slaProductSearch = [];
          this.slaData = [];
          this.monthlyData = [];
          this.isSla = true;
          for (let k in this.summary.slmData) {
            let data = this.summary.slmData[k]
            if (!this.slaData[data['slmSLAName']]) {
              this.slaData[data['slmSLAName']] = [];
              this.slaProductSearch.push({ 'key': k, 'title': data['slmSLAName'] });
              if (!this.slaData[data['slmSLAName']]['p']) {
                this.slaData[data['slmSLAName']]['p'] = [];
              }
            }
            if (this.slaData[data['slmSLAName']] && !this.slaData[data['slmSLAName']][data['slmYear']]) {
              this.slaData[data['slmSLAName']][data['slmYear']] = [];
              this.slaData[data['slmSLAName']]['p'][data['slmYear']] = [];
            }
            this.slaData[data['slmSLAName']][data['slmYear']][data['slmMonth']] = data['slmSLAGoal']
            this.slaData[data['slmSLAName']]['p'][data['slmYear']][data['slmMonth']] = data['slmFinalPerformanceScore']
          }
        }
        this.stillLoading = false;
        this.graphInit();
      },
      (error) => { }
    );
  }

  clientCommitments(isClientLoad = false, isAdvanceSearch = false) {
    this.commitmentsError = false;
    this.projectRows = [];
    this.projectRowsTotalPages = 0;
    this.projectTotalCount = 0;
    this.rowsTitleSuggestionsProject = [];
    this.clarityProjectNumbersSuggestions = [];
    if (this.selectedEntityId <= 0) {
      this.paginatedProjectRows = [];
      this.commitmentStatusesSuggestions = [];
      this.isRowsLoading = false;
      return;
    }

    this.isRowsLoading = true;
    this.pageTemplateService.getclientdashboardCommitments(this.selectedEntityId, this.queryParam).pipe(take(1)).subscribe(
      response => {
        this.heightCalculator();
        const formFieldSuggestion = response.body as ICommitments;
        
        const rows = formFieldSuggestion.commitments.filter((data) => {
          data.product = data.product?.replace(/\[|\]/g, '')?.trim();
          const commitmentStatuses = [            
            'Client - On Hold',
            'On Hold',
            'Delivered',
            'Completed',
            'Canceled'
          ];
          if (commitmentStatuses.includes(data.commitmentStatus) || 
              (data.isRisk == 'false' && (data.commitmentStatus == 'Fiserv - On Hold' || data.commitmentStatus == 'Not Started' || data.commitmentStatus == 'In Planning'))) {
            data.isRisk = 'blank';
          }

          return true;
        });

        const projectTitles = [];
        const projectNumbers = [];
        const projectStatuses = [];
        rows.forEach(element => {
          element.plannedCommittedDeliveryDate = this.dateFormat(element.plannedCommittedDeliveryDate);
          element.dateChangeByStakeholderClient = this.getStakeholderDaysChanged(element, 'Client');
          element.dateChangeByStakeholderFiserv = this.getStakeholderDaysChanged(element, 'Fiserv');
          const formFields = JSON.stringify(this.queryParam.FormFields);
          if (element.datasource.toLowerCase() === 'manual') {
            if (element.clarityProjectNumber != null && element.clarityProjectNumber.indexOf('PR') != -1) {
              this.projectRows.push(element);
              projectTitles.push(element.commitmentTitle);
              projectNumbers.push(element.clarityProjectNumber);
              if (isClientLoad || formFields.includes('CommitmentTitle') || formFields.includes('ClarityProjectNumber')) {
                projectStatuses.push(element.commitmentStatus);
              }
            }
          }
          
          if (element.datasource.toLowerCase() !== 'manual'){
            this.projectRows.push(element);
            projectTitles.push(element.commitmentTitle);
            projectNumbers.push(element.clarityProjectNumber);
            if (isClientLoad || formFields.includes('CommitmentTitle') || formFields.includes('ClarityProjectNumber')) {
              projectStatuses.push(element.commitmentStatus);
            }
          }});

          //this.isClientLoad = false; //reset  this.isClientLoad 
          if (isClientLoad || this.isRiskSort || isAdvanceSearch){
            this.projectRows = this.sortByRisk(this.projectRows);
          }

          this.getProjectSuggestions(isClientLoad, projectTitles, projectNumbers, projectStatuses);

          this.projectRowsTotalPages = Math.ceil(this.projectRows.length/this.projectRowsPerPage);
          if(this.searchFlag == 1)
            this.onAllRowsPageChange(1);
          else
            this.getPaginatedAllRows();
          this.projectTotalCount = this.projectRows.length;
          this.isRowsLoading = false;
        },
        (error) => {
          this.commitmentsError = true;
          // this.isClientLoad = false;
        }
      );
  }

  isAtRisk(row: any, key: any) {
    const data = row[key].replace('Fulfillment - ', '');
    if (key !== 'isRisk') {
      return false;
    }

    const commitmentStatus = row['commitmentStatus'];
    const isRisk = data.toString() === 'true';
    if (isRisk && (commitmentStatus == 'In Progress' || commitmentStatus == 'In Planning' || commitmentStatus == 'Not Started' || commitmentStatus === 'Fiserv - On Hold' )) { 
      return true;
    }
    
    return false;
  }
  
  sortByRisk(rows: any[]) {
    const customOrder = [
      "In Progress",      
      "In Planning",
      "Not Started",
      "Fiserv - On Hold",
      "Client - On Hold",
      "On Hold",      
      "Delivered",
      "Completed",
      "Canceled"
    ];
  
    const atRiskRows = rows.filter(row => row.isRisk === 'true');
    const blankRiskRows = rows.filter(row => row.isRisk.toLowerCase() === 'blank');
    const notAtRiskRows = rows.filter(row => row.isRisk === 'false');

    atRiskRows.sort((a, b) => customOrder.indexOf(a.commitmentStatus) - customOrder.indexOf(b.commitmentStatus));
    blankRiskRows.sort((a, b) => customOrder.indexOf(a.commitmentStatus) - customOrder.indexOf(b.commitmentStatus));
    notAtRiskRows.sort((a, b) => customOrder.indexOf(a.commitmentStatus) - customOrder.indexOf(b.commitmentStatus));
    if ((!this.queryParam.orderBy || this.queryParam.orderBy.includes('isRisk desc'))) {
      return [...atRiskRows,...notAtRiskRows,...blankRiskRows];
    }
    
    return [...blankRiskRows,...notAtRiskRows,...atRiskRows];
  }

  sortByRiskNonProject(rows: any[]) {
    const customOrder = [
      "In Progress",      
      "In Planning",
      "Not Started",
      "Fiserv - On Hold",
      "Client - On Hold",
      "On Hold",      
      "Delivered",
      "Completed",
      "Canceled"
    ];
  
    const atRiskRows = rows.filter(row => row.isRisk === 'true');
    const blankRiskRows = rows.filter(row => row.isRisk.toLowerCase() === 'blank');
    const notAtRiskRows = rows.filter(row => row.isRisk === 'false');

    atRiskRows.sort((a, b) => customOrder.indexOf(a.commitmentStatus) - customOrder.indexOf(b.commitmentStatus));
    blankRiskRows.sort((a, b) => customOrder.indexOf(a.commitmentStatus) - customOrder.indexOf(b.commitmentStatus));
    notAtRiskRows.sort((a, b) => customOrder.indexOf(a.commitmentStatus) - customOrder.indexOf(b.commitmentStatus));
    if ((!this.queryParamNonProject.orderBy || this.queryParamNonProject.orderBy.includes('isRisk desc'))) {
      return [...atRiskRows,...notAtRiskRows,...blankRiskRows];
    }
    
    return [...blankRiskRows,...notAtRiskRows,...atRiskRows];
  }

  getProjectSuggestions(isClientLoad = false, projectTitles: any[], projectNumbers: any[], projectStatuses: any[]) {
    const uniqueTitlesMap = new Map<string, string>();
    const uniqueProjectMap = new Map<string, string>();
    projectTitles.map((title, index) => {
      if (!uniqueTitlesMap.has(title)) {
        uniqueTitlesMap.set(title,index.toString());
      }
    });

    this.rowsTitleSuggestionsProject = Array.from(uniqueTitlesMap, ([title, key]) => ({
        key: key,
        title: title
      })
    );

    projectNumbers.map((title, index) => {
      if (!uniqueProjectMap.has(title)) {
        uniqueProjectMap.set(title,index.toString());
      }
    });
    this.clarityProjectNumbersSuggestions = Array.from(uniqueProjectMap, ([title, key]) => ({
        key:key,
        title:title
    }));

    const formFields = JSON.stringify(this.queryParam.FormFields);
    if (isClientLoad || formFields.includes('CommitmentTitle') || formFields.includes('ClarityProjectNumber')) {
      this.rowsStatusSuggestionsProject = projectStatuses.map((title ,index) => ({
          key : (index).toString(),
          name: title
      }));
    
      const orderedStates = [
        "Not Started",
        "In Planning",
        "In Progress",
        "Delivered",
        "Completed",
        "Fiserv - On Hold",
        "Client - On Hold",
        "On Hold",
        "Canceled"
      ];

      this.commitmentStatusesSuggestions = [];
      this.commitmentStatusesSuggestions.push({
        id: '',
        name: 'All'
      });
      for (const state of orderedStates) {
        const currentStatus = this.rowsStatusSuggestionsProject.find(x => x.name === state);
        if (currentStatus) {
          const stateIndex = orderedStates.indexOf(state);
          this.commitmentStatusesSuggestions.push({
            id: stateIndex.toString(),
            name: currentStatus.name
          });
        }
      }
      this.commitmentStatusList = this.commitmentStatusList.filter(x => this.commitmentStatusesSuggestions.some(c => c.id === x));
      this.isClientLoad = false;
    }
  }

  clientCommitmentsNonProject(isClientLoad = false, isAdvanceSearch = false) {
    this.commitmentsError = false;
    this.manualRows = [];
    this.rowsTitleSuggestionsNonProject = [];
    this.clarityProjectNumbersSuggestionsNonProject = [];
    this.manualRowsTotalPages = 0;
    this.nonProjectTotalCount = 0;
    if (this.selectedEntityId <= 0) {
      this.paginatedManualRows = [];
      this.commitmentStatusesSuggestionsNonProject = [];
      this.isRowsLoading = false;
      return;
    }

    this.isRowsLoading = true;
    this.pageTemplateService.getclientdashboardCommitments(this.selectedEntityId, this.queryParamNonProject).pipe(take(1)).subscribe(
      response => {
        this.heightCalculator();
        const formFieldSuggestion = response.body as ICommitments;
        const rows = formFieldSuggestion.commitments.filter((data) => {
          data.product = data.product?.replace(/\[|\]/g, '')?.trim();
          const commitmentStatuses = [                       
            'Client - On Hold',
            'On Hold',
            'Delivered',
            'Completed',
            'Canceled'
          ];
          if (commitmentStatuses.includes(data.commitmentStatus) || (data.isRisk == 'false' && 
                (data.commitmentStatus == 'Fiserv - On Hold' || data.commitmentStatus == 'Not Started' || data.commitmentStatus == 'In Planning'))){
            data.isRisk = 'blank';
          }
          return true;
        });
        
        const manualTitles = [];
        const manualProjectNumbers = [];
        const manualStatuses = [];
        rows.forEach(element => {
          element.plannedCommittedDeliveryDate = this.dateFormat(element.plannedCommittedDeliveryDate);
          element.dateChangeByStakeholderClient = this.getStakeholderDaysChanged(element, 'Client');
          element.dateChangeByStakeholderFiserv = this.getStakeholderDaysChanged(element, 'Fiserv');
          
          if (element.datasource.toLowerCase() === 'manual') {
            if (element.clarityProjectNumber == null || element.clarityProjectNumber.indexOf('PR') == -1) {
              this.manualRows.push(element);
              manualProjectNumbers.push(element.clarityProjectNumber);
              const formFields = JSON.stringify(this.queryParamNonProject.FormFields);
              if (element.commitmentTitle != null && element.commitmentTitle != undefined) {
                manualTitles.push(element.commitmentTitle);
              }
               
              if (isClientLoad || formFields.includes('CommitmentTitle') || formFields.includes('ClarityProjectNumber') ){
                manualStatuses.push(element.commitmentStatus);
              }
            }
          }
        });
        
        if (isClientLoad ||  isAdvanceSearch ||  this.isRiskSortNonProject){
          this.manualRows = this.sortByRiskNonProject(this.manualRows);
        }
        this.getNonProjectSuggestions(isClientLoad, manualTitles, manualProjectNumbers, manualStatuses);
        this.manualRowsTotalPages = Math.ceil(this.manualRows.length/this.manualRowsPerPage);
        if (this.searchFlag == 1) {
          this.onManualRowsPageChange(1);
        }
        else {
          this.getPaginatedManualRows();
        }
        
        this.nonProjectTotalCount = this.manualRows.length;
        this.isRowsLoading = false;
      },
      (error) => {
        this.commitmentsError = true;
      }
    );
  }

  getNonProjectSuggestions(isClientLoad = false, projectTitles: any[], projectNumbers: any[], projectStatuses: any[]) {
    const uniqueTitlesMap = new Map<string, string>();
    const uniqueProjectMap = new Map<string, string>();
    projectTitles.map((title, index) =>  {
      if(!uniqueTitlesMap.has(title)) {
        uniqueTitlesMap.set(title,index.toString());
      }
    });

    this.rowsTitleSuggestionsNonProject = Array.from(uniqueTitlesMap, ([title, key]) => ({
      key: key,
      title: title
    }));

    projectNumbers.map((title, index) => {
      if (!uniqueProjectMap.has(title)) {
        uniqueProjectMap.set(title,index.toString());
      }
    });

    this.clarityProjectNumbersSuggestionsNonProject = Array.from(uniqueProjectMap, ([title, key]) => ({
      key: key,
      title: title
    }));
    
    const formFields = JSON.stringify(this.queryParamNonProject.FormFields);
    if (isClientLoad || formFields.includes('CommitmentTitle') || formFields.includes('ClarityProjectNumber')) {
      this.rowsStatusSuggestionsNonProject = projectStatuses.map((title ,index) => ({
        key : (index).toString(),
        name: title
      }));
        
      const orderedStates = [
        "Not Started",
        "In Planning",
        "In Progress",
        "Delivered",
        "Completed",
        "Fiserv - On Hold",
        "Client - On Hold",
        "On Hold",
        "Canceled"       
      ];
      this.commitmentStatusesSuggestionsNonProject = [];
      this.commitmentStatusesSuggestionsNonProject.push({
        id: '',
        name: 'All'
      });
      for (const state of orderedStates) {
        const currentStatus = this.rowsStatusSuggestionsNonProject.find(x => x.name === state);
        if (currentStatus) {
          const stateIndex = orderedStates.indexOf(state);
          this.commitmentStatusesSuggestionsNonProject.push({
            id: stateIndex.toString(),
            name: currentStatus.name
          });
        }
      }
      this.commitmentStatusListNonProject = this.commitmentStatusListNonProject.filter(x => this.commitmentStatusesSuggestionsNonProject.some(c => c.id === x));
      this.isClientLoad = false;
    }
  }

  getPaginatedManualRows() {
    const startIndex = (this.manualRowsPage - 1)* this.manualRowsPerPage;
    const endIndex = startIndex + this.manualRowsPerPage;
    this.paginatedManualRows = this.manualRows.slice(startIndex,endIndex);
  }
  
  onManualRowsPageChange(page: number):void{
    this.manualRowsPage = page;
    this.getPaginatedManualRows();
  }

  getPaginatedAllRows() {
    const startIndexAll = (this.projectRowsPage - 1)* this.projectRowsPerPage;
    const endIndexAll = startIndexAll + this.projectRowsPerPage;
    this.paginatedProjectRows = this.projectRows.slice(startIndexAll,endIndexAll);
  }
  
  onAllRowsPageChange(page: number):void{
    this.projectRowsPage = page;
    this.getPaginatedAllRows();
  }
  
  graphInit() {
    var date = new Date();
    let lastYearDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.labels = [];
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    this.monthlyPData = [];
    this.monthlyData = [];
    this.counter = 0;
    lastYearDate.setMonth(lastYearDate.getMonth() - 12);
    for (let i = 0; i < 13; i++) {
      this.labels.push(`${monthNames[lastYearDate.getMonth()]}-${lastYearDate.getFullYear()}`)
      for (let k in this.slaData) {
        if (!this.slaData[k].Data) {
          this.slaData[k].Data = [];
          this.slaData[k].pData = [];
        }
        let data_to_push = 0;
        let performance_data_to_push = 0;
        let slaCat = this.slaData[k];
        let slaPerCat = this.slaData[k]['p']
        if (slaCat[lastYearDate.getFullYear()] && slaCat[lastYearDate.getFullYear()][lastYearDate.getMonth()]) {
          data_to_push = parseInt(slaCat[lastYearDate.getFullYear()][lastYearDate.getMonth()]);
          this.counter = this.counter + 1;
        }
        let index = `${lastYearDate.getFullYear()}.${lastYearDate.getMonth()}`;
        if (!this.monthlyData[index])
          this.monthlyData[index] = 0;

        this.monthlyData[index] = this.monthlyData[index] + data_to_push;
        this.slaData[k].Data.push(data_to_push)
        if (slaPerCat[lastYearDate.getFullYear()] && slaPerCat[lastYearDate.getFullYear()][lastYearDate.getMonth()])
          performance_data_to_push = parseInt(slaPerCat[lastYearDate.getFullYear()][lastYearDate.getMonth()]);

        if (!this.monthlyPData[index])
          this.monthlyPData[index] = 0;

        this.monthlyPData[index] = parseInt(this.monthlyPData[index]) + performance_data_to_push;

        if (slaPerCat[lastYearDate.getFullYear()] && slaPerCat[lastYearDate.getFullYear()][lastYearDate.getMonth()])
          this.slaData[k].pData.push(performance_data_to_push)
      }
      lastYearDate.setMonth(lastYearDate.getMonth() + 1);
    }
    this.createChart(this.labels, this.monthlyData, this.monthlyPData, 'all')
  }

  createChart(labels: any, monthArray: any = this.monthlyData, monthlyPdata: any = this.monthlyPData, performance: any = 'all') {
    let dataMonthArray = [];
    let label = ''
    for (let k in monthArray) {
      if (performance == 'all') {
        let monthCount: number = monthArray[k] as unknown as number;
        dataMonthArray.push((monthCount / this.slaProductSearch.length).toFixed(2));
      } else {
        dataMonthArray.push(monthArray[k]);
      }
    }
    let count: number = 0;
    let total: number = 0;
    for (let k in monthlyPdata) {
      count = count + 1;
      total = total + parseInt(monthlyPdata[k]);
    }
    if (performance == 'all') {
      count = this.counter;
      label = 'All';
    } else {
      label = performance
    }

    const slaPercentage: any = total / count;
    this.slaPercentage = parseFloat(slaPercentage).toFixed(2)

    const data = {
      labels: labels,
      datasets: [{
        label: '', // Name the series
        data: dataMonthArray, // Specify the data values array
        fill: true,
        borderColor: '#2196f3', // Add custom color border (Line)
        backgroundColor: 'orange', // Add custom color background (Points and Fill)
        borderWidth: 1 // Specify bar border width
      },
      ]
    };

    const options: any = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          onClick: function (e) {
            e.stopPropagation();
          },
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'white',
            fontSize: 0,
          },
        },
        animation: {
          duration: 0
        }
      },
    };
    if (this.chart) this.chart.destroy();

    const ctx: any = document.getElementById('areaChart') as HTMLElement;
    this.chart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: options,
    });
  }

  setOrderBy(sortingData) {
    if (sortingData) {
      
      this.queryParam.orderBy = (sortingData.isAsc) ? sortingData.key : sortingData.key + " desc";
    }
    else {
      delete this.queryParam.orderBy;
    }
  }

  setOrderByNonProject(sortingData) {
    if (sortingData) {
      this.queryParamNonProject.orderBy = (sortingData.isAsc) ? sortingData.key : sortingData.key + " desc";
    }
    else {
      delete this.queryParamNonProject.orderBy;
    }
  }

  applySorting(event) {
    this.sortingObject = event;
    this.setOrderBy(this.sortingObject);
    if(this.sortingObject.key.includes("isRisk")) {
      this.isRiskSort = true;
    }
    else {
      this.isRiskSort = false;
    }

    if (this.filterColumn !== this.sortingObject.key) {
      this.filterColumn = this.sortingObject.key;
    }
    this.clientCommitments();
  }
  
  applySortingNonProject(event) {
    this.sortingObject = event;
    this.setOrderByNonProject(this.sortingObject);
    if (this.sortingObject.key.includes("isRisk")) {
      this.isRiskSortNonProject = true;
    }
    else {
      this.isRiskSortNonProject = false;
    }

    if (this.filterColumnNonProject !== this.sortingObject.key) {
      this.filterColumnNonProject = this.sortingObject.key;
    }
    this.clientCommitmentsNonProject();
  }
  

  onSelectProjectNumberDone() {
    if (this.selectedProjectNumber) {
      this.searchFilter['ClarityProjectNumber'] = this.selectedProjectNumber;
    }
    else if (this.selectedProjectNumber === this._blankSelectText) {
      this.searchFilter['ClarityProjectNumber'] = '';
    }
    else {
      delete this.searchFilter['ClarityProjectNumber'];
    }
  }

  onSelectProjectNumberDoneNonProject() {
    if (this.selectedProjectNumberNonProject) {
      this.nonProjectSearchFilter['ClarityProjectNumber'] = this.selectedProjectNumberNonProject;
    }
    else if (this.selectedProjectNumberNonProject === this._blankSelectText) {
      this.nonProjectSearchFilter['ClarityProjectNumber'] = '';
    }
    else {
      delete this.nonProjectSearchFilter['ClarityProjectNumber'];
    }
  }

  onSelectTitleDone() {
    if (this.selectedTitle && this.selectedTitle != '') {
      this._previousTitleSelected = this.selectedTitle;
      this.searchFilter['CommitmentTitle'] = this.selectedTitle;
    }
    else if (this._previousTitleSelected && this._previousTitleSelected != '') {
      delete this.searchFilter['CommitmentTitle'];
    }
  }

  onSelectTitleDoneNonProject() {
    if (this.selectedTitleNonProject && this.selectedTitleNonProject != '') {
      this._previousTitleSelected = this.selectedTitleNonProject;
      this.nonProjectSearchFilter['CommitmentTitle'] = this.selectedTitleNonProject;
    }
    else if (this._previousTitleSelected && this._previousTitleSelected != '') {
      delete this.nonProjectSearchFilter['CommitmentTitle'];
    }
  }

  onClearText(type) {
    this.cancelSearch(type);
  }

  onClearTextNonProject(type) {
    this.cancelSearchNonProject(type);
  }
  
  onModelChange(type) {
    if (type == 'CommitmentTitle') {
      if (!this.selectedTitle || this.selectedTitle == '') {
        this.cancelSearch(type);
      }
    }
    else if (type == 'ClarityProjectNumber') {
      if (!this.selectedProjectNumber || this.selectedProjectNumber == '') {
        this.cancelSearch(type);
      }
    }
  }

  onModelChangeNonProject(type) {
    if (type == 'CommitmentTitle') {
      if (!this.selectedTitleNonProject || this.selectedTitleNonProject == '') {
        this.cancelSearchNonProject(type);
      }
    }
    else if (type == 'ClarityProjectNumber') {
      if (!this.selectedProjectNumberNonProject || this.selectedProjectNumberNonProject == '') {
        this.cancelSearchNonProject(type);
      }
    }
  }

  onStateChanged(event) {
    if (event && event.length > 0 && event.some(x => x.id !== '')) {
      if (event[event.length - 1].id === '') {
        this.commitmentStatusList = this.commitmentStatusesSuggestions.map(x => x.id);
      }
      else if (this._previousSelectedStatuses.every(x => x !== '') && event.every(x => x.id !== '') && event.length === this.commitmentStatusesSuggestions.filter(x => x.id !== '').length) {
        this.commitmentStatusList = this.commitmentStatusesSuggestions.map(x => x.id);
      }
      else if (this._previousSelectedStatuses.some(x => x === '') && event.every(x => x.id !== '') && event.length === this.commitmentStatusesSuggestions.filter(x => x.id !== '').length) {
        this.commitmentStatusList = [];
      }
      else {
        this.commitmentStatusList = event.map(x => x.id).filter(x => x !== '');
      }
    }
    else if (event && event.length === 1 && event[0].id === '') {
      this.commitmentStatusList = this.commitmentStatusesSuggestions.map(x => x.id);
    }

    this._previousSelectedStatuses = this.commitmentStatusList;
  }

  onStateChangedNonProject(event) {
    if (event && event.length > 0 && event.some(x => x.id !== '')) {
      if (event[event.length - 1].id === '') {
        this.commitmentStatusListNonProject = this.commitmentStatusesSuggestionsNonProject.map(x => x.id);
      }
      else if (this._previousSelectedStatuses.every(x => x !== '') && event.every(x => x.id !== '') && event.length === this.commitmentStatusesSuggestionsNonProject.filter(x => x.id !== '').length) {
        this.commitmentStatusListNonProject = this.commitmentStatusesSuggestionsNonProject.map(x => x.id);
      }
      else if (this._previousSelectedStatuses.some(x => x === '') && event.every(x => x.id !== '') && event.length === this.commitmentStatusesSuggestionsNonProject.filter(x => x.id !== '').length) {
        this.commitmentStatusListNonProject = [];
      }
      else {
        this.commitmentStatusListNonProject = event.map(x => x.id).filter(x => x !== '');
      }
    }
    else if (event && event.length === 1 && event[0].id === '') {
      this.commitmentStatusListNonProject = this.commitmentStatusesSuggestionsNonProject.map(x => x.id);
    }

    this._previousSelectedStatuses = this.commitmentStatusListNonProject;
  }

  getStateSelectedLabel() {
    const isSelectedAll = this.commitmentStatusList.indexOf('') >= 0;
    const selectedLabel = isSelectedAll ? 'All' : this.commitmentStatusList.length;
    return `${selectedLabel} item${this.commitmentStatusList.length > 1 || isSelectedAll ? 's' : ''}`;
  }

  getStateSelectedLabelNonProject() {
    const isSelectedAll = this.commitmentStatusListNonProject.indexOf('') >= 0;
    const selectedLabel = isSelectedAll ? 'All' : this.commitmentStatusListNonProject.length;
    return `${selectedLabel} item${this.commitmentStatusListNonProject.length > 1 || isSelectedAll ? 's' : ''}`;
  }

  initateFilterSearch(isClientLoad = false, isAdvanceSearch = false) {
    this.searchFlag = 1;
    const formFields: any = [];
    //this.initQueryParams();
    for (let key in this.searchFilter) {
      const searchValue = this.searchFilter[key];
      if (searchValue != '' && searchValue !== this._blankSelectText) {
        formFields.push({ 'apiKey': key, 'apiKeyValue': searchValue });
      }
      else if (key === 'ClarityProjectNumber' && searchValue == this._blankSelectText) {
        formFields.push({ 'apiKey': key, 'apiKeyValue': '' });
      }
    }

    for (let k in this.filterPage) {
      formFields.push({ 'apiKey': k, 'apiKeyValue': this.filterPage[k] })
    }

    formFields.push({ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' });
    this.queryParam.FormFields = JSON.stringify(formFields);
    
    this.clientCommitments(isClientLoad, isAdvanceSearch);
  }

  initateFilterSearchNonProject(isClientLoad = false, isAdvanceSearch = false) {
    this.searchFlag = 1;
    const formFields: any = [];
    //this.initQueryParams();
    for (let key in this.nonProjectSearchFilter) {
      const searchValue = this.nonProjectSearchFilter[key];
      if (searchValue != '' && searchValue !== this._blankSelectText) {
        formFields.push({ 'apiKey': key, 'apiKeyValue': searchValue });
      }
      else if (key === 'ClarityProjectNumber' && searchValue == this._blankSelectText) {
        formFields.push({ 'apiKey': key, 'apiKeyValue': '' });
      }
    }

    for (let k in this.filterPage) {
      formFields.push({ 'apiKey': k, 'apiKeyValue': this.filterPage[k] })
    }

    formFields.push({ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' });
    this.queryParamNonProject.FormFields = JSON.stringify(formFields);
    this.clientCommitmentsNonProject(isClientLoad, isAdvanceSearch);
  }

  getRmClients() {
    let suggestionSubscriber: any;
    const queryParam: IQuery = Object.assign({}, {});
    queryParam['searchClientBy'] = this.clientTypeSearch;
    if (this.isAdmin || this.isCCTAdmin) {
      suggestionSubscriber = this.pageTemplateService.getClientSuggestionsAdmin(queryParam);
    }
    else {
      suggestionSubscriber = this.pageTemplateService.getClientSuggestionsRm(queryParam);
    }

    suggestionSubscriber.subscribe((data) => {
      this.allClientOneViews = data;
      const clientData = data;
      const oneViews = [];
      var clients = [];
      clientData.forEach(item => {
        if (item.oneViewGroupName && !oneViews.some(x => x.id === item.oneViewGroupId)) {
          oneViews.push({
            id: item.oneViewGroupId,
            titleWithDuns: `${item.oneViewGroupName} ${this._oneViewSuffix}`,
            type: OneViewType 
          });
        }

        if (!clients.some(x => x.id === item.id)) {
          clients.push(item);
        }
      });
      if (this.isClientAdmin) {
        clients = clients.filter(c => c.oneViewGroupName == null);
      }      

      this.clientSuggestions = [...oneViews, ...clients];
      if (this.isClientAdmin && this.firstTime) {
        this.firstTime = false;
        this.clientText = this.clientSuggestions.find(r => r.id === this.selectedEntityId).titleWithDuns;
      }
    });
  }

  private heightCalculator(): void {
    const tabSection = document.getElementById('tabSection').clientHeight;

    document.getElementById('sideButton').style.height = (tabSection / 2 - 15) + 'px';
    document.getElementById('sideButton2').style.height = (tabSection / 2 - 17) + 'px';
    if (this.noteVisibilityCheck()) {
      document.getElementById('noteTextarea').style.height = (tabSection - (tabSection / 3)) + 'px';
    } else {
      document.getElementById('notePreTag').style.height = (tabSection - (tabSection / 3)) + 'px';
    }

    const temp15 = document.getElementById('tab15boxWrapper');
    if (temp15) {
      temp15.style.height = (tabSection - 32) + 'px';
    }
    const temp16 = document.getElementById('tab16boxWrapper');
    if (temp16) {
      temp16.style.height = (tabSection - 32) + 'px';
    }
  }

  private resetHeight(): void {
    document.getElementById('sideButton').style.height = '339px';
    document.getElementById('sideButton2').style.height = '337px';
    if (this.noteVisibilityCheck()) {
      document.getElementById('noteTextarea').style.height = '472px';
    } else {
      document.getElementById('notePreTag').style.height = '472px';
    }

    const temp15 = document.getElementById('tab15boxWrapper');
    if (temp15) {
      temp15.style.height = '676px';
    }
    const temp16 = document.getElementById('tab16boxWrapper');
    if (temp16) {
      temp16.style.height = '676px';
    }
  }

  clientSelected() {
    this.filterColumn = null;
    this.isClientLoad = true;
    this.selectedCEUsersForSearch = [];
    this.note = '';
    this.alertInput.message = '';
    this.resetHeight();
    const foundObj: any = this.projectService.fetchIdForClient(this.clientSuggestions, this.clientText);
    if (foundObj != -1) {
      if (foundObj.type === OneViewType) {
        // Set client id = view id. Set isOneViewSelected = true when an oneview is selected
        this.oneViewClientSuggestions = this.allClientOneViews.filter(x => x.oneViewGroupId === foundObj.id).map(x => {
          return {
            id: x.id,
            name: x.titleWithDuns
          }
        });
        this.isOneViewSelected = true;
        this.isClientLoadedPartofOneView = false;
        this.oneViewId = foundObj.id;
        this.selectedEntityId = foundObj.id;
        this.getUserData();
        this.loadOneViewNote();
      } else {
        this.isOneViewSelected = false;
        this.oneViewClientSuggestions = [];
        this.selectedOneViewClients = [];
        if (this.clientTypeSearch == 'duns') {
          this.selectedEntityId = foundObj.id;
          this.getUserData();
        }
        else {
          this.selectedEntityId = foundObj.gud;
        }
        this.checkIfClientPartofOneView();
      }
        
      this.checkRestrictedAdmin();
      this.initData(this.isClientLoad);
      if (this.isOneViewSelected == false) {
        this.loadClientNote();
        this.clientService.getClientDataById(this.selectedEntityId).subscribe(
          res => {
            this.isCustomClient = res.isSrategicCLient;
          }, error => { }
        );
      }
      if (this.isOneViewSelected == true) {
        this.oneViewService.get(this.selectedEntityId).subscribe(
          result => {
            var res = result.clients.filter(x => x.isStrategicClient == true);
            if (res.length > 0) {
              this.isCustomClient = true;
            }
            else {
              this.isCustomClient = false;
            }
          }, error => { }
        );
      }
    } else {
      this.selectedEntityId = -1;
      this.initData();
    }
  }

  oneViewClientsSelected() {
    this.getDataDateWise(false, true, true);
  }

  checkIfClientPartofOneView() {
    this.oneViewService.getByClientId(this.selectedEntityId).subscribe(
      result => {
        if (result.id > 0) {
          this.isClientLoadedPartofOneView = true;
        }
      }, error => { }
    );
  }

  getUserData() {
    if (this.isOneViewSelected == true) {
      this.clientService.getClientExecutiveUsersByOneViewGroupId(this.selectedEntityId)
        .subscribe(suggestions => {
          this.clientUserSuggestions = suggestions;
          this.selectAllForDropdownItems(this.clientUserSuggestions);
        },
          error => { this.errorMsg = error; });
    }
    else {
      this.clientService.getClientExecutiveUsersByClientId(this.selectedEntityId)
        .subscribe(suggestions => {
          this.clientUserSuggestions = suggestions;
          this.selectAllForDropdownItems(this.clientUserSuggestions);
        },
          error => { this.errorMsg = error; });
    }
  }

  checkRestrictedAdmin() {
    if (this.userData.isRestrictedAdmin === true) {
      let isExists: boolean = false;
      let sortOrder = ['Read', 'Write'];
      let groups = this.userData.groups.sort((a, b) => { return sortOrder.indexOf(a.accessLevel) - sortOrder.indexOf(b.accessLevel); });

      for (let g in groups) {
        isExists = false;

        if (this.selectedEntityId > 0) {
          if (groups[g].clients.some(clnt => clnt.clientId === this.selectedEntityId)) {
            isExists = true;
          }
        }

        if (isExists) {
          this.isRestrictedAdmin = groups[g].accessLevel === "Write" ? true : false;
        }
      }
    }
  }

  exportExcel() {
    const queryParam = this.generateQueryParamForExporting(this.searchFilter);
    if (!queryParam.orderBy || queryParam.orderBy.includes('isRisk')) {
      this.exportRiskExcel(queryParam);
    }
    else {
      this.exportExcelNonRisk(queryParam);
    }
  }

  exportExcelNonRisk(queryParam) {
    const exportResponsesRed = [];
    const exportResponsesGreen = [];
    const exportResponsesBlank = [];
    
    const statusOrder: {[key:string]:number} = {
      'In Progress': 1,
      'In Planning': 2,
      'Not Started': 3,
      'Fiserv - On Hold': 4,
      'Client - On Hold': 5,
      'On Hold': 6,
      'Delivered': 7,
      'Completed': 8,
      'Cancelled': 9      
    };
    
    const exportResponses = [];
    this.pageTemplateService.exportClientdashboardCommitments(this.selectedEntityId, queryParam).subscribe(responses => {

      let exportResponseList = responses.body as ICommitments;
      let len = Object.keys(exportResponseList).length;
      let j = 0;
      for (let i = 0; i < len; i++) {
        const commitmentStatus = exportResponseList[i]['commitmentStatus'];
        const plannedCommittedDeliveryDate = exportResponseList[i]['plannedCommittedDeliveryDate'];
        const isManual = exportResponseList[i]['datasource'] === 'manual';
        if(!isManual || (isManual && exportResponseList[i]['clarityProjectNumber'].includes('PR'))) {
          const originalDeliveryDate = exportResponseList[i]['originalDeliveryDate'];
          exportResponses[j] = [];
          exportResponses[j]['Status'] = exportResponseList[i]['riskStatus'];
          exportResponses[j]['ProjectNumber'] = exportResponseList[i]['clarityProjectNumber'];
          exportResponses[j]['Title'] = exportResponseList[i]['commitmentTitle'];

          exportResponses[j]['Stage'] = commitmentStatus;
          exportResponses[j]['Original Delivery Date'] = (!originalDeliveryDate || originalDeliveryDate.includes('1900')) ? '— — —' : this._datePipe.transform(originalDeliveryDate.trim(), 'MM/dd/yyyy');
          exportResponses[j]['Target Delivery Date'] = plannedCommittedDeliveryDate;
          exportResponses[j]['Date Change By Stakeholder'] = this.getStakeholderDaysChangedForExportExcel(exportResponseList[i]);
          
          const isRisk = exportResponseList[i]['isRisk'] === 'true';
          if (isRisk && (commitmentStatus == 'In Progress' || commitmentStatus === 'Fiserv - On Hold' || commitmentStatus == 'In Planning' || commitmentStatus == 'Not Started')) {
            exportResponses[j]['Status'] = 'Red';
          } else if (commitmentStatus == 'In Progress' && !isRisk) {
            exportResponses[j]['Status'] = 'Green';
          } 
          else {
            exportResponses[j]['Status'] = 'Gray';
          }

          if (exportResponses[j]['Stage'] == 'Completed' || exportResponses[j]['Stage'] == 'Canceled' 
                      || exportResponses[j]['Stage'] == 'On Hold' || exportResponses[j]['Stage'] == 'Client - On Hold') {
            exportResponses[j]['Status'] = 'Gray';
          }
          exportResponses[j]['Product'] = exportResponseList[i]['product']?.replace(/\[|\]/g, '')?.trim();
          j++;
          
        }
      }
      this.excelService.exportAsExcelFile(exportResponses, this.clientText + '_commitments');
    }, (error) => { this.errorMsg = error; });
  }

  exportRiskExcel(queryParam) {
    let exportResponses = [];
    const exportResponsesRed = [];
    const exportResponsesGreen = [];
    const exportResponsesBlank = [];
    
    const statusOrder: {[key:string]:number} = {
      'In Progress': 1,
      'In Planning': 2,
      'Not Started': 3,
      'Fiserv - On Hold': 4,
      'Client - On Hold': 5,
      'On Hold': 6,
      'Delivered': 7,
      'Completed': 8,
      'Cancelled': 9      
    };

    this.pageTemplateService.exportClientdashboardCommitments(this.selectedEntityId, queryParam).subscribe(responses => {
      let exportResponseList = responses.body as ICommitments;
      let len = Object.keys(exportResponseList).length;
      let j = 0; let k = 0; let l = 0;
      console.log('exportResponseList.len: ' + len.toString());
      for (let i = 0; i < len; i++) {
        const commitmentStatus = exportResponseList[i]['commitmentStatus'].trim();
        const plannedCommittedDeliveryDate = exportResponseList[i]['plannedCommittedDeliveryDate'];
        const isManual = exportResponseList[i]['datasource'] === 'manual';
        const isRisk = exportResponseList[i]['isRisk'] === 'true';
       // console.log('plannedCommittedDeliveryDate' + plannedCommittedDeliveryDate + ', status: ' + commitmentStatus + ', isRisk: ' + isRisk + ', isManual: ' + isManual);
        
        if (!isManual || (isManual && exportResponseList[i]['clarityProjectNumber'].includes('PR'))) {
          //if (isRisk && (commitmentStatus == 'In Progress'|| commitmentStatus === 'Fiserv - On Hold' || commitmentStatus === 'In Planning' || commitmentStatus === 'Not Started') 
          if (isRisk && (commitmentStatus == 'In Progress'|| commitmentStatus === 'Fiserv - On Hold' || commitmentStatus === 'In Planning' || commitmentStatus === 'Not Started'))  {
            const originalDeliveryDate = exportResponseList[i]['originalDeliveryDate'];
            exportResponsesRed[j] = [];
            exportResponsesRed[j]['Status'] = 'Red'; //exportResponseList[i]['riskStatus'];
            exportResponsesRed[j]['ProjectNumber'] = exportResponseList[i]['clarityProjectNumber'];
            exportResponsesRed[j]['Title'] = exportResponseList[i]['commitmentTitle'];
            
            exportResponsesRed[j]['Stage'] = commitmentStatus;
            exportResponsesRed[j]['Original Delivery Date'] = (!originalDeliveryDate || originalDeliveryDate.includes('1900')) ? '— — —' : this._datePipe.transform(originalDeliveryDate.trim(), 'MM/dd/yyyy');
            exportResponsesRed[j]['Target Delivery Date'] = plannedCommittedDeliveryDate;
            exportResponsesRed[j]['Date Change By Stakeholder'] = this.getStakeholderDaysChangedForExportExcel(exportResponseList[i]);
            exportResponsesRed[j]['Product'] = exportResponseList[i]['product']?.replace(/\[|\]/g, '')?.trim();                        
           
            j++;
            exportResponsesRed.sort((a,b) => {
              return statusOrder[a['Stage']] - statusOrder[b['Stage']];
            });
          }        
          else if (commitmentStatus == 'In Progress' && !isRisk){
            const originalDeliveryDate = exportResponseList[i]['originalDeliveryDate'];
            exportResponsesGreen[k] = [];
            exportResponsesGreen[k]['Status'] = exportResponseList[i]['riskStatus'];
            exportResponsesGreen[k]['ProjectNumber'] = exportResponseList[i]['clarityProjectNumber'];
            exportResponsesGreen[k]['Title'] = exportResponseList[i]['commitmentTitle'];
            exportResponsesGreen[k]['Stage'] = commitmentStatus;
            exportResponsesGreen[k]['Original Delivery Date'] = (!originalDeliveryDate || originalDeliveryDate.includes('1900')) ? '— — —' : this._datePipe.transform(originalDeliveryDate.trim(), 'MM/dd/yyyy');
            exportResponsesGreen[k]['Target Delivery Date'] = plannedCommittedDeliveryDate;
            exportResponsesGreen[k]['Date Change By Stakeholder'] = this.getStakeholderDaysChangedForExportExcel(exportResponseList[i]);
            exportResponsesGreen[k]['Status'] = 'Green';            
            exportResponsesGreen[k]['Product'] = exportResponseList[i]['product']?.replace(/\[|\]/g, '')?.trim();
            k++;
            exportResponsesGreen.sort((a,b) => {
              return statusOrder[a['Stage']] - statusOrder[b['Stage']];
            });
          }
          else {
            const originalDeliveryDate = exportResponseList[i]['originalDeliveryDate'];
            exportResponsesBlank[l] = [];
            exportResponsesBlank[l]['Status'] = exportResponseList[i]['riskStatus'];
            exportResponsesBlank[l]['ProjectNumber'] = exportResponseList[i]['clarityProjectNumber'];
            exportResponsesBlank[l]['Title'] = exportResponseList[i]['commitmentTitle'];
            exportResponsesBlank[l]['Stage'] = commitmentStatus;
            exportResponsesBlank[l]['Original Delivery Date'] = (!originalDeliveryDate || originalDeliveryDate.includes('1900')) ? '— — —' : this._datePipe.transform(originalDeliveryDate.trim(), 'MM/dd/yyyy');
            exportResponsesBlank[l]['Target Delivery Date'] = plannedCommittedDeliveryDate;
            exportResponsesBlank[l]['Date Change By Stakeholder'] = this.getStakeholderDaysChangedForExportExcel(exportResponseList[i]);
            exportResponsesBlank[l]['Status'] = 'Gray';
            exportResponsesBlank[l]['Product'] = exportResponseList[i]['product']?.replace(/\[|\]/g, '')?.trim();            
            l++;
            exportResponsesBlank.sort((a,b) => {
                return statusOrder[a['Stage']] - statusOrder[b['Stage']];
            });
          }
        }
      }
      if (!queryParam.orderBy || queryParam.orderBy.includes('isRisk desc')) {
        exportResponses = [...exportResponsesRed,...exportResponsesGreen,...exportResponsesBlank];
      }
      else{
        exportResponses = [...exportResponsesBlank,...exportResponsesGreen,...exportResponsesRed];
      }
      this.excelService.exportAsExcelFile(exportResponses, this.clientText + '_commitments');
    }, (error) => { this.errorMsg = error; });
  }

  exportExcelManual() {
    const queryParam = this.generateQueryParamForExportingNonProject(this.nonProjectSearchFilter);
    if (!this.queryParamNonProject.orderBy || this.queryParamNonProject.orderBy.includes('isRisk')) {
      this.exportRiskExcelNonProject(queryParam);
    }
    else {
      this.exportExcelNonRiskNonProject(queryParam);
    }
  }

  exportExcelNonRiskNonProject(queryParam) {
    const exportResponsesManual = [];
    this.pageTemplateService.exportClientdashboardCommitments(this.selectedEntityId, queryParam).subscribe(responses => {
      let exportResponseList = responses.body as ICommitments;
      let len = Object.keys(exportResponseList).length;
      let j = 0;
      for (let i = 0; i < len; i++) {
        const commitmentStatus = exportResponseList[i]['commitmentStatus'];
        const plannedCommittedDeliveryDate = exportResponseList[i]['plannedCommittedDeliveryDate'];
        const isManual = exportResponseList[i]['datasource'] === 'manual';
        if (isManual && !exportResponseList[i]['clarityProjectNumber'].includes('PR')) {
          const originalDeliveryDate = exportResponseList[i]['originalDeliveryDate'];
          exportResponsesManual[j] = [];
          exportResponsesManual[j]['Status'] = exportResponseList[i]['riskStatus'];
          exportResponsesManual[j]['ProjectNumber'] = exportResponseList[i]['clarityProjectNumber'];
          exportResponsesManual[j]['Title'] = exportResponseList[i]['commitmentTitle'];

          exportResponsesManual[j]['Stage'] = commitmentStatus;
          exportResponsesManual[j]['Original Delivery Date'] = (!originalDeliveryDate || originalDeliveryDate.includes('1900')) ? '— — —' : this._datePipe.transform(originalDeliveryDate.trim(), 'MM/dd/yyyy');
          exportResponsesManual[j]['Target Delivery Date'] = plannedCommittedDeliveryDate;
          exportResponsesManual[j]['Date Change By Stakeholder'] = this.getStakeholderDaysChangedForExportExcel(exportResponseList[i]);
          
          const isRisk = exportResponseList[i]['isRisk'] === 'true';
          if (isRisk && (commitmentStatus == 'In Progress' || commitmentStatus == 'In Planning' || commitmentStatus === 'Fiserv - On Hold' || commitmentStatus == 'Not Started'))  {
            exportResponsesManual[j]['Status'] = 'Red';
          } else if (commitmentStatus == 'In Progress' && !isRisk) {
            exportResponsesManual[j]['Status'] = 'Green';
          }  
          else {
            exportResponsesManual[j]['Status'] = 'Gray';
          }

          if (exportResponsesManual[j]['Stage'] == 'Completed' || exportResponsesManual[j]['Stage'] == 'Canceled' || exportResponsesManual[j]['Stage'] == 'On Hold' || exportResponsesManual[j]['Stage'] == 'Client - On Hold') {
            exportResponsesManual[j]['Status'] = 'Gray';
          }
          
          exportResponsesManual[j]['Product'] = exportResponseList[i]['product']?.replace(/\[|\]/g, '')?.trim();
          j++;
        }
      }
      this.excelService.exportAsExcelFile(exportResponsesManual, this.clientText + '_manualcommitments');
    }, (error) => { this.errorMsg = error; });
  }

  exportRiskExcelNonProject(queryParam) {
    let exportResponsesManual = [];
    const exportResponsesRedManual = [];
    const exportResponsesGreenManual = [];
    const exportResponsesBlankManual = [];
    const statusOrder: {[key:string]:number} = {
      'In Progress': 1,
      'In Planning': 2,
      'Not Started': 3,
      'Fiserv - On Hold': 4,
      'Client - On Hold': 5,
      'On Hold': 6,
      'Delivered': 7,
      'Completed': 8,
      'Cancelled': 9 
    };
    
    this.pageTemplateService.exportClientdashboardCommitments(this.selectedEntityId, queryParam).subscribe(responses => {
      let exportResponseList = responses.body as ICommitments;
      let len = Object.keys(exportResponseList).length;
      let j = 0; let k = 0; let l = 0;
      for (let i = 0; i < len; i++) {
        const commitmentStatus = exportResponseList[i]['commitmentStatus'];
        const plannedCommittedDeliveryDate = exportResponseList[i]['plannedCommittedDeliveryDate'];
        const isManual = exportResponseList[i]['datasource'] === 'manual';
        const isRisk = exportResponseList[i]['isRisk'] === 'true';
        if (isManual && !exportResponseList[i]['clarityProjectNumber'].includes('PR')) {
          if (isRisk && (commitmentStatus == 'In Progress' || commitmentStatus == 'In Planning' || commitmentStatus == 'Not Started' || commitmentStatus === 'Fiserv - On Hold')) {
            const originalDeliveryDate = exportResponseList[i]['originalDeliveryDate'];
            exportResponsesRedManual[j] = [];
            exportResponsesRedManual[j]['Status'] = exportResponseList[i]['riskStatus'];
            exportResponsesRedManual[j]['ProjectNumber'] = exportResponseList[i]['clarityProjectNumber'];
            exportResponsesRedManual[j]['Title'] = exportResponseList[i]['commitmentTitle'];

            exportResponsesRedManual[j]['Stage'] = commitmentStatus;
            exportResponsesRedManual[j]['Original Delivery Date'] = (!originalDeliveryDate || originalDeliveryDate.includes('1900')) ? '— — —' : this._datePipe.transform(originalDeliveryDate.trim(), 'MM/dd/yyyy');
            exportResponsesRedManual[j]['Target Delivery Date'] = plannedCommittedDeliveryDate;
            exportResponsesRedManual[j]['Date Change By Stakeholder'] = this.getStakeholderDaysChangedForExportExcel(exportResponseList[i]);
            exportResponsesRedManual[j]['Status'] = 'Red';            
            if (exportResponsesRedManual[j]['Stage'] == 'Completed' || exportResponsesRedManual[j]['Stage'] == 'Canceled' || exportResponsesRedManual[j]['Stage'] == 'On Hold' || exportResponsesRedManual[j]['Stage'] == 'Client - On Hold') {
              exportResponsesRedManual[j]['Status'] = 'Gray';
            }
            exportResponsesRedManual[j]['Product'] = exportResponseList[i]['product']?.replace(/\[|\]/g, '')?.trim();
            j++;
            exportResponsesRedManual.sort((a,b) => {
              return statusOrder[a['Stage']] - statusOrder[b['Stage']];
            });
          }
          else if (!isRisk || plannedCommittedDeliveryDate === 'To Be Scheduled') {
            const originalDeliveryDate = exportResponseList[i]['originalDeliveryDate'];
            exportResponsesGreenManual[k] = [];
            exportResponsesGreenManual[k]['Status'] = exportResponseList[i]['riskStatus'];
            exportResponsesGreenManual[k]['ProjectNumber'] = exportResponseList[i]['clarityProjectNumber'];
            exportResponsesGreenManual[k]['Title'] = exportResponseList[i]['commitmentTitle'];
            exportResponsesGreenManual[k]['Stage'] = commitmentStatus;
            exportResponsesGreenManual[k]['Original Delivery Date'] = (!originalDeliveryDate || originalDeliveryDate.includes('1900')) ? '— — —' : this._datePipe.transform(originalDeliveryDate.trim(), 'MM/dd/yyyy');
            exportResponsesGreenManual[k]['Target Delivery Date'] = plannedCommittedDeliveryDate;
            exportResponsesGreenManual[k]['Date Change By Stakeholder'] = this.getStakeholderDaysChangedForExportExcel(exportResponseList[i]);
            exportResponsesGreenManual[k]['Status'] = 'Green';
            if (exportResponsesGreenManual[k]['Stage'] == 'Completed' || exportResponsesGreenManual[k]['Stage'] == 'Canceled' || exportResponsesGreenManual[k]['Stage'] == 'On Hold' || exportResponsesGreenManual[k]['Stage'] == 'Client - On Hold') {
              exportResponsesGreenManual[k]['Status'] = 'Gray';
            }
            exportResponsesGreenManual[k]['Product'] = exportResponseList[i]['product']?.replace(/\[|\]/g, '')?.trim();
            k++;
            exportResponsesGreenManual.sort((a,b) => {
              return statusOrder[a['Stage']] - statusOrder[b['Stage']];
            });
          }
          else {
            const originalDeliveryDate = exportResponseList[i]['originalDeliveryDate'];
            exportResponsesBlankManual[l] = [];
            exportResponsesBlankManual[l]['Status'] = exportResponseList[i]['riskStatus'];
            exportResponsesBlankManual[l]['ProjectNumber'] = exportResponseList[i]['clarityProjectNumber'];
            exportResponsesBlankManual[l]['Title'] = exportResponseList[i]['commitmentTitle'];
            exportResponsesBlankManual[l]['Stage'] = commitmentStatus;
            exportResponsesBlankManual[l]['Original Delivery Date'] = (!originalDeliveryDate || originalDeliveryDate.includes('1900')) ? '— — —' : this._datePipe.transform(originalDeliveryDate.trim(), 'MM/dd/yyyy');
            exportResponsesBlankManual[l]['Target Delivery Date'] = plannedCommittedDeliveryDate;
            exportResponsesBlankManual[l]['Date Change By Stakeholder'] = this.getStakeholderDaysChangedForExportExcel(exportResponseList[i]);
            exportResponsesBlankManual[l]['Status'] = 'Gray';
            exportResponsesBlankManual[l]['Product'] = exportResponseList[i]['product']?.replace(/\[|\]/g, '')?.trim();
            l++;
            exportResponsesBlankManual.sort((a,b) => {
                return statusOrder[a['Stage']] - statusOrder[b['Stage']];
            });
          }
        }
      }

      if (!queryParam.orderBy || queryParam.orderBy.includes('isRisk desc')) {
        exportResponsesManual = [...exportResponsesRedManual,...exportResponsesGreenManual,...exportResponsesBlankManual];
      }
      else {
        exportResponsesManual = [...exportResponsesBlankManual,...exportResponsesGreenManual,...exportResponsesRedManual];
      }
      this.excelService.exportAsExcelFile(exportResponsesManual, this.clientText + '_manualcommitments');
    }, (error) => { this.errorMsg = error; });
  }

  generateQueryParamForExporting(searchFilter: any) {
    const formFields: any = [];
    for (let key in searchFilter) {
      const searchValue = searchFilter[key];
      if (searchValue != '' && searchValue !== this._blankSelectText) {
        formFields.push({ 'apiKey': key, 'apiKeyValue': searchValue });
      }
      else if (key === 'ClarityProjectNumber' && searchValue == this._blankSelectText) {
        formFields.push({ 'apiKey': key, 'apiKeyValue': '' });
      }
    }

    for (let k in this.filterPage) {
      formFields.push({ 'apiKey': k, 'apiKeyValue': this.filterPage[k] })
    }

    formFields.push({ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' });
    const queryParam = {...this.queryParam};
    queryParam.FormFields = JSON.stringify(formFields);
    queryParam.isNonProject = false;
    if (this.isOneViewSelected && this.selectedOneViewClients && this.selectedOneViewClients.length > 0) {
      queryParam.selectedOneViewClientIds = this.selectedOneViewClients.join(",");
    }
    else {
      delete queryParam.selectedOneViewClientIds;
    }

    return queryParam;
  }

  generateQueryParamForExportingNonProject(searchFilter: any) {
    const formFields: any = [];
    for (let key in searchFilter) {
      const searchValue = searchFilter[key];
      if (searchValue != '' && searchValue !== this._blankSelectText) {
        formFields.push({ 'apiKey': key, 'apiKeyValue': searchValue });
      }
      else if (key === 'ClarityProjectNumber' && searchValue == this._blankSelectText) {
        formFields.push({ 'apiKey': key, 'apiKeyValue': '' });
      }
    }

    for (let k in this.filterPage) {
      formFields.push({ 'apiKey': k, 'apiKeyValue': this.filterPage[k] })
    }

    formFields.push({ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' });
    const queryParam = {...this.queryParamNonProject};
    queryParam.FormFields = JSON.stringify(formFields);
    queryParam.isNonProject = true;

    return queryParam;
  }

  public showDrawerForReviewTrailer(): void {
    if (this.selectedEntityId !== 0 && this.selectedEntityId !== -1) {
      $('#containerPage').scrollTop(0);
      $('#drawerDialog').removeClass('d-none');
      $('#drawerDialog').addClass('d-block');
      $('#containerPage').addClass('overflow-hidden');
      $('.tooltip').remove();
    }
  }

  public close(): void {
    $('#drawerDialog').removeClass('d-block');
    $('#drawerDialog').addClass('d-none');
    $('#containerPage').removeClass('overflow-hidden');
  }

  public navigateToPageResponse(): void {
    let clientCommitmentId = 0;
    if (environment.env === 'prod') {
      clientCommitmentId = 8;
    } else if (environment.env === 'app') {
      clientCommitmentId = 8;
    } else if (environment.env === 'dev') {
      clientCommitmentId = 14;
    }
    const url2 = '/self-service/pageTemplateR/' + clientCommitmentId + '/referesh';
    this.router.navigate([url2]);
  }

  /*******************/
  calanderCommitment(mode: string) {
    this.typeOfCommitmentCalander = mode;
    if (!this.commitmentCalendar && !this.dataCommitmentCalendarError && !this.isPageLoad) {
      console.log('returned')
      return;
    }

    this.commitmentCalendar = false;
    this.commitmentCalendarLabel = [];
    this.commitmentCalendarNumbers = [];
    this.commitmentCalendarData = [];
    this.tableData = null;
    if (this.selectedEntityId <= 0) {
      this.isPageLoad = false;
      this.commitmentCalendar = true;
      this.dataCommitmentCalendarError = false;
      return;
    }

    let type = '';
    if (mode == 'year') {
      type = 'Year';
    }
    if (mode == 'month') {
      type = 'Month'
    }
    if (mode == 'qtr') {
      type = 'Quarter'
    }
    
    const queryParam: IQuery = Object.assign({}, this.queryParam);
    queryParam['dateRangeType'] = type;
    if (this.selectedCEUsersForSearch) {
      queryParam.clientUserIds = this.selectedCEUsersForSearch.toString();
    }
    const formFields = [];
    for (let k in this.filterPage) {
      formFields.push({ 'apiKey': k, 'apiKeyValue': this.filterPage[k] })
    }
    
    formFields.push({ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' });
    queryParam.FormFields = JSON.stringify(formFields);

    const commitmentCalendarData_notStartedCommitments = [];
    const commitmentCalendarData_inPlanningCommitments = [];
    const commitmentCalendarData_inProgressCommitments = [];
    const commitmentCalendarData_onHoldCommitments = [];
    const commitmentCalendarData_canceledCommitments = [];
    const commitmentCalendarData_completedCommitments = [];
    const commitmentCalendarData_deliveredCommitments = [];
    this.dataCommitmentCalendarError = false;
    this.pageTemplateService.commitmentCalander(this.selectedEntityId, queryParam).subscribe(data => {
      for (let k in data) {
        if (this.typeOfCommitmentCalander == 'qtr') {
          this.commitmentCalendarLabel.push(`${data[k].plannedCommittedDeliveryDateYear}-${data[k].plannedCommittedDeliveryDateQuarter}`);
        }
        else if (this.typeOfCommitmentCalander == 'month') {
          this.commitmentCalendarLabel.push(`${data[k].plannedCommittedDeliveryDateYear}-${data[k].plannedCommittedDeliveryDateMonth}`);
        }
        else {
          this.commitmentCalendarLabel.push(data[k].plannedCommittedDeliveryDateYear);
        }

        this.commitmentCalendarNumbers.push(data[k].numberOfCommitments);
        commitmentCalendarData_notStartedCommitments.push(data[k].notStartedCommitments);
        commitmentCalendarData_inPlanningCommitments.push(data[k].inPlanningCommitments);
        commitmentCalendarData_inProgressCommitments.push(data[k].inProgressCommitments);
        commitmentCalendarData_onHoldCommitments.push(data[k].onHoldCommitments);
        commitmentCalendarData_canceledCommitments.push(data[k].canceledCommitments);
        commitmentCalendarData_completedCommitments.push(data[k].completedCommitments);
        commitmentCalendarData_deliveredCommitments.push(data[k].deliveredCommitments);
      }

      const data2 = [                    
        { 'label': 'Not Started', 'data': commitmentCalendarData_notStartedCommitments, 'backgroundColor': '#36454f' },
        { 'label': 'In Planning', 'data': commitmentCalendarData_inPlanningCommitments, 'backgroundColor': '#757575' },
        { 'label': 'In Progress', 'data': commitmentCalendarData_inProgressCommitments, 'backgroundColor': '#FF5E0E' },
        { 'label': 'Delivered', 'data': commitmentCalendarData_deliveredCommitments, 'backgroundColor': '#0080BF' },
        { 'label': 'Completed', 'data': commitmentCalendarData_completedCommitments, 'backgroundColor': '#0080BF' },
        { 'label': 'On Hold', 'data': commitmentCalendarData_onHoldCommitments, 'backgroundColor': '#FFC626' },
        { 'label': 'Canceled', 'data': commitmentCalendarData_canceledCommitments, 'backgroundColor': '#90d5ff' }
      ];

      this.commitMentCalendarGraph(this.commitmentCalendarLabel, data2);
      this.tableData = data2;
      this.commitmentCalendarData = data;
      this.isPageLoad = false;
      this.commitmentCalendar = true;
    }, (error) => {
      this.dataCommitmentCalendarError = true;
    });
  }

  /***Commitments By Products */
  commitmentsbyProduct() {
    this.isProductsLoading = true;
    const queryParam: IQuery = Object.assign({}, this.queryParam);
    const formFields = [];
    for (let k in this.filterPage) {
      formFields.push({ 'apiKey': k, 'apiKeyValue': this.filterPage[k] });
    }
    if (this.selectedCEUsersForSearch) {
      this.queryParam.clientUserIds = this.selectedCEUsersForSearch.toString();
    }
    formFields.push({ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' });
    queryParam.FormFields = JSON.stringify(formFields);
    this.ngShows = false;
    this.cBProductsError = false;
    if (this.selectedEntityId <= 0) {
      this.commitmentProducts = [];
      this.pagerObjectProduct = { "totalCount": 0, "pageSize": 0, "currentPage": 1, "totalPages": 0 };
      this.pageProducts = [];
      this.isProductsLoading = false;
      this.ngShows = true;
      return;
    }

    this.pageTemplateService.getCommitmentsbyProduct(this.selectedEntityId, queryParam).pipe(take(1)).subscribe(
      response => {
        if (response == null) {
          this.commitmentProducts = [];
        }
        else {
          this.commitmentProducts = response;
        }
        
        let totalPages = Math.floor(this.commitmentProducts.length / this._pageSizeProduct);
        this.ngShows = true;
        if ((totalPages * this._pageSizeProduct < this.commitmentProducts.length)) {
          totalPages = totalPages + 1;
        }
        this.pagerObjectProduct = { "totalCount": this.commitmentProducts.length, "pageSize": this._pageSizeProduct, "currentPage": 1, "totalPages": totalPages }
        this.pageWiseProduct(1);
        this.isProductsLoading = false;
      },
      (error) => {
        this.ngShows = true;
        this.cBProductsError = true;
        this.isProductsLoading = false;
      }
    );
  }
  changePageProduct(page) {
    this.pageWiseProduct(page)
  }
  pageWiseProduct(pageNumber) {
    this.pageProducts = [];
    const start = (pageNumber - 1) * this._pageSizeProduct;
    const end = pageNumber * this._pageSizeProduct;
    for (let k = start; k < end; k++) {
      if (this.commitmentProducts[k]) {
        this.commitmentProducts[k].productName = this.commitmentProducts[k].productName?.replace(/\[|\]/g, '').trim();
        this.commitmentProducts[k].productDescription = this.commitmentProducts[k].productDescription?.replace(/\[|\]/g, '').trim();
        this.pageProducts.push(this.commitmentProducts[k])
      }
    }
  }

  /**
   * *
   * @param labels
   * @param Month
   * @param type
   */
  commitMentCalendarGraph(labels, Month) {
    const ctx2: any = document.getElementById('areaChart2') as HTMLElement;
    const data = {
      labels: labels,
      datasets: Month
    };

    const options: any = {
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true
        }
      },
      animation: false,
      maintainAspectRatio: false,
      scaleLabel: function (label) {
        return Math.round(label.value);
      }, ticks: {
        precision: 0
      },
      plugins: {
        animation: {
          duration: 0
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            boxWidth: 10,
            padding: 5,
            fontColor: 'white',
            fontSize: 0,
          },
        },
      },
    };
    if (this.chart2) {
      this.chart2.destroy();
    }

    this.chart2 = new Chart(ctx2, {
      type: 'bar',
      data: data,
      options: options,
    });
  }

  getLoginTimestamp(user: IUser) {
    this.userService.getLoginData(user.userId).subscribe((data) => {
      moment.locale(this.content['locale']);
      this.LastLoginTimeStamp = moment.utc(data.body).local().format("dddd D MMMM YYYY HH:mm");
    },
    (error) => console.log("Error"));
  }

  openFilterPage() {
    this.filterActivePage = !this.filterActivePage;
  }

  openUserFilterPage() {
    this.filterUserActivePage = !this.filterUserActivePage;
  }

  renderPdfExport() {
    this.isPdfExport = true;
    const formFields: any = [];
    formFields.push({ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' });
    this.queryParam.FormFields = JSON.stringify(formFields);
    this.queryParam.needToSelectCommitmentNotes = true;
    this.queryParam.isNonProject = false;
    if (this.selectedCEUsersForSearch) {
      this.queryParam.clientUserIds = this.selectedCEUsersForSearch.toString();
    }
    this.queryParamNonProject.FormFields = JSON.stringify(formFields);
    this.queryParamNonProject.needToSelectCommitmentNotes = true;
    this.queryParamNonProject.isNonProject = true;
    const exportingTasks: Observable<Object>[] = [
      this.pageTemplateService.exportClientdashboardCommitments(this.selectedEntityId, this.queryParam),
      this.pageTemplateService.exportClientdashboardCommitments(this.selectedEntityId, this.queryParamNonProject)
    ];

    const commitmentStatuses = [      
      'Client - On Hold',
      'On Hold',
      'Delivered',
      'Completed',
      'Canceled',    
    ];
    forkJoin(exportingTasks).pipe(take(1)).subscribe((response) => {
      this.commitmentAllRows = [];
      this.commitmentManualRows = [];
      this.queryParam.needToSelectCommitmentNotes = false;
      const projectCommitments = response[0]['body'] as any[];
      projectCommitments.forEach((data) => {
        data.product = data.product?.replace(/\[|\]/g, '')?.trim();
        if (commitmentStatuses.includes(data.commitmentStatus) || 
            ((data.commitmentStatus === 'Fiserv - On Hold' || data.commitmentStatus === 'Not Started' || data.commitmentStatus === 'In Planning') && !data.isRisk)) {
          data.isRisk = 'blank';
        }
        data.originalDeliveryDate = (!data?.originalDeliveryDate?.trim() || data?.originalDeliveryDate?.includes('1900')) ? '— — —' : this._datePipe.transform(data.originalDeliveryDate.trim(), 'MM/dd/yyyy');
        data.totalOfDaysChanged = this.getStakeholderDaysChangedForExportPDF(data);
        const isManual = data.datasource.toLowerCase() === 'manual';
        if (isManual) {
          if (data.clarityProjectNumber != null && data.clarityProjectNumber.indexOf('PR') != -1) {
            this.commitmentAllRows.push(data);
          }
        }

        if (!isManual) {
          this.commitmentAllRows.push(data);
        } 
      });

      const manualCommitments = response[1]['body'] as any[];
      manualCommitments.forEach((data) => {
        data.product = data.product?.replace(/\[|\]/g, '')?.trim();
        if (commitmentStatuses.includes(data.commitmentStatus) ||
          ((data.commitmentStatus === 'Fiserv - On Hold' || data.commitmentStatus === 'Not Started' || data.commitmentStatus === 'In Planning') && !data.isRisk)) {
          data.isRisk = 'blank';
        }

        data.originalDeliveryDate = (!data?.originalDeliveryDate?.trim() || data?.originalDeliveryDate?.includes('1900')) ? '— — —' : this._datePipe.transform(data.originalDeliveryDate.trim(), 'MM/dd/yyyy');
        data.totalOfDaysChanged = this.getStakeholderDaysChangedForExportPDF(data);
        const isManual = data.datasource.toLowerCase() === 'manual';
        if (isManual) {
          if (data.clarityProjectNumber == null || data.clarityProjectNumber.indexOf('PR') == -1) {
            this.commitmentManualRows.push(data);
          }
        } 
      });

      if (!this.queryParam.orderBy || this.queryParam.orderBy.includes('isRisk')) {
        this.commitmentAllRows = this.sortByRisk(this.commitmentAllRows);
      }

      if (!this.queryParamNonProject.orderBy || this.queryParamNonProject.orderBy.includes('isRisk')) {
        this.commitmentManualRows = this.sortByRiskNonProject(this.commitmentManualRows);
      }
      
      this.commitmentAllRowsCount = this.commitmentAllRows.length;
      this.commitmentManualRowsCount = this.commitmentManualRows.length;
      setTimeout(() => {
        this.exportToPDF();
      }, 300);
    },
    (error) => {
      this.queryParam.needToSelectCommitmentNotes = false;
      this.queryParamNonProject.needToSelectCommitmentNotes = false;
      this.commitmentsError = true;
      this.isPdfExport = false;
    });
  }

  renderPPTExport() {
    this.isPdfExport = true;
    const formFields: any = [];
    formFields.push({ 'apiKey': 'isReviewed', 'apiKeyValue': 'true' });
    this.queryParam.FormFields = JSON.stringify(formFields);
    this.queryParam.needToSelectCommitmentNotes = true;
    this.queryParam.isNonProject = false;
    if (this.selectedCEUsersForSearch) {
      this.queryParam.clientUserIds = this.selectedCEUsersForSearch.toString();
    }
    this.queryParamNonProject.FormFields = JSON.stringify(formFields);
    this.queryParamNonProject.needToSelectCommitmentNotes = true;
    this.queryParamNonProject.isNonProject = true;
    const exportingTasks: Observable<Object>[] = [
      this.pageTemplateService.exportClientdashboardCommitments(this.selectedEntityId, this.queryParam),
      this.pageTemplateService.exportClientdashboardCommitments(this.selectedEntityId, this.queryParamNonProject)
    ];

    const commitmentStatuses = [      
      'Client - On Hold',
      'On Hold',
      'Delivered',
      'Completed',
      'Canceled'               
    ];
    forkJoin(exportingTasks).pipe(take(1)).subscribe((response) => {
      this.commitmentAllRows = [];
      this.commitmentManualRows = [];
      this.queryParam.needToSelectCommitmentNotes = false;
      const projectCommitments = response[0]['body'] as any[];
      projectCommitments.forEach((data) => {
        data.product = data.product?.replace(/\[|\]/g, '')?.trim();
        if (commitmentStatuses.includes(data.commitmentStatus) ||
        ((data.commitmentStatus === 'Fiserv - On Hold' || data.commitmentStatus === 'Not Started' || data.commitmentStatus === 'In Planning') && !data.isRisk)){
          data.isRisk = 'blank';
        }
        data.originalDeliveryDate = (!data?.originalDeliveryDate?.trim() || data?.originalDeliveryDate?.includes('1900')) ? '— — —' : this._datePipe.transform(data.originalDeliveryDate.trim(), 'MM/dd/yyyy');
        data.totalOfDaysChanged = this.getStakeholderDaysChangedForExportPDF(data);
        const isManual = data.datasource.toLowerCase() === 'manual';
        if (isManual) {
          if (data.clarityProjectNumber != null && data.clarityProjectNumber.indexOf('PR') != -1) {
            this.commitmentAllRows.push(data);
          }
        }

        if (!isManual) {
          this.commitmentAllRows.push(data);
        } 
      });

      const manualCommitments = response[1]['body'] as any[];
      manualCommitments.forEach((data) => {
        data.product = data.product?.replace(/\[|\]/g, '')?.trim();
        if (commitmentStatuses.includes(data.commitmentStatus) || 
          ((data.commitmentStatus === 'Fiserv - On Hold' || data.commitmentStatus === 'Not Started' || data.commitmentStatus === 'In Planning') && !data.isRisk)){
          data.isRisk = 'blank';
        }

        data.originalDeliveryDate = (!data?.originalDeliveryDate?.trim() || data?.originalDeliveryDate?.includes('1900')) ? '— — —' : this._datePipe.transform(data.originalDeliveryDate.trim(), 'MM/dd/yyyy');
        data.totalOfDaysChanged = this.getStakeholderDaysChangedForExportPDF(data);
        const isManual = data.datasource.toLowerCase() === 'manual';
        if (isManual) {
          if (data.clarityProjectNumber == null || data.clarityProjectNumber.indexOf('PR') == -1) {
            this.commitmentManualRows.push(data);
          }
        } 
      });

      if (!this.queryParam.orderBy || this.queryParam.orderBy.includes('isRisk')) {
        this.commitmentAllRows = this.sortByRisk(this.commitmentAllRows);
      }

      if (!this.queryParamNonProject.orderBy || this.queryParamNonProject.orderBy.includes('isRisk')) {
        this.commitmentManualRows = this.sortByRiskNonProject(this.commitmentManualRows);
      }
      
      this.commitmentAllRowsCount = this.commitmentAllRows.length;
      this.commitmentManualRowsCount = this.commitmentManualRows.length;
      setTimeout(() => {
        this.exportToPPT();
      }, 300);
    },
    (error) => {
      this.queryParam.needToSelectCommitmentNotes = false;
      this.queryParamNonProject.needToSelectCommitmentNotes = false;
      this.commitmentsError = true;
      this.isPdfExport = false;
    });
  }

  public deleteLatestCommitmentIsReviewed(): void {
    this.advanceSearch();
    this.advanceSearchNonProject();
    this.getCommitmentReviewsByClientId();
  }

  public hideUndoButton(): boolean {
    return this.selectedEntityId === 0 || this.selectedEntityId === -1 ||
      (!this.isCCTAdmin && !this.isAdmin && !this.isRestrictedAdmin && !this.isRm) ||
      this.commitmentReviews.length === 0 || this.isCCTAdminReadOnly;
  }

  public toggleNotpadSection(notepadSectionType: string): void {
    if (!this.isNotpadSectionVisible && notepadSectionType !== this.notepadSectionType) {
      this.isNotpadSectionVisible = true;
      document.getElementById('tabSection').classList.add('openNotepad');
      document.getElementById('notepadSection').classList.add('openNotepad');
      this.notepadSectionType = notepadSectionType;
    } else if (this.isNotpadSectionVisible && notepadSectionType === this.notepadSectionType) {
      document.getElementById('tabSection').classList.remove('openNotepad');
      document.getElementById('notepadSection').classList.remove('openNotepad');
      this.isNotpadSectionVisible = false;
      this.notepadSectionType = '';
    } else {
      this.notepadSectionType = notepadSectionType;
    }
  }

  private loadClientNote(): void {
    this.clientNoteService.getClientNote(this.selectedEntityId).subscribe(result => {
      this.note = (result.body as any).note;
    }, error => {
      this.alertInput.alertType = AlertType.Error;
      this.alertInput.message = error;
    });
  }

  public saveNote(): void {
    if (this.isOneViewSelected) {
      this.saveOneViewNote();
    } else {
      this.saveClientNote();
    }
  }

  public saveClientNote(): void {
    const clientNoteInput: ClientNoteInput = {
      clientId: this.selectedEntityId,
      note: this.note
    };
    this.clientNoteService.createOrUpdateClientNote(clientNoteInput).subscribe(() => {
      this.alertInput.alertType = AlertType.Success;
      this.alertInput.message = 'Note saved successfully.';
    }, error => {
      this.alertInput.alertType = AlertType.Error;
      this.alertInput.message = error;
    }).add(() => {
      this.scrollToElement('#alertInput');
    });
  }


  private loadCommitmentNote(): void {
    this.commitmentNoteForm.controls["noteForCommitment"].setValue("");
    this.alertInputPopup.message = '';
    this.noteForCommitment = '';
    this.commitmentNoteService.getCommitmentNote(this.detailsCommitment.responseId).subscribe(result => {
      this.noteForCommitment = (result.body as any).note;
      this.commitmentNoteForm.controls["noteForCommitment"].setValue(this.noteForCommitment)
    }, error => {
      this.alertInputPopup.alertType = AlertType.Error;
      this.alertInputPopup.message = error;
    });
  }

  public saveNoteForCommitment(): void {
    const commitmentNoteInput: CommitmentNoteInput = {
      pagetemplateresponseId: this.detailsCommitment.responseId,
      note: this.commitmentNoteForm.controls["noteForCommitment"].value
    };
    this.commitmentNoteService.createOrUpdateCommitmentNote(commitmentNoteInput).subscribe(() => {
      this.alertInputPopup.alertType = AlertType.Success;
      this.alertInputPopup.message = 'Commitment Note saved successfully.';
    }, error => {
      this.alertInputPopup.alertType = AlertType.Error;
      this.alertInputPopup.message = error;
    });
  }

  private loadOneViewNote(): void {
    this.oneViewService.getOneViewNote(this.selectedEntityId).subscribe(result => {
      this.note = (result.body as any).note;
    }, error => {
      this.alertInput.alertType = AlertType.Error;
      this.alertInput.message = error;
    });
  }

  public saveOneViewNote(): void {
    const noteInput: OneViewNoteInput = {
      oneViewId: this.selectedEntityId,
      note: this.note
    };
    this.oneViewService.updateOneViewNote(noteInput).subscribe(() => {
      this.alertInput.alertType = AlertType.Success;
      this.alertInput.message = 'Note saved successfully.';
    }, error => {
      this.alertInput.alertType = AlertType.Error;
      this.alertInput.message = error;
    }).add(() => {
      this.scrollToElement('#alertInput');
    });
  }

  private scrollToElement(elementId: string) {
    setTimeout(() => {
      const el = document.querySelector<HTMLElement>(elementId);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  }

  public setAlertMsgToEmpty(): void {
    this.alertInput.message = '';
  }

  public noteVisibilityCheck(): boolean {
    return this.isRm || this.isAdmin || (this.isCCTAdmin && !this.isCCTAdminReadOnly) || this.isSalesExecutive || this.isAccountManager || this.isRestrictedAdmin || this.isAccountExecutive ||this.isNewRM;
  }

  exportToPDF() {
    const statisticsExportingHtml = $('.statistics-container')[0].outerHTML;
    const deliveryExportingHtml = $('.delivery-container')[0].outerHTML;
    const commitmentsExportingHtml = $('.commitments-container')[0].outerHTML;
    const manualCommitmentsExportingHTML = $('.commitments-container')[1].outerHTML;
    const contactsExportingHtml = $('.contacts-container')[0].outerHTML;
    const productsExportingHtml = $('.products-container')[0].outerHTML;
    const clientNoteExportingHtml = $('.client-note-container')[0].outerHTML;

    const firstHtml = statisticsExportingHtml + deliveryExportingHtml;
    const query = {
      htmlList: [
        firstHtml,
        commitmentsExportingHtml, 
        manualCommitmentsExportingHTML, 
        productsExportingHtml, 
        clientNoteExportingHtml,
        contactsExportingHtml
      ],
      isOneViewSelected: this.isOneViewSelected
    };

    this.pageTemplateService.exportClientDashboardPDF(this.selectedEntityId, query).subscribe(response => {
      let newBlob = new Blob([response.body], { type: "application/pdf" });
      const nav = window.navigator as any;
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
      } else {
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        let fileTitle = this.clientText;
        if (this.isOneViewSelected) {
          fileTitle = fileTitle.replace(` ${this._oneViewSuffix}`, '');
        }
        link.download = `clientdashboard-${fileTitle}-${this.selectedEntityId}.pdf`;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);

        this.isPdfExport = false;
      }
    }, (error) => {
      this.isPdfExport = false;
      this.errorMsg = error;
    });
  }

  exportToPPT() {
    const statisticsExportingHtml = $('.statistics-container')[0].outerHTML;
    const deliveryExportingHtml = $('.delivery-container')[0].outerHTML;
    const commitmentsExportingHtml = $('.commitments-container')[0].outerHTML;
    const manualCommitmentsExportingHTML = $('.commitments-container')[1].outerHTML;
    const contactsExportingHtml = $('.contacts-container')[0].outerHTML;
    const productsExportingHtml = $('.products-container')[0].outerHTML;
    const clientNoteExportingHtml = $('.client-note-container')[0].outerHTML;

    const firstHtml = statisticsExportingHtml + deliveryExportingHtml;
    const query = {
      htmlList: [
        firstHtml,
        commitmentsExportingHtml, 
        manualCommitmentsExportingHTML, 
        productsExportingHtml, 
        clientNoteExportingHtml,
        contactsExportingHtml
      ],
      isOneViewSelected: this.isOneViewSelected
    };

    this.pageTemplateService.exportClientDashboardPPT(this.selectedEntityId, query).subscribe(response => {
      let newBlob = new Blob([response.body], { type: "application/pptx" });
      const nav = window.navigator as any;
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
      } else {
        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        let fileTitle = this.clientText;
        if (this.isOneViewSelected) {
          fileTitle = fileTitle.replace(` ${this._oneViewSuffix}`, '');
        }
        link.download = `clientdashboard-${fileTitle}-${this.selectedEntityId}.pptx`;
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);

        this.isPdfExport = false;
      }
    }, (error) => {
      this.isPdfExport = false;
      this.errorMsg = error;
    });
  }

  private dateFormat(value: string): string {
    const temp2 = moment(value).format('MM/DD/YYYY');
    if (!value || value === '' || value === 'To Be Scheduled' || value === 'Invalid date' || temp2 === 'Invalid date') {
      return value;
    } else {
      return temp2;
    }
  }

  getStakeholderDaysChangedForExportExcel(rowData: any): any {
    const result = `Client ${this.getStakeholderDaysChanged(rowData, 'Client')} \r\nFiserv ${this.getStakeholderDaysChanged(rowData, 'Fiserv')}`;
    return result;
  }

  getStakeholderDaysChangedForExportPDF(rowData: any): any {
    const result = `Client ${this.getStakeholderDaysChanged(rowData, 'Client')} <br>Fiserv ${this.getStakeholderDaysChanged(rowData, 'Fiserv')}`;
    return result;
  }

  getStakeholderDaysChanged(rowData: any, type: string): any {
    const result = '(0x / 0 days)';
    const totalDayChanges = rowData.totalOfDaysChanged;
    if (!totalDayChanges) return result;

    const array = totalDayChanges.split(this._totalDayChangesSign);
    const clientChanges = array.find(el => el.includes('Client'));
    const clientArray = clientChanges ? clientChanges.split(',') : [];
    const fiservChanges = array.find(el => el.includes('Fiserv'));
    const fiservArray = fiservChanges ? fiservChanges.split(',') : [];
    switch (type) {
      case 'Client':
        if (clientArray.length > 2) {
          const res = `(${clientArray[1]}x / ${clientArray[2]} days)`;
          return res;
        }
        break;
      case 'Fiserv':
        if (fiservArray.length > 2) {
          const res = `(${fiservArray[1]}x / ${fiservArray[2]} days)`;
          return res;
        }
        break;
    }

    return result;
  }

  addUserstoAdvanceSearch() {
    this.initData(true);
  }

  addUserstocancelSearch() {
    this.selectedCEUsersForSearch = [];
    this.initData(true);
  }

  public redirectToClientReviewTrailOverall(): void {
    if (this.isOneViewSelected) {
      this.redirectOneViewReviewTrailOverall();
    } else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`/ClientReviewTrailOverall`, { clientId: this.selectedEntityId, clientName: this.clientText }])
      );
      window.open(url, '_blank');
    }
    
  }

  public redirectOneViewReviewTrailOverall(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/oneview-review-trail/`, this.selectedEntityId])
    );
    window.open(url, '_blank');
  }

  clientTextChanges() {
    if (this.clientText === '') {
      this.isCustomClient = false;
      this.isOneViewSelected = false;
      this.oneViewClientSuggestions = [];
      this.selectedOneViewClients = [];
    }
  }

  isProjectManagerShown() {
    //if (this.userData.type !== 'Fiserv' ) {
    //  return false;
    //}

    //if (!this.detailsCommitment.projectManager || !this.detailsCommitment.projectManagerEmailAddress) {
    //  return false;
    //}

    const dataSource = this.detailsCommitment.datasource.toLowerCase();
    if (dataSource === 'ecrm') {
      return false;
    }

    if (dataSource === 'cims' || dataSource === 'servicenow') {
      return true;
    }

    if ((dataSource === 'clarity' || dataSource === 'manual') && this.detailsCommitment.dateStatus === 'Committed') {
      return true;
    }
  }

  isProjectManagerLinkShown() {
    const dataSource = this.detailsCommitment.datasource.toLowerCase();
    if (dataSource != 'ecrm') {
      if (this.detailsCommitment.projectManager && this.detailsCommitment.projectManagerEmailAddress) {
        return true;
      }
    }
    return false;
  }

  isProjectManagerTextShown() {
    const dataSource = this.detailsCommitment.datasource.toLowerCase();
    if (dataSource != 'ecrm') {
      if (this.detailsCommitment.projectManager && !this.detailsCommitment.projectManagerEmailAddress) {
        return true;
      }
    }
    return false;
  }

  isDeliveryOwnerShown() {
    if (!this.detailsCommitment.deliveryOwner || this.detailsCommitment.deliveryOwner === '')
      return false;
    return true;
  }

  isDeliveryOwnerTextShown() {
    if (this.detailsCommitment.deliveryOwner && !this.detailsCommitment.deliveryOwnerEmailAddress
      && this.detailsCommitment.deliveryOwnerEmailAddress === '') {
      return true;
    }
    return false;
  }

  isDeliveryOwnerLinkShown() {
    if (this.detailsCommitment.deliveryOwner && this.detailsCommitment.deliveryOwnerEmailAddress
      && this.detailsCommitment.deliveryOwnerEmailAddress !== '') {
      return true;
    }
    else {
      return false;
    }
  }

  public keyDown(keyboardEvent: KeyboardEvent): void {
    const focusableElements =
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
    const modal = document.getElementById('drawerDialog');
    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
    const focusableContent = modal.querySelectorAll(focusableElements);
    const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal
    if (keyboardEvent.shiftKey) { // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        (lastFocusableElement as HTMLElement).focus(); // add focus for the last focusable element
        keyboardEvent.preventDefault();
      }
    } else { // if tab key is pressed
      if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
        (firstFocusableElement as HTMLElement).focus(); // add focus for the first focusable element
        keyboardEvent.preventDefault();
      }
    }
  }

  public switchView(mode: boolean): void {
    this.showTable = mode;
  }

  private getClientTypeSearch(): any {
    const result = this.isOneViewSelected ? 'oneview' : this.clientTypeSearch;

    return result;
  }
}



function requiredIfValidator(predicate) {
  return (formControl => {
    if (!formControl.parent) {
      return null;
    }

    console.log('predicate()' + predicate())
    if (predicate()) {
      return Validators.required(formControl);
    }
    return null;
  })
}