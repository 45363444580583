export const ClarityFulfillmentDetailsFields = [
    'doYouWantToMapTheCommitmentWithClarityProject',
    'searchClarityProjectNumber',
    'clientTotalOfDaysChanged',
    'clientTotalOfDaysChangedCount',
    'fiservTotalOfDaysChanged',
    'fiservTotalOfDaysChangedCount',
    'projectKey',
    'projectNumber',
    'workStatus',
    'progress',
    'statusUpdate',
    'projectManager',
    'resourceManagerLevel4',
    'plannedBenefitAmount',
    'totalPlannedEffortHours',
    'dateStatus',
    'plannedBenefitCurrentYear',
    'lastDateChangeCommentary',
    'deliveryConfirmation',
    'projectStatus',
    'clarityStatusIntake',
    'plannedCommittedDeliveryDate',
    'displayPlannedDeliveryDate',
    'statusUpdateDate',
    'mc',
    'deliveryOwner',
    'resourceManagerLevel5',
    'plannedBenefitCurrencyCode',
    'hdnPlannedCommittedDeliveryDateYear',
    'totalForecastCost',
    'firstCommittedDeliveryDate',
    'hleRequirementStatus',
    'scope'
];

export const EcrmOpportunityDetailsFields = [
    'doYouWantToMapTheCommitmentWithECrmOpportunity',
    'searchOpportunityId',
    'eCrmAccountId',
    'opportunityStage',
    'opportunityType',
    'opportunityCreatedDate',
    'opportunityPlannedGoLiveDate',
    'projectKey',
    'opportunityId',
    'opportunityOwnerName',
    'opportunityClosedDate'
];

export const IgnoredClarityFieldsFromPopulating: string[] = ['searchClarityProjectNumber', 'doYouWantToMapTheCommitmentWithClarityProject'];
export const IgnoredEcrmFieldsFromPopulating: string[] = ['searchOpportunityId', 'doYouWantToMapTheCommitmentWithECrmOpportunity'];

export const CommitmentTypeOptions = [
    { name: 'Base/New Product Feature', value: 'Base/New Product Feature' },
    { name: 'Custom Feature and Enhancements', value: 'Custom Feature and Enhancements' },
    { name: 'Implementation', value: 'Implementation' },
    { name: 'Advisory', value: 'Advisory' },
    { name: 'Staff Augmentation', value: 'Staff Augmentation' }
];
export const CommitmentSubTypeOptions = [
    { name: 'Estimated Credits', value: 'Estimated Credits' },
    { name: 'Contractual', value: 'Contractual' },
    { name: 'Verbal', value: 'Verbal' }
];
export const StatusOptions = [
    { name: 'Not Started', value: 'Not Started'  },
    { name: 'In Planning', value: 'In Planning' },
    { name: 'In Progress', value: 'In Progress' },
    { name: 'Delivered', value: 'Delivered' },
    { name: 'Completed', value: 'Completed' },
    { name: 'Fiserv - On Hold', value: 'Fiserv - On Hold' },
    { name: 'Client - On Hold', value: 'Client - On Hold' },
    //{ name: 'On Hold', value: 'On Hold' },
    { name: 'Cancelled', value: 'Cancelled' }
];
export const plannedGoLiveDateChangeReasonOptions = [
    { name: 'Scope Creep', value: 'Scope Creep' },
    { name: 'Technical Issues', value: 'Technical Issues' },
    { name: 'Resource Constraints', value: 'Resource Constraints' },
    { name: 'Testing and Quality Assurance', value: 'Testing and Quality Assurance' },
    { name: 'Dependencies', value: 'Dependencies' },
    { name: 'Stakeholder Approval', value: 'Stakeholder Approval' },
    { name: 'Client Request', value: 'Client Request' },
    { name: 'Other', value: 'Other' }
];
export const OnHoldStakeholderOptions = [
    { name: 'Client - On Hold', value: 'Client - On Hold' },
    { name: 'Fiserv - On Hold', value: 'Fiserv - On Hold' }];
export const OnHoldReasonOptions = [
    { name: 'Capacity / Resource Gap', value: 'Capacity / Resource Gap' },
    { name: 'Prioritization Change', value: 'Prioritization Change' },
    { name: 'Product Gap / Integration Issue', value: 'Product Gap / Integration Issue' },
    { name: 'Legal Barrier', value: 'Legal Barrier' },
    { name: 'Infrastructure / Network', value: 'Infrastructure / Network' },
    { name: 'External Dependency', value: 'External Dependency' },
    { name: 'Other (Please Specify)', value: 'Other' }
];
export const RiskReasonOptions: any[] = [
    { name: 'Aggressive Schedule', value: 'Aggressive Schedule' },
    { name: 'Client Requested Project On Hold', value: 'Client Requested Project On Hold' },
    { name: 'Client Temperature is Red', value: 'Client Temperature is Red' },
    { name: 'Federal Regulatory Approval Required', value: 'Federal Regulatory Approval Required' },
    { name: 'Go Live/Delivery Date Changed', value: 'Go Live/Delivery Date Changed' },
    { name: 'Late Requirement/Design', value: 'Late Requirement/Design' },
    { name: 'On Hold By Fiserv', value: 'On Hold By Fiserv' },
    { name: 'Product Integration Delays', value: 'Product Integration Delays' },
    { name: 'Product Quality Issues', value: 'Product Quality Issues' },
    { name: 'Requirement Not Clear', value: 'Requirement Not Clear' },
    { name: 'Resource Availability', value: 'Resource Availability' },
    { name: 'Scope Not Finalized', value: 'Scope Not Finalized' },
    { name: 'Other (Please specify)', value: 'other' }
];

export const tooltips = {
    commitmentTitle: 'Enter short description of commitment',
    commitmentDescription: 'Describe the commitment made about the product features availability, customization,  implementation go live dates, SLAs committed, etc. ',
    clientTemperature: 'Guidance on how is Client perceiving the commitment based on prevailing or upcoming events.',
    buResponsibleForDeliveryIntakes: 'Select Business unit/s that are responsible for fulfillment of the commitment. </br>Auto populated from eCRM/Clarity in case of feed.</br>Need manual entry for Verbal commitment</br>',
    organizations: 'Select Business unit/s that are responsible for fulfillment of the commitment. </br>Auto populated from eCRM/Clarity in case of feed.</br>Need manual entry for Verbal commitment</br>',
    products: 'Start typing to select Fiserv recognized Product Name.</br>Auto populated from eCRM/Clarity in case of feed.</br>Need manual entry for Verbal commitment</br>',
    commitmentType: "<b>Base/New Product Feature</b> - Fiserv's commitment to deliver base product / new product feature that will be used by all clients and documented as part of Product Roadmap.</br><b>Customer Feature and Enhancement</b> - Fiserv's commitment to deliver custom functionality that will be used by specific clients.</br><b>Implementation</b> - Fiserv's commitment to configure, implement and deliver the standard product/s for a specific client.</br><b>Advisory</b> - Fiserv's commitment to provide advisory or consultation services for a niche area.</br><b>Staff Augmentation</b> - Fiserv's commitment to provide required resources to specific client.",
    commitmentSubType: '<b>Contractual</b> - Legal commitment contract is in place but not linked with any credits.</br><b>Estimated Credits</b> - Legal commitment contract is in place but linked with credits on missed commitment.</br><b>Verbal</b> - Legal commitment contract is not in place.',
    commitmentDate: 'The date on which the commitment was made to the client. Useful to track commitment aging.',
    commitmentAuthorizedBy: 'Input the name of the person from Fiserv who authorized the commitment.\n<br>Example : Sales / PS Rep name for Sales commitments. Account Executive / Delivery Leader for defects or delivery related commitments. ',
    status: "<b>Not Started:</b> Commitment is not started.</br><b>In Planning: </b>Commitment or opportunity is in planning phase.</br><b>In Progress:</b> Delivery/Implementation team is working on the commitment or project.</br><b>Delivered:</b> Delivery/Implementation team has delivered the commitment or project.</br><b>Completed:</b> Commitment is completed.</br><b>On Hold:</b> Commitment is on-hold.</br><b>Cancelled:</b> Commitment is cancelled.</br>",
    originalGoLiveDate: 'Original planned Go live date.',
    plannedGoLiveDate: 'Use this field to capture any revision to existing or planned Go live date.',
    selectedPlannedGoLiveDateChangeReason: '<b>Scope Creep:</b> As the project progresses, stakeholders may request additional features or changes to the project scope, which can delay the timeline and require additional development time.  </br><b>Technical Issues:</b> Technical problems such as software bugs or hardware failures can arise during the implementation process, which can cause delays in the go-live date.  </br><b>Resource Constraints:</b> If there are not enough resources (e.g., staff, budget, time) to complete the project on schedule, the go-live date may need to be pushed back.  </br><b>Testing and Quality Assurance:</b> Extensive testing and quality assurance processes are necessary to ensure that the product is stable, reliable, and meets the requirements. If issues are discovered during testing, additional time may be needed to address them.  </br><b>User Acceptance:</b> User acceptance testing is critical to ensure that the product meets the needs of end-users. If there are issues with user acceptance, changes may be required, which can impact the go-live date.  </br><b>Dependencies:</b> The implementation of a product may be dependent on other projects or third-party systems. If there are delays or issues with these dependencies, it can impact the go-live date.  </br><b>Stakeholder Approval:</b> Stakeholder approval is necessary for any major changes or decisions made during the implementation process. If there are delays in obtaining stakeholder approval, it can impact the go-live date.  </br><b>Client Request:</b> Client might request to change Go-live date for some internal reasons like consumer awareness by Marketing team, etc.  ',
    isCommitmentBreached: '<b>Missed Commitment:</b> Commitment clause/s not fulfilled as per contract.',
    fulfillmentDetails: 'Below fields are auto populated from Clarity source system. If any information requires update, please connect with Clarity stakeholder for your account.',
    searchClarityProjectNumber: 'Key in Project Identifier to identify the project associated with fulfillment of commitment described above.',
    plannedCommittedStartDate: 'Committed or Planned Start Date that is associated with Clarity PR#.',
    plannedCommittedDeliveryDate: 'Committed or Planned Delivery Date that is associated with Clarity PR#.',
    displayPlannedDeliveryDate: 'Committed or Planned Delivery Date that is associated with Clarity PR#.',
    statusUpdateDate: 'Planned Delivery Date that is associated with Clarity PR#. This is the most latest date when Fiserv has planned to deliver the commitment.',
    fulfillmentDetailsSrTool: 'Below fields are auto populated from SR Tool source system. If any information requires update, please connect with SR Tool stakeholder for your account.',
    srOpportunityId: 'SR Tool oppurtunity id.',
    projectManagerServiceNow: 'Project manager from service now',
    serviceNowProjectNumber: 'SR Tool Project Number/ Ids',
    opportunityDetailsPanel: 'Below fields are auto populated from eCRM source system. If any information requires update, please connect with eCRM stakeholder for your account.',
    searchOpportunityId: 'Key in eCRMAccountId to review opportunity details and link to a commitment.',
    opportunityOwnerName: 'Short description of commitment'
};

export const createManualAsDraftRequiredFields = [
    'commitmentTitle',
    'clientName',
    'clientDunsNumber',
    'commitmentType',
    'status'
  ];