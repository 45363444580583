import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICommitmentIntakeDto } from '../models/commitment-intake-d-t-o';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-commitment-intake-delete-modal',
  templateUrl: './commitment-intake-delete-modal.component.html',
  styleUrls: ['./commitment-intake-delete-modal.component.css']
})
export class CommitmentIntakeDeleteModalComponent {
  public blueThemeMode = false;
  @Input()
  public item: ICommitmentIntakeDto = null;

  @Output()
  private onDelete = new EventEmitter<ICommitmentIntakeDto>();

  constructor(
    private userService: UserService) {
    this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r);
  }

  public onDeleteClicked(): void {
    this.onDelete.emit(this.item);
  }
}
