import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class CommitmentReviewTrailService {
  private _commitmentintakereviewtrailsBaseUrl: string = "commitmentintakereviewtrails";

  constructor(private http: HttpClient) { }

  public getReviewTrailsByClientDunsNumber(clientDunsNumber: string) {
    const options = { 'refresh': 'true' };
    return this.http.get(`${this._commitmentintakereviewtrailsBaseUrl}/${clientDunsNumber}`, { headers: options, observe: 'response' });
  }

  public getReviewTrailDetails(projectKey: string) {
    const options = { 'refresh': 'true' };
    return this.http.get(`${this._commitmentintakereviewtrailsBaseUrl}/details/${projectKey}`, { headers: options, observe: 'response' });
  }
}