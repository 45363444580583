<div id="mapFulfilmentMainDiv" class="map-dw d-done">
    <div tabindex="-1" aria-labelledby="addContacts" aria-expanded="true" role="region"
        class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open">
        <div class="drawer-contents commitment-dw  col-xs-12 col-sm-12 col-md-12 ">
            <div class="dw-header">
                <div class="modal-header-container">
                    <button type="button" data-dismiss="modal" tabindex="0" autofocus="" aria-label="Close popup"
                        (click)="closeDrawer()" class="arrow-dw"
                        (keydown.tab)="keyDown($event)">
                        <span class="material-icons" [ngClass]="{'blueThemeMode':(blueThemeMode)}">
                            arrow_back
                        </span>
                    </button>
                    <h4 class="modal-title" attr.title="{{title}}">
                        {{title}}
                    </h4>
                </div>
            </div>
            <div class="form-row mt-3" *ngIf="showProjectIdSection === true">
                <div class="col-md-7 mb-3 typeahead-wrapper">
                    <label for="selectedProjectKey">Project ID</label>
                    <div class="custom-search-input">
                        <div class="input-group">
                            <input class="search-query form-control fdl-ng-typeahead" placeholder="Project ID"
                                [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedProjectKey" type="text">
                        </div>
                    </div>
                </div>
                <div class="col-md-2 typeahead-wrapper pt-3 pl-0 mt-1 fdl-2">
                    <button type="button" class="btn fdl-btn btn-primary-orange" [disabled]="selectedProjectKey === ''"
                        (click)="loadProjectDetails()" (keydown.tab)="keyDown($event)">GO</button>
                </div>
            </div>
            <!-- Error Msg Alert -->
            <div class="alert alert-dismissible alert-danger" *ngIf="errorMsg">
                <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="errorMsg=''">×</button>
                <i class="material-icons">warning</i>
                <p [innerHtml]="errorMsg"></p>
            </div>
            <div class="dw-body" [ngClass]="{'dw-error-stage' : (errorMsg !== '')}">
                <ng-container *ngIf="commitmentIntakeMapDetails !== null">
                    <div class="dw-box">
                        <div class="dw-box-title-container" role="button" data-toggle="collapse"
                            data-target="#collapseStepOne" aria-expanded="true">
                            <h3 [ngClass]="{'blueThemeMode':(blueThemeMode)}">Commitment</h3>
                        </div>
                        <div class="dw-box-content-container collapse show" id="collapseStepOne"
                            aria-labelledby="headingOne">
                            <ul class="content-list">
                                <li class="col-width col-66">
                                    <div class="title"
                                        *ngIf="projectDataSource !== 'ecrm' && currentDataSource === 'clarity'">
                                        Clarity Project Title
                                    </div>
                                    <div class="title" *ngIf="currentDataSource === 'ecrm'">eCRM
                                        Opportunity Title
                                    </div>
                                    <div class="title" *ngIf="currentDataSource === 'cims'">CIMS
                                        Project Title
                                    </div>
                                    <div class="title"
                                        *ngIf="currentDataSource === 'servicenow'">Servicenow
                                        Project Title
                                    </div>
                                    <div class="content">{{commitmentIntakeMapDetails.title}} </div>
                                </li>
                                <li class="col-width col-33"
                                    *ngIf="projectDataSource !== 'ecrm' && currentDataSource !== 'ecrm'">
                                    <div class="title">
                                        Project Number/ID
                                    </div>
                                    <div class="content">
                                        {{commitmentIntakeMapDetails.projectDetails.projectNumber}}</div>
                                </li>
                                <li class="col-width col-33"
                                    *ngIf="projectDataSource === 'ecrm' || currentDataSource === 'ecrm'">
                                    <div class="title">
                                        Opportunity ID
                                    </div>
                                    <div class="content">
                                        {{commitmentIntakeMapDetails.projectDetails.opportunityId}}</div>
                                </li>
                            </ul>
                            <ul class="content-list">
                                <li class="flex-column"
                                    *ngIf="projectDataSource !== 'ecrm' && currentDataSource !== 'ecrm'">
                                    <div class="title" *ngIf="currentDataSource === 'clarity'">
                                        Clarity Project Description
                                    </div>
                                    <div class="title"
                                        *ngIf="currentDataSource === 'servicenow'">
                                        Servicenow Project Description
                                    </div>
                                    <div class="title" *ngIf="currentDataSource === 'cims'">
                                        CIMS Project Description
                                    </div>

                                    <div class="content">{{commitmentIntakeMapDetails.description}}</div>
                                </li>
                                <li class="flex-column"
                                    *ngIf="projectDataSource === 'ecrm' || currentDataSource === 'ecrm'">
                                    <div class="title">eCRM Opportunity Description
                                    </div>
                                    <div class="content">{{commitmentIntakeMapDetails.description}}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="dw-box">
                        <div class="dw-box-title-container" role="button" data-toggle="collapse"
                            data-target="#collapseStepTwo" aria-expanded="true">
                            <h3 [ngClass]="{'blueThemeMode':(blueThemeMode)}">Client Details</h3>
                        </div>
                        <div class="dw-box-content-container collapse show" id="collapseStepTwo"
                            aria-labelledby="headingOne">
                            <ul class="content-list">
                                <li class="col-width col-33">
                                    <div class="title">Client Name
                                    </div>
                                    <div class="content">{{commitmentIntakeMapDetails.clientDetails.clientName}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Relationship Manager/ Account Executive
                                    </div>
                                    <div class="content">
                                        {{commitmentIntakeMapDetails.clientDetails.relationshipManagerName}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Commitment Type </div>
                                    <div class="content">{{commitmentIntakeMapDetails.clientDetails.commitmentType}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Segment</div>
                                    <div class="content">{{commitmentIntakeMapDetails.clientDetails.segment}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">GlobalUltimateDUNS__c</div>
                                    <div class="content">
                                        {{commitmentIntakeMapDetails.clientDetails.GUD}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Billing Street
                                    </div>
                                    <div class="content">{{commitmentIntakeMapDetails.clientDetails.billingStreet}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Billing City
                                    </div>
                                    <div class="content">{{commitmentIntakeMapDetails.clientDetails.billingCity}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Billing Postal Code
                                    </div>
                                    <div class="content">
                                        {{commitmentIntakeMapDetails.clientDetails.billingPostalCode}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Billing State
                                    </div>
                                    <div class="content">{{commitmentIntakeMapDetails.clientDetails.billingState}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Phone
                                    </div>
                                    <div class="content">{{commitmentIntakeMapDetails.clientDetails.phone}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Billing Country
                                    </div>
                                    <div class="content">{{commitmentIntakeMapDetails.clientDetails.billingCountry}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Website
                                    </div>
                                    <div class="content">{{commitmentIntakeMapDetails.clientDetails.website}}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="dw-box">
                        <div class="dw-box-title-container" role="button" data-toggle="collapse"
                            *ngIf="projectDataSource !== 'ecrm' && currentDataSource !== 'ecrm'"
                            data-target="#collapseStepThree" aria-expanded="true">
                            <h3 *ngIf="currentDataSource === 'clarity'" [ngClass]="{'blueThemeMode':(blueThemeMode)}">Clarity Details
                            </h3>
                            <h3 *ngIf="currentDataSource === 'servicenow'" [ngClass]="{'blueThemeMode':(blueThemeMode)}">Servicenow Details
                            </h3>
                            <h3 *ngIf="currentDataSource === 'cims'" [ngClass]="{'blueThemeMode':(blueThemeMode)}">CIMS Details
                            </h3>
                            <h3 *ngIf="currentDataSource === 'manual'" [ngClass]="{'blueThemeMode':(blueThemeMode)}">Manual Details
                            </h3>
                        </div>
                        <div class="dw-box-title-container" role="button" data-toggle="collapse"
                            *ngIf="projectDataSource === 'ecrm' || currentDataSource === 'ecrm'"
                            data-target="#collapseStepThree" aria-expanded="true">
                            <h3 [ngClass]="{'blueThemeMode':(blueThemeMode)}">Opportunity Details</h3>
                        </div>
                        <div class="dw-box-content-container collapse show" id="collapseStepThree"
                            aria-labelledby="headingOne">
                            <ul class="content-list">
                                <li class="col-width col-33">
                                    <div class="title">BU Responsible for Delivery </div>
                                    <div class="content">{{commitmentIntakeMapDetails.projectDetails.bu}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Product/s </div>
                                    <div class="content">
                                        {{commitmentIntakeMapDetails.projectDetails.product}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Project Manager
                                    </div>
                                    <div class="content">
                                        <span
                                            *ngIf="currentDataSource === 'servicenow'">{{commitmentIntakeMapDetails.projectDetails.projectManagerServiceNow}}</span>
                                        <span
                                            *ngIf="currentDataSource !== 'servicenow'">{{commitmentIntakeMapDetails.projectDetails.projectManager}}</span>
                                        <span *ngIf="!commitmentIntakeMapDetails.projectDetails.projectManager && !commitmentIntakeMapDetails.projectDetails.projectManagerServiceNow"> Not Available </span>
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">MC</div>
                                    <div class="content">{{commitmentIntakeMapDetails.projectDetails.mc}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">MC-1</div>
                                    <div class="content">{{commitmentIntakeMapDetails.projectDetails.mc1}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Resource Manager Level4</div>
                                    <div class="content">
                                        {{commitmentIntakeMapDetails.projectDetails.resourceManagerLevel4}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Delivery Owner
                                    </div>
                                    <div class="content">{{commitmentIntakeMapDetails.projectDetails.deliveryOwner}}
                                    </div>
                                    
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Planned/ Committed Delivery Date</div>
                                    <div class="content">
                                        {{commitmentIntakeMapDetails.projectDetails.plannedCommittedDeliveryDate}}
                                    </div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Resource Manager Level5
                                    </div>
                                    <div class="content">
                                        {{commitmentIntakeMapDetails.projectDetails.resourceManagerLevel5}}</div>
                                </li>
                                <li class="col-width col-33">
                                    <div class="title">Project Status
                                    </div>
                                    <div class="content">{{commitmentIntakeMapDetails.projectDetails.projectStatus}}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </ng-container>
                <div class="pt-3">
                    <div class="fdl-2 button-container">
                        <button class="btn fdl-btn btn-secondary-light dCancel" (click)="closeDrawer()"
                            [ngClass]="{'blueThemeMode':(blueThemeMode)}"
                            id="dCancel" (keydown.tab)="keyDown($event)">Cancel</button>
                        <button *ngIf="displayMapButton" type="button"
                            class="btn fdl-btn btn-primary-orange float-right mr-4"
                            [disabled]="selectedProjectKey === '' || commitmentIntakeMapDetails === null"
                            (click)="onMappingSubmit()" (keydown.tab)="keyDown($event)"> Map
                        </button>
                        <button *ngIf="displayUnmapButton" type="button" (keydown.tab)="keyDown($event)"
                            class="btn fdl-btn btn-primary-orange float-right mr-4"
                            [disabled]="selectedProjectKey === '' || commitmentIntakeMapDetails === null"
                            data-toggle="modal" data-target="#unmapModal_one"> Unmap
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="backdrop-space">
        <div class="backdrop"></div>
    </div>
</div>
<app-commitment-unmap-fulfillment-modal [zIndexMode]="false"
    [projectName]="commitmentIntakeMapDetails === null ? '' : commitmentIntakeMapDetails.title"
    [mappingCommitmentIntakeId]="commitmentIntakeMapDetails === null ? 0 : commitmentIntakeMapDetails.id"
    [commitmentMapType]="commitmentMapType" [projectKey]="selectedProjectKey"
    (unmap)="unmap($event)"></app-commitment-unmap-fulfillment-modal>
