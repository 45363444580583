import { Injectable, SecurityContext } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { IUser, IUsersList, IUserLoginDetails } from '../shared/user';
import { Observable } from 'rxjs';
import { ISuggestion } from '../shared/suggestion';
import { debug } from "console";
import { query } from "@angular/animations";
import moment from "node_modules/moment";
import { UserService } from '../services/user.service';
import { DomSanitizer } from '@angular/platform-browser'
declare var $: any;
@Injectable({
    providedIn: 'root'
})
export class ContentService {

 
  private content: any = false;
  private allDataFetch: any = false;

  constructor(private userService: UserService, private sanitizer: DomSanitizer) { }
  setAllContent() {
    let userData = this.userService.getUserListData();
    const baseContent = this.userService.callorgSpecificConfiguration('base');
    if ((userData && userData.organization)) {
      const orgConfig = this.userService.callorgSpecificConfiguration(userData.organization);

    }
  }
  setContent() {
    return new Promise((resolve, reject) => {
      let userData = this.userService.getUserListData();
      let content = [];
      return this.userService.callorgSpecificConfiguration('base').subscribe(user => {
        if (!(userData && userData.organization)) {
          this.content = user;
          resolve('Promise Resolved');
        } else {
          for (let k in user) {
            content[k] = user[k]
          }
          this.content = content;
          if (userData && userData.organization) {
            this.userService.callorgSpecificConfiguration(userData.organization).subscribe(user => {
              if (user['css']) {
                $('head').append('<link rel="stylesheet" href="' + this.sanitizer.sanitize(SecurityContext.HTML, "/assets/css/" + user['css']) + '" type="text/css" />');
              }
              if (user['content']) {
                this.userService.callorgSpecificConfiguration(user['content']).subscribe(user => {
                  for (let k in user) {
                    content[k] = user[k]
                  }
                  this.content = content;

                  resolve("Promise Resolved");

                }, error => { resolve("Promise Resolved") });
              } else {
                this.content = content;

                resolve("Promise Resolved");

              }
            }, error => { resolve("Promise Resolved") })
          }
        }
      })

    });
  }

  getcontent() {
    return this.content;
  }
}

