import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IQuery } from 'src/app/shared/query';
import { IClients } from 'src/app/shared/client';
import { ClientsService } from 'src/app/services/clients.service';
import { ISuggestions } from 'src/app/shared/suggestion';
import * as $ from 'jquery';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { ICommitmentIntakeDto } from '../models/commitment-intake-d-t-o';

@Component({
  selector: 'app-commitment-update-modal',
  templateUrl: './commitment-update-modal.component.html',
  styleUrls: ['./commitment-update-modal.component.css'],
})
export class CommitmentUpdateModalComponent implements OnInit {
  queryParam: IQuery;
  clientList: IClients;
  errorMsg: any;
  @Input() updatedCommitments: ICommitmentIntakeDto[] = [];
  @Input() untrackedCommitments: ICommitmentIntakeDto[] = [];
  @Output() private closeModal = new EventEmitter();
  @Output() onUpdateCommitments = new EventEmitter<{ updatedCommitments: any[], untrackedCommitments: any[] }>();
  constructor(private clientService: ClientsService, private clientCommitmentsService: ClientCommitmentsService) {}

  ngOnInit(): void {
    $('body').addClass('modal-open');
  }

  closeDrawer() {
    this.closeModal.emit();
    $('.container-page').removeClass('overflow-hidden');
    $('body').removeClass('modal-open');
  }

  submit() {
    if (this.updatedCommitments.length > 0) {
      for (var i = 0; i < this.updatedCommitments.length; i++) {
        if (this.updatedCommitments[i].isCustomCommitment && this.updatedCommitments[i].oneViewGroupId != undefined && this.updatedCommitments[i].oneViewGroupId > 0) {
          this.createStrategicOneViewUsers(this.updatedCommitments[i]);
        }
        else if (this.updatedCommitments[i].isCustomCommitment) {
          this.createStrategicClientUsers(this.updatedCommitments[i]);
        }
      }
    }

    this.onUpdateCommitments.emit({ updatedCommitments: this.updatedCommitments, untrackedCommitments: this.untrackedCommitments });

    $('.container-page').removeClass('overflow-hidden');
    $('body').removeClass('modal-open');
  }

  createStrategicOneViewUsers(updatedCommitment: ICommitmentIntakeDto) {
    var duns = updatedCommitment.clientDunsNumber
    var ProjectId = updatedCommitment.projectKey;
    var OneViewGroupId = updatedCommitment.oneViewGroupId;
    var request = [];
    if (updatedCommitment.clientCommitmentUsersList != undefined && updatedCommitment.clientCommitmentUsersList.length > 0) {
      for (var i = 0; i < updatedCommitment.clientCommitmentUsersList.length; i++) {
        var tempreq = {
          clientDUNS: duns,
          oneViewGroupId: OneViewGroupId,
          ProjectKey: ProjectId,
          Userid: updatedCommitment.clientCommitmentUsersList[i].id
        };
        request.push(tempreq);
      }
    }
    this.clientCommitmentsService.createCustomOneViewUsers(ProjectId, request).subscribe(
      (response) => {
      },
      (error) => {
      }
    );

    console.log(request);
  }

  createStrategicClientUsers(updatedCommitment: ICommitmentIntakeDto) {
    var duns = updatedCommitment.clientDunsNumber;
    var ProjectId = updatedCommitment.projectKey;
    var request = [];
    if (updatedCommitment.clientCommitmentUsersList != undefined && updatedCommitment.clientCommitmentUsersList.length > 0) {
      for (var i = 0; i < updatedCommitment.clientCommitmentUsersList.length; i++) {
        var tempreq = {
          DUNS: duns,
          ProjectKey: ProjectId,
          Userid: updatedCommitment.clientCommitmentUsersList[i].id
        };
        request.push(tempreq);
      }
    }
    this.clientCommitmentsService.createCustomCLientUsers(ProjectId, request).subscribe(
      (response) => {
      },
      (error) => {
      }
    );

    console.log(request);
  }

  getStatusCSSClass(row: any) {
    if (row.updateState == 'update') {
      return 'alert-message success'
    };

    if (row.updateState == 'untrack') {
      return 'alert-message'
    };
  }
}
