<div class="modal fade bd-example-modal-lg" id="clientViewModal" tabindex="-1" role="dialog" data-backdrop="false" 
    aria-labelledby="clientViewModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document" style="max-width: 1000px;">
        <div class="modal-content" style="min-height: 400px;">
            <div class="modal-header">
              <h5 class="modal-title">{{ isCreate ? 'Create New OneView' : 'OneView Details' }} </h5>
                <button (click)="closeOneViewModal()" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div id="clientViewBody" class="modal-body oneview-body">
                <div class="alert alert-dismissible alert-danger" *ngIf="hasError">
                    <button type="button" class="close" data-dismiss="alert" aria-label="close" (click)="hasError=false">×</button>
                    <i class="material-icons">warning</i>
                    <p [innerHtml]="errorMsg"></p>
                </div>

                <div class="form-group col" style="line-height: 1.5">
                    <i class="fa fa-info-circle" aria-hidden="true"></i>
                    The selected clients will have their dashboards merged into a single OneView.
                    Users at all clients will be able to view the tracked commitments of all clients within the OneView.
                    Account Team members will also be able to track commitments of any client within the OneView, to be visible to any user within OneView.
                </div>
                
                <div>
                    <div class="row">
                        <!--left side -->
                        <div class="col">
                            <div class="form-group">
                                <label class="col-sm-6 col-form-label asterisk">Select which clients to include in OneView:</label>

                                <div class="col">
                                    <ng-select [(ngModel)]="selectedClients" [multiple]="true" [disabled]="!canEdit"
                                        (change)="onSelectClientChanged($event)" [items]="clientSuggestion"
                                        [closeOnSelect]="false" [virtualScroll]="true" [clearOnBackspace]="false"
                                        placeholder="{{ isLoading ? 'Loading...' : 'Type to search'}}" bindLabel="titleWithDuns" class="ng-select">
                                        <ng-option value="">Select all</ng-option>
                                        <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$"
                                            let-index="index">
                                            <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                                            <span title="{{itemTemp.titleWithDuns}}"> {{itemTemp.titleWithDuns}} </span>
                                        </ng-template>
                                        <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                                            <div class="ng-value" *ngFor="let item of items">
                                                <span class="ng-value-label"
                                                    title="{{item.titleWithDuns}}">{{item.titleWithDuns}} </span>
                                                <span class="ng-value-icon right" (click)="clear(item)"
                                                    aria-hidden="true">×</span>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" *ngIf="selectedClients && selectedClients.length > 0">
                        <div class="col">
                            <div class="table-responsive col">
                                <table class="table table-hover">
                                    <thead>
                                      <tr class="col">
                                        <th scope="col">DUNS</th>
                                        <th scope="col">Client Name</th>
                                        <th colspan="2" scope="col"><span style="margin-left: -3px;">Client Users</span></th>
                                        <th scope="col" style="padding-left: 2px;">Is Custom Client</th>
                                      </tr>
                                    </thead>
                                    <tbody id="clientList">
                                        <ng-container>
                                            <tr style="line-height: 1.75rem;" class="col"
                                                *ngFor="let item of selectedClients; index as i">
                                                <td class="client-info-td">{{item.dunsId}}</td>
                                                <td class="client-info-td">{{item.title}}</td>
                                                <td class="client-info-td"colspan="2" ><span [innerHTML]="handleClientUserEmail(item.clientUserEmails)"></span></td>
                                                <td class="client-info-td">{{item.isSrategicCLient ? 'Yes':'No' }}</td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label class="col col-form-label asterisk">OneView Name</label>
                                <div class="col d-flex">
                                    <input [(ngModel)]="viewName" (ngModelChange)="onOneViewNameChanged($event)" type="text" class="form-control mr-3" [disabled]="!canEdit || isLoading">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
            </div>
            <div class="oneview-footer">
                <div class="d-flex">
                    <div class="fdl-2 mr-auto">
                        <button *ngIf="isEdit && !isLoading" class="btn fdl-btn btn-primary-orange" [disabled]="!canEdit" (click)="onDeleteItem()">Delete</button>
                    </div>
                 
                    <div class="fdl-2">
                        <button type="button" data-dismiss="modal" aria-label="Close" class="btn btn-secondary mr-3" (click)="closeOneViewModal()">Cancel</button>
                        <button (click)="submit()" type="button" class="btn fdl-btn btn-primary-orange" [disabled]="!canEdit || !isReadyToSubmit()"> {{ isEdit ? 'Update' : 'Submit' }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteConfirmModal" tabindex="-1" role="dialog" aria-labelledby="deleteConfirmModalTitle" data-backdrop="false" 
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Delete OneView
                </h5>
                <button type="button" class="close" (click)="onCloseDeleteModal()" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
              <h4>
                <b>Are you sure you want to delete the {{viewName}} OneView?</b>
              </h4>
              <p class="ml-3">
                This OneView merges the following clients: {{ClientNameList}}.  It will be removed, and users of these clients will only have access to view their respective dashboards and commitments.
              </p>

              <p class="ml-3">
                IF CUSTOM CLIENT: All individual clients will retain custom client status.  Within a client, custom tracking of commitments will remain unchanged.  Users will be able to see the same commitments made to their respective client as what was previously on their dashboard.  They will no longer be able to view commitments of the other clients.
              </p>
              <p class="ml-3">
                Please review and confirm.
              </p>
            </div>
            <div class="modal-footer">
                <div class="fdl-2">
                    <button type="button" class="btn btn-secondary mr-3" (click)="onCloseDeleteModal()">Cancel</button>
                    <button type="button" class="btn fdl-btn btn-primary-orange" (click)="deleteCommitmentDetail()" [disabled]="!canEdit || isLoading">Delete</button>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="customConfirmModal" tabindex="-1" role="dialog" aria-labelledby="customConfirmModalTitle" data-backdrop="false"  aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Custom OneView
        </h5>
        <button type="button" class="close" (click)="onCloseCustomOneViewModal()" data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4>
          <b>Are you sure you want to create the {{viewName}} OneView?</b>
        </h4>
        <p class="ml-3">
          One of the clients included in your OneView is a custom client. Proceeding with OneView creation will convert all selected clients in the OneView to custom clients.
          If you want to continue, click Yes; otherwise click No to change the selection</p>
        
      </div>
      <div class="modal-footer">
        <div class="fdl-2">
          <button type="button" class="btn btn-secondary mr-3" (click)="onCloseCustomOneViewModal()">No</button>
          <button type="button" class="btn fdl-btn btn-primary-orange" (click)="customSubmit()">Yes</button>
        </div>
      </div>
    </div>
  </div>
</div>
