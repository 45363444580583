<div class="justify-content-between d-flex">
  <div class="headline-container mb-1">
    <p *ngIf="pagerObject">Total {{pagerObject.totalCount | number}} items</p>
  </div>
  <div class="d-flex">
    <div class="action-container fdl-2" style="display: flex; align-items: self-start;">
      <div class="nav-link">
        <a href="javascript:void(0);" id="DataDictionary" (click)="navigateToDataDict(commitmentIntakeDictUrl)"
           [ngClass]="{'blueThemeMode':(blueThemeMode)}">Data Dictionary</a>
      </div>
    </div>

    <div class="action-container fdl-2">
      <button (click)="onSave()" [disabled]="!enableSaveButton" class="btn fdl-btn btn-primary-orange mr-2"
              id="actionUpdateCommitmentsDwDialog" type="button">
        Save
      </button>
    </div>
    <div class="action-container fdl-2">
    </div>
    <div class="refresh-icon-link mt-1">
      <a id="refreshTask" title="Refresh List" class="">
        <i aria-hidden="true" class="fa fa-refresh" (click)="refresh()"></i>
      </a>
    </div>

    <div class="dropdown downloadDropdownMenuButton mt-0">
      <button type="button" title="Download Extract" id="downloadDropdownMenuButton" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="true" class="btn btn fdl-btn btn-primary-dark btn-addcomment">
        <span class="material-icons material-icons-wrapper"> get_app </span>
      </button>
      <div class="dropdown-menu" aria-labelledby="downloadDropdownMenuButton" x-placement="bottom-start">
        <a class="dropdown-item" (click)="exportExcel()">Export List</a>
      </div>
    </div>
    <div class="refresh-icon-link mt-1">
      <a href="javascript:void(0);" data-toggle="modal" class="clickable" (click)="showColumnSettings()">
        <span class="material-icons">settings</span>
      </a>
    </div>
  </div>
</div>
<p class="mt-3">
  Commitments listed below can be flagged for tracking to the Client Commitment Dashboard.  Please review each commitment for accuracy and thoroughness.  Select 'Track' to make Commitments visible on the client's dashboard. To highlight commitments at risk, select the 'Risk' checkbox with associated reason.  Click 'Save' to keep edited information.
</p>
<div class="table-responsive overflow-auto pt-2">
  <table id="parent" aria-describedby="tblDesc" class="f-12 table table-hover main-nested-table table-label"
         *ngIf="commitmentIntakeList && commitmentIntakeList.length > 0">

    <thead class="parent-thead">
      <tr role="item" class="table-topbar">
        <th [attr.colspan]="2">
          <div class="checkbox">
            <label for="{{'trackAllCheckbox' + pagerObject.currentPage}}" class="track-all">Track All</label>
            <input type="checkbox" class="track-all" *ngIf="shouldDisableAllTrackCheckbox()" disabled="disabled" />
            <input type="checkbox" class="track-all" (change)="trackAll()" id="{{'trackAllCheckbox' + pagerObject.currentPage}}"
                   *ngIf="!shouldDisableAllTrackCheckbox()" [(ngModel)]="isAllTracked" />
            <label class="label-checkbox" for="{{'trackAllCheckbox' + pagerObject.currentPage}}"></label>
          </div>
        </th>
        <th [attr.colspan]="(columnPreferenceSettings ? columnPreferenceSettings.length : 0) + 2">
        </th>
      </tr>
      <tr role="item">
        <th>
        </th>
        <!-- columns setting -->
        <th role="columnheader" scope="col" *ngFor="let columnSetting of columnPreferenceSettings; let i = index;">
          <span>
            <a (click)="sort(columnSetting)" *ngIf="applySorting(columnSetting)"
               href="javascript:void(0);">
              <span *ngIf="columnSetting.title === 'Stage'" class="column-field-unwrapper">{{columnSetting.title}}</span>
              <span *ngIf="columnSetting.title !== 'Stage'">{{columnSetting.title}}</span>
              <i class="material-icons msort14" aria-hidden="true"
                 *ngIf="isAsc && displayColumnsSortIcon(columnSetting)">arrow_upward</i>
              <i class="material-icons msort14" aria-hidden="true"
                 *ngIf="!isAsc && displayColumnsSortIcon(columnSetting)">arrow_downward</i>
            </a>
            <span *ngIf="!applySorting(columnSetting)">
              {{columnSetting.title}}
            </span>
            <span *ngIf="columnSetting.key === 'oneViewGroupName'"
                  style="font-size: 14px; cursor: pointer;" data-toggle="tooltip" data-placement="bottom"
                  class="material-icons"
                  title="If a OneView (merge of multiple clients) has been created for this client, the name of the OneView will be displayed here.">
              info
            </span>
          </span>
        </th>
        <th style="background: white; position: sticky; right: 0;" role="columnheader" scope="col">Actions</th>
      </tr>
    </thead>
    <ng-container *ngIf="columnPreferenceSettings.length > 0">
      <tbody class="parent-tbody" *ngFor="let item of commitmentIntakeList; let i = index">
        <tr app-commitment-intake-row
            role="row" [ngClass]="{'has-changed' : item.hasTrackChanged }" class="tr-parent grey collapsed"
            [rowIndex]="i"
            [item]="item"
            [isPageTemplateAdmin]="isPageTemplateAdmin"
            [disabledCustomCommitment]="disabledCustomCommitment"
            [disabledNonCustomCommitment]="disabledNonCustomCommitment"
            [originCommitments]="originCommitments"
            [commitmentIntakeList]="commitmentIntakeList"
            [columnPreferenceSettings]="columnPreferenceSettings"
            [content]="content"
            [selectedTabName]="selectedTabName"
            (onTrackingUpdated)="trackingCommitmentToUpdate($event)"
            (onFieldChanged)="onFieldChanged($event)"
            (onRedirectToDetails)="redirectToDetails(item.projectKey)"
            (onOpenMapFulfillment)="openMapFulfillment(item, i, $event)"
            (onOpenDeleteModal)="openDeleteModal(item)"
            (oneViewClicked)="onOneVliewClicked($event)"
            (updateUserClicked)="onEditUsersClicked(item)"
            (addUserClicked)="onAddUsersClicked(item)">
        </tr>
        <tr app-commitment-intake-mapping-row
            id="{{'tableParentAccordion' + i}}" class="tr-child grey collapse"
            [rowIndex]="i"
            [item]="item"
            [hasMapOrUnmapPermission]="hasMapOrUnmapPermission"
            [commitmentIntakeList]="commitmentIntakeList"
            (onOpenMapFulfillment)="openMapFulfillment(item, i, $event)"
            (onOpenMappedDetailsModal)="openMappedDetailsModal(item, i, $event)"
            (onOpenUnmapModal)="openUnmapModal()"
            (alertChanged)="changeAlert($event)"></tr>
      </tbody>
    </ng-container>
    <div style="width: max-content;" *ngIf="!commitmentIntakeList || commitmentIntakeList.length === 0" class="headline-container mb-1 text-center">
      <p>No data available</p>
    </div>
  </table>
</div>
<div *ngIf="pagerObject">
  <app-pagination [isCalledFromCCT]="blueThemeMode" *ngIf="pagerObject && pagerObject.totalPages > 1 " [pagerObject]="pagerObject"
                  (pagingChanged)="changePage($event)"></app-pagination>
</div>

<app-commitment-update-modal *ngIf="showUpdateCommitmentsIntakeDrawer" [updatedCommitments]="commimentsTobeUpdated"
                             (closeModal)="closeUpdateCommitmentModal()" (onUpdateCommitments)="updateCommitments()"></app-commitment-update-modal>

<app-commitment-map-fulfillment-modal *ngIf="isMapFulfilmentModalShown" [projectDataSource]="projectDataSource"
                                      [isCalledForMap]="isCalledForMap" [projectKey]="projectKey" [title]="fulfillmentTitle"
                                      [canMapOrUnmap]="canMapOrUnmapOnMapModal" [commitmentMapType]="commitmentMapType" [isReadOnlyMode]="isReadOnlyMode"
                                      [currentCommimentDataSource]="currentCommimentDataSource" [isPageTemplateReadAdmin]="isPageTemplateReadAdmin"
                                      [isPageTemplateAdmin]="isPageTemplateAdmin" (submit)="onMapFulfilmentComponentSubmited($event)"
                                      (cancel)="closeMapFulfilmentDrawer()"></app-commitment-map-fulfillment-modal>

<app-commitment-intake-delete-modal [item]="itemForDelete"
                                    (onDelete)="onCommitmentDelete($event)"></app-commitment-intake-delete-modal>

<app-commitment-unmap-fulfillment-modal [projectName]="titleForUnmap" [zIndexMode]="zIndexMode"
                                        [mappingCommitmentIntakeId]="commitmentIntakeDetailsId" [commitmentMapType]="commitmentMapType"
                                        [projectKey]="projectKey" (unmap)="unmap($event)"></app-commitment-unmap-fulfillment-modal>

<app-commitment-assignusers (updateCommitment)="saveUsersClick()" (closeModal)="closeAddCommitmentUsersModal()"
                             [updatedCommitments]="commimentsTobeUpdated" [commitmentDetails]="selectedForUserAssignement"></app-commitment-assignusers>

<app-commitment-edit-assignusers (closeModal)="editCommitmentUsersModal()" (submitModal)="editCommitmentUsersModal()"
                                  [commitmentDetails]="selectedForeditUserAssignement"></app-commitment-edit-assignusers>

<!-- start of column settings changes-->
<div *ngIf="showColumnSettingsDrawer">
  <div class="modal full-width fade position-right no-backdrop show" id="columnSettings" tabindex="-1" role="dialog"
       aria-labelledby="myModalLabel" style="display: block;" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header white-modal-header">
          <h4 class="modal-title right-arrow-image"><span>Column Settings</span></h4>
          <button type="button" class="close" data-dismiss="modal" tabindex="0" autofocus=""
                  aria-label="Close popup" (click)="cancelPreference()">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body pt-0">
          <div class="row tag-line-wrapper">
            <div class="col-md-12">
              <div class="tag-line">
                <p>
                  Add or remove columns.
                </p>
                <p class="mb-0">
                  Please note that you can add up to {{maxColumnCount}} columns at a time.
                </p>
              </div>
            </div>
          </div>
          <div class="alert alert-dismissible alert-danger" *ngIf="columnSettingErrorMsg">
            <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="columnSettingErrorMsg=''">
              ×
            </button>
            <i class="material-icons">warning</i>
            <p [innerHtml]="columnSettingErrorMsg"></p>
          </div>
          <!-- Column Field starts -->
          <div class="row column-field-wrapper"
               *ngFor="let columnPref of columnPreferences; index as counter;">
            <div class="col-lg-11 form-field-container">
              <div class="filter-form-control">
                <ng-select [(ngModel)]="columnPref.id" class="ng-select">
                  <ng-option *ngFor="let column of prefColumnOptions"
                             [value]="column.id">{{column.title}}</ng-option>
                </ng-select>
              </div>
            </div>
            <div class="col-lg-1 cross-icon-container">
              <button type="button" data-dismiss="modal" tabindex="0" autofocus=""
                      aria-label="Close popup" class="close" (click)="removeColumn(counter)">
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
          <div class="row column-field-wrapper" *ngIf="showAddColumnOptions">
            <div class="col-lg-12 ">
              <a class="add-col-link" (click)="addColumn()">
                <span class="material-icons">add</span>
                <span>Add a Column</span>
              </a>
            </div>
          </div>
        </div>
        <div class="modal-footer fdl-2">
          <button class="btn fdl-btn btn-secondary-light" (click)="cancelPreference()">Cancel</button>
          <button class="btn btn-primary-orange m-0" (click)="savePreference()">Save</button>
        </div>
      </div>
    </div>
  </div>
  <!-- end of column settings changes-->
</div>
