import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CommitmentValidator {
    static requiredIfDirtyValidator(control: AbstractControl):  ValidationErrors | null {
        if (!control || !control.dirty) {
            return null;
        }

        return control.errors;
    }
}