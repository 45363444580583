import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { CustomHttpParamEncoder } from "../shared/custom-encoder";
import { InterceptorSkipHeader } from 'src/app/core/constants';
import { DashboardCommitmentDTO, IClientDashboard, ClientSuggestionDTO } from "../self-service/components/client-dashboard/models/client-dashboard-d-t-o";
import { IQuery } from "../shared/query";
@Injectable({
  providedIn: 'root'
})
export class CommitmentDashboardService {
  private commitmentDashboardAPIURL = 'commitmentdashboard';
  constructor(private http: HttpClient) { }

  getCommitments(request: IQuery) {
    const options = { 'refresh': 'true' };
    return this.http.post<IClientDashboard>(`${this.commitmentDashboardAPIURL}/commitments`, request, { headers: options, observe: 'response' });
  }

  exportCommitments(request: IQuery) {
    const options = { 'refresh': 'true' }
    return this.http.post<DashboardCommitmentDTO[]>(`${this.commitmentDashboardAPIURL}/exports`, request, { headers: options, observe: 'response' });
  }

  getCommitmentClients(searchClientBy: string, canGetUserEmail: boolean = false) {
    let options = { 'refresh': 'true' }
    options[InterceptorSkipHeader] = '';
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
    params = params.append('searchClientBy', searchClientBy);
    if (canGetUserEmail) {
      params = params.append('canGetUserEmail', canGetUserEmail);
    }
    const suggestionAPIURL = `${this.commitmentDashboardAPIURL}/commitmentclients`;
    return this.http.get<ClientSuggestionDTO[]>(suggestionAPIURL, { headers: options, params: params });
  }

  getAllCommitmentClients(searchClientBy: string, canGetUserEmail: boolean = false) {
    let options = { 'refresh': 'true' }
    options[InterceptorSkipHeader] = '';
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
    params = params.append('searchClientBy', searchClientBy);
    if (canGetUserEmail) {
      params = params.append('canGetUserEmail', canGetUserEmail);
    }
    const suggestionAPIURL = `${this.commitmentDashboardAPIURL}/commitmentallclients`;
    return this.http.get<ClientSuggestionDTO[]>(suggestionAPIURL, { headers: options, params: params });
  }

  exportClientDashboardPDF(clientId: number, params: any) {
    const exportToPdfUrl = `${this.commitmentDashboardAPIURL}/exports/${clientId}/pdf`;
    return this.http.post<any>(exportToPdfUrl, params, { observe: 'response', responseType: 'blob' as 'json' });
  }

  exportClientDashboardPPT(clientId: number, params: any) {
    const exportToPdfUrl = `${this.commitmentDashboardAPIURL}/exports/${clientId}/ppt`;
    return this.http.post<any>(exportToPdfUrl, params, { observe: 'response', responseType: 'blob' as 'json' });
  }

  public untrackCommitment(dataSource: string, projectKey: string) {
    return this.http.delete(`${this.commitmentDashboardAPIURL}/${dataSource}/${projectKey}/untrack`);
  }
}
