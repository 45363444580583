export const REGEX_PATTERNS = {
  Name: /^[a-zA-Z0-9_\- .]*$/,
  name_title_validator: /^[a-zA-Z\[\]_0-9\-^()_.,/'\s]+$/,
  organization_name_validator: /^[a-zA-Z\[\]_0-9!@\-$&#^()_+.,;:/'\s]+$/,
  product_name_validator: /^[a-zA-Z\[\]_0-9!@\-$&#^()_+.,;:/'\s]+$/,
  client_name_validator: /^[a-zA-Z\[\]_0-9!@\-$~*&?#|<>^()_+.,;":/'`\s]+$/,
  project_name_validator: /^[a-zA-Z\[\]_0-9!@\-$&#^()_+.,;:/'\s]+$/,
  form_response_survey_name_validator: /^[a-zA-Z\[\]_0-9!$~&?#<>*@\- ^(){}_+=.,;"":/'`|\s]+$/,
  email_template_name_validator: /^[a-zA-Z\[\]_0-9\-#&^()_.,:/'\s]+$/,
  user_name_validator: /^[a-zA-Z0-9\-^()_.,'\s]+$/,
  relationship_manager_name: /^[a-zA-Z\-^()_.,'\s]+$/,
  email_validator: /^[a-zA-Z0-9_\-@.]+$/,
  Email: /^[_A-Za-z0-9\.\-]+([\.\-][_A-Za-z0-9]+)*@[A-Za-z0-9]+([\.\-][A-Za-z0-9]+)*\.[A-Za-z]{2,}$/,
  Mobile: /^\d{10}$/,
  //USMobile: /^\(?\d{3}\)?\s? *\d{3}-? *-?\d{4}$/,
  USMobile: /^(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/,
  //Cannot use this validator. Is too braod for fortify auditors
  //text: /^[a-zA-Z\[\]_0-9!@\-$&%#^()_+.,;:/'\s]+$/,
  description_comment: /^[a-zA-Z0-9_\- .,$@&%#^()';":\[\]*=|`{}+~!><?\\/%\t\r\n]+$/,
  description_validator: /^[a-zA-Z0-9_\- .,()';%\t\r\n]+$/,
  disclaimer_validator: /^[a-zA-Z0-9, /$;&"#<>=:_\\\-.©\s]+$/,
  comment_validator: /^[a-zA-Z0-9_\- .,()';%\t\r\n]+$/,
  page_name_validator: /^[a-zA-Z_0-9!\-().\s]+$/,
  zipCode: /^\d{5}$/,
  AlphaNumeric: /^[a-z0-9\s]+$/i,
  Numeric: /^[0-9]*$/,
  SkipCachingUrls: /((api)((\/)|(\\))[a-zA-Z]+((\/)|(\\))(\d+))/,
  UserName: /^[a-zA-Z](.*[a-zA-Z'.\s])*$/,
  SemiColonSeperatedEmail: /^((\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)*([;])*)*$/,
  url: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  duns_id: /^\d{9}$/,
  filter_duns_id: /^\d{0,9}$/,
  gud_id: /^\d{9}$/,
  filter_gud_id: /^\d{0,9}$/,
  FileName: /^[0-9a-zA-Z()\-\_\.\ ]+$/, // It Allows 0-9 digit, A to Z char and special character (-_.() and space)
  street_validator: /^[a-zA-Z0-9_\-. ,]+$/,
  number_text_hyphen_underscore_validator: /^[a-zA-Z0-9_\-]+$/,
  value_validator: /^[a-zA-Z0-9_\-. @\\:\/]+$/,
  page_client_name_validator: /^[a-zA-Z\[\]_0-9!@\-$*~&%#^()_+.,;:/'\s]+$/, // PBI-266617 Changes
  invalid_file_name: /[^0-9a-zA-Z\-\_\.\ ]+/, // It Allows 0-9 digit, A to Z char and special charactor (-_. and space),
  Fiserv_Email: /^[_A-Za-z0-9-\+]+(\.[_A-Za-z0-9-]+)*@fiserv.com$/
};

export const REGEX_VALIDATION_MESSAGE = {
  NAME_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _- .",
  NAME_TITLE_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _- []^().,/'",
  ORGANIZATION_NAME_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _- []!@$&#^()_+.,;:/'",
  PRODUCT_NAME_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _- []!@$&#^()_+.,;:/'",
  CLIENT_NAME_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _- []!@$~*&?#|<>^()_+.,;\":/`'",
  PROJECT_NAME_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _- []!@$&#^()_+.,;:/'",
  FORM_RESPONSE_SURVEY_NAME_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _- []!$~&?#<>*@\- ^(){}_+=.,;\":/'`|",
  EMAIL_TEMPLATE_NAME_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _- #&^()_.,:/'",
  USER_NAME_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _- ^().,'",
  RELATION_MANAGER_NAME_VALIDATION_MESSAGE: "You may use letters, and certain special characters like _- ^().,'",
  EMAIL_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _-.@",
  MOBILE_VALIDATION_MESSAGE: "Mobile number must be 10-digit",
  USMOBILE_VALIDATION_MESSAGE: "Mobile must be in (xxx)-xxx-xxxx format",
  DESCRIPTION_COMMENT_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _- .,$@&%#^()';:*=|`{}+~!><[]\\?\"\/%\t\r\n",
  DESCRIPTION_VALIDATOR_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _- .,()';%\t\r\n",
  DISCLAIMER_VALIDATOR_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like ,/$;&\"#<>=:_\\\-.©",
  COMMENT_VALIDATOR_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _- .,()';%\t\r\n",
  PAGE_NAME_VALIDATOR_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _- .()!",
  ZIPCODE_VALIDATION_MESSAGE: "Zipcode must be 5-digit",
  ALPHANUMERIC_VALIDATION_MESSAGE: "You may use letters, numbers and white space",
  NUMERIC_VALIDATION_MESSAGE: "You may use numbers only",
  SKIPCACHINGURLS_VALIDATION_MESSAGE: "",
  USERNAME_VALIDATION_MESSAGE: "You may use letters, and certain special characters like '. ",
  SEMICOLONSEPERATEDEMAIL_VALIDATION_MESSAGE: "For multiple emails, please use semicolon (;) as separator.",
  URL_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like .:?/-@&%_\+~#=",
  DUNSID_VALIDATION_MESSAGE: "DUNS ID must be 9-digit",
  FILTERDUNSID_VALIDATION_MESSAGE: "DUNS ID must be numeric and 9-digits",
  FILENAME_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like ()_- .",
  STREET_VALIDATOR_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _- .,",
  NUMBER_TEXT_HYPHEN_UNDERSCORE_VALIDATOR_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _-",
  VALUE_VALIDATOR_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _- .:@/",
  PAGE_CLIENT_NAME_VALIDATOR_VALIDATION_MESSAGE: "You may use letters, numbers, and certain special characters like _- []!@$*~&%#^()+.,;:/'",
  IMAGE_EXTENSION_VALIDATION_MESSAGE: "File extension must be .jpg | .jpeg | .png | .gif",
  IMAGE_CONTENT_VALIDATION_MESSAGE: "Invalid content. File must be image",
};

//SkipCachingUrls Regex matches with pattern like "api/<sometxt>/4"

export const MAX_LENGTHS = {
  User: {
    Name: 60,
    Email: 100
  },
  Client: {
    Name: 80,
    Description: 250,
    ContactName: 60,
    DunsId: 9,
    Gud: 9
  },
  Project: {
    Name: 80,
    Description: 250
  },
  Delegate: {
    Comments: 250
  },
  Approval: {
    Comments: 250
  },
  File: {
    Alias: 60,
    Size: 50 //Unit: MB
  },
  Form: {
    Name: 100,
    Description: 250
  },
  PageTemplate: {
    Name: 100,
    Description: 500
  },
  EmailGroup: {
    Name: 60,
    Description: 250
  },
  Organization: {
    Name: 60,
    Description: 250
  },
  HelpCategory: {
    Name: 60,
    Description: 250
  },
  Role: {
    Title: 150,
    Description: 250
  },
  Image: {
    Size: 10 //Unit: MB
  }
}

export enum ADMIN_MODULES {
  Users,
  Forms,
  Clients,
  EmailGroups,
  Products,
  FileAssets,
  Intake,
  Help,
  Report,
  Workflow,
  Extracts,
  SelfServiceHome,
  BulkUpload,
  Roles,
  Feature,
  CommitmentDelegate
}
export enum PERMISSIONS {
  NoSet = 0,
  Forms_ReadWrite_All = 10,
  Forms_Read_All = 11,
  Forms_ReadWrite_Products_Assigned = 12,
  Forms_Read_Products_Assigned = 13,
  Products_ReadWrite_All = 20,
  Products_Read_All = 21,
  Products_ReadWrite_Assigned = 22,
  Products_Create_Only = 23,
  Products_Read_Assigned = 24,
  Projects_ReadWrite_All = 30,
  Projects_Read_All = 31,
  Projects_Read_Assigned = 32,
  Projects_Edit_Assigned = 33,
  Projects_ReadWrite_Products_Assigned = 34,
  Projects_Create_Only = 35,
  Projects_Read_Products_Assigned = 36,
  Responses_ReadWrite_All = 40,
  Responses_ReadWrite_Manager = 41,
  Responses_Edit_Manager = 42,
  Responses_Edit_Assigned = 43,
  Responses_Edit_All = 44,
  Responses_Read_All = 45,
  Responses_ReadWrite_Products_Assigned = 46,
  Responses_Read_Products_Assigned = 47,
  Users_ReadWrite_All = 50,
  Users_Read_All = 51,
  Users_Create_Only = 52,
  Roles_ReadWrite_All = 60,
  Roles_Read_All = 61,
  Clients_ReadWrite_All = 70,
  Clients_Read_All = 71,
  Clients_Create_Only = 72,
  Sales_Intake_Combo = 100,
  HelpTopics_ReadWrite_All = 80,
  HelpTopics_Read_All = 81,
  HelpTopics_Create_Only = 82,
  Workflow_ReadWrite_All = 90,
  Workflow_Read_All = 91,
  Workflow_Create_Only = 92,
}

export const allowedPermissions = {
  10: { 'formcreate': true, 'formedit': true, 'formview': true, 'formdelete': true, 'showformlink': true, 'showmanageassets': true, 'showResponseExtractsLink': true, 'showformExtractionTemplateLink': true, 'showclientlink': true, 'reportcreate': true, 'showreportlink': true, 'showhelpArticlelink': true, 'showResourceCenterLink': true },
  11: { 'formcreate': false, 'formedit': false, 'formview': false, 'formdelete': false, 'showformlink': false, 'showResourceCenterLink': true },
  13: { 'showResourceCenterLink': true },

  20: { 'productcreate': true, 'productedit': true, 'productview': true, 'productdelete': true, 'showproductlink': true },
  21: { 'productcreate': false, 'productedit': false, 'productview': true, 'productdelete': false, 'showproductlink': true, 'showResponseExtractsLink': true, 'showhelpArticlelink': true },

  30: { 'initiateDGFSubmitForm': true, 'projectcreate': true, 'projectedit': true, 'projectview': true, 'projectdelete': true, 'showprojectlink': true, 'showswitch': true, 'showResponseExtractsLink': true },
  31: { 'initiateDGFSubmitForm': false, 'projectcreate': false, 'projectedit': false, 'projectview': true, 'projectdelete': false, 'showprojectlink': true, 'showswitch': true, 'showResponseExtractsLink': true },
  32: { 'initiateDGFSubmitForm': false, 'projectcreate': false, 'projectedit': false, 'projectview': true, 'projectdelete': false, 'showprojectlink': true, 'showswitch': true },
  33: { 'initiateDGFSubmitForm': false, 'projectcreate': false, 'projectedit': true, 'projectview': true, 'projectdelete': true, 'showprojectlink': true, 'showswitch': true },
  34: { 'initiateDGFSubmitForm': true, 'projectcreate': true, 'projectedit': true, 'projectview': true, 'projectdelete': true, 'showprojectlink': true, 'showswitch': true, 'showResponseExtractsLink': true },
  35: { 'initiateDGFSubmitForm': true, 'projectcreate': true, 'projectedit': false, 'projectview': false, 'projectdelete': false, 'showprojectlink': false, 'showswitch': false, 'showResponseExtractsLink': true },

  40: { 'responsecreate': true, 'responseedit': true, 'responseview': true, 'responsedelete': true, 'showprojectlink': true, 'showresponseslink': true, 'canEditAll': true, 'showResponseExtractsLink': true },
  41: { 'responsecreate': true, 'responseedit': true, 'responseview': true, 'responsedelete': true, 'showprojectlink': true, 'showresponseslink': true, 'canEditAll': false, 'showResponseExtractsLink': true },
  42: { 'responsecreate': false, 'responseedit': true, 'responseview': true, 'responsedelete': true, 'showprojectlink': true, 'showresponseslink': true, 'showResponseExtractsLink': true },
  43: { 'responsecreate': false, 'responseedit': true, 'responseview': true, 'responsedelete': false, 'showprojectlink': false, 'showresponseslink': true, 'showResponseExtractsLink': true },
  44: { 'responsecreate': false, 'responseedit': true, 'responseview': true, 'responsedelete': true, 'showresponseslink': true },
  45: { 'responsecreate': false, 'responseedit': false, 'responseview': true, 'responsedelete': false, 'showresponseslink': true, 'showResponseExtractsLink': true },

  50: { 'usercreate': true, 'useredit': true, 'userview': true, 'userdelete': true, 'showuserlink': true, 'showgroup': true, 'showhelpcategorylink': true },
  51: { 'usercreate': false, 'useredit': false, 'userview': true, 'userdelete': false, 'showuserlink': false },
  52: { 'usercreate': true, 'useredit': false, 'userview': false, 'userdelete': false, 'showuserlink': false, 'showgroup': false },

  61: { 'showreportlink': true },

  70: { 'clientcreate': true, 'clientedit': true, 'clientview': true, 'clientdelete': true, 'showclientlink': true, 'reportcreate': true, 'showreportlink': true },
  71: { 'clientcreate': false, 'clientedit': false, 'clientview': true, 'clientdelete': false, 'showclientlink': true },
  72: { 'clientcreate': true, 'clientedit': false, 'clientview': true, 'clientdelete': false, 'showclientlink': true },

  80: { 'helpCreate': true, 'formview': true },

  100: { 'showResponseExtractsLink': true },
  130: { 'helpCreate': true, 'helpView': true },
  131: { 'helpCreate': false, 'helpView': true },
};

export const manageFormsConst = {

  errorMessages: {
    'securityMessage': `Form Component containes malicious character please remove it.`,
    'blankFormMessage': ` does not have any fields. You need to have at-least one field to submit the form.`,
    'undefinedFormJsonMessage': `Do not directly input default value in the form, use the default value property of the element for the same.`,
    'dublicateKeYMessage': ` have the same API key. You can not have a duplicate API key in the same data gathering form. Please correct it.`,
    'noSensitiveField': `The form is marked as 'Sensitive Data Form' but does not include any sensitive field component. Please review the fields and use Sensitive component for any PCI data used in the form. Alternatively, you can also uncheck the' Sensitive Data Form' checkbox on the form details page.`
  }
}

export const InterceptorSkipHeader = 'X-Skip-Interceptor';
export const FileUploadHeader = 'X-FileUpload-Skip-ContentType';

export enum AdminEntityLevel {
  System = 1,
  Organization = 2,
  Product = 3,
  Form = 4
}

export enum AdminWorkflowEntityLevel {
  System = 1,
  Organization = 2,
  Product = 3,
  Form = 4,
  PageTemplate = 5
}

export enum EditEntityLevel {
  Organization = 1,
  Product = 2,
  Form = 3,
}

export enum EntityLevel {
  Product = 1,
  Form = 2,
}

export enum WorkflowContextEntityLevel {
  Product = 1,
  Form = 2,
  PageTemplate = 3
}

export enum WorkflowEntityLevel {
  Organization = 1,
  Product = 2,
  Form = 3,
  PageTemplate = 4
}

export enum ROLES {
  Fiserv_Division_Admin = "Fiserv Division Admin",
  Fiserv_Portfolio_Manager = "Fiserv Portfolio Manager",
  Fiserv_Implementation_Manager = "Fiserv Implementation Manager",
  Intake_Submitter = "Intake Form Submitter",
  Form_Respondent = "Form Respondent",
  Channel_Partner = "Channel Partner",
  Admin = "Admin",
  Restricted_Admin = "Restricted Admin",
  Restricted_Admin_Read_Only = "Restricted Admin - Read Only",
  Client_Executive = 'Client Executive',
  Fiserv_Portfolio_Manager_Read_Only = "Fiserv Portfolio Manager - Read Only",
  Client_Implementation_Manager = "Client Implementation Manager",
  Relationship_Manager = "Relationship Manager",
  CCT_Admin = "CCT Admin",
  Division_Leader = "Division Leader",
  BU_Leader = "BU Leader",
  Delivery_Owner = "Delivery Owner",
  Delivery_Leader = "Delivery Leader",
  Sales_Executive = "Sales Executive",  
  Account_Manager = "Account Manager",
  Account_Team = "Account Team",
  Account_Executive="Account Executive",
  New_RM="New RM",
  Client_Admin = "Client Admin",
  OneView_Admin = "OneView Admin",
}

export enum FavoriteCategory {
  Responses = 1,
  Projects = 2,
}
export enum FavoriteRespondentCategory {
  Responses = 1
}

export enum FormTypeCategory {
  Intake = "Intake",
  Data_Gathering = "Data Gathering",
  Project_Workflow_Template = "Project Workflow Template"
}

export enum TemplateType {
  CSV = 1,
  Excel = 2,
  Xml = 3,
  Pdf = 4,
  Json = 5,
  Word = 6
}

export enum ExtractionStatuses {
  'NotStarted' = "Not Started",
  'In Progress' = "In Progress",
  'Failed' = "Failed",
  'Completed' = "Completed",
  'Deleted' = "Deleted"
}

export const MimeTypeConstants =
{
  IMAGE_JPG: "image/jpeg",
  IMAGE_GIF: "image/gif",
  IMAGE_PNG: "image/png",
  IMAGE_PDF: "application/pdf",
  XLS: "application/vnd.ms-excel",
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet (.xlsx)",
  JSON: "application/json"
}


export enum ResponsesStatuses {
  'NotStarted' = "Not Started",
  'InProgress' = "In Progress",
  'UnderReview' = "Under Review",
  'Overdue' = "Overdue",
  'Completed' = "Completed",
  'Rejected' = 'Rejected',
  'OnHold' = 'On Hold',
  'Cancel' = 'Cancel'
}

export const GUID_EMPTY = "00000000-0000-0000-0000-000000000000";

export enum RiskReasons {
  'Aggressive Schedule' = 'Aggressive Schedule',
  'Client Requested Project On Hold' = 'Client Requested Project On Hold',
  'Client Temperature is Red' = 'Client Temperature is Red',
  'Federal Regulatory Approval Required' = 'Federal Regulatory Approval Required',
  'Go Live/Delivery Date Changed' = 'Go Live/Delivery Date Changed',
  'Late Requirement/Design' = 'Late Requirement/Design',
  'On Hold By Fiserv' = 'On Hold By Fiserv',
  'Product Integration Delays' = 'Product Integration Delays',
  'Product Quality Issues' = 'Product Quality Issues',
  'Requirement Not Clear' = 'Requirement Not Clear',
  'Resource Availability' = 'Resource Availability',
  'Scope Not Finalized' = 'Scope Not Finalized',
  'Other (Please specify)' = 'other'
}


export const QuickSearchType = {
  PROJECT: 'project',
  RESPONSE: 'response',
  ARTICLE: 'article'
}
export const QuickSearchTypeValues = [
  { value: QuickSearchType.PROJECT, text: 'Projects' },
  { value: QuickSearchType.RESPONSE, text: 'Responses' },
  { value: QuickSearchType.ARTICLE, text: 'Articles' }
];

export enum PERMISSION_NEED_TO {
  Admin = 0,
  Create = 1,
  Read = 2,
  ReadAll = 3,
  Write = 4,
  WriteAll = 5,
  ReadPartial = 6,
  WritePartial = 7,
  Respond = 8,
  DelegateAll = 9,
  RespondAll = 10,
  RespondPartial = 11,
  RespondProductManager = 12
}

export enum UserNotificationState {
  New = 'New',
  UnRead = 'UnRead',
  Read = 'Read',
  Dismissed = 'Dismissed'
}

export const UserNotificationStateValues = [
  { value: UserNotificationState.New, text: 'New' },
  { value: UserNotificationState.UnRead, text: 'UnRead' },
  { value: UserNotificationState.Read, text: 'Read' },
  { value: UserNotificationState.Dismissed, text: 'Dismissed' }
];

export enum UserNotificationSeverity {
  Low = 1,
  Medium = 2,
  High = 3,
  Critical = 4
}

export const UserNotificationSeverityValues = [
  { value: UserNotificationSeverity.Low, text: 'Low' },
  { value: UserNotificationSeverity.Medium, text: 'Medium' },
  { value: UserNotificationSeverity.High, text: 'High' },
  { value: UserNotificationSeverity.Critical, text: 'Critical' }
];

export enum UserNotificationType {
  Project = 'Project',
  Response = 'Response',
  Article = 'Article',
  RequestAccess = 'RequestAccess'
}

// Maunual commitment
export const ManualCommitmentPlatform = {
  EPOC: 'EPOC',
  Northeast: 'Northeast',
  OmahaDebit: 'Omaha Debit',
  Optis: 'Optis (Full-Service Credit)'
};

export const ManualCommitmentPlatformOptions = [
  { value: ManualCommitmentPlatform.EPOC, name: 'EPOC' },
  { value: ManualCommitmentPlatform.Northeast, name: 'Northeast' },
  { value: ManualCommitmentPlatform.OmahaDebit, name: 'Omaha Debit' },
  { value: ManualCommitmentPlatform.Optis, name: 'Optis (Full-Service Credit)' }
];

export const ManualCommitmentFAP = {
  Cleartouch: 'Cleartouch',
  CUnify: 'CUnify',
  CUSA: 'CUSA',
  Charlotte: 'Charlotte',
  DNA: 'DNA',
  DataSafe: 'DataSafe',
  Galaxy: 'Galaxy',
  OnCu: 'OnCu',
  Premier: 'Premier',
  Reliance: 'Reliance',
  Spectrum: 'Spectrum',
  Signature: 'Signature',
  XP2: 'XP2'
};

export const ManualCommitmentFAPOptions = [
  { value: ManualCommitmentFAP.Cleartouch, name: 'Cleartouch' },
  { value: ManualCommitmentFAP.CUnify, name: 'CUnify' },
  { value: ManualCommitmentFAP.CUSA, name: 'CUSA' },
  { value: ManualCommitmentFAP.Charlotte, name: 'Charlotte' },
  { value: ManualCommitmentFAP.DNA, name: 'DNA' },
  { value: ManualCommitmentFAP.DataSafe, name: 'DataSafe' },
  { value: ManualCommitmentFAP.Galaxy, name: 'Galaxy' },
  { value: ManualCommitmentFAP.OnCu, name: 'OnCu' },
  { value: ManualCommitmentFAP.Premier, name: 'Premier' },
  { value: ManualCommitmentFAP.Reliance, name: 'Reliance' },
  { value: ManualCommitmentFAP.Spectrum, name: 'Spectrum' },
  { value: ManualCommitmentFAP.Signature, name: 'Signature' },
  { value: ManualCommitmentFAP.XP2, name: 'XP2' }
];


export const ManualCommitmentType = {
  NewProduct: 'New Product/ Product Feature',
  CustomFeatureEnhancement: 'Custom Feature/Enhancement',
  SLA: 'Service Level Agreement (SLA)',
  ImplementationLeadTime: 'Implementation Lead Time',
  ConsultationServices: 'Consultation Services',
  ResourceAllocation: 'Resource Allocation',
  RelationshipManagerFollowUpOnly: 'Relationship Manager Follow Up Only'
};

export const ManualCommitmentTypeOptions = [
  {
    value: ManualCommitmentType.NewProduct,
    name: 'New Product/ Product Feature',
    tooltip: 'A request to deliver a new solution/ new solution feature from Fiserv’s Product Vision prior to general availability' },
  {
    value: ManualCommitmentType.CustomFeatureEnhancement,
    name: 'Custom Feature/Enhancement',
    tooltip: 'A request to deliver custom functionality that will be used by a specific client(s).' 
  },
  {
    value: ManualCommitmentType.SLA,
    name: 'Service Level Agreement (SLA)' ,
    tooltip: 'A request for operational performance metrics outside of Fiserv’s current standards. ' 
  },
  { 
    value: ManualCommitmentType.ImplementationLeadTime,
    name: 'Implementation Lead Time',
    tooltip: 'A request to deliver an in-market product/ feature in less than the standard implementation lead-time for clients in an active renewal or for clients on the client health tracker report.' 
   },
  { 
    value: ManualCommitmentType.ConsultationServices,
    name: 'Consultation Services',
    tooltip: 'A request to provide advisory or consultative services for a niche area.'
   },
  { value: ManualCommitmentType.ResourceAllocation,
    name: 'Resource Allocation',
    tooltip: 'A request to provide dedicated Fiserv resources to a client.'
  },
  { value: ManualCommitmentType.RelationshipManagerFollowUpOnly,
    name: 'Relationship Manager Follow Up Only',
    tooltip: 'A client request requiring the RM to take action/ follow up on the deliverable and a formal approval by another team/ BU is not required.' }
];

export const ManualCommitmentObligationType = {
  ContractualWCredits: 'Contractual with Credits',
  ContractualWoCredits: 'Contractual without Credits',
  VerbalCommitment: 'Verbal Commitment',
  ExecutiveSponsoredCommitment: 'Executive Sponsored Commitment',
  RelationshipManagerCommitment: 'Relationship Manager Commitment'
};

export const ManualCommitmentObligationOptions = [
  {
    value: ManualCommitmentObligationType.ContractualWCredits,
    name: 'Contractual with Credits',
    tooltip: 'A commitment made to a client, where commitment language is included in the legal contract and includes credits if the commitment is missed.'
   },
  { 
    value: ManualCommitmentObligationType.ContractualWoCredits,
    name: 'Contractual without Credits' ,
    tooltip: 'A commitment made to a client, where commitment language is included in the legal contract without credits if the commitment is missed.'
   },
  { 
    value: ManualCommitmentObligationType.VerbalCommitment,
    name: 'Verbal Commitment',
    tooltip: 'A commitment made to a client for a product or service that is not included in a Fiserv contract but still requires approval. (ie, client requests to be a pilot client for a new solution).'
  },
  {
    value: ManualCommitmentObligationType.ExecutiveSponsoredCommitment,
    name: 'Executive Sponsored Commitment',
    tooltip: 'A commitment requested by or agreed to by a Fiserv Executive Leader on behalf of a client.'
   },
  {
    value: ManualCommitmentObligationType.RelationshipManagerCommitment,
    name: 'Relationship Manager Commitment',
    tooltip: 'A commitment made to a client where the RM is primarily responsible to deliver on the request and a formal approval by another team/BU is not required.'
   }
];

export const ManualCommitmentStatusCCT = {
  NewRequest: 'New Request',
  UnderReview: 'Under Review',
  AdditionalInformationRequired: 'Additional Information Required',
  ApprovedNoChanges: 'Approved - No Changes',
  ApprovedWithChanges: 'Approved - With Changes',
  NotApproved: 'Not Approved',
  OnHold: 'On Hold',
  Canceled: 'Canceled'
};

export const ManualCommitmentStatusCCTOptions = [
  { value: ManualCommitmentStatusCCT.NewRequest, name: 'New Request' },
  { value: ManualCommitmentStatusCCT.UnderReview, name: 'Under Review' },
  { value: ManualCommitmentStatusCCT.AdditionalInformationRequired, name: 'Additional Information Required' },
  { value: ManualCommitmentStatusCCT.ApprovedNoChanges, name: 'Approved - No Changes' },
  { value: ManualCommitmentStatusCCT.ApprovedWithChanges, name: 'Approved - With Changes' },
  { value: ManualCommitmentStatusCCT.NotApproved, name: 'Not Approved' },
  { value: ManualCommitmentStatusCCT.OnHold, name: 'On Hold' },
  { value: ManualCommitmentStatusCCT.Canceled, name: 'Canceled' },
];

export const ManualCommitmentStatusRM = {
  InProgress: 'In Progress',
  Completed: 'Completed',
  OnHold: 'On Hold',
  Canceled: 'Canceled'
};

export const ManualCommitmentStatusRMOptions = [
  { value: ManualCommitmentStatusRM.InProgress, name: 'In Progress' },
  { value: ManualCommitmentStatusRM.Completed, name: 'Completed' },
  { value: ManualCommitmentStatusRM.OnHold, name: 'On Hold' },
  { value: ManualCommitmentStatusRM.Canceled, name: 'Canceled' }
];

export const ManualCommitmentProduct = {
  NA: 'N/A',
  Other: 'Other'
};

export const ManualCommitmentProductOptions = [
  { value: ManualCommitmentProduct.NA, name: 'N/A' },
  { value: ManualCommitmentProduct.Other, name: 'Other' }
];

export const ManualCommitmentBU = {
  BillerSolutions: 'Biller Solutions',
  CoreAccountProcessingSolutions: 'Core Account Processing Solutions (CAPS)',
  CardServices: 'Card Services',
  DigitalIntegratedFinancialSolutions: 'Digital and Integrated Financial Solutions (DFS)',
  DigitalBillPaymentSolutions: 'Digital and Bill Payment Solutions (DPS)',
};

export const ManualCommitmentBUOptions = [
  { value: ManualCommitmentBU.BillerSolutions, name: 'Biller Solutions' },
  { value: ManualCommitmentBU.CoreAccountProcessingSolutions, name: 'Core Account Processing Solutions (CAPS)' },
  { value: ManualCommitmentBU.CardServices, name: 'Card Services' },
  { value: ManualCommitmentBU.DigitalIntegratedFinancialSolutions, name: 'Digital and Integrated Financial Solutions (DFS)' },
  { value: ManualCommitmentBU.DigitalBillPaymentSolutions, name: 'Digital and Bill Payment Solutions (DPS)' }
];


export const ManualCommitmentExecLeaders = {
  Frank: 'Frank Bisignano',
  John: 'John Gibbons',
  Dudley: 'Dudley White',
  Whitney: 'Whitney Stewart Russell',
  Matt: 'Matt Wilcox',
  Patrick: 'Patrick Davie',
  Other: 'Other'
};

export const ManualCommitmentExecLeadersOptions = [
  { value: ManualCommitmentExecLeaders.Frank, name: ManualCommitmentExecLeaders.Frank , email: 'ceo@fiserv.com'},
  { value: ManualCommitmentExecLeaders.John, name: ManualCommitmentExecLeaders.John , email: 'john.gibbons@fiserv.com' },
  { value: ManualCommitmentExecLeaders.Dudley, name: ManualCommitmentExecLeaders.Dudley , email: 'dudley.white@fiserv.com' },
  { value: ManualCommitmentExecLeaders.Whitney, name: ManualCommitmentExecLeaders.Whitney , email: 'whitney.stewartrussell@fiserv.com' },
  { value: ManualCommitmentExecLeaders.Matt, name: ManualCommitmentExecLeaders.Matt , email: 'matthew.wilcox@fiserv.com' },
  { value: ManualCommitmentExecLeaders.Patrick, name: ManualCommitmentExecLeaders.Patrick , email: 'patrick.davie@fiserv.com' },
  { value: ManualCommitmentExecLeaders.Other, name: 'Other' }
];


export const ManualCommitmentYesNo = {
  Yes: 'Yes',
  No: 'No'
};

export const ManualCommitmentYesNoOptions = [
  { value: ManualCommitmentYesNo.Yes, name: 'Yes' },
  { value: ManualCommitmentYesNo.No, name: 'No' }
];

export const CurrentLoggedInUser = 'currentLoggedInUser';
export const COMMITMENT_INTAKE_FILTER = 'commitments-intake-filter';
export const COMMITMENT_INTAKES_TAB_NAME = 'commitment-intakes-tab-name';
export const COMMITMENT_DASHBOARD_PATH = 'commitmentdashboard';
export const COMMITMENT_INTAKES_PATH = 'commitments-intake';
export const IS_PAGE_TEMPLATE_ADMIN = 'IsPageTemplateAdmin';

export const ManualCommitmentSearchSuggestionNames = {
  Title: 'title',
  ClientTitle: 'clientTitle',
  ClientDuns: 'clientDuns',
  ProductName: 'productName'
};

export const OneViewType = 'oneView';