import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { IProducts, IProduct } from '../shared/product';
import { IQuery } from '../shared/query';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export class ProductService {

  private productsAPIURL = 'products';
  private exportproductsAPIURL = 'products/ExportProducts';

  constructor(private http: HttpClient) {

  }

  getProducts(queryParams: IQuery, ignoreFavorites: boolean = false) {
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }
    if(ignoreFavorites){
      params = params.append('ignoreFavorites', ignoreFavorites.toString());
    }
    return this.http.get(this.productsAPIURL, { params, observe: 'response' });
  }

  getRefreshProducts(queryParams: IQuery, ignoreFavorites: boolean = false) {
    let params = new HttpParams();
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };
    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }
    if (ignoreFavorites) {
      params = params.append('ignoreFavorites', ignoreFavorites.toString());
    }
    return this.http.get(this.productsAPIURL, {headers:httpOptions.headers, params, observe: 'response' });
  }

  exportProducts(Params) {
    let params = new HttpParams();
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };
    for (const key of Object.keys(Params)) {
      const value = Params[key];
      params = params.append(key, value);
    }    
    return this.http.get(this.exportproductsAPIURL, { headers: httpOptions.headers, params: params, observe: 'response', responseType: 'blob' });

    //return this.http.put<any>(`${this.exportproductsAPIURL}`, params,

    //  {

    //    observe: 'response', responseType: 'blob' as 'json'

    //  });
  }

  getProductDetailsById(productId: number) {
    return this.http.get<IProduct>(`${this.productsAPIURL}/${productId}`);
  }

  createProduct(data) {
    return this.http.post<IProduct>(this.productsAPIURL, data);
  }

  updateProduct(productId, data) {
    return this.http.put<IProduct>(`${this.productsAPIURL}/${productId}`, data);
  }

  deleteProduct(productId: number): Observable<IProduct> {
    return this.http.delete<IProduct>(`${this.productsAPIURL}\\${productId}`);
  }

  getAllSuggestions() {
    return this.http.get<IProduct[]>(`${this.productsAPIURL}/allSuggestions`);
  }
}
