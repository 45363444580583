import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { UserService } from './services/user.service';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { COMMITMENT_INTAKES_TAB_NAME, COMMITMENT_INTAKE_FILTER } from './core/constants';
import { Subject, timer } from 'rxjs';
import { FormsService } from 'src/app/services/forms.service';
import { UserFilterService } from './services/user.filter.service';
import { NoticeBoardService } from './services/noticeboard.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { environment } from '../environments/environment';
import { AuthenticationService } from './SharedModule/services/authentication.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit, OnDestroy  {
  private readonly _destroying$ = new Subject<void>();
  private readonly _idleTimeout: number = 900;
  private readonly _timeoutPeriod: number = 5;
  private readonly _beforeTimeout: number = 600;
  loggedInUser: string = "";
  loggedIn: boolean = false;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  isAnonymous: boolean = true;
  notice: any = {};
  noticeClosed: boolean = false;
  isBlankHeader: boolean = false;
  isCommitmentDashboardPage: boolean = false;
  isLoginPage: boolean = false;
  isCCT: boolean = environment.isCCT;

  constructor( 
    private userService: UserService,
    private router: Router, 
    private activatedRoute: ActivatedRoute,
    private titleService: Title, 
    private authenticationService: AuthenticationService,
    private idle: Idle, 
    private keepalive: Keepalive,
    private formService: FormsService,
    private userFilterService: UserFilterService,
    private noticeBoardService: NoticeBoardService) {

    this.setIdleTimeOut(idle, keepalive);
    this.isAnonymous = window.location.href.indexOf('survey') > -1; // || window.location.href.indexOf('login') > -1;
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  getQueryParam(name) {
    var hashes = location.search.replace('?', '').split('&');
    for (var i = 0; i < hashes.length; i++) {
      var hash = hashes[i].split('=');
      if (hash[0].toLowerCase() === name.toLowerCase()) {
        return hash[1];
      }
    }
    return null;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  ngOnInit(): void {
    if (this.isAnonymous) {
      this.loggedIn = false;
    }
    else if (this.isInDocumentPages()) {
      this.isBlankHeader = true;
    }
    else {
      this.checkoutAccount();
    }

    this.judgeThePageByTitle();
  }

  private judgeThePageByTitle() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === "primary"),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        this.titleService.setTitle(event["title"] ? event["title"] : "Fiserv");
        // TODO: - ask if need to add the check for environment.isCCT???
        const pageTitle = event["title"];
        this.isCommitmentDashboardPage = pageTitle === 'Client Dashboard';
        this.isLoginPage = pageTitle === 'Sign in';
      });
  }

  checkoutAccount() {
    this.loggedIn = this.authenticationService.isLoggedIn();
    if (!this.loggedIn) {
      return;
    }
    
    this.setLoginTimestamp();
    this.getNotifications();
    //this.idle.watch();
    this.timedOut = false;
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      const eventUrl = event['url'];
      if (eventUrl === '/' || eventUrl.indexOf('/?code') > -1) {
        this.router.navigate(["dashboard"]);
      }
      else if (eventUrl.indexOf('/auth') > -1) {
        //this.router.navigate(["dashboard/project"]);
        if (this.isCCT)
          this.router.navigate(["clientdashboard"]);
        else
          this.router.navigate(["dashboard/mydashboard"]);
      }
    });
  }

  //#region ** #283257 ticket number **
  // These empty functions force window and containerPage div to realize scroll function faster.
  // (The scroll function which is written in footer component)
  public onContainerPageScroll(): void {
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll($event) {
  }
  //#endregion

  private isInDocumentPages() {
    const documentDownloadUrls = [
      '/document-files/',
      '/documents/',
      '/folders/documents/',
      '/document-folders/'
    ];

    for (const docUrl of documentDownloadUrls) {
      if (window.location.href.indexOf(docUrl) > -1) {
        return true;
      }
    }

    return false;
  }
 

  setLoginTimestamp() {
    this.userService.callUserListData().subscribe(user => {
      this.userService.setUserListData(user);
      const { accessToken } = this.authenticationService.getLogin(); 
      this.userService.setLoginData(user, accessToken.substring(-20)).subscribe((data) => {
        console.log("userdetails entered in DB");
      }, (error) => {
        console.log(`Error:${error}`);
      });
    }, (error) => {
      console.log(`Error:${error}`);
    });
  }

  getNotifications() {
    this.noticeBoardService.getNoticeboardData().subscribe(noticedata => {
      this.notice = noticedata;
    });
  }

  setIdleTimeOut(idle: Idle, keepalive: Keepalive) {
    // idle timeout of 900 seconds, for testing purposes.
    idle.setIdle(this._idleTimeout);
    // timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(this._timeoutPeriod);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    /*idle.setInterrupts([
      new EventTargetInterruptSource(
        this.element.nativeElement,'keydown scroll mousewheel')
    ]);*/

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.'
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      // dad46f23-cf7d-478a-a173-879a5923bce0
      //this.router.navigate([]);
      this.formService.triggerSave$.next('1');
      timer(0, 5000).subscribe(() => {
        this.authenticationService.removeLogin();
        this.userFilterService.clearFilter();
        localStorage.removeItem('commitments-intake-search');
        localStorage.removeItem(COMMITMENT_INTAKE_FILTER);
        localStorage.removeItem(COMMITMENT_INTAKES_TAB_NAME);
        if (environment.env === 'prod')
          this.authenticationService.logoutRedirect(`${environment.pingIdOAuthSettings.logoutUrl}?TargetResource=${environment.pingIdOAuthSettings.redirectUri}/logout.html`);
        else
          this.authenticationService.logoutRedirect(`${environment.pingIdOAuthSettings.redirectUri}/logout.html`);    
      });

      //this.router.navigate(['/']);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!';
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!'
    });

    const aboutToEnded = new Date();
    aboutToEnded.setSeconds(this._beforeTimeout);
    idle.onInterrupt.subscribe(() => {
      if (aboutToEnded < new Date() && this.idle.isRunning() && this.authenticationService.isTokenExpired()) {
        // Refresh token
        this.authenticationService.refreshToken().then((response) => {
          this.reset();
        });
      }
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);
    keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();
    });
    this.reset();
  }

  reset() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  closeNotice() {
    this.noticeClosed = true;
  }

  getAppContainerClasses() {
    return {
      'container-page get-notification-hight': (this.notice.showNoticeBoard && !this.noticeClosed),  
      'container-page': !(this.notice.showNoticeBoard && !this.noticeClosed) && !this.isCommitmentDashboardPage,  
      'commitment-dashboard-container-page': (this.isCommitmentDashboardPage )  
    };
  }
}
