import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ICommitmentIntakeDto } from '../models/commitment-intake-d-t-o';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';
import { CommitmentIntakeService } from 'src/app/services/commitment-intake.service';
import { AlertInput } from '../models/alert-input';
import { AlertType } from '../enums/alert-type.enum';
import { ManageCommitmentListService } from '../services/manageCommitmentList.service';

@Component({
  selector: 'tr[app-commitment-intake-mapping-row]',
  templateUrl: './commitment-intake-mapping-row.component.html',
  styleUrls: ['./commitment-intake-mapping-row.component.css']
})
export class CommitmentIntakeMappingRowComponent implements OnInit {
  @Input() public rowIndex: number = 0;
  @Input() public item: ICommitmentIntakeDto;
  @Input() public commitmentIntakeList: ICommitmentIntakeDto[];
  @Input() public hasMapOrUnmapPermission = false;

  @Output() private alertChanged = new EventEmitter<AlertInput>();
  @Output() private onOpenMapFulfillment = new EventEmitter<{ projectDataSource: string, suggestion?: any }>();
  @Output() private onOpenMappedDetailsModal = new EventEmitter<{ projectDataSource: string }>();
  @Output() private onOpenUnmapModal = new EventEmitter();

  public getFulfillmentSuggestionsClicked = false;
  public getOpportunitySuggestionsClicked = false;
  public currentIndex: number;
  public blueThemeMode = false;

  private _alertInput: AlertInput = {
    alertType: AlertType.Success,
    message: ''
  };
  
  constructor(
    private userService: UserService,
    private permission: PermissionsService, 
    private _manageCommitmentService: ManageCommitmentListService,
    private _commitmentIntakeService: CommitmentIntakeService) { 
      this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r);
  }

  ngOnInit(): void {
  }
  
  public canGetOpportunitySuggestion(item: ICommitmentIntakeDto): boolean {
    if (item?.status == 'Canceled') {
      return false;
    }
    const result = environment.excludedDatasources.indexOf(item.datasource) === -1
      && !this._manageCommitmentService.isReadOnlyCommiment(item.accessLevel)
      && !item?.opportunityId
      && (this.permission.hasWriteAccessOnCommitmentIntake());

    return result;
  }


  public canGetProjectSuggestion(item: ICommitmentIntakeDto): boolean {
    if (item?.status == 'Canceled') {
      return false;
    }

    const result = item.datasource.toLowerCase() !== 'clarity'
      && !this._manageCommitmentService.isReadOnlyCommiment(item.accessLevel)
      && !item.projectNumber
      && this.permission.hasWriteAccessOnCommitmentIntake();

    return result;
  }

  getOpportunityMappingSuggestions(item: ICommitmentIntakeDto): void {
    this.getFulfillmentSuggestionsClicked = false;
    this.getOpportunitySuggestionsClicked = false;
    item.opportunitySuggestionsIsCalled = false;
    item.fulfillmentSuggestionsIsCalled = false;
    this._alertInput.message = '';
    this._commitmentIntakeService.getOpportunityMappingSuggestions(item.id).subscribe((res: any) => {
      this.commitmentIntakeList.forEach(element => {
        if (element.id === item.id) {
          element.opportunitySuggestions = res.body;
        }
      });
      item.opportunitySuggestionsIsCalled = true;
      this.getOpportunitySuggestionsClicked = true;
    }, (error) => {
      this._alertInput.alertType = AlertType.Error;
      this._alertInput.message = error;
      this.alertChanged.emit(this._alertInput);
      this.getOpportunitySuggestionsClicked = true;
      item.opportunitySuggestionsIsCalled = true;
    });

    this.currentIndex = item.id;
  }

  getClarityMappingSuggestions(item: ICommitmentIntakeDto): void {
    this.getFulfillmentSuggestionsClicked = false;
    this.getOpportunitySuggestionsClicked = false;
    item.opportunitySuggestionsIsCalled = false;
    item.fulfillmentSuggestionsIsCalled = false;
    this._alertInput.message = '';
    this._commitmentIntakeService.getClarityMappingSuggestions(item.id).subscribe((res: any) => {
      this.commitmentIntakeList.forEach(element => {
        if (element.id === item.id) {
          element.fulfillmentSuggestions = res.body;
        }
      });
      item.fulfillmentSuggestionsIsCalled = true;
      this.getFulfillmentSuggestionsClicked = true;
    },
    (error) => {
      this._alertInput.alertType = AlertType.Error;
      this._alertInput.message = error;
      this.alertChanged.emit(this._alertInput);
      this.getFulfillmentSuggestionsClicked = true;
      item.fulfillmentSuggestionsIsCalled = true;
    });

    this.currentIndex = item.id;
  }

  removeSuggestions(item: any): any {
    // Just remove on UI
    this.currentIndex = -1;
  }

  public canUnmapMappedProject(item: ICommitmentIntakeDto): boolean {
    const result = item.canUnmapFulfillment && item.datasource.toLowerCase() !== 'clarity'
      && item.datasource.toLowerCase() !== 'servicenow'
      && item.datasource.toLowerCase() !== 'cims'
      && this.hasMapOrUnmapPermission
      && !this._manageCommitmentService.isReadOnlyCommiment(item.accessLevel);

    return result;
  }

  public canUnmapMappedOpportunity(item: ICommitmentIntakeDto): boolean {
    const result = item.canUnmapFulfillment && item.datasource.toLowerCase() !== 'ecrm'
      && (item.datasource.toLowerCase() !== 'cims' || (item.datasource.toLowerCase() === 'cims'))
      && this.hasMapOrUnmapPermission
      && !this._manageCommitmentService.isReadOnlyCommiment(item.accessLevel);

    return result;
  }

  public openMapFulfillment(projectDataSource: string, suggestion?: any) {
    this.onOpenMapFulfillment.emit({ projectDataSource: projectDataSource.toLowerCase(), suggestion});
  }

  openMappedDetailsModal(projectDataSource: string) {
    this.onOpenMappedDetailsModal.emit({ projectDataSource});
  }

  public openUnmapModal() {
    this.onOpenUnmapModal.emit();
  }
}
