import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from "@angular/common/http";
import { IQuery } from '../shared/query';
import { InterceptorSkipHeader } from '../core/constants';
import { CustomHttpParamEncoder } from '../shared/custom-encoder';

@Injectable({
  providedIn: 'root'
})
export class LandingService {
  private _landingBaseUrl: string = "landing";

  constructor(private http: HttpClient) { }

  getClarityProjectDetailsById(searchClarityProjectNumber: string, queryParams: IQuery) {
    const options = { 'refresh': 'true' }
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
    for (let key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }
    
    return this.http.get(`${this._landingBaseUrl}/getClarityProjectDetailsById/${searchClarityProjectNumber}`, { headers: options, params: params });
  }

  geteCRMOppurtunityDetailsById(queryParams: IQuery) {
    const options = { 'refresh': 'true' }
    let params = new HttpParams({
      encoder: new CustomHttpParamEncoder()
    });
    for (let key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }
    
    return this.http.get(`${this._landingBaseUrl}/getecrmoppurtunitydetailsbyid`, { headers: options, params: params });
  }
}
