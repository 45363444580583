<form [formGroup]="reasonForm" autocomplete="off">
  <div class="drawer-contents commitment-dw  col-xs-12 col-sm-12 col-md-12 ">
    <div class="dw-header">
      <div class="modal-header-container">
        <button id="closeButton" [ngClass]="{'blueThemeMode':(blueThemeMode)}" type="button" data-dismiss="modal"
          tabindex="0" autofocus="" aria-label="Close popup" class="arrow-dw" (click)="closeDrawer();"
          (keydown.tab)="keyDown($event)">
          <span class="material-icons">
            arrow_back
          </span>
        </button>

        <h4 class="modal-title  ">
          {{detailsCommitment.commitmentTitle}}
          <!--<p>{{detailsCommitment.commitmentDescription}}</p>-->
        </h4>
      </div>

      <!-- <div class="right-wrapper d-flex" style="display: flex;">
        <button (click)="closeDrawer();" type="button" data-dismiss="modal" aria-label="Close popup" id="closeDw" class="close"><span aria-hidden="true">×</span></button>
      </div> -->
    </div>

    <div class="dw-body">

      <div class="dw-box">
        <div class="dw-box-title-container" role="button" data-toggle="collapse" data-target="#collapseStepOne"
             aria-expanded="true">
          <h3 [ngClass]="{'foregroundColor':(blueThemeMode)}">Commitment Details</h3>
        </div>
        <div class="dw-box-content-container collapse show" id="collapseStepOne" aria-labelledby="headingOne">
          <ul class="content-list mb-2">
            <li class="flex-column">
              <div class="title">Title</div>
              <div class="content">
                {{detailsCommitment.commitmentTitle || 'Not Available'}}
              </div>
            </li>
          </ul>
          <ul class="content-list mb-2" *ngIf="!(isClientExecutive || isClientAdmin)">
            <li class="flex-column">
              <div class="title">Description</div>
              <div class="content">{{detailsCommitment.commitmentDescription || 'Not Available'}}</div>
            </li>
          </ul>
          <ul class="content-list mb-2">
            <li class="half-width products">
              <div class="title">Product(s)</div>
              <div class="content">{{detailsCommitment.productNames || 'Not Available'}}</div>
            </li>
          </ul>
          <ul class="content-list">
            <!-- <li class="half-width">
              <div class="title">
                Type <i id="commitmentTypeTooltip" class="fa fa-question-circle question-icon text-muted"
                  ref="tooltip"></i>
              </div>
              <div class="content">{{detailsCommitment.commitmentType || 'Not Available'}}</div>
            </li> -->
            <li class="half-width">
              <div class="title">
                Stage <i title="Stage of the Commitment"
                         class="fa fa-question-circle question-icon text-muted" ref="tooltip" data-toggle="tooltip"
                         data-animation="false"></i>
              </div>
              <div class="content"> {{detailsCommitment.commitmentStatus || 'Not Available'}}</div>
            </li>
            <!-- <li class="half-width">
              <div class="title">Delivery Date <i title="Planned Delivery Date for commitment" class="fa fa-question-circle question-icon text-muted" ref="tooltip" data-toggle="tooltip" data-animation="false"></i></div>
              <div class="content">{{detailsCommitment.plannedCommittedDeliveryDate | date: content['dateFormat']}}</div>
            </li> -->
            <li class="half-width">
              <div class="title">Fulfillment ID </div>
              <div class="content">{{detailsCommitment.projectNumber || 'Not Available'}}</div>
            </li>

            <li class="half-width">
              <div class="title">Delivery Owner </div>
              <div class="content"
                   *ngIf="!isDeliveryOwnerShown() || (!isDeliveryOwnerTextShown() && !isDeliveryOwnerLinkShown())">
                Not Available
              </div>
              <div class="content" *ngIf="isDeliveryOwnerShown() && isDeliveryOwnerLinkShown()">
                <a href="mailto: {{detailsCommitment.deliveryOwnerEmailAddress}}"
                  title="{{detailsCommitment.deliveryOwner}}"
                  [ngClass]="{'blueThemeMode':(blueThemeMode)}">{{detailsCommitment.deliveryOwner}}</a>
              </div>
              <div class="content"
                   *ngIf="isDeliveryOwnerShown() && isDeliveryOwnerTextShown() && !isDeliveryOwnerLinkShown()">
                <label>{{detailsCommitment.deliveryOwner}}</label>
              </div>
            </li>
            <li class="half-width">
              <div class="title">Project Manager </div>
              <div class="content"
                   *ngIf="!isProjectManagerShown() || (!isProjectManagerTextShown() && !isProjectManagerLinkShown())">
                Not Available
              </div>
              <div class="content" *ngIf="isProjectManagerShown() && isProjectManagerLinkShown()">
                <a href="mailto: {{detailsCommitment.projectManagerEmailAddress}}"
                  title="{{detailsCommitment.projectManager}}"
                  [ngClass]="{'blueThemeMode':(blueThemeMode)}">{{detailsCommitment.projectManager}}</a>
              </div>
              <div class="content"
                   *ngIf="isProjectManagerShown() && isProjectManagerTextShown() && !isProjectManagerLinkShown()">
                <label>{{detailsCommitment.projectManager}}</label>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="dw-box">
        <div class="dw-box-title-container" role="button" data-toggle="collapse" data-target="#collapseStepTwo"
             aria-expanded="true">
          <h3 [ngClass]="{'foregroundColor':(blueThemeMode)}">Note</h3>
        </div>

        <div class="col-md-12 p-0 alert-input-popup" *ngIf="alertInputPopup.message !== ''">
          <!-- Error Msg Alert -->
          <div class="alert alert-dismissible alert-danger" *ngIf="alertInputPopup.alertType === alertType.Error">
            <button type="button" class="close" data-dismiss="alert" aria-label="close"
              (keydown.tab)="keyDown($event)" (click)="clearAlertMessage()">
              ×
            </button>
            <i class="material-icons">warning</i>
            <p [innerHtml]="alertInputPopup.message"></p>
          </div>
          <!-- Success Msg Alert -->
          <div class="alert alert-success alert-dismissible"
               *ngIf="alertInputPopup.alertType === alertType.Success">
            <button type="button" class="close" data-dismiss="alert" aria-label="close"
              (keydown.tab)="keyDown($event)" (click)="clearAlertMessage()">
              ×
            </button>
            <i class="material-icons" aria-hidden="true">check_circle</i>
            <p [innerHtml]="alertInputPopup.message"></p>
          </div>
        </div>

        <div class="dw-box-content-container collapse show" id="collapseStepTwo" aria-labelledby="headingTwo">
          <div id="noteMainDivCommitment">
            <form [formGroup]="commitmentNoteForm">
              <textarea id="noteTextareaForCommitment"
                        formControlName="noteForCommitment" class="form-control noteTextareaForCommitment"
                        *ngIf="noteVisibilityCheck()" style="" name="noteForCommitment"
                        [disabled]="!clientEntityKey"></textarea>
              <pre id="notePreTagForCommitment" [hidden]="noteVisibilityCheck()">{{noteForCommitment}}</pre>
            </form>
            <button *ngIf="noteVisibilityCheck() && clientEntityKey !== null" type="button"
                    class="btn fdl-btn myButton" [ngClass]="{'blueThemeMode':(blueThemeMode)}"
                    (click)="saveNoteForCommitment()" (keydown.tab)="keyDown($event)">Save</button>
            <p *ngIf="noteVisibilityCheck()">
              Reminder: Notes are Client Visible.
            </p>
          </div>

        </div>
      </div>
      <div class="dw-box" *ngIf="isCustomClient && !isClientLoadedPartofOneView && !isClientAdmin && !isClientExecutive && !isClientSuperUser">
        <div class="dw-box-title-container" role="button" data-toggle="collapse" data-target="#collapseStepThree"
             aria-expanded="true">
          <h3>Client User Assignment</h3>
        </div>
        <div class="dw-box-content-container collapse show" id="collapseStepThree">
          <br />
          <label>The Project is visible to below client users: </label>
          <table class="table table-hover" *ngIf="SelectedCEUsersDetails">
            <thead>
              <tr role="row">
                <th role="columnheader">Name</th>
                <th role="columnheader">Email</th>
                <th role="columnheader">Group</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let SelectedCEUser of SelectedCEUsersDetails;trackBy: trackByFn">
                <td role="cell">{{SelectedCEUser.name}}</td>
                <td role="cell">{{SelectedCEUser.email}}</td>
                <td role="cell">{{SelectedCEUser.restrictedUserGroup}}</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <div >
            <button style="background: #f60; margin-bottom: 15px; margin-right: 18px; color: white; font-weight:bold" type="button" *ngIf="!isClientExecutive && !this.isClientAdmin &&
(((isCCTAdmin && pageTemplateAdmin) || (isCCTAdmin && !pageTemplateAdmin && detailsCommitment.cretedById === userData.id))
 || isAdmin || isRestrictedAdmin || isRm || isSalesExecutive || isAccountManager ||isAccountExecutive || isNewRM)" class="btn fdl-btn btn-primary-orange float-right"
                    data-toggle="modal" data-target="#dashboardEditCommitmentUsers" (click)="onOpenUpdateUsersModal(detailsCommitment)">
              Edit User Assignment
            </button>
          </div>
        </div>
      </div>

      <div class="reason-field"> 
        <div class="fdl-2 button-container">
          <button class="btn fdl-btn btn-secondary-light" [ngClass]="{'blueThemeMode':(blueThemeMode)}"
            id="dCancel" (click)="closeDrawer();" (keydown.tab)="keyDown($event)">Close</button>
          <button type="button" *ngIf="canDelete()"
            class="btn fdl-btn btn-primary-orange float-right myButton"
            [ngClass]="{'blueThemeMode':(blueThemeMode)}" (click)="openDeleteModal()"
            (keydown.tab)="keyDown($event)">Remove</button>
          <!-- <div>
            <button type="button" class="btn fdl-btn btn-primary-orange float-right" (click)="submitRisk()">Submit</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</form>
