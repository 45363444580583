import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ICommitmentIntakeDetailsDto } from '../models/commitment-intake-d-t-o';
import { CommitmentIntakeService } from 'src/app/services/commitment-intake.service';
import { CommitmentManualService } from 'src/app/services/commitment-manual.service';
import { take } from 'rxjs/operators';
import { ManageCommitmentService } from '../services/manageCommitment.service';
import { Subscription } from 'rxjs';
import { COMMITMENT_INTAKES_TAB_NAME } from 'src/app/core/constants';
import { CommitmentEcrmService } from 'src/app/services/commitment-ecrm.service';
import { ManageCommitmentListService } from '../services/manageCommitmentList.service';
import * as $ from 'jquery';

@Component({
  selector: 'commitment-intake-view',
  templateUrl: './commitment-intake-view.component.html'
})
export class CommitmentIntakeViewComponent implements OnInit, OnDestroy {
  public commitmentDetails: ICommitmentIntakeDetailsDto;
  public commitmentForm: FormGroup;
  public originalFormValue: ICommitmentIntakeDetailsDto;
  public commitmentProjectKey: string;
  public readOnly: boolean = false;
  public isSubmitted: boolean = false;
  public isError: boolean = false;
  public errorMsg: string = '';
  public isSuccess: boolean = false;
  public anyChangedSuccess: boolean = false;
  public successMsg: string;

  private _routeSubscription: Subscription;
  private _commitmentIntakeSubscription: Subscription;
 
  constructor(private router: Router,
    private _route: ActivatedRoute,
    private _commitmentIntakeService: CommitmentIntakeService,
    private _commitmentManualService: CommitmentManualService,
    private _commitmentEcrmService: CommitmentEcrmService,
    private _manageCommitmentService: ManageCommitmentService,
    private _manageCommitmentListService: ManageCommitmentListService) { }
 
  ngOnInit(): void {
    this._manageCommitmentService.initialCommitmentIntakeForm();
    this.commitmentForm = this._manageCommitmentService.commitmentIntakeForm;
    this._routeSubscription = this._route.params
    .pipe(take(1))
    .subscribe((params) => {
      this.commitmentProjectKey = params['projectKey'];
      this.getCommitmentData();
    });
  }

  handleAfterCreateCommitmentSuccessful() {
    if (this._manageCommitmentListService.createSuccessMessage) {
      this.isSuccess = true;
      this.successMsg = this._manageCommitmentListService.createSuccessMessage;
      this.scrollToAlert();
      this._manageCommitmentListService.createSuccessMessage = null;
    }
  }

  getCommitmentData() {
    this._commitmentIntakeSubscription = this._commitmentIntakeService.getCommitmentById(this.commitmentProjectKey).pipe(take(1)).subscribe(response => {
      const pageResponse = response as ICommitmentIntakeDetailsDto;
      this.commitmentDetails = {
        ...pageResponse
      };

      if (localStorage.getItem(COMMITMENT_INTAKES_TAB_NAME) === 'Presale'){
        this.readOnly = true;
      }

      if (this.commitmentDetails?.accessLevel === 'Read') {
        this.readOnly = true;
      }

      this._manageCommitmentService.isReadOnlyForm = this.readOnly;
      this.loadDataToForm();
      this.handleAfterCreateCommitmentSuccessful();
    }, (error) => {
      this.isError = true;
      this.errorMsg = error;
    });
  }

  loadDataToForm() {
    const rootFormKeys = Object.keys(this.commitmentForm.controls);
    rootFormKeys.forEach(rootFormKey => {
      const nestedForm = this.commitmentForm.get(rootFormKey);
      const currentValue = this._manageCommitmentService.transFormFormValue(rootFormKey, this.commitmentDetails);
      nestedForm.setValue(currentValue, {
        emitViewToModelChange: false
      });
      nestedForm.markAsPristine();
    });
    this.originalFormValue = this.commitmentForm.getRawValue();
  }
  
  getSelectedLabel(options: any[], value: string) {
    const selected = options.find(o => o.value === value);
    if(selected) {
      return selected.name;
    }
    return value;
  }

  getBuSelectedLabels(options: any[], item: any) {
    const selected = options.filter(o => o.id === item.id);
    if(selected) {
      return selected.map(x => x.title).join(',');
    }
    return item;
  }

  updateCommitment(data: { formData: ICommitmentIntakeDetailsDto }) {
    const { formData } = data;
    this.isSubmitted = true;
    this.isSuccess = false;
    this.isError = false;
    if (this.commitmentForm.invalid) {
      return;
    }
    
    if (formData.datasource.toLocaleLowerCase() === 'manual') {
      this.updateManualCommitment(formData);
    }
    else if(formData.datasource.toLocaleLowerCase() === 'ecrm') {
      this.updateEcrmCommitment(formData);
    }
    else {
      this.updateCommitmentIntake(formData);
    }
  }

  updateCommitmentIntake(updatedData: ICommitmentIntakeDetailsDto) {
    const keys = Object.keys(this.commitmentForm.controls);
    if (this.commitmentForm.invalid) {
      const errors = [];
      keys.forEach((key: string) => {
        const control = this.commitmentForm.get(key);
        if (control.invalid) {
          errors.push(`${key}: ${JSON.stringify(control.errors)}`);
        }
      });

      this.isError = true;
      this.errorMsg = errors.join(',');
      this.isSubmitted = false;
      return;
    }
    
    const updates = this.getTheFieldUpdates();
    const updatedKeys = Object.keys(updates);
    if (!updatedKeys || updatedKeys.length === 0) {
      this.isError = true;
      this.errorMsg = 'There is no field to update';
      this.isSubmitted = false;
      return;
    }

    this._commitmentIntakeService.updateCommitment(this.commitmentProjectKey, {
      rowVersion: this.commitmentDetails['rowVersion'],
      updates: updates
     }).subscribe(() => {
      this.isSuccess = true;
      this.anyChangedSuccess = true;
      this.successMsg = `Request - '${updatedData.commitmentTitle}' is updated successfully.`;
      this.getCommitmentData();
    },
    error => {
      this.isError = true;
      this.errorMsg = error;
    }, () => {
      this.isSubmitted = false;
      this.scrollToAlert();
    });
  }

  updateManualCommitment(updatedData: ICommitmentIntakeDetailsDto) {
    this._commitmentManualService.updateCommitment(this.commitmentProjectKey, updatedData).subscribe(() => {
      this.isSuccess = true;
      this.anyChangedSuccess = true;
      this.successMsg = `Request - '${updatedData.commitmentTitle}' is updated successfully.`;
      this.getCommitmentData();
    },
    error => {
      this.isError = true;
      this.errorMsg = error;
    }, () => {
      this.isSubmitted = false;
      this.scrollToAlert();
    });
  }

  updateEcrmCommitment(updatedData: ICommitmentIntakeDetailsDto) {
    this._commitmentEcrmService.updateCommitment(this.commitmentProjectKey, updatedData).subscribe(() => {
      this.isSuccess = true;
      this.anyChangedSuccess = true;
      this.successMsg = `Request - '${updatedData.commitmentTitle}' is updated successfully.`;
      this.getCommitmentData();
    },
    error => {
      this.isError = true;
      this.errorMsg = error;
    }, () => {
      this.isSubmitted = false;
      this.scrollToAlert();
    });
  }

  private getTheFieldUpdates() {
    const keys = Object.keys(this.commitmentForm.controls);
    const updates = {};
    keys.forEach((key: string) => {
      const control = this.commitmentForm.get(key);
      const originalValue = this.originalFormValue[key];
      if (!control.value && !originalValue) {
        return true;
      }
      const isArray = Array.isArray(control.value);
      if (isArray && !this.areSequenceEqual(control.value, originalValue)) {
        updates[key] = control.value;
      } else if (control.value !== originalValue) {
        updates[key] = control.value;
      }
    });

    return updates;
  }

  private areSequenceEqual(array1: any[], array2: any[]) {
    if (array1.length !== array2.length) {
      return false;
    }

    const isDifference = array1.some(obj1 => !array2.some(obj2 => {
      return this.isObjectEqual(obj1, obj2);
    }));
    return !isDifference;
  }

  private isObjectEqual (obj1: any, obj2: any) {
    if (typeof(obj1) === 'object') {
      const obj1Keys = Object.keys(obj1);
      if (obj1Keys.length !== Object.keys(obj2).length) {
        return false;
      }
      return !(obj1Keys.some(p => !this.isObjectEqual(obj1[p], obj2[p])));
    }
    
    return obj1 === obj2;
  }

  private scrollToAlert() {
    setTimeout(function () {
      $('.alert')[0].scrollIntoView(true);
    }, 100);
  }
  
  backToList(): void {
    // To make sure the list will be reloaded if there was any updated. Cannot use isSucess field because of user behavior
    this._manageCommitmentListService.isFromViewCancelling = !this.anyChangedSuccess;
    this.router.navigate(['self-service/commitment-intakes']);
  }

  ngOnDestroy(): void {
    this._routeSubscription?.unsubscribe();
    this._commitmentIntakeSubscription?.unsubscribe();
    this.commitmentForm?.reset();
  }
}