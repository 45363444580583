import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { select } from "d3-selection";
import { scaleBand, scaleLinear } from "d3-scale";
import { axisBottom, axisLeft } from "d3-axis";
import { stack } from "d3-shape";
import { sum, transpose } from "d3-array";
import { ManageClientDashboardService } from '../services/manageClientDashboard.service';
import { ClientDashboardContactDTO, DashboardCommitmentDTO } from '../models/client-dashboard-d-t-o';

@Component({
  selector: 'app-client-dashboard-exporting',
  templateUrl: './client-dashboard-exporting.component.html',
  styleUrls: ['./client-dashboard-exporting.component.css'],
})
export class ClientDashboardExportingComponent implements OnChanges {
  headerColumns = [
    'Status',
    'Project Number',
    'Title',
    'Stage',
    'Original Delivery Date',
    'Target Delivery Date',
    'Date Change By Stakeholder',
    'Product'
  ];
  keys = [
    'isRisk',
    'projectNumber',
    'commitmentTitle',
    'commitmentStatus',
    'originalPlannedCommittedDeliveryDate',
    'displayPlannedCommittedDeliveryDate',
    'totalOfDaysChanged',
    'productNames'
  ];
  @Input() note = '';
  @Input() summary: any = {};
  @Input() contacts: ClientDashboardContactDTO[] = [];
  @Input() commitmentProducts: any;
  @Input() allCommitments: DashboardCommitmentDTO[] = [];
  @Input() pageFilterForm: any = [];
  @Input() clientTypeSearch: any = 'duns';
  @Input() typeOfCommitmentCalander: any = 'quarter';
  @Input() isCalendarReady: boolean = false;
  @Input() calendarCommitments: any = [];
  @Input() isPdfExport: boolean = false;
  @Input() manualRowsTotalPages: number;
  @Input() allRowsTotalPages: number;
  @Input() nonProjectTotalCount: any;
  @Input() projectTotalCount: any;
  @Input() commitmentProjectRows: DashboardCommitmentDTO[];
  @Input() commitmentManualRows: DashboardCommitmentDTO[];

  // private queryParam: IQuery;
  private isPageLoad: boolean = true;

  commitmentCalendarNumbers: any = [];
  dataCommitmentCalendarError: boolean = false;
  errorMsg: any;
  commitmentStatsCount: any = [];
  applyFilter: boolean = false;
  pageProducts: any = [];
  commitmentsError: boolean = false;
  commitmentStatuses: any = [];
  commitmentStats: any = {
    Active: 0,
    Cancelled: 0,
    Completed: 0,
    'On Hold': 0,
    'Not Started': 0,
    'In Planning': 0,
    Total: 0,
    Delivered: 0
  };

  constructor(private _manageClientDashboardService: ManageClientDashboardService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes) {
      return;
    }
    if (changes.allCommitments) {
      this.loadCommitmentCalendar();
    }

    if (changes.isPdfExport?.currentValue) {
      this.initData();
    }
  }

  initData() {
    this.pageFilterForm = '';
    this.getDataDateWise();
  }

  getDataDateWise() {
    this.applyFilter = false;
    this.loadCommitmentCalendar();
    this.clientCommitmentSummaray();
    this.commitmentsbyProduct();
  }

  clientCommitmentSummaray() {
    this.summary = this._manageClientDashboardService.getSummaryData(this.allCommitments);
    this.commitmentStats = {
      'In Planning': 0,
      'In Progress': 0,
      Cancelled: 0,
      Completed: 0,
      'On Hold': 0,
      'Not Started': 0,
      Total: 0,
      "Delivered": 0
    };
    this.commitmentStatsCount = {
      'In Progress': 0,
      'In Planning': 0,
      Cancelled: 0,
      Completed: 0,
      'On Hold': 0,
      'Not Started': 0,
      Total: 0,
      "Delivered": 0
    };
    if (this.summary && this.summary.website && this.summary.website != null) {
      if (this.summary.website.indexOf('https://') == '-1') {
        this.summary.website = `https://${this.summary.website}`;
      }
    }
    if (!this.summary) {
      return;
    }
    if (this.summary && this.summary.relationshipManagerName) {
      let initials = this.summary.relationshipManagerName.split(' ');
      this.summary.rmIn = initials[0][0];
      if (initials[1] && initials[1][0]) {
        this.summary.rmIn = initials[0][0] + initials[1][0];
      }
    }

    if (this.summary && this.summary.accountManagerName) {
      let seinitials = this.summary.accountManagerName.split(' ');
      this.summary.amIn = seinitials[0][0];
      if (seinitials[1] && seinitials[1][0]) {
        this.summary.amIn = seinitials[0][0] + seinitials[1][0];
      }
    }

    if (!this.summary.commitmentStatuses) {
      return;
    }

    let totalCommitmentStatuses = 0;
    this.summary.commitmentStatuses.forEach(c => {
      totalCommitmentStatuses += c.commitmentCount;
    });
    
    this.commitmentStats['Total'] = totalCommitmentStatuses;
    for (let k in this.summary.commitmentStatuses) {
      const commitmentCount = this.summary.commitmentStatuses[k].commitmentCount;
      const commitmentStatus = this.summary.commitmentStatuses[k].commitmentStatus;
      if (commitmentCount > 0) {
        this.commitmentStats[commitmentStatus] = (commitmentCount / totalCommitmentStatuses) * 100;
      }
      this.commitmentStatsCount[commitmentStatus] = commitmentCount;
      this.commitmentStats[commitmentStatus] = parseFloat(this.commitmentStats[commitmentStatus]).toFixed(2);
    }
  }

  /*******************/
  loadCommitmentCalendar() {
    if (!this.isCalendarReady && !this.dataCommitmentCalendarError && !this.isPageLoad) {
      return;
    }

    const commitmentCalendarData = this._manageClientDashboardService.getDeliveryCalendarData(this.allCommitments, this.typeOfCommitmentCalander);
    if (!commitmentCalendarData) {
      this.calendarCommitments = [];
    }
    else {
      this.calendarCommitments = [...commitmentCalendarData];
    }

    this.isPageLoad = false;
    this.commitmentCalendarNumbers = [];
    const commitmentCalendarLabels = [];
    const commitmentCalendarData_notStartedCommitments = [];
    const commitmentCalendarData_inPlanningCommitments = [];
    const commitmentCalendarData_inProgressCommitments = [];
    const commitmentCalendarData_onHoldCommitments = [];
    const commitmentCalendarData_canceledCommitments = [];
    const commitmentCalendarData_completedCommitments = [];
    const commitmentCalendarData_deliveredCommitments = [];

    for (let k in this.calendarCommitments) {
      const calendarCommitment = this.calendarCommitments[k];
      commitmentCalendarLabels.push(calendarCommitment.label);
      this.commitmentCalendarNumbers.push(commitmentCalendarData[k].numberOfCommitments);
      commitmentCalendarData_notStartedCommitments.push(this.calendarCommitments[k].notStartedCommitments);
      commitmentCalendarData_inPlanningCommitments.push(this.calendarCommitments[k].inPlanningCommitments);
      commitmentCalendarData_inProgressCommitments.push(calendarCommitment.inProgressCommitments);
      commitmentCalendarData_deliveredCommitments.push(calendarCommitment.deliveredCommitments);
      commitmentCalendarData_onHoldCommitments.push(calendarCommitment.onHoldCommitments);
      commitmentCalendarData_canceledCommitments.push(calendarCommitment.canceledCommitments);
      commitmentCalendarData_completedCommitments.push(calendarCommitment.completedCommitments);
    }

    const commitmentCalendars = [
      {
        label: 'Not Started',
        values: commitmentCalendarData_notStartedCommitments,
        color: '#36454f',
      },
      {
        label: 'In Planning',
        values: commitmentCalendarData_inPlanningCommitments,
        color: '#B7B7B7',
      },    
      {
        label: 'In Progress',
        values: commitmentCalendarData_inProgressCommitments,
        color: '#FF5E0E',
      },
      {
        label: 'Delivered',
        values: commitmentCalendarData_deliveredCommitments,
        color: '#0080BF',
      },
      {
        label: 'Completed',
        values: commitmentCalendarData_completedCommitments,
        color: '#0080BF',
      },
      {
        label: 'On Hold',
        values: commitmentCalendarData_onHoldCommitments,
        color: '#ffc626',
      },
      {
        label: 'Cancelled',
        values: commitmentCalendarData_canceledCommitments,
        color: '#90d5ff',
      },
    ];

    const svg = select("svg#deliveryCalendarChart");
    svg.selectAll("*").remove();
    setTimeout(() => {
      this.commitmentCalendarGraph(
        commitmentCalendarLabels,
        commitmentCalendars
      );
    }, 300);
  }

  /***Commitments By Products */
  commitmentsbyProduct() {
    if (!this.commitmentProducts) {
      this.pageProducts = [];
      return;
    }
    this.pageProducts = [...this.commitmentProducts];
  }

  calculateSemiDonutRotation(status: string): number {
    return Math.round((-45 + this.commitmentStats[status] * 1.8) * 100) / 100;
  }

  /**
   * *
   * @param labels
   * @param Month
   * @param type
   */
  commitmentCalendarGraph(monthLabels, data) {
    const svg = select("svg#deliveryCalendarChart");
    if (!svg.node()) {
      return;
    }

    const margins = [5, 5, 50, 20];
    const { clientWidth, clientHeight } = svg.node();
    const innerWidth = clientWidth - margins[1] - margins[3];
    const innerHeight = clientHeight - margins[0] - margins[2];

    const sums = monthLabels.map((_, i) => sum(data.map(({ values }) => values[i])));
    const max = Math.max(...sums);

    // Rendering the legend items to show the notes
    const legendItems = select("#legend")
      .selectAll("li")
      .data(data);

    legendItems
      .enter()
      .append("li")
      .text(({ label }) => label)
      .append("span")
      .style("background-color", ({ color }) => color);

    legendItems
      .select("li")
      .text(({ label }) => label);

    // Rendering the xAxis & yAxis
    const scaleX = scaleBand().padding(0.3).domain(monthLabels).range([0, innerWidth]);
    const scaleY = scaleLinear()
      .domain([0, max])
      .nice()
      .range([innerHeight, 0]);

    const xAxisWrapper = svg
      .append("g")
      .attr("class", "xaxis")
      .attr("transform", `translate(${margins[3]}, ${margins[0] + innerHeight})`);
    const yAxisWrapper = svg
      .append("g")
      .attr("transform", `translate(${margins[3]}, ${margins[0]})`);

    let xAxisTickIndex = 0;
    const xAxis = axisBottom(scaleX).tickFormat((value) => {
      xAxisTickIndex += 1;
      if (monthLabels.length >= 25 && monthLabels.length < 50 && (xAxisTickIndex - 1) % 2 !== 0) {
        return null;
      }

      if (monthLabels.length >= 50 && monthLabels.length < 75 && (xAxisTickIndex - 1) % 4 !== 0) {
        return null;
      }

      if (monthLabels.length >= 75 && monthLabels.length < 100 && (xAxisTickIndex - 1) % 5 !== 0) {
        return null;
      }

      if (monthLabels.length >= 100 && (xAxisTickIndex - 1) % 10 !== 0) {
        return null;
      }

      return value;
    }).tickPadding(8).tickSize(0);

    const yAxis = axisLeft(scaleY)
      .tickFormat((value) => {
        if (value % 1 !== 0) {
          return null;
        }

        return `${Math.round(value)}`
      }).tickPadding(8).tickSize(0);

    xAxisWrapper.call(xAxis);
    yAxisWrapper.call(yAxis);

    // Re-calculate the transformation (translation & rotation) of the xAxis's ticks
    const xAxisBar = svg.select(".xaxis");
    if (!xAxisBar.node()) {
      return;
    }

    const xAxisTickText = xAxisBar.selectAll(".tick text").node();
    if (!xAxisTickText) {
      return;
    }

    const xAxisBarWidth = xAxisBar.node().getBoundingClientRect().width;
    const xAxisTickLabelWidth = xAxisTickText.getBoundingClientRect().width;
    const xAxisTickLabelHeight = xAxisTickText.getBoundingClientRect().height;
    const maxRotationAngle = xAxisBarWidth / xAxisTickLabelHeight;

    const xAxisTicks = xAxisBar.selectAll(".tick");
    const xAxisTicksTransforms = xAxisTicks.nodes().map((t) => {
      const transform = t.getAttribute("transform");
      const transformValue = transform.split('(').pop().split(')')[0];
      return Number.parseFloat(transformValue);
    });

    xAxisTicks.attr("transform", (_, i) => {
      const xAxisTickTranslate = xAxisTicksTransforms[i] - (xAxisTickLabelWidth - (xAxisTickLabelWidth * 0.5));
      return `translate(${xAxisTickTranslate}, 25) rotate(${-maxRotationAngle})`;
    });

    // Rendering the grid lines
    const xGridGroup = svg.append('g').attr('class', 'grid')
      .attr("transform", `translate(${margins[3]}, ${margins[0] + innerHeight})`);

    const yGridGroup = svg.append('g').attr('class', 'grid')
      .attr("transform", `translate(${margins[3]}, ${margins[0]})`);

    // Create a grid line generator for the x-axis and y-axis.
    const xGridLine = axisBottom(scaleX).tickFormat(() => null).tickSize(-clientHeight);
    const yGridLine = axisLeft(scaleY).tickFormat(() => null).tickSize(-clientWidth);

    xGridGroup.call(xGridLine);
    yGridGroup.call(yGridLine);

    // Rendering the bars chart
    const dataMatrix = transpose(data.map((type) => type.values));
    const stackData = stack().keys(Object.keys(data))(dataMatrix);

    const chartRoot = svg
      .append("g")
      .attr("class", "chart")
      .attr("transform", `translate(${margins[3]}, ${margins[0]})`);

    const stacks = chartRoot.selectAll(".layer").data(stackData);

    const layer = stacks
      .enter()
      .append("g")
      .attr("class", "layer")
      .attr("fill", (_, i) => data[i].color);

    const bandWidth = scaleX.bandwidth();
    layer
      .selectAll("rect")
      .data((d) => d)
      .enter()
      .append("rect")
      .attr("x", (_, i) => scaleX(monthLabels[i]))
      .attr("width", bandWidth)
      .attr("y", ([, end]) => scaleY(end))
      .attr("height", ([start, end]) => scaleY(start) - scaleY(end));
  }
}
