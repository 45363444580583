import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import Chart from 'chart.js/auto'
import { ManageClientDashboardService } from '../services/manageClientDashboard.service';
import { DashboardCommitmentDTO } from '../models/client-dashboard-d-t-o';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-client-commitment-statistics',
  templateUrl: './client-commitment-statistics.component.html',
  styleUrls: ['./client-commitment-statistics.component.css']
})
export class ClientCommitmentStatisticsComponent implements OnInit, OnChanges {
  @Input() isSummaryLoading: boolean;
  @Input() slaDashActive: boolean;
  @Input() allCommitments: DashboardCommitmentDTO[] = [];
  @Input() commitmentStats: any;
  @Output() slaActiveTab = new EventEmitter<boolean>();
  @Output() onCommitmentStatsChange = new EventEmitter<object>();
  public blueThemeMode = false;
  
  public labels: string[];
  public isSla: boolean = false;
  public selectedSlaProduct: string = '';
  public commitmentStatsCount: any;
  public showGraph: boolean = true;
  public slaPercentage: any;
  public monthlyData: any = [];
  public monthlyPData: any = [];
  public counter: number;
  public slaData: any[] = [];
  public chart: any;
  public slaProductSearch: { key: string, title: string }[];

  constructor(
    private _manageClientDashboardService: ManageClientDashboardService, 
    private userService: UserService)
  { 
      this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r); 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.allCommitments) {
      this.commitmentStats = { 'Not Started': 0, 'In Planning': 0, 'In Progress': 0, 'Cancelled': 0, "Completed": 0, "On Hold": 0, "Total": 0, "Delivered": 0 }
      this.commitmentStatsCount = { 'Not Started': 0, 'In Planning': 0, 'In Progress': 0, 'Cancelled': 0, "Completed": 0, "On Hold": 0, "Total": 0, "Delivered": 0 };
      const summary = this._manageClientDashboardService.getSummaryData(this.allCommitments);

      if (summary.commitmentStatuses) {
        let totalCommitmentStatuses = 0;
        summary.commitmentStatuses.forEach(c => {
          totalCommitmentStatuses += c.commitmentCount;
        });

        this.commitmentStats['Total'] = totalCommitmentStatuses;
        for (let k in summary.commitmentStatuses) {
          const commitmentCount = summary.commitmentStatuses[k].commitmentCount;
          const commitmentStatus = summary.commitmentStatuses[k].commitmentStatus;
          if (commitmentCount > 0) {
            this.commitmentStats[commitmentStatus] = (commitmentCount / totalCommitmentStatuses) * 100;
          }
          this.commitmentStatsCount[commitmentStatus] = commitmentCount;
          this.commitmentStats[commitmentStatus] = parseFloat(this.commitmentStats[commitmentStatus]).toFixed(2);
        }
      }

      this.onCommitmentStatsChange.emit(this.commitmentStats);
      this.graphInit();
    }
  }

  ngOnInit(): void {
  }

  setSlaActiveTab(value: boolean) {
    this.slaActiveTab.emit(value);
  }

  slaGraphChange() {
    this.showGraph = false;
    let productValue = this.selectedSlaProduct;
    if (productValue == '')
      this.createChart(this.labels, this.monthlyData, this.monthlyPData, 'all')
    else {
      let monthlyData = this.slaData[productValue].Data;
      let monthlyPData = this.slaData[productValue].pData;
      this.createChart(this.labels, monthlyData, monthlyPData, productValue)
    }
    this.showGraph = true;
  }

  graphInit() {
    var date = new Date();
    let lastYearDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.labels = [];
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    this.monthlyPData = [];
    this.monthlyData = [];
    this.counter = 0;
    lastYearDate.setMonth(lastYearDate.getMonth() - 12);
    for (let i = 0; i < 13; i++) {
      this.labels.push(`${monthNames[lastYearDate.getMonth()]}-${lastYearDate.getFullYear()}`)
      for (let k in this.slaData) {
        if (!this.slaData[k].Data) {
          this.slaData[k].Data = [];
          this.slaData[k].pData = [];
        }
        let data_to_push = 0;
        let performance_data_to_push = 0;
        let slaCat = this.slaData[k];
        let slaPerCat = this.slaData[k]['p']
        if (slaCat[lastYearDate.getFullYear()] && slaCat[lastYearDate.getFullYear()][lastYearDate.getMonth()]) {
          data_to_push = parseInt(slaCat[lastYearDate.getFullYear()][lastYearDate.getMonth()]);
          this.counter = this.counter + 1;
        }
        let index = `${lastYearDate.getFullYear()}.${lastYearDate.getMonth()}`;
        if (!this.monthlyData[index])
          this.monthlyData[index] = 0;

        this.monthlyData[index] = this.monthlyData[index] + data_to_push;
        this.slaData[k].Data.push(data_to_push)
        if (slaPerCat[lastYearDate.getFullYear()] && slaPerCat[lastYearDate.getFullYear()][lastYearDate.getMonth()])
          performance_data_to_push = parseInt(slaPerCat[lastYearDate.getFullYear()][lastYearDate.getMonth()]);

        if (!this.monthlyPData[index])
          this.monthlyPData[index] = 0;

        this.monthlyPData[index] = parseInt(this.monthlyPData[index]) + performance_data_to_push;

        if (slaPerCat[lastYearDate.getFullYear()] && slaPerCat[lastYearDate.getFullYear()][lastYearDate.getMonth()])
          this.slaData[k].pData.push(performance_data_to_push)
      }
      lastYearDate.setMonth(lastYearDate.getMonth() + 1);
    }
    this.createChart(this.labels, this.monthlyData, this.monthlyPData, 'all')
  }

  createChart(labels: any, monthArray: any = this.monthlyData, monthlyPdata: any = this.monthlyPData, performance: any = 'all') {
    let dataMonthArray = [];
    let label = ''
    for (let k in monthArray) {
      if (performance == 'all') {
        let monthCount: number = monthArray[k] as unknown as number;
        dataMonthArray.push((monthCount / this.slaProductSearch.length).toFixed(2));
      } else {
        dataMonthArray.push(monthArray[k]);
      }
    }
    let count: number = 0;
    let total: number = 0;
    for (let k in monthlyPdata) {
      count = count + 1;
      total = total + parseInt(monthlyPdata[k]);
    }
    if (performance == 'all') {
      count = this.counter;
      label = 'All';
    } else {
      label = performance
    }

    const slaPercentage: any = total / count;
    this.slaPercentage = parseFloat(slaPercentage).toFixed(2)

    const data = {
      labels: labels,
      datasets: [{
        label: '', // Name the series
        data: dataMonthArray, // Specify the data values array
        fill: true,
        borderColor: '#2196f3', // Add custom color border (Line)
        backgroundColor: 'orange', // Add custom color background (Points and Fill)
        borderWidth: 1 // Specify bar border width
      },
      ]
    };

    const options: any = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          onClick: function (e) {
            e.stopPropagation();
          },
          display: false,
          position: 'bottom',
          labels: {
            fontColor: 'white',
            fontSize: 0,
          },
        },
        animation: {
          duration: 0
        }
      },
    };
    if (this.chart) this.chart.destroy();

    const ctx: any = document.getElementById('areaChart') as HTMLElement;
    if (!ctx) {
      return;
    }
    
    this.chart = new Chart(ctx, {
      type: 'line',
      data: data,
      options: options,
    });
  }
}
