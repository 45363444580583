<div class="form-container mt-3 mb-3">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12">
                <div id="clientName" class="part">
                    <h1>{{clientName}}</h1>
                </div>
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="part">
                    <h2>Client Dashboard Review Trail</h2>
                    <div *ngIf="noData">
                        <p>
                            There is no update
                        </p>
                    </div>
                    <div class="table-responsive overflow-auto">
                        <table id="parent" aria-describedby="tblDesc"
                            class="f-12 table table-hover main-nested-table table-label"
                            *ngIf="data && data.length > 0">
                            <thead class="parent-thead">
                                <tr role="row">
                                    <th>Modified Date</th>
                                    <th>Project Id</th>
                                    <th>Title</th>
                                    <th>Modified By</th>
                                </tr>
                            </thead>
                            <tbody class="parent-tbody">
                                <tr role="row" *ngFor="let timeline of data" class="single-timeline-area grey"
                                    tabindex="0" (click)="selectProject(timeline.projectKey)"
                                    (keydown.enter)="keypress(timeline.projectKey)" data-toggle="modal"
                                    data-target="#reviewTrail" [id]="'tr' + timeline.projectKey">
                                    <td role="cell">
                                        <div class="timeline-date"></div>
                                        <div class="single-timeline-content">
                                            <div class="timeline-text dateDiv">
                                                <p class="h6">{{timeline.modifiedOn | dateFormat}}</p>
                                                <p>{{timeline.modifiedOn | formatDateTime}}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td role="cell">
                                        <div class="single-timeline-content">
                                            <div class="timeline-text">
                                                <p class="h6">{{timeline.projectKey}}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td role="cell">
                                        <div class="single-timeline-content">
                                            <div class="timeline-text">
                                                <p class="h6">{{timeline.title}}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td role="cell">
                                        <div class="single-timeline-content">
                                            <div class="timeline-text">
                                                <p class="h6">{{timeline.modifiedByName}}</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="part">
                    <h2>Client Dashboard Note Review Trail</h2>
                    <div *ngIf="noteNoData">
                        <p>
                            There is no update
                        </p>
                    </div>
                    <div class="table-responsive overflow-auto">
                        <table aria-describedby="tblDesc" class="f-12 table table-hover main-nested-table table-label"
                            *ngIf="noteData && noteData.length > 0">

                            <thead class="parent-thead">
                                <tr role="row">
                                    <th>Modified Date</th>
                                    <th>Note</th>
                                    <th>Modified By</th>
                                </tr>
                            </thead>
                            <tbody class="parent-tbody">
                                <tr role="row" *ngFor="let timeline of noteData" class="single-timeline-area grey">
                                    <td role="cell">
                                        <div class="timeline-date"></div>
                                        <div class="single-timeline-content">
                                            <div class="timeline-text dateDiv">
                                                <p class="h6">{{timeline.createdOn | dateFormat}}</p>
                                                <p>{{timeline.createdOn | formatDateTime}}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td role="cell">
                                        <div class="single-timeline-content">
                                            <div class="timeline-text">
                                                <pre>{{timeline.note}}</pre>
                                            </div>
                                        </div>
                                    </td>
                                    <td role="cell">
                                        <div class="single-timeline-content">
                                            <div class="timeline-text">
                                                <p class="h6">{{timeline.createdByName}}</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-commitment-review-trail [projectKey]="projectKey" (modalClosed)="modalClosed()"></app-commitment-review-trail>