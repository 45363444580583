import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommitmentIntakeSearchTag } from '../models/commitment-intake-search-tag';
import { CommitmentIntakeSearchType } from '../enums/commitment-intake-search-type.enum';
import { CommitmentIntakeSearchLocalStorage } from '../models/commitment-intake-search-local-storage';
import { COMMITMENT_INTAKE_FILTER } from 'src/app/core/constants';
import { UserService } from 'src/app/services/user.service';
import { CommitmentIntakeSuggestionFields } from '../enums/commitment-intake-filter-fields.enum';

@Component({
  selector: 'app-commitment-intake-search',
  templateUrl: './commitment-intake-search.component.html',
  styleUrls: ['./commitment-intake-search.component.css']
})
export class CommitmentIntakeSearchComponent {
  showResetButton = false;
  public blueThemeMode = false;
  public currentFilterTags: CommitmentIntakeSearchTag[] = [];
  public searchFieldsValue: { 
    fieldName: string, 
    fieldDisplayName: string, 
    selected: string[], 
    fieldSuggestions: string[], 
    loading: boolean,
    originalSuggestions: string[]
   }[];

  @Input()
  public set searchFields(value: any) {
    if (!value) {
      return;
    }

    this.searchFieldsValue = value;
    const storedUserFiltering = localStorage.getItem(COMMITMENT_INTAKE_FILTER);
    if (!storedUserFiltering) {
      return;
    }

    const userFilteringData = <CommitmentIntakeSearchLocalStorage>JSON.parse(storedUserFiltering);
    const currentFilterTags = userFilteringData.searchCriteria.filter(r => r.fieldName !== 'Datasource');
    if (currentFilterTags.length === 0) {
      return;
    }

    this.showResetButton = true;
    currentFilterTags.forEach(item => {
      item.title = item.title;
      const searchFieldItem = this.searchFieldsValue.find(r => r.fieldName === item.fieldName);
      if (!searchFieldItem.selected) {
        searchFieldItem.selected = [];
      }

      if (!searchFieldItem.selected.includes(item.title)) {
        searchFieldItem.selected.push(item.title);
      }
    });

    this.currentFilterTags = [...currentFilterTags];
  }
  
  @Output()
  private onSearch = new EventEmitter<{ searchCriteria: CommitmentIntakeSearchTag[], shouldReset?: boolean }>();

  constructor(private userService: UserService) {
    this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r);
  }

  public onSearchFilterChanged(data: string[], searchType: string, fieldName: string): void {
    const searchCriteriaFromForm = this.currentFilterTags.filter(item => item.type !== searchType);
    const typeEnumForSort = CommitmentIntakeSuggestionFields[fieldName] >= 0 ? CommitmentIntakeSuggestionFields[fieldName] : CommitmentIntakeSearchType.Datasource;
    data.forEach(item => {
      const searchCriteria: CommitmentIntakeSearchTag = {
        title: item,
        type: searchType,
        fieldName: fieldName,
        typeEnumForSort: typeEnumForSort
      };
      searchCriteriaFromForm.push(searchCriteria);
    });
    this.currentFilterTags = searchCriteriaFromForm.sort((prev, current) => {
      const prevTagIndex = this.searchFieldsValue.findIndex(x => x.fieldName === prev.fieldName);
      const currentTagIndex = this.searchFieldsValue.findIndex(x => x.fieldName === current.fieldName);
      if (prevTagIndex === currentTagIndex) {
        return prev.typeEnumForSort < current.typeEnumForSort ? -1 : 1;
      }
      return prevTagIndex - currentTagIndex;
    });
  }

  public onDeleteSearchFilter(data: CommitmentIntakeSearchTag): void {
    this.currentFilterTags = this.currentFilterTags.filter(r => r !== data);

    for (let i = 0; i < this.searchFieldsValue.length; i++) {
      if (this.searchFieldsValue[i].fieldDisplayName === data.type) {
        this.searchFieldsValue[i].selected = this.searchFieldsValue[i].selected.filter(r => r !== data.title);
        break;
      }
    }
  }

  public onSearchClicked(shouldReset: boolean = false): void {
    const searchCriteria: CommitmentIntakeSearchTag[] = [];
    if (this.currentFilterTags.length > 0) {
      this.showResetButton = true;
      this.currentFilterTags.forEach(item => {
        searchCriteria.push({
          type: item.type,
          title: item.title,
          fieldName: item.fieldName,
          typeEnumForSort: item.typeEnumForSort
        });
      });
    } else {
      this.showResetButton = false;
    }
    this.onSearch.emit({ searchCriteria: searchCriteria, shouldReset });
  }

  public onResetClicked(): void {
    this.currentFilterTags = [];
    this.searchFieldsValue.forEach(item => {
      item.selected = [];
    });
    this.onSearchClicked(true);
  }
}
