<div class="commitment-intake-container">
  <div class="heading-container">
    <div class="bank-name" [ngClass]="(!isClient || isClientAdmin)?'':'bank-name-single-fi'">
      <div class="label-text-search" *ngIf="!isClient || isClientAdmin">Search Client By:</div>
      <div class="d-flex">
        <div class="bank-name-value" *ngIf="!isClient">
          <div class="fdl-select mid-width">
            <select [(ngModel)]="clientTypeSearch" (change)="clientTypeChange();" class="form-control" aria-label="Client Type">
              <option value="duns">DUNS</option>
              <option value="gud">GUD</option>
            </select>
          </div>
        </div>
        <div class="bank-name-value cursor-text">
          <p *ngIf="isClient && !isClientAdmin" class="client-name-wrapper">{{selectedClientEntityTitle}}</p>
          <div class="ng-select-input-group input-group" *ngIf="!isClient || isClientAdmin">
            <ng-select *ngIf="clientTypeSearch === 'duns' && (!isClient || isClientAdmin)"
              class="custom-search-input client-search-select search-query fdl-ng-typeahead"
              [(ngModel)]="selectedClientEntityKey"
              placeholder="Type Client to search"
              [items]="clientSuggestions"
              bindLabel="titleWithDuns" bindValue="id"
              (clear)="clearClientSelected()"
              (change)="clientSelected($event)"
              [editableSearchTerm]="true"
              [clearOnBackspace]="true"
              #clientSelect
              placeholder="{{ isClientSuggestionLoading ? 'Loading...' : 'Type Client to search'}}"
              (keydown)="handleSelectKeyDown($event, 'selectedClientEntityKey')"
              [virtualScroll]="true">
            </ng-select>
            <ng-select *ngIf="clientTypeSearch === 'gud' && (!isClient || isClientAdmin)" 
              class="custom-search-input client-search-select search-query fdl-ng-typeahead"
              [(ngModel)]="selectedClientEntityKey"
              placeholder="Type Client to search"
              [items]="clientSuggestions"
              bindLabel="titleWithDuns" bindValue="gud"
              (clear)="clearClientSelected()"
              (change)="clientSelected($event)"
              [editableSearchTerm]="true"
              [clearOnBackspace]="true"
              #clientSelect
              (keydown)="handleSelectKeyDown($event, 'selectedClientEntityKey')"
              [virtualScroll]="true">
            </ng-select>
            <span class="input-group-btn" *ngIf="!selectedClientEntityKey">
              <button type="button" class="btn"><i class="material-icons">search</i> </button>
            </span>
          </div>
        </div>
        <div class="ml-3 oneview-select-box">
          <ng-select *ngIf="isOneViewSelected && !isClient" [items]="oneViewClientSuggestions" 
              [clearable]="false" bindLabel="name" bindValue="id" placeholder="Type to search the client in OneView"
              [closeOnSelect]="false" [virtualScroll]="true" [clearOnBackspace]="false" [multiple]="true"
              [(ngModel)]="selectedOneViewClients" [ngModelOptions]="{standalone: true}" (change)="oneViewClientsSelected()">
              <ng-template ng-option-tmp let-itemTemp="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
                <span title="{{itemTemp.name}}"> {{itemTemp.name}} </span>
              </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
    <!-- Please add a class of mt-0 if the line number 57 and 58 not display on the UI -->
    <div class="last-refresh-note">
      Last login on <span>{{lastLoginTimeStamp}}</span>
    </div>
  </div>
  <div class="bank-name-label" *ngIf="!selectedClientEntityKey">
    Please select the client to view the commitments.
  </div>
  <div class="bank-name-label" *ngIf="commitmentStats['Total'] == 0 && selectedClientEntityKey && selectedClientEntityKey > 0 && !isRowsLoading">
    No
    Commitments Available.
  </div>
  <div class="box-container statistics pl-0">
    <div class="m-0 p-0">
      <section class="col-lg-12 advance-search-wrapper">
        <div class="advance-search-container">

          <div class=" form-border mb-3 collapse-view" autocomplete="off">
            <h2 class="h4 collapse-arrow  search-text" data-toggle="collapse" href="#collapseFilter" [ngClass]="{'foregroundColor':(blueThemeMode)}">
              Advanced Search
            </h2>

            <div class="icon-wrapper pdf-icon-btn"
                 [hidden]="isRowsLoading || !selectedClientEntityKey">
              <button class="download-icon" (click)="renderPdfExport()" [ngClass]="{'buttonColor':(blueThemeMode)}" aria-label="Download PDF">
                <div class="icon"></div>
              </button>
            </div>
            <div id="PPTDiv" class="fdl-2 btn-review-commitment" data-toggle="tooltip" data-placement="top"
                 title="Download PPT extract"
                 [hidden]="isClientExecutive || isClientAdmin || isRowsLoading || !selectedClientEntityKey">
              <button type="button" id="btnPPT" ref="tooltip" class="btn fdl-btn btn-primary-orange ml-2" [ngClass]="{'buttonColor':(blueThemeMode)}"
                aria-label="Download PPT extract" (click)="renderPPTExport()">
                <span style="align-content:center">PPT</span>
              </button>
            </div>
            <div id="ReviewTrailDiv" class="fdl-2 btn-review-commitment" data-toggle="tooltip" data-placement="top"
                 title="Click to view all review comments"
                 [hidden]="isClientExecutive || isClientAdmin || (!selectedClientDunsNumber && !selectedClientEntityKey)">
              <!--Audit Trail Button Starts  -->
              <button type="button" id="btnReviewCommitment" ref="tooltip" class="btn fdl-btn btn-primary-orange ml-3" [ngClass]="{'buttonColor':(blueThemeMode)}"
                      (click)="redirectToClientReviewTrailOverall()" aria-label="View all review comments"
                      *ngIf="!isClientExecutive && !isClientAdmin && (!!selectedClientDunsNumber || !!selectedClientEntityKey)">
                <span class="material-icons"> rate_review </span>
                <span class="btn-title">Review Trail</span>
              </button>
              <!--Audit Trail Button Ends  -->
            </div>
            <form [formGroup]="topFilterForm" autocomplete="off">
              <div class="filter-form-container-column2 show" id="collapseFilter" [ngClass]="{'blueThemeMode':(blueThemeMode)}">
                <div class="filter-section mr-10 ">
                  <label>Line of Business: </label>
                  <div class="filter-section line-of-business">
                    <div class="ng-select-input-group input-group">
                      <ng-select placeholder="Type to search"
                        class="ng-select dashboard-commitment-select"
                        [items]="lineOfBusinessOptions"
                        bindLabel="title" bindValue="title"
                        formControlName="lineOfBusiness"
                        (search)="onSelectSearch($event, 'lineOfBusiness')"
                        [clearOnBackspace]="true"
                        [editableSearchTerm]="true"
                        #lineOfBusinessSelect
                        (keydown)="handleSelectKeyDown($event, 'lineOfBusiness')"
                        [virtualScroll]="true">
                      </ng-select>
                      <span class="input-group-btn" *ngIf="isSearchButtonDisplay('lineOfBusiness')">
                        <button type="button" class="btn"><i class="material-icons">search</i> </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="filter-section search-right ">
                  <label> Product:</label>
                  <div class="filter-section product">
                    <div class="ng-select-input-group input-group">
                      <ng-select placeholder="Type to search"
                        class="ng-select dashboard-commitment-select"
                        [items]="productOptions"
                        bindLabel="title" bindValue="title"
                        formControlName="selectedProduct"
                        (search)="onSelectSearch($event, 'selectedProduct')"
                        [clearOnBackspace]="true"
                        [editableSearchTerm]="true"
                        #selectedProductSelect
                        (keydown)="handleSelectKeyDown($event, 'selectedProduct')"
                        [virtualScroll]="true">
                      </ng-select>
                      <span class="input-group-btn" *ngIf="isSearchButtonDisplay('selectedProduct')">
                        <button type="button" class="btn"><i class="material-icons">search</i> </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="filter-section search-left target-wrapper">
                  <label class="">Target Delivery Date:</label>
                  <div class="filters-date">
                    <div class="filter-section-date mr-3">
                      <label class="filter-section-date">From: </label>
                      <input class="form-control" max="{{topFilterForm.get('fromMonth').value}}" formControlName="fromMonth" type="month" name="fromMonth">
                    </div>
                    <div class="filter-section-date mr-0">
                      <label class="filter-section-date">To: </label>
                      <input class="form-control" min="{{topFilterForm.get('toMonth').value}}" formControlName="toMonth" type="month" name="toMonth">
                    </div>
                  </div>
                </div>
                <div class="fdl-2 d-flex width100">
                  <button type="button" class="btn fdl-btn btn-primary-orange ml-3" aria-label="Search" (click)="topSearch()" [ngClass]="{'buttonColor':(blueThemeMode)}">
                    Search
                  </button>
                  <button type="button" class="btn fdl-btn btn-secondary-light ml-3" aria-label="Reset" (click)="clearTopSearch()" [ngClass]="{'buttonColor':(blueThemeMode)}">
                    Reset
                  </button>
                  <div class="dropdown ml-auto view-contacts-dropdown">
                    <button class="btn btn-primary-orange dropdown-toggle btn-contacts" id="dropdownContactButton" type="button" data-toggle="dropdown" 
                    aria-expanded="false" [ngClass]="{'buttonColor':(blueThemeMode)}">
                      <div>Contacts</div>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown contact button">
                      <app-client-dashboard-contacts [contacts]="contacts"></app-client-dashboard-contacts>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="advance-search-container" *ngIf="selectedClientEntityTitle != '' && isCustomClient && !isClientLoadedPartofOneView && !isClientExecutive && !isClientAdmin && !isClientSuperUser">

          <div class=" form-border mb-3 collapse-view" autocomplete="off">
            <h2 class="h4 collapse-arrow  search-text" data-toggle="collapse" href="#collapseUserFilter" (click)="openUserFilterPage();">
              Custom Client User View
            </h2>
            <div class="filter-form-container-column2 show" id="collapseUserFilter">
              <div class="filter-section mr-10">
                <label>Client Users: </label>
                <div class="filter-section client-user">
                  <ng-select [items]="clientUserSuggestions" bindLabel="name" bindValue="id" placeholder="Type to search"
                             groupBy="selectedAllGroup" [selectableGroup]="true" [selectableGroupAsModel]="false"
                             [closeOnSelect]="false" [virtualScroll]="true" [clearOnBackspace]="false"
                             [multiple]="true" [(ngModel)]="selectedCEUsersForSearch" [ngModelOptions]="{standalone: true}" style="width: 500px">
                    <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> Select All
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                      <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.name}}
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="fdl-2 d-flex width100">
                <button type="button" class="btn fdl-btn btn-primary-orange ml-3" aria-label="Search"
                        (click)="addUserstoAdvanceSearch()">
                  Search
                </button>
                <button type="button" class="btn fdl-btn btn-secondary-light ml-3" aria-label="Reset"
                        (click)="addUserstocancelSearch()">
                  Reset
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class=" m-0 separate-form-container p-0">
      <section class="col-lg-12 summary-main-container">
        <div class="app-col-1">
          <div class="summary-main-container-panel" style="display: flex;">
            <div class="box-container statistics pl-0">
              <h2 [ngClass]="{'foregroundColor':(blueThemeMode)}">Commitment Statistics</h2>
              <app-client-commitment-statistics 
                [isSummaryLoading]="isRowsLoading"
                [slaDashActive]="slaDashActive"
                [allCommitments]="allCommitments"
                [commitmentStats]="commitmentStats"
                (slaActiveTab)="slaActiveTab($event)"
                (onCommitmentStatsChange)="onCommitmentStatsChange($event)">
              </app-client-commitment-statistics>
            </div>
          </div>
        </div>

        <div class="app-col-2">
          <div class="box-container commitments-calendar">
            <h2 [ngClass]="{'foregroundColor':(blueThemeMode)}">Delivery Calendar</h2>
            <app-client-commitment-calendar
              [allCommitments]="allCommitments"
              [isCalendarReady]="isRowsLoading === false"
              [typeOfCommitmentCalander]="typeOfCommitmentCalander"
              [isCommitmentCalendarError]="isCommitmentCalendarError"
              (setTypeOfCommitmentCalander)="setTypeOfCommitmentCalander($event)">
            </app-client-commitment-calendar>
          </div>
        </div>
        <div id="alertInput" class="col-md-12 p-0" *ngIf="alertInput.message !== ''">
          <!-- Error Msg Alert -->
          <div class="alert alert-dismissible alert-danger" *ngIf="alertInput.alertType === alertType.Error">
            <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="setAlertMsgToEmpty()">
              ×
            </button>
            <i class="material-icons">warning</i>
            <p [innerHtml]="alertInput.message"></p>
          </div>
          <!-- Success Msg Alert -->
          <div class="alert alert-success alert-dismissible" *ngIf="alertInput.alertType === alertType.Success">
            <button type="button" class="close" data-dismiss="alert" aria-label="close"
                    (click)="setAlertMsgToEmpty()">
              ×
            </button>
            <i class="material-icons" aria-hidden="true">check_circle</i>
            <p [innerHtml]="alertInput.message"></p>
          </div>
        </div>
        <div id="tabSection" class="box-container commitments-table">
          <div class="commitments-tabs-wrapper">
            <ul class="nav nav-tabs scoped-tabs" role="tablist">
              <li class="nav-item">
                <a class="nav-link active show" id="tab-1" role="tab" aria-selected="false"
                   href="#tab13" [ngClass]="{'blueThemeMode':(blueThemeMode)}" data-toggle="tab">
                  Project Commitments <div class="total-count">
                    <div class="count-badge" [ngClass]="{'buttonColor':(blueThemeMode)}">{{projectTotalCount || 0}}</div>
                  </div>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link inactive show" id="tab-2" role="tab" aria-selected="false"
                   href="#tab14" [ngClass]="{'blueThemeMode':(blueThemeMode)}" data-toggle="tab">
                  Non Project Commitments <div class="total-count">
                    <div class="count-badge" [ngClass]="{'buttonColor':(blueThemeMode)}">{{nonProjectTotalCount || 0}}</div>
                  </div>
                </a>
              </li>
            </ul> <!-- Tab panes -->
            <div class="tab-content" id="myTabContentForClientDashboard">
              <div class="tab-pane fade active show" id="tab13" role="tabpanel" aria-labelledby="tab-1">
                <app-client-commitment-list
                  [isNonProject]="false"
                  [clientEntityKey]="selectedClientEntityKey"
                  [commitmentsError]="commitmentsError"
                  [isRowsLoading]="isRowsLoading"
                  [allCommitments]="allCommitments"
                  [isInitialSearch]="isInitialSearch"
                  (onNavigate)="navigate($event)"
                  (exportRiskExcel)="exportRiskExcel($event)"
                  (exportExcelNonRisk)="exportExcelNonRisk($event)"
                  (populateTotalCount)="populateProjectTotalCount($event)"
                  (onSetOrderBy)="setOrderByProject($event)"
                ></app-client-commitment-list>
              </div>
              <div class="tab-pane fade inactive hidden" id="tab14" role="tabpanel" aria-labelledby="tab-2">
                <app-client-commitment-list
                  [isNonProject]="true"
                  [clientEntityKey]="selectedClientEntityKey"
                  [commitmentsError]="commitmentsError"
                  [isRowsLoading]="isRowsLoading"
                  [allCommitments]="allCommitments"
                  [isInitialSearch]="isInitialSearch"
                  (onNavigate)="navigate($event)"
                  (exportRiskExcel)="exportRiskExcel($event, true)"
                  (exportExcelNonRisk)="exportExcelNonRisk($event, true)"
                  (populateTotalCount)="populateProjectNonTotalCount($event)"
                  (onSetOrderBy)="setOrderByNonProject($event)"
                ></app-client-commitment-list>
              </div>
              <div id="sideButtonDiv" class="nav nav-tabs scoped-tabs" role="tablist">
                <button class="myButton" [ngClass]="{'blueThemeMode':(blueThemeMode)}" id="sideButton" data-toggle="tab" data-target="#tab15" type="button" role="tab"
                        aria-controls="tab15" aria-selected="true" (click)="toggleNotpadSection('commitment')">
                  Commitments by
                  Products
                  <span [ngClass]="{'arrow-left':(!isNotpadSectionVisible), 'arrow-right':(isNotpadSectionVisible), 'blueThemeMode':(blueThemeMode)}"></span>
                </button>
                <button class="myButton" [ngClass]="{'blueThemeMode':(blueThemeMode)}" id="sideButton2" data-toggle="tab" data-target="#tab16" type="button"
                        role="tab" aria-controls="tab16" aria-selected="false" (click)="toggleNotpadSection('note')">
                  Notes
                  <span [ngClass]="{'arrow-left':(!isNotpadSectionVisible), 'arrow-right':(isNotpadSectionVisible), 'blueThemeMode':(blueThemeMode)}"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div id="notepadSection" class="box-container product-details">
          <div class="commitments-tabs-wrapper">
            <div class="tab-content">
              <div class="tab-pane fade active show" id="tab15" role="tabpanel" aria-labelledby="tab-1">
                <div id="tab15boxWrapper" class="box-wrapper" [ngClass]="{'blueThemeMode':(blueThemeMode)}">
                  <div *ngIf="!ngShows" role="status" aria-label="Please wait, do not refresh the page"
                       class="ccdrms-loader"> </div>
                  <ul class="product-list" *ngIf="ngShows"
                      [ngClass]="commitmentProducts && commitmentProducts.length > 7 ? 'border-bottom':''">
                    <li *ngFor="let k of pageProducts">
                      <div class="product-wrapper">
                        <h3 title="{{k.productName}}">{{k.productName}} </h3>
                        <p title="{{k.productDescription}}">{{k.productDescription}}</p>
                      </div>
                      <div class="two-col">
                        <div class="name">
                          <span class="badge">{{k.totalCommitments}}</span>
                        </div>
                      </div>
                    </li>

                    <!-- No data available -->
                    <li *ngIf="commitmentProducts && commitmentProducts.length == 0">
                      <div class="product-wrapper no-data-message">
                        <h3>No Data Available</h3>
                      </div>
                    </li>
                  </ul>
                  <span *ngIf="ngShows">
                    <app-pagination [isCalledFromCCT]="blueThemeMode" [template]="'2'" *ngIf="pagerObjectProduct && pagerObjectProduct.totalPages > 1 "
                                    [pagerObject]="pagerObjectProduct" (pagingChanged)="changePageProduct($event)"></app-pagination>
                  </span>
                </div>
              </div>
              <div class="tab-pane fade" id="tab16" role="tabpanel" aria-labelledby="tab-1">
                <div id="tab16boxWrapper" class="box-wrapper" [ngClass]="{'blueThemeMode':(blueThemeMode)}">
                  <div id="noteMainDiv">
                    <textarea id="noteTextarea" [(ngModel)]="note" class="form-control" *ngIf="noteVisibilityCheck()"
                              [disabled]="!selectedClientEntityKey"></textarea>
                    <pre id="notePreTag" [hidden]="noteVisibilityCheck()">{{note}}</pre>
                    <button *ngIf="noteVisibilityCheck() && selectedClientEntityKey !== null" type="button"
                            class="btn fdl-btn myButton" (click)="saveNote()" [ngClass]="{'blueThemeMode':(blueThemeMode)}">
                      Save
                    </button>
                    <p *ngIf="noteVisibilityCheck()">
                      Reminder: Notes are Client Visible.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

  </div>
  <!-- Basic Info Page Ends  -->
  <!-- Drawer Starts - Please use the drawer patten instead of this static HTML this is just an example -->

  <div id="drawerDialog" *ngIf="drawerActive" [ngClass]="drawerActive?'d-block ':'d-none'">
    <div tabindex="-1" aria-labelledby="addContacts" aria-expanded="true" role="region"
         class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-5 fold open">
      <app-client-commitment-details 
        [detailsCommitment]="detailsCommitment"
        [isClientExecutive]="isClientExecutive"
        [isClientAdmin]="isClientAdmin"
        [isCCTAdminReadOnly]="isCCTAdminReadOnly"
        [clientEntityKey]="selectedClientEntityKey"
        [drawerActive]="drawerActive"
        [isCustomClient]="isCustomClient"
        [isCCTAdmin]="isCCTAdmin"
        [pageTemplateAdmin]="pageTemplateAdmin"
        [isAdmin]="isAdmin"
        [isRestrictedAdmin]="isRestrictedAdmin"
        [isRm]="isRm"
        [isSalesExecutive]="isSalesExecutive"
        [isAccountManager]="isAccountManager"
        [isAccountExecutive]="isAccountExecutive"
        [isNewRM]="isNewRM"
        [userData]="userData"
        [SelectedCEUsers]="SelectedCEUsers"
        [SelectedCEUsersDetails]="SelectedCEUsersDetails"
        [isClientLoadedPartofOneView]="isClientLoadedPartofOneView"
        [isClientSuperUser]="isClientSuperUser"
        (setAlertMsgToEmpty)="setAlertMsgToEmpty()"
        (openUpdateUsersModal)="openUpdateUsersModal($event)"
        (onCloseDrawer)="closeDrawer()">
      </app-client-commitment-details>
    </div>
    <div class="backdrop-space">
      <div class="backdrop"></div>
    </div>
  </div>

  <delete-warning *ngIf="this.detailsCommitment" [componentId]="'deleteWarningModal'"
                  [type]="this.detailsCommitment.commitmentTitle" (okBtnClicked)="untrackCommitment()" [clientDashboard]="true">
  </delete-warning>

  <app-client-dashboard-edit-assignusers (closeModal)="editCommitmentUsersModal()" (submitModal)="editCommitmentUsersModal()"
                                             [commitmentDetails]="selectedForeditUserAssignement"></app-client-dashboard-edit-assignusers>
  <app-client-dashboard-exporting
      [summary]="summary"
      [contacts]="contacts"
      [commitmentProducts]="commitmentProducts"
      [allCommitments]="allCommitments"
      [pageFilterForm]="filterPage"
      [clientTypeSearch]="clientTypeSearch"
      [isCalendarReady]="isRowsLoading === false" 
      [isPdfExport]="isPdfExport"
      [typeOfCommitmentCalander]="typeOfCommitmentCalander" 
      [note]="note"
      [projectTotalCount]="commitmentProjectRowsCount" 
      [nonProjectTotalCount]="commitmentManualRowsCount"
      [commitmentProjectRows]="commitmentProjectRows"
      [commitmentManualRows]="commitmentManualRows"></app-client-dashboard-exporting>
