<div class="map-dw d-done">
    <div tabindex="-1" aria-labelledby="addContacts" aria-expanded="true" role="region"
        class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open">
        <div class="drawer-contents commitment-dw  col-xs-12 col-sm-12 col-md-12 ">
            <div class="form-container p-1">
                <form [formGroup]="commitmentFormGroup" autocomplete="off">
                    <div class="form-row">
                        <div class="col-md-12 mb-3 pt-4">
                            <div><label class=""> Please provide below details for Commitments Intake</label>
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <div class="col-md-12 mb-3" style="margin-left: -15px">
                                <label for="clientName" class="asterisk"> Client Name/DUNS: </label>
                                <div class="pr-4 typeahead-wrapper">
                                    <ng-select
                                        id="clientName"
                                        formControlName="clientName"
                                        [items]="clientSuggestions$ | async"
                                        [typeahead]="clientSuggestionInput$"
                                        [virtualScroll]="true"
                                        [clearOnBackspace]="false" placeholder="Type to search" class="ng-select"
                                        [editableSearchTerm]="true"
                                        [searchFn]="searchFunction"
                                        [loading]="isClientsuggestionLoading"
                                        (change)="onClientSelect($event)"
                                        (clear)="onClientClear()"
                                        >
                                        <ng-template class="col" ng-option-tmp let-itemTemp="item" let-item$="item$"
                                            let-index="index">
                                            <span title="{{itemTemp.titleWithDuns}}"> {{itemTemp.titleWithDuns}} </span>
                                        </ng-template>
                                        <ng-template ng-label-tmp let-item="item">
                                            <span class="ng-value-label" title="{{item.titleWithDuns}}">
                                                {{item.titleWithDuns}}
                                            </span>
                                        </ng-template>
                                    </ng-select>
                                </div>
                                <div *ngIf="selectedClientValue" class="mt-2">
                                    <span><i>{{this.selectedClientValue}}</i></span>
                                </div>
                                <div [ngClass]="{'has-error': invalidClientName}">
                                    <span *ngIf="invalidClientName">
                                        <i class="material-icons">warning</i>Please select a client from the list.
                                    </span>
                                </div>
                                <div [ngClass]="{'has-error': invalidClientDuns}">
                                    <span *ngIf="invalidClientDuns">
                                        <i class="material-icons">warning</i>Please select a client with valid DUNS ID.
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="fdl-2 button-container">
                        <button type="button" class="btn fdl-btn btn-secondary-light"
                            [ngClass]="{'blueThemeMode':(blueThemeMode)}" data-dismiss="modal"
                            (click)="cancelCreatingCommitmentForm()" (keydown.tab)="keyDown($event)"> Cancel </button>
                        <div>
                            <button type="button" class=" btn fdl-btn btn-primary-orange ml-2"
                                [ngClass]="{'buttonColor':(blueThemeMode)}" (keydown.tab)="keyDown($event)"
                                (click)="goToCreateCommitment()" [disabled]="((selectedClientId < 1) || invalidClientDuns)">
                                Continue </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="backdrop-space">
        <div class="backdrop"></div>
    </div>
</div>