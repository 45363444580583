import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { ClientsService } from 'src/app/services/clients.service';
import { IPageTemplate } from 'src/app/shared/page-template';
import { ISuggestions } from 'src/app/shared/suggestion';
import * as $ from 'jquery';
import { ICommitmentIntakeDto } from '../models/commitment-intake-d-t-o';

@Component({
  selector: 'app-commitment-assignusers',
  templateUrl: './commitment-assignusers.component.html',
  styleUrls: ['./commitment-assignusers.component.css']
})
export class CommitmentAssignUsersComponent implements OnInit {
  @Input() commitmentDetails: ICommitmentIntakeDto;
  @Input() selectedResponseId: number;
  @Input() untrackedCommitments: any[] = [];
  @Input() updatedCommitments: any[] = [];
  @Output() private closeModal = new EventEmitter();
  @Output() updateCommitment = new EventEmitter <{updatedCommitments: any[], untrackedCommitments: any[]}>();

  clientExecutiveSuggestions: any[]; 
  public SelectedCEUsers: any[];
  SelectedCEUsersDetails: any;
  isError: boolean = false;
  isAdmin = false;
  errorMsg = '';
  successMsg = '';
  isLoading = false;
  disableSaveButton: boolean = true;

  constructor(
    private clientsService: ClientsService,
    private clientCommitmentService: ClientCommitmentsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.commitmentDetails
      && !changes.commitmentDetails.firstChange
      && changes.commitmentDetails.currentValue
      && changes.commitmentDetails.currentValue.id !== changes.commitmentDetails.previousValue?.id) {
        this.getUserData();
    }
  }

  ngOnInit() {
  }
    
  onSelectCEUsers() {
    this.SelectedCEUsersDetails = [];
    for (var i = 0; i < this.SelectedCEUsers.length; i++) {
      var userData = this.clientExecutiveSuggestions.find(u => u.id == this.SelectedCEUsers[i]);
      this.SelectedCEUsersDetails.push(userData);
    }
    
    if (this.SelectedCEUsersDetails && this.SelectedCEUsersDetails.length > 0) {
      this.disableSaveButton = false;
    }
    else if (this.SelectedCEUsersDetails && this.SelectedCEUsersDetails.length === 0) {
      this.disableSaveButton = true;
    }
    this.updatedCommitments[0].clientCommitmentUsersList = this.SelectedCEUsersDetails; 
  }

  getUserData() {
    if (!this.commitmentDetails) return;
    
    let clientDunsNumber = this.commitmentDetails.clientDunsNumber;
    let oneViewGroupId = this.commitmentDetails.oneViewGroupId;

    if (oneViewGroupId > 0) {
      this.clientsService.getClientExecutiveUsersByOneViewGroupId(oneViewGroupId)
        .subscribe(suggestions => {
          this.clientExecutiveSuggestions = suggestions;
          this.selectAllForDropdownItems(this.clientExecutiveSuggestions);
        },
          error => { this.errorMsg = error; });
    }
    else {
      this.clientsService.getClientExecutiveUsersByClientDUNS(clientDunsNumber)
        .subscribe(suggestions => {
          this.clientExecutiveSuggestions = suggestions;
          this.selectAllForDropdownItems(this.clientExecutiveSuggestions);
        },
          error => { this.errorMsg = error; });
    }
  }

  selectAllForDropdownItems(items: any[]) {
    let allSelect = items => {
      items.forEach(element => {
        element['selectedAllGroup'] = 'selectedAllGroup';
      });
    };

    allSelect(items);
  }

  onSaveClick() {
    debugger;
    if (this.updatedCommitments.length > 0) {
      for (var i = 0; i < this.updatedCommitments.length; i++) {
        if (this.updatedCommitments[i].id == this.commitmentDetails.id && this.updatedCommitments[i].statusA == 'update' && this.updatedCommitments[i].statusA != 'untrack') {
          this.updatedCommitments[i].clientCommitmentUsersList = this.SelectedCEUsersDetails; 
        }
      }
    }
    this.resetForm();
    $('#containerPage').scrollTop(0);       
    this.updateCommitment.emit({ updatedCommitments: this.updatedCommitments, untrackedCommitments: this.untrackedCommitments });    
    //$('.container-page').removeClass('overflow-hidden');
    //$('body').removeClass('modal-open');
    //this.resetForm();
  }

  onCancel() {
    this.resetForm();
    this.closeModal.emit();    
    $('.container-page').removeClass('overflow-hidden');
    $('body').removeClass('modal-open');
  }

  resetForm(): any {
    this.SelectedCEUsers = [];
    this.SelectedCEUsersDetails = [];
  }
}
