import { Injectable } from '@angular/core';
import { IQuery } from 'src/app/shared/query';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { IOrganization, IOrganizationSuggestion } from 'src/app/shared/organization';
import { ISuggestions } from 'src/app/shared/suggestion';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  private organizationAPIURL = 'organizations';
  private exportorganizationAPIURL = 'organizations/ExportOrganizations';
  private organizationCreated: string;
  private isOrganizationCreated: boolean;
  private organizationUpdated: boolean;
 

  constructor(private http: HttpClient) {}

  getOrganizations(queryParams: IQuery) {
    let params = new HttpParams();

    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.organizationAPIURL, { params, observe: 'response' });
  }

  getRefreshOrganizations(queryParams: IQuery) {
    let params = new HttpParams();
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };
    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.organizationAPIURL, {headers:httpOptions.headers, params, observe: 'response' });
  }

  exportOrganizations(queryParams: IQuery) {
    let params = new HttpParams();
    let httpOptions = {
      headers: new HttpHeaders({
        refresh: "true"
      })
    };
    for (const key of Object.keys(queryParams)) {
      const value = queryParams[key];
      params = params.append(key, value);
    }
    return this.http.get(this.exportorganizationAPIURL, { headers: httpOptions.headers, params: params, observe: 'response', responseType: 'blob' });

    //return this.http.put<any>(`${this.exportorganizationAPIURL}`, params,

    //  {

    //    observe: 'response', responseType: 'blob' as 'json'

    //  });
  }

  getSuggestions(entity) {
    const suggestionAPIURL = `${entity}/suggestions`;
    return this.http.get<ISuggestions>(suggestionAPIURL);
  }

  fetchIdForRequest(suggestions: any, selectedVal: string): number {
    let suggestionArr = [];
    suggestionArr = suggestions;
    let foundObj = suggestionArr.find(suggestion => suggestion.title ? (suggestion.title === selectedVal) : (suggestion.name === selectedVal));
    return foundObj? foundObj.id : -1;
}

  getOrganizationDetailsById(organizationId: number) {
    return this.http.get<IOrganization>(`${this.organizationAPIURL}/${organizationId}`);
  }

  createOrganization(data) {
    return this.http.post<IOrganization>(this.organizationAPIURL, data);
  }

  updateOrganization(organizationId, data) {
    return this.http.put<IOrganization>(`${this.organizationAPIURL}/${organizationId}`, data);
  }

  deleteOrganization(organizationId: number): Observable<IOrganization> {
    return this.http.delete<IOrganization>(`${this.organizationAPIURL}\\${organizationId}`);
  }

  getOrganizationList(organizationName: string) {
    let url = this.organizationAPIURL + "/organizationlist?organizationName=" + organizationName;
    return this.http.get(url);
  }

  getAllSuggestions() {
    return this.http.get<IOrganizationSuggestion[]>(`${this.organizationAPIURL}/allSuggestions`);
  }

   set _newCreatedOrg(value){
    this.organizationCreated= value;
  }

  get _newCreatedOrg(){
    return this.organizationCreated;
  }

  set _isOrgCreated(value){
    this.isOrganizationCreated= value;
  }

  get _isOrgCreated(){
    return this.isOrganizationCreated;
  }

  set _orgUpdated(value){
    this.organizationUpdated= value;
  }

  get _orgUpdated(){
    return this.organizationUpdated;
  }

}
