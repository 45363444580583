import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { IQuery } from '../shared/query';

@Injectable({
  providedIn: 'root'
})
export class CommitmentEcrmService {
  private _ecrmCommitmentBaseUrl: string = "commitmentintakes/ecrms";

  constructor(private http: HttpClient) { }

  public updateCommitment(projectKey: string, update: any) {
    return this.http.put(`${this._ecrmCommitmentBaseUrl}/${projectKey}`, update);
  }

  public updateCommitments(updates: any) {
    return this.http.put(`${this._ecrmCommitmentBaseUrl}`, updates);
  }

  public deleteCommitment(projectKey: string) {
    return this.http.delete(`${this._ecrmCommitmentBaseUrl}/${projectKey}`);
  }
}
