import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { take } from 'rxjs/internal/operators/take';
import { ClientCommitmentsService } from 'src/app/services/client-commitments.service';
import { IPageTemplate } from 'src/app/shared/page-template';
import { IQuery } from 'src/app/shared/query';
import { CommitmentsIntakeSearchInput } from './models/commitments-intake-search-input';
import { environment } from 'src/environments/environment';
import { IPager } from 'src/app/shared/pagination';
import { UserService } from 'src/app/services/user.service';
import { ClientCommitmentDTO } from './models/client-commitment-d-t-o';
import { PreferenceService } from 'src/app/services/preference.service';
import { forkJoin } from 'rxjs';
import { PermissionsService } from 'src/app/services/permissions.service';
import { CommitmentsIntakeSearchType } from './enums/commitments-intake-search-type.enum';
import { AlertInput } from './models/alert-input';
import { AlertType } from './enums/alert-type.enum';
import { PageTemplateService } from 'src/app/services/page-template.service';
import { CommitmentsIntakeSearchInputForLocalStorage } from './models/commitments-intake-search-input-for-local-storage';
declare var $: any;
import * as _ from 'lodash';
import { id } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-commitments-intake',
  templateUrl: './commitments-intake.component.html',
  styleUrls: ['./commitments-intake.component.css'],
})
export class CommitmentsIntakeComponent implements OnInit, AfterViewChecked {
  isError: boolean = false;
  public alertType = AlertType;
  public alertInput: AlertInput = {
    alertType: AlertType.Success,
    message: ''
  };

  private isFirstTime = true;
  private pageTemplateId = environment.clientCommitmentPageTemplateId;
  public selectedTabName = 'All';
  public searchByFormFieldJson: any;
  public pageTemplate: IPageTemplate = {};
  public queryParam: IQuery = {};
  public tabList: string[] = [];
  public pageResponseList: ClientCommitmentDTO[] = [];
  public searchFieldData: CommitmentsIntakeSearchInput[] = [];
  public pagerObject: IPager;
  public showSubmitNewCommitmentsIntakeDrawer = false;
  public showClientView = false;
  public isEntityLink = false;
  public isDynamicApprover = false;
  public isLoadSearchFieldSuggestionsCompleted = false;
  public showStaticTab: boolean | null = null;
  public filterColumn = '';
  public isAsc = true;
  public isReset = false;
  selectedOrganizationId = 0;
  canRequest = false;
  listFormFieldJson: any;
  ignoreSortingColumns = [
    'description',
    'formsCount',
    'fileSize',
    'count',
    'formType',
    'level',
    'entityName',
    'status',
    'entityLevel',
    'currentApproverName',
    'editAccessWith',
    'pageTemplateResponseStatusTitle',
    'submittedByName',
  ];
  userData: any;
  public isAdmin = false;
  public isCCTAdmin = false;
  public isSalesExecutive = false;
  public isRestrictedAdmin = false;
  public isRelationshipManager = false;
  public isAccountManager = false;
  public isOneViewAdmin = false;
  pageTemplateAdmin = false;
  pageTemplateReadAdmin = false;
  public isRestrictedAdminReadOnly = false;
  customFormFieldListJson: any;
  organizationDetails: any;
  pageResponses: any[] = [];
  showAddColumnOptions: boolean = false;
  displayColumns: any;
  keys: any;
  listName: string = '';
  userId: any;
  preferenceId: any;
  columnPreferences: any;
  prefColumnOptions: any;
  maxColumnCount: any;
  filterExists: boolean = false;
  filtered: boolean = false;
  refreshGrid: boolean = false;
  pageTemplateNameTitle: string;
  pageTemplateNameDescription: string;
  blueThemeMode = false;
  isCreateNewOneView = false;
  isEditOneView = false;
  canEditOneView = false;
  selectedOneViewId = null;

  constructor(
    private userService: UserService,
    private permission: PermissionsService,
    private clientCommitmentsService: ClientCommitmentsService,
    private preferenceService: PreferenceService,
    private pageTemplateService: PageTemplateService
  ) {
    this.userService.blueThemeMode.subscribe(r => this.blueThemeMode = r);
  }

  ngAfterViewChecked(): void {
    if (this.isFirstTime && this.searchFieldData.length > 0 && this.showStaticTab !== null) {
      const formFieldsData = <Array<any>>JSON.parse(this.queryParam.formFields);
      let temp = null;
      let temp1 = null;
      if (formFieldsData) {
        temp = formFieldsData.find(r => r.ApiKey === 'isTracked' && r.ApiKeyValue[0] === 'true');
      }
      if (formFieldsData) {
        temp1 = formFieldsData.find(r => r.ApiKey === 'isPresaleSelected' && r.ApiKeyValue[0] === 'true');
      }
      const temp2 = this.searchFieldData.filter(r => r.type === 'Data Source');
      if ((temp2 && temp2.length > 0) || (temp && temp.length > 0) || (temp1 && temp1.length > 0)) {
        let element: HTMLElement;
        if (temp) {
          element = document.getElementById('TrackedTab');
        }
        else if (temp1) {
          element = document.getElementById('PresaleTab');
        } else {
          element = document.getElementById(temp2[0].title + 'Tab');
        }
        if (element) {
          this.isFirstTime = false;
          const x = document.getElementsByClassName('nav-link active show');
          if (x && x.length > 0) {
            while (x.length > 0) {
              x[0].classList.remove('active', 'show');
            }
          }
          element.classList.add('active', 'show');
        }
      }
    }
  }

  ngOnInit(): void {
    this.initQueryParams();
    this.getPageTemplateData();
    this.userData = this.userService.getUserListData();
    this.isAdmin = this.userData.isAdmin;
    this.isCCTAdmin = this.permission.isCCTAdmin();
    this.isSalesExecutive = this.permission.isSalesExecutive();
    this.isRestrictedAdmin = this.permission.isRestrictedAdmin();
    this.isRelationshipManager = this.permission.isRelationShipManager();
    this.isAccountManager = this.permission.isAccountManager();
    this.isOneViewAdmin = this.permission.isOneViewAdmin();

    this.organizationDetails = Object.assign({}, this.userService.getUserListData());
    if (this.pageTemplateService.isResponseCreated !== '') {
      this.alertInput.alertType = AlertType.Success;
      this.alertInput.message = this.pageTemplateService.isResponseCreated;
    }
    if (this.pageTemplateService.isPrePopulateRequest === true) {
      this.alertInput.alertType = AlertType.Success;
      this.alertInput.message = 'Pre-populate request(s) has been uploaded successfully. Request(s) would be available once processed by System.';
    }
  }

  private initQueryParams(): void {
    this.queryParam = Object.assign(
      {},
      {
        pageNumber: 1,
        pageSize: environment.pageSize,
      }
    );
  }

  private getPageTemplateData(): void {
    this.clientCommitmentsService
      .getPageTemplateById(this.pageTemplateId)
      .pipe(take(1))
      .subscribe(
        (page) => {
          this.pageTemplate = page.body as IPageTemplate;
          this.pageTemplate.parentPageTemplateList = this.pageTemplate.parentPageTemplateList ?? [];
          this.pageTemplateNameTitle = this.pageTemplate.title;
          this.isDynamicApprover = this.pageTemplate.isDynamicApprover;
          this.isEntityLink = this.pageTemplate.isEntityLink;
          if (this.pageTemplate.formFieldListJson) {
            this.customFormFieldListJson = JSON.parse(
              this.pageTemplate.formFieldListJson
            );

            this.setClientOneView();
          }

          this.canRequest = this.pageTemplate.pageTemplateAdmins?.some(a => a.userId === this.userData.id) || this.permission.userData.isAdmin || (this.userData.isRestrictedAdmin && this.userData.roles.some(r => r.roleId === 10));
          this.pageTemplateAdmin = this.pageTemplate.pageTemplateAdmins?.some(a => a.userId === this.userData.id);
          this.pageTemplateReadAdmin = this.pageTemplate.pageTemplateReadAdmins?.some(a => a.userId === this.userData.id);
          if (!this.canRequest) {
            this.selectedOrganizationId = this.organizationDetails?.organization;
          }
          const userFilterString = localStorage.getItem('commitments-intake-search');
          if (userFilterString) {
            const temp = <CommitmentsIntakeSearchInputForLocalStorage>JSON.parse(userFilterString);
            if (temp.commitmentsIntakeSearchInput.length > 0) {
              this.searchFieldData = temp.commitmentsIntakeSearchInput;
              this.queryParam = temp.queryParam;

              if (this.queryParam?.orderBy) {
                const temp = this.queryParam?.orderBy.split(' ');
                this.isAsc = false;
                this.filterColumn = temp[0];
                if (temp[1]) {
                  this.isAsc = temp[1] !== 'desc';
                }
              } else if (this.queryParam?.sortingFormField) {
                this.filterColumn = this.queryParam?.sortingFormField;
                this.isAsc = this.queryParam?.sortingFormFieldOption !== 'desc';
              }
            }
          }

          //this.callPreferenceGetService();
          this.searchFromList(this.queryParam);

          if (!this.pageTemplate.searchByFormFieldJson) {
            this.searchByFormFieldJson = JSON.parse('[]');
          } else {
            this.searchByFormFieldJson = JSON.parse(
              this.pageTemplate.searchByFormFieldJson
            );
            this.loadSearchFieldSuggestions();
          }
        },
        (error) => {
          this.alertInput.alertType = AlertType.Error;
          this.alertInput.message = error;
        }
      );
  }

  private loadSearchFieldSuggestions(): void {
    if (this.searchByFormFieldJson && this.searchByFormFieldJson.length > 0) {
      this.clientCommitmentsService
        .getFormFieldSuggestionByUser(this.pageTemplateId)
        .subscribe(
          (data) => {
            if (data) {
              const suggestions: any = data;
              const suggestionLength = suggestions.length;
              const searchByFormFieldLength = this.searchByFormFieldJson.length;
              const defaultSortedStatusIntake = [
                    "Not Started",
                    "In Planning",
                    "In Progress",
                    "Delivered",
                    "Completed",
                    "Fiserv - On Hold",
                    "Client - On Hold",
                    "On Hold",
                    "Canceled"
                  ];    
              var tempStatus = [];
            
              for (let s = 0; s < suggestionLength; s++) {
                for (let f = 0; f < searchByFormFieldLength; f++) {
                  if (suggestions[s]['apiKey'] === this.searchByFormFieldJson[f]['formfieldapikey']) {
                    if (suggestions[s]['apiKey'] === 'statusIntake') {
                      const tempStatusList = [];
                      const statusList = [];
                      suggestions[s].apiKeyValues.forEach(r => {
                        const cancelledStatuses = ['Canceled'];
                        const hasFulfillmentCancelled = tempStatusList.indexOf('Canceled') >= 0;
                        if (cancelledStatuses.indexOf(r) < 0) {
                          tempStatusList.push(r);
                        }
                        else if (!hasFulfillmentCancelled) {
                          tempStatusList.push('Canceled');
                        }
                      });
                      var isStatusExisted = false;
                      defaultSortedStatusIntake.forEach(st => {
                        for (const status of tempStatusList){
                          if (st === status) {
                              isStatusExisted = true;
                              break;
                          }
                        }
                        if (isStatusExisted)
                        {
                          statusList.push(st);
                        }
                      });

                      this.searchByFormFieldJson[f]['formFieldSuggestion'] = statusList.filter(r => r.trim() !== '' && r !== 'Invalid date');
                    } else {
                      this.searchByFormFieldJson[f]['formFieldSuggestion'] = suggestions[s].apiKeyValues.filter(r => r.trim() !== '' && r !== 'Invalid date');
                    }
            
                    if (suggestions[s]['apiKey'] === 'opportunitytcv') { // This section is temprorarry till we fix the issue from database
                      this.searchByFormFieldJson[f]['formFieldSuggestion'] = [...new Set(suggestions[s].apiKeyValues.map(r => +r))];
                      this.searchByFormFieldJson[f]['formFieldSuggestion'] = this.searchByFormFieldJson[f]['formFieldSuggestion'].map(r => r.toString());
                    }

                    if (suggestions[s]['apiKey'] === 'productSIntake') { // This section is temprorarry till we fix the issue from database: Remove [] for product name
                      this.searchByFormFieldJson[f]['formFieldSuggestion'] = suggestions[s].apiKeyValues.filter(el => !el.includes('[') && !el.includes(']') && el.length > 3);
                    }

                    if (suggestions[s].apiKeyValues.some(r => r.trim() === '' || r.trim() === '[]')) {
                      this.searchByFormFieldJson[f]['formFieldSuggestion'].push('(Blanks)');
                    }

                    if (suggestions[s]['apiKey'] === 'isTracked') { // This section is temprorarry till we fix the issue from database: Remove [] for product name
                      suggestions[s].apiKeyValues = [];
                      suggestions[s].apiKeyValues.push("Tracked");
                      suggestions[s].apiKeyValues.push("Untracked");
                      this.searchByFormFieldJson[f]['formFieldSuggestion'] = suggestions[s].apiKeyValues;
                    }

                    this.searchByFormFieldJson[f]['data'] = [];
                  }
                }
              }

              this.tabList = suggestions
                .filter((r) => r.apiKey === 'datasource')
                .map((s) => s.apiKeyValues)[0]
                .filter((r) => r.trim() !== '');

              const index = this.tabList.indexOf('ServiceNow');
              this.tabList[index] = 'Servicenow';

              const indexCims = this.tabList.indexOf('cims');
              if (indexCims > -1)
                this.tabList[indexCims] = 'CIMS';

              // following  code is to move ecrm to last
              let ecrmindex = this.tabList.indexOf('eCRM'); // replace 3 with the item you want to move
              if (ecrmindex !== -1) {
                let item = this.tabList.splice(ecrmindex, 1)[0]; // remove the item
                this.tabList.push(item); // add it to the end
              }
            }
            this.isLoadSearchFieldSuggestionsCompleted = true;

            this.showStaticTab = this.isValidTabList();
          },
          (error) => {
            // this.isError = true;
            this.alertInput.alertType = AlertType.Error;
            this.alertInput.message = "Form Field suggestion list can't be loaded. " + error;
          }
        );
    }
  }

  public onSearchClicked(event: { searchTags: CommitmentsIntakeSearchInput[], shouldReset?: boolean, isTabClicked?: boolean }) {
    const { isTabClicked, shouldReset } = event;
    if (!isTabClicked && shouldReset) {
      this.isReset = true;
    }

    const currentTab = localStorage.getItem('commitments-intake-tab-name');
    this.selectedTabName = currentTab ? currentTab : this.selectedTabName;
    this.setAlertMsgToEmpty();

    let orderBy: any;
    let sortingFormField: any;
    let sortingFormFieldOption: any;

    if (this.queryParam?.orderBy) {
      orderBy = this.queryParam?.orderBy;
    }
    if (this.queryParam?.sortingFormField) {
      sortingFormField = this.queryParam?.sortingFormField;
      sortingFormFieldOption = this.queryParam?.sortingFormFieldOption;
    }

    this.initQueryParams();

    const { searchTags } = event;
    if ((searchTags && searchTags.length > 0) || (sortingFormField && sortingFormFieldOption)) {
      this.queryParam.orderBy = orderBy;
      this.queryParam.sortingFormField = sortingFormField;
      this.queryParam.sortingFormFieldOption = sortingFormFieldOption;
    }

    if (this.queryParam?.orderBy) {
      const temp = this.queryParam?.orderBy.split(' ');
      this.isAsc = false;
      this.filterColumn = temp[0];
      if (temp[1]) {
        this.isAsc = temp[1] !== 'desc';
      }
    } else if (this.queryParam?.sortingFormField) {
      this.filterColumn = this.queryParam?.sortingFormField;
      this.isAsc = this.queryParam?.sortingFormFieldOption !== 'desc';
    }

    if (shouldReset) {
      this.filterColumn = '';
      this.isAsc = false;
      this.queryParam.sortingFormField = null;
      this.queryParam.sortingFormFieldOption = null;
      this.queryParam.orderBy = null;
    }

    let needToInsertIntoLocalStorage = true;
    let tempSearchFieldData = searchTags.filter(r => r.formFieldApiKey !== 'datasource');

    // Fix bug 313108
    // if (tempSearchFieldData.length === 0) {
    //   needToInsertIntoLocalStorage = false;
    // }}
    // END: Bug 313108

    if (this.selectedTabName !== 'All' &&
      this.selectedTabName !== 'Internal') {
      // this.selectedTabName !== 'Tracked' )  &&
      //this.selectedTabName !== 'Presale') {
      const temp: CommitmentsIntakeSearchInput = {
        type: 'Data Source',
        title: this.selectedTabName,
        formFieldApiKey: 'datasource',
        typeEnumForSort: CommitmentsIntakeSearchType.DataSource
      };
      tempSearchFieldData.push(temp);
    }

    this.pageResponseList = [];
    // if (tempSearchFieldData.length > 0) {
    var formFieldList: any = [];

    if (this.selectedTabName === 'All') {
      formFieldList.push({ ApiKey: 'isAllSelected', ApiKeyValue: ['true'] });
    }
    else if (this.selectedTabName === 'Presale') {
      formFieldList.push({ ApiKey: 'isPresaleSelected', ApiKeyValue: ['true'] });
    }

    tempSearchFieldData.forEach((item) => {

      // Product in AnswerJson is array. When its empty it will show [] as a string.
      if (item.formFieldApiKey === 'productSIntake' && item.title === '(Blanks)') {
        item.title = '[]';
      }
      const formField = formFieldList.find(
        (f) => f.ApiKey === item.formFieldApiKey
      );
      if (!formField) {
        const formField: any = {
          ApiKey: item.formFieldApiKey,
          ApiKeyValue: [item.title],
        };
        formFieldList.push(formField);
      } else {
        formField.ApiKeyValue.push(item.title);
      }
      if (item.formFieldApiKey === 'statusIntake' && item.title === 'Canceled') {
        const formFieldStatusIntake = formFieldList.find(
          (f) => f.ApiKey === item.formFieldApiKey
        );
        formFieldStatusIntake.ApiKeyValue.push('Canceled');
        //formFieldStatusIntake.ApiKeyValue.push('Cancelled');
      }
      if (item.formFieldApiKey === 'opportunityTcv') { // This section is temprorarry till we fix the issue from database
        const formFieldOpportunityTcv = formFieldList.find(
          (f) => f.ApiKey === item.formFieldApiKey
        );
        if ((+item.title) % 1 === 0) {
          formFieldOpportunityTcv.ApiKeyValue.push(item.title + '.0');
          formFieldOpportunityTcv.ApiKeyValue.push(item.title + '.00');
        } else if (item.title.split('.')[1].length < 2) {
          formFieldOpportunityTcv.ApiKeyValue.push(item.title + '0');
        }
      }

      if (item.formFieldApiKey === 'isTracked') { // This section is temprorarry till we fix the issue from database
        const formFieldIsTracked = formFieldList.find(
          (f) => f.ApiKey === item.formFieldApiKey
        );

        if (formFieldIsTracked.ApiKeyValue.length == 1) {
          if (formFieldIsTracked.ApiKeyValue[0] === 'Tracked') {
            formFieldList = formFieldList.filter(f => f.ApiKey != 'isTracked');
            formFieldList.push({ ApiKey: 'isTracked', ApiKeyValue: ['true'] });
          }
          if (formFieldIsTracked.ApiKeyValue[0] === 'Untracked') {
            formFieldList = formFieldList.filter(f => f.ApiKey != 'isTracked');
            formFieldList.push({ ApiKey: 'isTracked', ApiKeyValue: ['false'] });
          }
        }
        else if (formFieldIsTracked.ApiKeyValue.length > 1) {
          formFieldList = formFieldList.filter(f => f.ApiKey != 'isTracked');
          formFieldList.push({ ApiKey: 'isTracked', ApiKeyValue: ['true', 'false'] });
        }
      }
    });
    if (this.selectedTabName === 'Internal') {
      formFieldList.push({ ApiKey: 'isInternalProject', ApiKeyValue: ['true'] });
    } else if (this.selectedTabName === 'Tracked') {
      formFieldList.push({ ApiKey: 'isTracked', ApiKeyValue: ['true'] });
    } else if (this.selectedTabName === 'Presale') {
      formFieldList.push({ ApiKey: 'isPresaleSelected', ApiKeyValue: ['true'] });
    }
    // if (formFieldList.length > 0) {
    this.queryParam.formFields = JSON.stringify(formFieldList);
    do {
      this.queryParam.formFields = this.queryParam.formFields.replace('(Blanks)', '');
    } while (this.queryParam.formFields.includes('(Blanks)'));
    // }
    // }

    this.searchFieldData = tempSearchFieldData;
   
    if (needToInsertIntoLocalStorage) {
      const commitmentsIntakeSearchInputForLocalStorage: CommitmentsIntakeSearchInputForLocalStorage = {
        commitmentsIntakeSearchInput: this.searchFieldData,
        queryParam: this.queryParam
      }
      localStorage.setItem('commitments-intake-search', JSON.stringify(commitmentsIntakeSearchInputForLocalStorage));
    } else {
      localStorage.removeItem('commitments-intake-search');
    }

    this.search();
  }

  public onTabClicked(tabName: string): void {
    this.selectedTabName = tabName;
    localStorage.setItem('commitments-intake-tab-name', tabName);
    this.onSearchClicked({ searchTags: this.searchFieldData, isTabClicked: true });
  }

  private search(): void {
    this.clientCommitmentsService
      .getPageTemplateResponseList(this.pageTemplateId, this.queryParam)
      .pipe(take(1))
      .subscribe(
        (responses: any) => {
          if (responses.headers.get('X-pagination')) {
            this.pagerObject = Object.assign({}, JSON.parse(responses.headers.get('X-pagination')) as IPager);
          }
          this.pageResponseList = responses.body.responses as ClientCommitmentDTO[];
          console.log("pageResponseList: " + JSON.stringify(this.pageResponseList));
          this.pageResponseList.forEach((responseItem) => {
            responseItem.opportunitySuggestionsIsCalled = false;
            responseItem.fulfillmentSuggestionsIsCalled = false;
            responseItem.pageTemplateAdmin = this.pageTemplateAdmin || this.isAdmin;
            responseItem.customeFormFieldListJsonAnswer = JSON.parse(
              responseItem.answerJSON
            );

            // if (responseItem?.customeFormFieldListJsonAnswer?.statusIntake === 'Cancelled' || responseItem?.customeFormFieldListJsonAnswer?.statusIntake === 'Canceled') {
            //   responseItem.customeFormFieldListJsonAnswer.statusIntake = 'Canceled';
            // }

            if (this.listFormFieldJson && this.listFormFieldJson.length > 0) {
              for (let item of this.listFormFieldJson) {
                const itemApiKey = item['formfieldapikey'];
                if (itemApiKey && responseItem.customeFormFieldListJsonAnswer[itemApiKey]) {
                  const formFieldKey = `${itemApiKey}OriginalValue`;
                  responseItem.customeFormFieldListJsonAnswer[formFieldKey] = responseItem.customeFormFieldListJsonAnswer[itemApiKey];
                }
              }
            }

            const validRoles = [
              'Division Leader',
              'BU Leader',
              'Delivery Owner',
              'Delivery Leader',
              'CCT Admin- Read Only',
            ];
            if (
              this.pageTemplateReadAdmin ||
              this.userData.roles.some(
                (r) => validRoles.indexOf(r.roleTitle) >= 0
              )
            ) {
              responseItem.isReadOnlyRole = true;
            }

            if (this.pageTemplateAdmin || this.isAdmin) {
              responseItem.isDeleteOrEditable = true;
            }

            responseItem.dataSource = responseItem.customeFormFieldListJsonAnswer.datasource;
            responseItem.productTitle = responseItem.customeFormFieldListJsonAnswer.productSIntake;
            responseItem.isRisk = responseItem.customeFormFieldListJsonAnswer.isRisk;
            responseItem.riskReason = responseItem.customeFormFieldListJsonAnswer.riskReason;
            responseItem.selected = !!responseItem.customeFormFieldListJsonAnswer.isTracked;
          });

          // if (responses.headers.get('X-pagination')) {
          //   this.pagerObject = Object.assign({}, JSON.parse(responses.headers.get('X-pagination')) as IPager);
          // }
          this.isReset = false;
        },
        (error) => {
          this.alertInput.alertType = AlertType.Error;
          this.alertInput.message = error;
          this.isReset = false;
        }
      );
  }

  public searchFromList(queryParam): void {
    this.queryParam = queryParam;
    this.search();

    const userFilterString = localStorage.getItem('commitments-intake-search');
    if (userFilterString) {
      const temp = <CommitmentsIntakeSearchInputForLocalStorage>JSON.parse(userFilterString);
      if (temp.commitmentsIntakeSearchInput.length > 0) {
        const commitmentsIntakeSearchInputForLocalStorage: CommitmentsIntakeSearchInputForLocalStorage = {
          commitmentsIntakeSearchInput: this.searchFieldData,
          queryParam: this.queryParam
        }

        localStorage.setItem('commitments-intake-search', JSON.stringify(commitmentsIntakeSearchInputForLocalStorage));
      }
    }

    if (this.queryParam?.orderBy) {
      const temp = this.queryParam?.orderBy.split(' ');
      this.isAsc = false;
      this.filterColumn = temp[0];
      if (temp[1]) {
        this.isAsc = temp[1] !== 'desc';
      }
    } else if (this.queryParam?.sortingFormField) {
      this.filterColumn = this.queryParam?.sortingFormField;
      this.isAsc = this.queryParam?.sortingFormFieldOption !== 'desc';
    }
    else {
      this.filterColumn = '';
      this.isAsc = true;
    }
  }

  public openSubmitNewCommitmentsIntake(): void {
    this.showSubmitNewCommitmentsIntakeDrawer = true;
    document.getElementById('containerPage').classList.add('overflow-hidden');
  }

  public closeSubmitNewCommitmentsDrawers(): void {
    document.getElementById('containerPage').classList.remove('overflow-hidden');
    this.showSubmitNewCommitmentsIntakeDrawer = false;
  }

  callPreferenceGetService() {
    const userId = this.userData.id;
    const listName = PreferenceService.pageTemplateResponseList;
    const fetchPreferenceByUserId =
      this.preferenceService.getPreferenceByUserId(
        userId,
        listName,
        this.pageTemplateId
      );
    const fetchAllPreferenceByListName =
      this.preferenceService.getAllPreferenceByListName(
        listName,
        this.pageTemplateId
      );
    this.showAddColumnOptions = true;
    forkJoin([fetchPreferenceByUserId, fetchAllPreferenceByListName]).subscribe(
      (response: any) => {
        const columnPrefs = response[0];
        const columnList = response[1];
        this.refreshPreference(columnPrefs, columnList);
      },
      (error) => {
        this.isError = true;
        this.alertInput.alertType = AlertType.Error;
        this.alertInput.message = error;
      }
    );
  }

  refreshPreference(columnPrefs: any, columnList?: any) {
    this.isError = false;
    const columnOptions = JSON.parse(columnPrefs.columnOptions);
    this.listName = columnPrefs.listName;
    this.userId = columnPrefs.userId;
    this.preferenceId = columnPrefs.id;
    this.columnPreferences =
      this.preferenceService.convertToDropDownItems(columnOptions);
    if (this.columnPreferences?.length < this.maxColumnCount) {
      this.showAddColumnOptions = true;
    } else {
      this.showAddColumnOptions = false;
    }
    if (columnList) {
      const listOfoptions = JSON.parse(columnList.allColumnOptions);
      this.prefColumnOptions =
        this.preferenceService.convertToDropDownItems(listOfoptions);
    }
    const columnPreferences = this.preferenceService.refreshGrid(columnOptions);
    this.initDataTableColumns(columnPreferences);
    this.searchFromList(this.queryParam);
    if (!this.filterExists) {
      this.filtered = false;
    }
    this.refreshGrid = true;
  }

  initDataTableColumns(preferences: any) {
    this.pageTemplateNameTitle = this.pageTemplate.title;
    this.pageTemplateNameDescription = this.pageTemplate.description;
    this.displayColumns = [];
    this.keys = [];

    for (let i = 0; i < preferences.keys.length; i++) {
      if (preferences.keys[i] === 'id') {
        this.keys.push('id');
        this.displayColumns.push(preferences.displayColumns[i]);
      }

      if (preferences.keys[i] === 'title') {
        this.keys.push('title');
        this.displayColumns.push(preferences.displayColumns[i]);
      }
    }

    if (this.pageTemplate.formFieldListJson) {
      this.listFormFieldJson = JSON.parse(
        this.pageTemplate.formFieldListJson
      );
      if (this.listFormFieldJson.length > 0) {
        for (let item of this.listFormFieldJson) {
          this.ignoreSortingColumns.push(item['formfieldapikey']);
        }
      }
    } else {
      this.listFormFieldJson = JSON.parse('[]');
    }

    for (let i = 0; i < preferences.keys.length; i++) {
      if (preferences.keys[i] !== 'id' && preferences.keys[i] !== 'title') {
        this.keys.push(preferences.keys[i]);
        this.displayColumns.push(preferences.displayColumns[i]);
      }
    }
  }

  public onAlertChanged(alertInput: AlertInput): void {
    this.alertInput = alertInput;
  }

  public setAlertMsgToEmpty(): void {
    this.alertInput.message = '';
    this.pageTemplateService.isResponseCreated = '';
    if (this.pageTemplateService.isPrePopulateRequest === true)
      this.pageTemplateService.isPrePopulateRequest = false;
  }

  public canCreateNewCommitmentIntake(): boolean {
    const result = this.permission.hasWriteAccessOnCommitmentIntake(this.pageTemplateAdmin);

    return result;
  }

  public canCreateOneView(): boolean {
    const result = this.permission.hasWriteAccessOnOneView(this.pageTemplateAdmin);

    return result;
  }

  private setClientOneView() {
    const oneView = {
      formfieldapikey: "oneViewGroupName",
      formfieldlabel: "OneView Name",
      formfieldtype: "textbox",
      isEditable: false,
      value: ""
    };
    const clientNameIndex = this.customFormFieldListJson.findIndex(item => item.formfieldapikey === 'commitmentTitleIntake');
    this.customFormFieldListJson.splice(clientNameIndex - 1, 0, oneView);
  }

  private isValidTabList(): boolean {
    const tabsFromDataSource = ['Clarity', 'eCRM', 'Manual', 'Servicenow', 'CIMS', 'Presale'];
    return this.tabList.length !== tabsFromDataSource.length;
  }

  public createClientView(): void {
    this.isCreateNewOneView = true;
    this.isEditOneView = false;
    this.canEditOneView = this.permission.hasWriteAccessOnOneView(this.pageTemplateAdmin);
    $('#clientViewModal').modal('show');
  }

  public oneViewClicked(oneViewId: any): any {
    this.canEditOneView = this.permission.hasWriteAccessOnOneView(this.pageTemplateAdmin);
    this.isEditOneView = true;
    this.isCreateNewOneView = false;
    this.selectedOneViewId = Object.assign(oneViewId);
    $('#clientViewModal').modal('show');
  }

  public onOneViewSuccess(event: any): any {
    const alert: AlertInput = {
      alertType: AlertType.Success,
      message: event
    };

    this.onAlertChanged(alert);
    this.search();
    $('#clientViewModal').modal('hide');
  }
}
