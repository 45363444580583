<div id="reviewTrail" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog"
    aria-labelledby="myLargeModalLabel" class="modal fade bd-example-modal-lg" style="display: none;"
    aria-hidden="true">
    <div role="document" class="modal-dialog review-commitments-modal modal-lg mt-10">
        <div class="modal-content">
            <div class="modal-header">
                <h4 id="myLargeModalLabel" class="modal-title">
                    Project Review Trail
                </h4>
                <button type="button" data-dismiss="modal" aria-label="Close popup" class="close"
                    (click)="resetData()"><span aria-hidden="true">×</span></button>
            </div>
            <div class="modal-body pt-2">
                <div class="row">
                    <div class="col-12">
                        <div class="table-responsive overflow-auto">
                            <table aria-describedby="tblDesc"
                                class="f-12 table table-hover main-nested-table table-label">
                                <thead class="parent-thead">
                                    <tr role="row">
                                        <th>Modified Date</th>
                                        <th>Project Id</th>
                                        <th>Event Name and Value</th>
                                        <th>Modified By</th>
                                    </tr>
                                </thead>
                                <tbody class="parent-tbody">
                                    <tr role="row" *ngFor="let timeline of data"
                                        [ngClass]="getTimelineStatusCSSClass(timeline.action, timeline.value)">
                                        <td role="cell">
                                            <div class="timeline-date"></div>
                                            <div class="single-timeline-content">
                                                <div class="timeline-text dateDiv">
                                                    <p class="h6">{{timeline.modifiedOn | dateFormat}}</p>
                                                    <p>{{timeline.modifiedOn | formatDateTime}}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td role="cell">
                                            <div class="single-timeline-content">
                                                <div class="timeline-text">
                                                    <p class="h6">{{timeline.projectKey}}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td role="cell">
                                            <div class="single-timeline-content">
                                                <div class="timeline-text">
                                                    <p class="h6">{{translate(timeline.action, timeline.value)}}</p>
                                                    <div
                                                        *ngIf="timeline.action === action.Title || timeline.action === action.Description || timeline.action === action.Note">
                                                        <pre>{{timeline.value}}</pre>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td role="cell">
                                            <div class="single-timeline-content">
                                                <div class="timeline-text">
                                                    <p class="h6">{{timeline.modifiedByName}}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>