import { Injectable, Pipe, PipeTransform } from "@angular/core";
import { CommitmentIntakeApiClarityData, CommitmentIntakeApiEcrmData, ICommitmentIntakeDetailsDto } from "../models/commitment-intake-d-t-o";
import moment from "moment";
import { FormGroup } from "@angular/forms";
import { IOrganizationSuggestion } from "src/app/shared/organization";
import { DateTimeService } from "./date-time.service";

@Injectable()
export class CommitmentTransformService {
    private _isFormGroup: boolean;
    private _formRoot: FormGroup;
    private _commitmentData: ICommitmentIntakeDetailsDto;
    private _apiEcrmData: CommitmentIntakeApiEcrmData;
    private _apiClarityData: CommitmentIntakeApiClarityData;

    constructor(private _datetimeService: DateTimeService) {

    }

    transform(fieldName: string, data: FormGroup | ICommitmentIntakeDetailsDto, fetchedApiData?: CommitmentIntakeApiEcrmData | CommitmentIntakeApiClarityData): string | boolean | Date | any[] {
        this._isFormGroup = data instanceof FormGroup;
        this._formRoot = data as FormGroup;
        this._commitmentData = data as ICommitmentIntakeDetailsDto;
        this._apiEcrmData = fetchedApiData as CommitmentIntakeApiEcrmData;
        this._apiClarityData = fetchedApiData as CommitmentIntakeApiClarityData;

        switch (fieldName) {
            case 'buResponsibleForDeliveryIntakes':
                return this.calculateBuResponsibleForDeliveryIntakes(fieldName);
            case 'lineOfBusinesses':
                return this.calculateLineOfBusinesses(fieldName);
            case 'commitmentDate':
                return this.calculateCommitmentDate(fieldName);
            case 'plannedGoLiveDateChangeReason':
                return this.calculatePlannedGoLiveDateChangeReason();
            case 'isReviewed':
                return this.calculateIsReviewed();
            case 'isTracked':
                return this.calculateIsTracked(fieldName);
                // Begin Clarity handling
            case 'doYouWantToMapTheCommitmentWithClarityProject':
                return this.calculateDoYouWantToMapTheCommitmentWithClarityProject(fieldName);
            case 'clientTotalOfDaysChanged':
                return this.calculateClientTotalOfDaysChanged();
            case 'clientTotalOfDaysChangedCount':
                    return this.calculateClientTotalOfDaysChangedCount();
            case 'fiservTotalOfDaysChanged':
                return this.calculateFiservTotalOfDaysChanged();
            case 'fiservTotalOfDaysChangedCount':
                return this.calculateFiservTotalOfDaysChangedCount();
            case 'projectNumber':
                return this.calculateClarityFieldValue(fieldName, data, 'investmentID');
            case 'workStatus':
            case 'progress':
            case 'statusUpdate':
            case 'projectManager':
            case 'resourceManagerLevel4':
            case 'totalPlannedEffortHours':
            case 'projectStatus':
            case 'mc':
            case 'deliveryOwner':
            case 'resourceManagerLevel5':
                return this.calculateClarityFieldValue(fieldName, data);
            case 'plannedCommittedStartDate':
                return this.calculateClarityFieldDate(fieldName, data);          
            case 'dateStatus':
                return this.calculateDateStatus();
            case 'plannedCommittedDeliveryDate':
                return this.calculatePlannedCommittedDeliveryDate();
            case 'displayPlannedDeliveryDate':
                return this.calculateDisplayPlannedDeliveryDate();
            case 'plannedBenefitCurrentYear':
            case 'lastDateChangeCommentary':
            case 'deliveryConfirmation':
            case 'totalForecastCost':
            case 'firstCommittedDeliveryDate':
            case 'hleRequirementStatus':
                return this.calculateClarityValue(fieldName, data);
            case 'statusUpdateDate':
                return this.calculateClarityFieldDate(fieldName, data, 'statusReportDate');
            case 'originalGoLiveDate':
                return this.calculateOriginalGoLiveDate(fieldName);
            case 'plannedGoLiveDate':
                return this.calculatePlannedGoLiveDate(fieldName, data);
            // Begin ECRM handling
            case 'doYouWantToMapTheCommitmentWithECrmOpportunity':
                return this.calculateDoYouWantToMapTheCommitmentWithECrmOpportunity(fieldName);
            case 'eCrmAccountId':
                return this.calculateOpportunityFieldValue(fieldName, data, 'eCRM_Account_ID');
            case 'opportunityStage':
                return this.calculateOpportunityFieldValue(fieldName, data, 'opportunity_Stage');
            case 'opportunityType':
                return this.calculateOpportunityFieldValue(fieldName, data, 'opportunity_Type');
            case 'opportunityCreatedDate':
                return this.calculateOpportunityFieldDate(fieldName, data, 'opportunity_Created_Date');
            case 'opportunityPlannedGoLiveDate':
                return this.calculateOpportunityFieldDate(fieldName, data, 'planned_Go_Live_Date');
            case 'opportunityId':
                return this.calculateOpportunityFieldValue(fieldName, data, 'opportunity_ID');
            case 'opportunityOwnerName':
                return this.calculateOpportunityFieldValue(fieldName, data, 'opportunity_OwnerName');
            case 'opportunityClosedDate':
                return this.calculateOpportunityFieldDate(fieldName, data, 'opportunity_Close_Date');
            default:
                if (!this._isFormGroup) {
                    return this._commitmentData[fieldName];
                }

                return this._formRoot.get(fieldName)?.value;
        }
    }

    private calculateBuResponsibleForDeliveryIntakes(fieldName: string) {
        if (this._isFormGroup) {
            const formOrganizations = this._formRoot.get('organizations').value as IOrganizationSuggestion[];
            const unique = this.distinctArray<IOrganizationSuggestion>(formOrganizations, 'mdmbuMasterId').map(p => {
                return {
                    businessUnitTitle: p.buTitle,
                    mdmbuMasterId: p.mdmbuMasterId
                };
            });

            return unique;
        }

        const organizations = this._commitmentData.organizations;
        if (organizations && organizations.length > 0) {
            const unique = this.distinctArray<IOrganizationSuggestion>(organizations, 'mdmbuMasterId').map(p => {
                return {
                    businessUnitTitle: p.buTitle,
                    mdmbuMasterId: p.mdmbuMasterId
                };
            });

            return unique;
        }

        return this._commitmentData[fieldName];
    }

    private calculateLineOfBusinesses(fieldName: string) {
        if (this._isFormGroup) {
            const formOrganizations = this._formRoot.get('organizations').value as IOrganizationSuggestion[];
            const unique = this.distinctArray<IOrganizationSuggestion>(formOrganizations, 'lobId');
            return unique.map(lob => lob.lobTitle);
        }

        const organizations = this._commitmentData.organizations;
        if (organizations && organizations.length > 0) {
            const unique = this.distinctArray<IOrganizationSuggestion>(organizations, 'lobId');
            return unique.map(lob => lob.lobTitle);
        }

        return this._commitmentData[fieldName];
    }

    private calculateOriginalGoLiveDate(fieldName: string) {
        const datasource = this.getDatSource();
        const plannedGoLiveDate = !this._isFormGroup ? this._commitmentData.plannedGoLiveDate : this._formRoot.get('plannedGoLiveDate').value;
        const originalGoLiveDate = !this._isFormGroup ? this._commitmentData.originalGoLiveDate : this._formRoot.get('originalGoLiveDate').value;
        const id = !this._isFormGroup ? this._commitmentData.id : this._formRoot.get('id').value;
        if (datasource && plannedGoLiveDate && datasource === 'Manual' && (!originalGoLiveDate || id === 0)) {
            return this._datetimeService.formatDate(plannedGoLiveDate);
        }

        const value = this._isFormGroup ? this._formRoot.get(fieldName).value : this._commitmentData[fieldName];
        if (value) {
            return this._datetimeService.formatDate(value);
        }

        return null;
    }
    
    private calculatePlannedGoLiveDate(fieldName: string, data: FormGroup | ICommitmentIntakeDetailsDto) {
        const value = this.getPropertyCaseInsensitive(fieldName, data);        
        if (value) {
            return this._datetimeService.formatDate(value);
        }

        return value;
    }

    calculateCommitmentDate(fieldName: string) {
        const value = this._isFormGroup ? this._formRoot.get(fieldName).value : this._commitmentData[fieldName];
        if (value) {
            return this._datetimeService.formatDate(value);
        }
        
        return null;
    }

    private calculatePlannedGoLiveDateChangeReason() {
        if (!this._isFormGroup) {
            if (this._commitmentData.selectedPlannedGoLiveDateChangeReason && this._commitmentData.selectedPlannedGoLiveDateChangeReason !== 'Other') {
                return this._commitmentData.selectedPlannedGoLiveDateChangeReason;
            }
    
            return this._commitmentData.plannedGoLiveDateChangeReasonOther;
        }

        const selectedPlannedGoLiveDateChangeReasonFormValue = this._formRoot.get('selectedPlannedGoLiveDateChangeReason').value;
        if (selectedPlannedGoLiveDateChangeReasonFormValue && selectedPlannedGoLiveDateChangeReasonFormValue !== 'Other') {
            return selectedPlannedGoLiveDateChangeReasonFormValue;
        }

        const plannedGoLiveDateChangeReasonOtherFormValue = this._formRoot.get('plannedGoLiveDateChangeReasonOther').value;
        return plannedGoLiveDateChangeReasonOtherFormValue;
    }

    private calculateIsReviewed() {
        return this.calculateIsTracked('isTracked');
    }

    private calculateIsTracked(fieldName: string) {
        const datasource = this.getDatSource();
        const commitmentTitle = !this._isFormGroup ? this._commitmentData.commitmentTitle : this._formRoot.get('commitmentTitle').value;
        if (datasource == 'Manual' && !commitmentTitle)
        {
            return true;
        }

        if (this._isFormGroup) {
            return this._formRoot.get(fieldName).value;
        }
        return this._commitmentData[fieldName] as boolean;
    }
    /**
     * BEGIN
     * ClarityDetails
     */
    private canMapClarityProject() {
        return this.calculateDoYouWantToMapTheCommitmentWithClarityProject('doYouWantToMapTheCommitmentWithClarityProject');
    }
    
    private calculateDoYouWantToMapTheCommitmentWithClarityProject(fieldName: string) {
        if (!this._isFormGroup) {
            return this._commitmentData[fieldName] as boolean;
        }

        return this._formRoot.get(fieldName).value as boolean;
    }

    private calculateClientTotalOfDaysChanged() {
        if (this._apiClarityData && this._apiClarityData.clarity[0] && this._apiClarityData.clarity[0].totalOfDaysChanged) {
            return this._apiClarityData.clarity[0].totalOfDaysChanged;
        }

        if (this._isFormGroup) {
            return this._formRoot.get('clientTotalOfDaysChanged').value;
        }

        return this._commitmentData.clientTotalOfDaysChanged;
    }

    private calculateClientTotalOfDaysChangedCount() {
        if (this._apiClarityData && this._apiClarityData.clarity[0] && this._apiClarityData.clarity[0].totalOfDaysChanged) {
            return this._apiClarityData.clarity[0].totalOfDaysChanged;
        }

        if (this._isFormGroup) {
            return this._formRoot.get('clientTotalOfDaysChangedCount').value;
        }

        return this._commitmentData.clientTotalOfDaysChangedCount;
    }

    private calculateFiservTotalOfDaysChanged() {
        if (this._apiClarityData && this._apiClarityData.clarity[0] && this._apiClarityData.clarity[0].totalOfDaysChanged) {
            return this._apiClarityData.clarity[0].totalOfDaysChanged;
        }

        if (this._isFormGroup) {
            return this._formRoot.get('fiservTotalOfDaysChanged').value;
        }

        return this._commitmentData.fiservTotalOfDaysChanged;
    }

    private calculateFiservTotalOfDaysChangedCount() {
        if (this._apiClarityData && this._apiClarityData.clarity[0] && this._apiClarityData.clarity[0].totalOfDaysChanged) {
            return this._apiClarityData.clarity[0].totalOfDaysChanged;
        }

        if (this._isFormGroup) {
            return this._formRoot.get('fiservTotalOfDaysChangedCount').value;
        }

        return this._commitmentData.fiservTotalOfDaysChangedCount;
    }

    private calculateDateStatus() {
        const datasource = this.getDatSource();
        if (!datasource) {
            return undefined;
        }

        if ((datasource == 'Manual' || datasource == 'eCRM') && this.isClarityApiDataHasSearch())
        {
            return this._apiClarityData.clarity[0].dateStatus;
        }
        
        const dateStatus = !this._isFormGroup ? this._commitmentData.dateStatus : this._formRoot.get('dateStatus').value;
        if ((datasource == 'Manual' || datasource == 'eCRM') && this.isClarityWithoutApiDataOrSearch() && dateStatus && dateStatus != '') 
        {
            return dateStatus;
        }
        
        if (datasource == 'Manual' && this.isClarityApiDataWithoutSearch())	
        {
            return 'Committed';
        }
        
        if (datasource == 'eCRM' && this.isClarityApiDataWithoutSearch())
        {
            return '';
        }
        
        return dateStatus;
    }

    // For lastDateChangeCommentary, deliveryConfirmation, plannedBenefitCurrentYear
    private calculateClarityValue(fieldName: string, data: FormGroup | ICommitmentIntakeDetailsDto) {
        const datasource = this.getDatSource();
        if ((datasource == 'Manual' || datasource == 'eCRM') && this.isClarityApiDataHasSearch()) 
        {
            return this.getPropertyCaseInsensitive(fieldName, this._apiClarityData.clarity[0]);
        }

        if ((datasource == 'Manual' || datasource == 'eCRM') && this.isClarityApiDataWithoutSearch())
        {
            return '';
        }
        return this.getPropertyCaseInsensitive(fieldName, data);
    }
    
    private calculateClarityFieldValue(fieldName: string, data: FormGroup | ICommitmentIntakeDetailsDto, apiFieldName?: string) {
        const datasource = this.getDatSource();
        if ((datasource == 'Manual' || datasource == 'eCRM') && this.isClarityApiDataHasSearch() && !apiFieldName) 
        {
            return this.getPropertyCaseInsensitive(fieldName, this._apiClarityData.clarity[0]);
        }

        if ((datasource == 'Manual' || datasource == 'eCRM') && this.isClarityApiDataHasSearch() && apiFieldName) 
        {
            return this.getPropertyCaseInsensitive(apiFieldName, this._apiClarityData.clarity[0]);
        }
        
        const value = this.getPropertyCaseInsensitive(fieldName, data);
        if ((datasource == 'Manual' || datasource == 'eCRM') && this.isClarityWithoutApiDataOrSearch() && value && value != '') 
        {
            return value;
        }
        
        if ((datasource == 'Manual' || datasource == 'eCRM') && this.isClarityApiDataWithoutSearch())		
        {
            return '';
        }
        
        return value;
    }

    private calculateClarityFieldDate(fieldName: string, data: FormGroup | ICommitmentIntakeDetailsDto, apiFieldName?: string) {
        const datasource = this.getDatSource();
        if ((datasource == 'Manual' || datasource == 'eCRM') && this.isClarityApiDataHasSearch() && !apiFieldName) 
        {
            return this.getPropertyCaseInsensitive(fieldName, this._apiClarityData.clarity[0]);
        }

        if ((datasource == 'Manual' || datasource == 'eCRM') && this.isClarityApiDataHasSearch() && apiFieldName) 
        {
            return this.getPropertyCaseInsensitive(apiFieldName, this._apiClarityData.clarity[0]);
        }
        
        const value = this.getPropertyCaseInsensitive(fieldName, data);
        if ((datasource == 'Manual' || datasource == 'eCRM') && this.isClarityWithoutApiDataOrSearch() && value && value != '') 
        {
            return this._datetimeService.formatDate(value);
        }
        
        if ((datasource == 'Manual' || datasource == 'eCRM') && this.isClarityApiDataWithoutSearch())		
        {
            return '';
        }

        if (value) {
            return this._datetimeService.formatDate(value);
        }
        
       return value;
    }

    private calculatePlannedCommittedDeliveryDate() {
        const datasource = this.getDatSource();
        if ((datasource == 'Manual' || datasource == 'eCRM') && this.isClarityApiDataHasSearch()
             && this._apiClarityData.clarity[0].plannedCommittedDeliveryDate != '') 
        {
          return this._datetimeService.formatDate(this._apiClarityData.clarity[0].plannedCommittedDeliveryDate);
        }

        const plannedCommittedDeliveryDate = !this._isFormGroup ? this._commitmentData.plannedCommittedDeliveryDate : this._formRoot.get('plannedCommittedDeliveryDate').value;
        if ((datasource == 'Manual' || datasource == 'eCRM') && this.isClarityWithoutApiDataOrSearch() 
            && plannedCommittedDeliveryDate && plannedCommittedDeliveryDate != '') 
        {
            this._datetimeService.formatDate(plannedCommittedDeliveryDate);
        }

        if ((datasource == 'Manual' || datasource == 'eCRM') && this.isClarityApiDataWithoutSearch())		
        {
          return '';
        }

        if (plannedCommittedDeliveryDate){
          return this._datetimeService.formatDate(plannedCommittedDeliveryDate);
        }
        return '';
    }

    private calculateDisplayPlannedDeliveryDate() {
        const datasource = this.getDatSource();
        const dateStatus = !this._isFormGroup ? this._commitmentData.dateStatus : this._formRoot.get('dateStatus').value;
        const status = !this._isFormGroup ? this._commitmentData.status : this._formRoot.get('status').value;
        const validStatuses = ['Cancelled', 'In Progress', 'Not Started', 'In Planning', 'Completed', 'Delivered'];
        if (datasource === 'Manual' && dateStatus === 'Committed' && validStatuses.indexOf(status) >= 0) {
            const plannedGoLiveDate = !this._isFormGroup ? this._commitmentData.plannedGoLiveDate : this._formRoot.get('plannedGoLiveDate').value;
            if (!plannedGoLiveDate) {
                return '';
            }
            return this._datetimeService.formatDate(plannedGoLiveDate, "MM/DD/yyyy");
        }
        else if (datasource === 'Manual') {
            const inprogressStatuses = ['In Progress', 'In Planning', 'Not Started'];
            if ((!dateStatus || dateStatus === 'Planned') && inprogressStatuses.indexOf(status) >= 0) {
                return 'To Be Scheduled';
            }

            return '';
        }

        const displayPlannedDeliveryDate = !this._isFormGroup ? this._commitmentData.displayPlannedDeliveryDate : this._formRoot.get('displayPlannedDeliveryDate').value;
        if (!displayPlannedDeliveryDate || displayPlannedDeliveryDate === 'To Be Scheduled') {
            return displayPlannedDeliveryDate;
        }
        
        return this._datetimeService.formatDate(new Date(displayPlannedDeliveryDate), "MM/DD/yyyy");
    }

    private isClarityApiDataHasSearch() {
        const canMap = this.canMapClarityProject();
        if (!canMap) {
            return false;
        }

        const searchClarityProjectNumber = !this._isFormGroup ? this._commitmentData.searchClarityProjectNumber : this._formRoot.get('searchClarityProjectNumber').value;
        if (!searchClarityProjectNumber) {
            return false;
        }

        return this._apiClarityData 
            && this._apiClarityData.clarity[0]
            && this._apiClarityData.status == 'success'
            && this._apiClarityData.clarity[0].investmentID == searchClarityProjectNumber;
    }

    private isClarityWithoutApiDataOrSearch() {
        const canMap = this.canMapClarityProject();
        const searchClarityProjectNumber = !this._isFormGroup ? this._commitmentData.searchClarityProjectNumber : this._formRoot.get('searchClarityProjectNumber').value;
        return (canMap || !searchClarityProjectNumber) && !this._apiClarityData;
    }

    private isClarityApiDataWithoutSearch() {
        const canMap = this.canMapClarityProject();
        const searchClarityProjectNumber = !this._isFormGroup ? this._commitmentData.searchClarityProjectNumber : this._formRoot.get('searchClarityProjectNumber').value;

        return canMap || !searchClarityProjectNumber
            || (this._apiClarityData
                && this._apiClarityData.clarity[0]
                && this._apiClarityData.status == 'success' 
                && this._apiClarityData.clarity[0].investmentID != searchClarityProjectNumber);
    }

    /**
     * END
     * ClarityDetails
     */

    /**
     * BEGIN
     * opportunityDetails
    */
    private canMapEcrmOpportunity() {
        return this.calculateDoYouWantToMapTheCommitmentWithECrmOpportunity('doYouWantToMapTheCommitmentWithECrmOpportunity');
    }

    private calculateDoYouWantToMapTheCommitmentWithECrmOpportunity(fieldName: string) {
        if (!this._isFormGroup) {
            return this._commitmentData[fieldName] as boolean;
        }

        return this._formRoot.get(fieldName).value as boolean;
    }

    private calculateOpportunityFieldValue(fieldName: string, data: FormGroup | ICommitmentIntakeDetailsDto, apiFieldName?: string) {
        const datasource = this.getDatSource();
        if ((datasource == 'Manual' || datasource == 'CIMS' || datasource == 'Clarity') && this.isEcrmApiDataHasSearch() && !apiFieldName) 
        {
            return this.getPropertyCaseInsensitive(fieldName, this._apiEcrmData.eCRMOppurtunity[0]);
        }

        if ((datasource == 'Manual' || datasource == 'CIMS' || datasource == 'Clarity') && this.isEcrmApiDataHasSearch() && apiFieldName) 
        {
            return this.getPropertyCaseInsensitive(apiFieldName, this._apiEcrmData.eCRMOppurtunity[0]);
        }
        
        const value = this.getPropertyCaseInsensitive(fieldName, data);
        if ((datasource == 'Manual' || datasource == 'CIMS' || datasource == 'Clarity') && this.isEcrmWithoutApiDataOrSearch()
            && value && value != ''
        ) 
        {
            return value;
        }
        
        if ((datasource == 'Manual' || datasource == 'CIMS' || datasource == 'Clarity') && this.isEcrmApiDataWithoutSearch())
        {
            return '';
        }

        return value;
    }

    private isEcrmApiDataHasSearch() {
        const canMap = this.canMapEcrmOpportunity();
        if (!canMap) {
            return false;
        }

        const searchOpportunityId = !this._isFormGroup 
            ? this._commitmentData.searchOpportunityId 
            : this._formRoot.get('searchOpportunityId').value;
        if (!searchOpportunityId) {
            return false;
        }
        
        return this._apiEcrmData
        && this._apiEcrmData.eCRMOppurtunity[0]
        && this._apiEcrmData.status == 'success'
        && this._apiEcrmData.eCRMOppurtunity[0].opportunity_ID == searchOpportunityId;
    }

    private isEcrmWithoutApiDataOrSearch() {
        const canMap = this.canMapEcrmOpportunity();
        const searchOpportunityId = !this._isFormGroup 
            ? this._commitmentData.searchOpportunityId 
            : this._formRoot.get('searchOpportunityId').value;
        return (!canMap || searchOpportunityId == '')  && !this._apiEcrmData;
    }

    private isEcrmApiDataWithoutSearch() {
        const canMap = this.canMapEcrmOpportunity();
        const searchOpportunityId = !this._isFormGroup 
            ? this._commitmentData.searchOpportunityId 
            : this._formRoot.get('searchOpportunityId').value;
        return (!canMap 
            || searchOpportunityId == '' 
            || (this._apiEcrmData 
                && this._apiEcrmData.eCRMOppurtunity[0]
                && this._apiEcrmData.status == 'success'
                && this._apiEcrmData.eCRMOppurtunity[0].opportunity_ID != searchOpportunityId)
        );
    }

    private calculateOpportunityFieldDate(fieldName: string, data: FormGroup | ICommitmentIntakeDetailsDto, apiFieldName?: string) {
        const datasource = this.getDatSource();
        const otherDatasources = ['Manual', 'CIMS', 'Clarity'];
        if (otherDatasources.includes(datasource) && this.isEcrmApiDataHasSearch()) 
        {
            const eCRMOppurtunity = this._apiEcrmData?.eCRMOppurtunity ? this._apiEcrmData.eCRMOppurtunity[0] : null;
            const apiDate = this.getPropertyCaseInsensitive(apiFieldName, eCRMOppurtunity);
            if (apiDate) {
                return this._datetimeService.formatDate(apiDate);
            }
        }

        const value = this.getPropertyCaseInsensitive(fieldName, data);
        if (otherDatasources.includes(datasource) && this.isEcrmWithoutApiDataOrSearch() && value && value != '') 
        {
            return this._datetimeService.formatDate(value);
        }

        if (otherDatasources.includes(datasource) && this.isEcrmApiDataWithoutSearch())
        {
            return '';
        }
        
        if (value != '') {
            return this._datetimeService.formatDate(value);
        }

        return value;
    }
    /**
     * END
     * opportunityDetails
     */

    private getDatSource() {
        if (this._isFormGroup) {
            return this._formRoot.get('datasource').value;;
        }
        return this._commitmentData.datasource;
    }


    private getPropertyCaseInsensitive(fieldName: string, obj: FormGroup | ICommitmentIntakeDetailsDto | CommitmentIntakeApiEcrmData | CommitmentIntakeApiClarityData) {
        const isFormGroup = obj instanceof FormGroup;
        if (!isFormGroup) {
            const asLowercase = fieldName.toLowerCase();
            const keyInLowercase = Object.keys(obj).find(k => k.toLowerCase() === asLowercase);
            return obj[keyInLowercase];
        }

        return this._formRoot.get(fieldName).value;
    }

  
    distinctArray<T>(srcArray: any[], key: string) {
        const distinctedIds = new Set(srcArray.map(r => r[key]));
        const unique = Array.from(distinctedIds).map(id => {
            return srcArray.find(r => r[key] === id);
        }) as T[];

        return unique;
    }
}
