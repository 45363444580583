import { Injectable } from '@angular/core';
import moment from 'moment';
import { DashboardCommitmentDTO } from '../models/client-dashboard-d-t-o';

@Injectable()
export class ManageClientDashboardService {
  getDeliveryCalendarData(commitments: DashboardCommitmentDTO[], typeOfCommitmentCalander: string): any {
    const calendarStatuses = ["Delivered", "Cancelled", "Completed", "In Progress", "In Planning", "Not Started", "On Hold"];
    const calendarCommitments = commitments.filter(x => x.displayPlannedCommittedDeliveryDate && x.displayPlannedCommittedDeliveryDate !== '' && x.displayPlannedCommittedDeliveryDate !== 'To Be Scheduled' && calendarStatuses.includes(x.commitmentStatus));
    const tmpCalendars = calendarCommitments.map(x => {
      const plannedCommittedDeliveryYear = this.formateDate(x.displayPlannedCommittedDeliveryDate, 'YYYY');
      const plannedCommittedDeliveryMonth = this.getReportFrequency(x.displayPlannedCommittedDeliveryDate, 'Month');
      const plannedCommittedDeliveryQuarter = this.getReportFrequency(x.displayPlannedCommittedDeliveryDate, 'Quarter');
      return {
        commitmentStatus: x.commitmentStatus,
        year: plannedCommittedDeliveryYear,
        quarter: `${plannedCommittedDeliveryYear}-${plannedCommittedDeliveryQuarter}`,
        month: `${plannedCommittedDeliveryYear}-${plannedCommittedDeliveryMonth}`,
      };
    });
 
    const dataGroup = this.groupBy(tmpCalendars, typeOfCommitmentCalander);
    const keys = Object.keys(dataGroup).sort();
    return keys.map(key => {
      const groupCommitments = dataGroup[key];    
      return {
        label: key,
        numberOfCommitments: groupCommitments.length,
        inProgressCommitments: groupCommitments.filter(x => x.commitmentStatus === 'In Progress').length,
        inPlanningCommitments: groupCommitments.filter(x => x.commitmentStatus === 'In Planning').length,
        notStartedCommitments: groupCommitments.filter(x => x.commitmentStatus === 'Not Started').length,
        completedCommitments: groupCommitments.filter(x => x.commitmentStatus === 'Completed').length,
        onHoldCommitments: groupCommitments.filter(x => x.commitmentStatus === 'On Hold' || x.commitmentStatus === 'Fiserv - On Hold' || x.commitmentStatus === 'Client - On Hold').length,
        canceledCommitments: groupCommitments.filter(x => x.commitmentStatus === 'Cancelled').length,
        deliveredCommitments: groupCommitments.filter(x => x.commitmentStatus === 'Delivered').length
      };
    });
  }

  getSummaryData(commitments: any[]): any {
    const calendarCommitments = [...commitments].filter(x => x.commitmentStatus).map(element => {
      return {
        ...element,
        commitmentStatus: this.standardizeStatisticsStatus(element.commitmentStatus)
      };
    });

    const dataGroup = this.groupBy(calendarCommitments, 'commitmentStatus');
    const statisticsStatuses = ['Not Started', 'In Planning', 'In Progress', 'Delivered', 'On Hold', 'Completed', 'Cancelled'];
    const summary = { commitmentStatuses: [] };
    statisticsStatuses.forEach(status => {
      const summaryStatus = {};
      summaryStatus['commitmentStatus'] = status;
      if (dataGroup[status]) {
        summaryStatus['commitmentCount'] = dataGroup[status].length
      }
      else {
        summaryStatus['commitmentCount'] = 0;
      }
      summary.commitmentStatuses.push(summaryStatus);
    });

    return summary;
  }

  groupBy(array: any[], key: string) {
    return array.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  sortByRisk(rows: any[], sortingObject: { key?: string, isAsc?: boolean }) {
    const customOrder = [
      "In Progress",
      "In Planning",
      "Not Started",
      "Fiserv - On Hold",
      "Client - On Hold",
      "On Hold",
      "Delivered",
      "Completed",
      "Cancelled"
    ];
  
    const atRiskRows = rows.filter(row => row.isRisk.toLowerCase() === 'true');
    const blankRiskRows = rows.filter(row => row.isRisk.toLowerCase() === 'blank');
    const notAtRiskRows = rows.filter(row => row.isRisk.toLowerCase() === 'false');

    atRiskRows.sort((a, b) => customOrder.indexOf(a.commitmentStatus) - customOrder.indexOf(b.commitmentStatus));
    blankRiskRows.sort((a, b) => customOrder.indexOf(a.commitmentStatus) - customOrder.indexOf(b.commitmentStatus));
    notAtRiskRows.sort((a, b) => customOrder.indexOf(a.commitmentStatus) - customOrder.indexOf(b.commitmentStatus));
    if ((!sortingObject.key || (sortingObject.key.includes('isRisk') && !sortingObject.isAsc))) {
      return [...atRiskRows,...notAtRiskRows,...blankRiskRows];
    }
    
    return [...blankRiskRows,...notAtRiskRows,...atRiskRows];
  }


  getReportFrequency(date: string, frequency: string) {
    switch(frequency) {
      case 'Month':
        return this.formateDate(date, 'MMM');
      case 'Quarter':
        return `Q${moment(date).quarter()}`;
      default:
        return moment(date).quarter();
    }
  }

  applySorting(commitmentRows: DashboardCommitmentDTO[], sortingObject: { key?: string, isAsc?: boolean }) {
    if (!sortingObject || !sortingObject.key || sortingObject.isAsc === undefined || sortingObject.isAsc === null) {
      return [...commitmentRows];
    }

    const isDateSorting = sortingObject.key.toLowerCase().includes('date');
    const sortResult = [...commitmentRows.sort((a,b) => {
      const prev = isDateSorting ? this.conculateDateToCompare(a[sortingObject.key]) : a[sortingObject.key];
      const next = isDateSorting ? this.conculateDateToCompare(b[sortingObject.key]) : b[sortingObject.key];
      if (prev > next) {
        return 1;
      }
      
      if (prev < next) {
        return -1;
      }
      
      const prev2 = a['projectNumber'];
      const next2 = a['projectNumber'];
      if (prev2 > next2) {
        return 1;
      }
      
      if (prev2 < next2) {
        return -1;
      }

      return 0;
    })];

    if (sortingObject.isAsc) {
      return sortResult.reverse();
    }

    return sortResult;
  }

  standardizeStatisticsStatus(status: string) {
    if (['On Hold', 'Fiserv - On Hold', 'Client - On Hold'].includes(status)) {
      return 'On Hold';
    }
    if (['Not Started', 'In Planning', 'In Progress', 'Completed', 'Cancelled', 'Delivered'].includes(status)) {
      return status;
    }

    return 'In Progress';
  }

  conculateDateToCompare(date: string): number {
    if (!date) {
      return 0;
    }

    if (date === 'To Be Scheduled') {
      return 1;
    }

    return new Date(date).getTime()
  }

  private formateDate(value: string, format: string): string {
    if (!value) {
      return null;
    }
    return moment(value).format(format);
  }
}
