<!-- Drawer Starts - Please use the drawer patten instead of this static HTML this is just an example -->
<div>
    <div id="updateCommitmentsIntake" class="map-dw">
        <div tabindex="-1" aria-labelledby="addContacts" aria-expanded="true" role="region"
            class="drawer drawer-right dw-xs-12 dw-sm-8 dw-md-6 fold open">
            <div class="drawer-contents commitment-dw  col-xs-12 col-sm-12 col-md-12 ">
                <div class="dw-header">
                    <div class="modal-header-container">
                        <button (click)="closeDrawer()" type="button" data-dismiss="modal" tabindex="0" autofocus=""
                            aria-label="Close popup" class="arrow-dw"><span class="material-icons">
                                arrow_back
                            </span></button>
                        <h4 class="modal-title  ">
                            Update Commitments Intake
                            <p>The list below displays updated response(s) for the commitment. Please review and
                                submit.</p>
                        </h4>
                    </div>
                </div>
                <div class="dw-body pr-0">
                  <div class="form-container">
                    <div class="form-row">
                      <div class="col-md-12  data-company-table">
                        <table aria-describedby="tblDesc" tabindex="0" class="table table-hover table-label">
                          <thead>
                            <tr role="row">
                              <th role="columnheader" tabindex="0" scope="col">ID</th>
                              <th role="columnheader" scope="col">
                                <a href="#" tabindex="0"> Title </a>
                              </th>
                              <th role="columnheader" tabindex="0" scope="col">Date/Time</th>
                              <th role="columnheader" tabindex="0" scope="col">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr role="row" *ngFor="let row of updatedCommitments" [ngClass]="getStatusCSSClass(row)" class="">
                              <td role="cell" tabindex="0">{{row.id}}</td>
                              <td role="cell" tabindex="0">{{row.commitmentTitle}}</td>
                              <td role="cell" tabindex="0">{{row.createdOn| date: 'MM/dd/yyyy HH:mm'}}</td>
                              <td role="cell" tabindex="0" *ngIf="row.updateState == 'update'"><i _ngcontent-vhd-c195="" class="material-icons" aria-hidden="true">check_circle</i> Updated</td>
                              <td role="cell" tabindex="0" *ngIf="row.updateState != 'update' && row.updateState != 'untrack'"><i _ngcontent-vhd-c195="" class="material-icons" aria-hidden="true">check_circle</i> Retained</td>
                              <td role="cell" tabindex="0" *ngIf="row.updateState == 'untrack'"><i _ngcontent-vhd-c195="" class="material-icons" aria-hidden="true">warning</i> Untracked</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="ml-0 mr-0 mt-5 mb-5">

                      <label *ngIf="updatedCommitments[0].clientCommitmentUsersList">The Project is visible to below client users: </label>

                      <table class="table table-hover" *ngIf="updatedCommitments[0].clientCommitmentUsersList">
                        <thead>
                          <tr role="row">
                            <th role="columnheader">Name</th>
                            <th role="columnheader">Email</th>
                            <th role="columnheader">Group</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let SelectedCEUser of updatedCommitments[0].clientCommitmentUsersList">
                            <td role="cell">{{SelectedCEUser.name}}</td>
                            <td role="cell">{{SelectedCEUser.email}}</td>
                            <td role="cell">{{SelectedCEUser.restrictedUserGroup}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="fdl-2 button-container">
                      <button type="button" data-dismiss="modal" class="btn fdl-btn btn-secondary-light dCancel" (click)="closeDrawer()" id="dCancel">
                        Cancel
                      </button>
                      <div>
                        <button type="button" class="btn fdl-btn btn-primary-orange ml-2" (click)="submit()">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <div class="backdrop-space">
            <div class="backdrop"></div>
        </div>
    </div>
</div>
