import { Injectable } from "@angular/core";
import moment from "moment";

@Injectable()
export class DateTimeService {
    constructor() { }

    public IsValidDate(date: string | Date): boolean {
        const dateTemp = new Date(date);
        const isValidDate = !isNaN(dateTemp.getTime());
        
        return isValidDate;
    }

    public isFormatMatched(value, format: string = null): boolean {
        return moment(value, 'MM/DD/yyyy', true).isValid();
    }

    public formatDate(value, format: string = null): string {
        if (format) {
            return moment(value, 'YYYYMMDDTHHmmss').format(format);
        }
        return moment(value, 'YYYYMMDDTHHmmss').format('yyyy-MM-DD');
    }

    public toDate(value): Date {
        if (!value) {
            return null;
        }
        return moment(value).toDate();
    }
}